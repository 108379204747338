<template>
	<div class="tab-ancien" id='report-ancien'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset :legend="$t('marche_de_lancien')" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="part-title">{{ $t('estimation_de_lancien_a_ladresse') }} </div>
					<div class="part-content">
						<div class="row">
							<div class="col full">
								<div class="" v-if="app.loyer_yanport.label">								
									<div class="header-item is-dark-bordered-12">
										<div class="item-inner">
											<div class="snacks" style="padding-bottom: 20px;">
												<Chip :label="DonneLibelleReference('adresse')" icon="pi pi-th-large" class="mr-2 custom-chip" />
												<Chip :label="DonneLibelleReference('forme')" icon="pi pi-building" class="mr-2 custom-chip" />
												<Chip :label="DonneLibelleReference('typologie')" icon="pi pi-th-large" class="mr-2 custom-chip" />
												<Chip :label="DonneLibelleReference('etat')" icon="pi pi-tag" class="mr-2 custom-chip" />
												<Chip :label="DonneLibelleReference('surface')" icon="pi pi-stop" class="mr-2 custom-chip" />
												<Chip :label="DonneLibelleReference('parking')" icon="pi pi-car" class="mr-2 custom-chip" />
											</div>
										</div>
									</div>
									<div style="display: flex;" class="grid">
										<div class="px-3 py-1 col-4" style="padding-right:0px !important">
											<div class="">
												<div class="">
													<div class="surface-card shadow-2 p-3 border-round">
														<div class="flex justify-content-between mb-3">
															<div>
																<span class="block text-500 font-medium mb-3">{{ $t('prix_au_m²') }}</span>
																<div class="text-900 font-medium text-xl">{{DonneValeurPrix(app.cityscan.adresse.pm2.toFixed(0)) }}</div>
															</div>
															<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-euro text-orange-500 text-xl"></i>
															</div>
														</div>
														<p v-if="app.cityscan.adresse.nb_biens" class="text-500 font-medium"><span><b>{{app.cityscan.adresse.nb_biens}}</b></span>
															<span class="text-500"> {{ $t('transaction') }}</span><span class="text-500" v-if="app.cityscan.adresse.nb_biens>1">s</span>*
														</p>
														<span v-else class="text-500">{{ $t('aucune_transaction_na_ete_communiquee') }}</span>
													</div>
												</div>
											</div>
										</div>
										<div class="px-3 py-1 col-4" style="padding-right:0px !important">
											<div class="">
												<div class="">
													<div class="surface-card shadow-2 p-3 border-round">
														<div class="flex justify-content-between mb-3">
															<div>
																<span class="block text-500 font-medium mb-3">{{ $t('estimation_basse') }}</span>
																<div class="text-900 font-medium text-xl">{{ DonneValeurPrix((app.cityscan.adresse.priceLow/parseFloat(app.cityscan.realty.area)).toFixed(0)) }}/m²</div>
															</div>
															<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-window-minimize text-green-500 text-xl"></i>
															</div>
														</div>
														<p class="text-500 font-medium"><span><b>{{ DonneValeurPrix(app.cityscan.adresse.priceLow.toFixed(0)) }}</b> {{ $t('prix_unitaire') }}</span></p>												
													</div>
												</div>
											</div>
										</div>
										<div class="px-3 py-1 col-4" style="padding-right:0px !important">
											<div class="">
												<div class="">
													<div class="surface-card shadow-2 p-3 border-round">
														<div class="flex justify-content-between mb-3">
															<div>
																<span class="block text-500 font-medium mb-3">{{ $t('estimation_haute') }}</span>
																<div class="text-900 font-medium text-xl">{{ DonneValeurPrix((app.cityscan.adresse.priceHigh/parseFloat(app.cityscan.realty.area)).toFixed(0)) }}/m²</div>
															</div>
															<div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
																<i class="pi pi-window-maximize text-red-500 text-xl"></i>
															</div>
														</div>
														<p class="text-500 font-medium"><span><b>{{ DonneValeurPrix(app.cityscan.adresse.priceHigh.toFixed(0)) }}</b> {{ $t('prix_unitaire') }}</span></p>												
													</div>
												</div>
											</div>
										</div>
									</div>
									<br/>
									<div class="part-title">{{ $t('marche_de_lancien') }}</div>
									<div style="display: flex;" class="grid">
										<div class="col-4">
											<div class="surface-card shadow-2 p-3 border-round">
												<div class="flex p-3 flex-column md:flex-row">
													<div class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
														<div class="flex align-items-center mb-3">
															<span class="text-500 font-medium">{{ $t('depuis_1_an') }}</span>
														</div>
														<div class="flex align-items-center">
															<i class="pi pi-arrow-up text-green-500 text-xl mr-2" v-if="unan>0"></i>
															<i class="pi pi-arrow-down text-pink-500 text-xl mr-2" v-if="unan<0"></i>
															<span class="text-green-500 font-medium " v-if="unan>0">+{{unan.toFixed(2)}} %</span>
															<span class="text-pink-500 font-medium " v-if="unan<0">{{unan.toFixed(2)}} %</span>
														</div>
													</div>
													<div class="border-bottom-1 md:border-bottom-none surface-border flex-auto p-3">
														<div class="flex align-items-center mb-3">
															<span class="text-500 font-medium">{{ $t('depuis_2_ans') }}</span>
														</div>
														<div class="flex align-items-center">
															<i class="pi pi-arrow-up text-green-500 text-xl mr-2" v-if="deuxans>0"></i>
															<i class="pi pi-arrow-down text-pink-500 text-xl mr-2" v-if="deuxans<0"></i>
															<span class="text-green-500 font-medium " v-if="deuxans>0">+{{deuxans.toFixed(2)}} %</span>
															<span class="text-pink-500 font-medium " v-if="deuxans<0">{{deuxans.toFixed(2)}} %</span>
														</div>
													</div>											
												</div>
											</div>
										</div>
										<div class="col-8">
											<div class="surface-card shadow-2 p-3 border-round">
												<div>
													<div class="chart-container">
														<highcharts :options="GraphPrixAncien" id='GraphPrixAncien'></highcharts>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import { Chart } from 'highcharts-vue'
import Fieldset from 'primevue/fieldset';
import Chip from 'primevue/chip';

export default {
	name: 'TabAncien',
	data: function() {
		return {
			activeSection2 : '',
			tab: 'tvap',
			unan: 0,
			deuxans: 0,
			GraphPrixAncien: {
				credits: {
					text: 'Source : Cityscan via Efocus',
					href: false,
					enabled: true
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: "#0bb985",
						name: "Evolution des prix de l'ancien",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					}					
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			reference : {
				name:'Logement T3', 
				typeId:2, 
				rooms: 3, 
				buildingConstruction: 7, 
				condition: 3, 
				parkingOpen: 1, 
				area: 60
			},
			fu_visible: false,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		
	}),
	
	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},

		DonneLibelleReference: function(filter) {
			switch (filter) {
				case 'adresse':
					return this.app.adr_init.name + ' ,' + this.app.adr_init.postcode + ' ,' + this.app.adr_init.city
				case 'typologie':
				return 'Logement T' + this.app.cityscan.realty.rooms
				case 'forme':
				if (this.app.cityscan.realty.typeId==1) {
					return 'Maison'
				} else {
					return 'Appartement'
				}
				case 'etat':
				switch (this.app.cityscan.realty.condition) {
					case 1:
					return 'Refait à neuf'
					case 2:
					return 'Rafraîchi'
					case 3:
					return 'Standard'
					case 4:
					return 'A rafraîchir'
					default:
					return 'A refaire'
				}
				case 'parking':
				if (this.app.cityscan.realty.parkingOpen==1) {
					return 'Parking inclus'
				} else {
					return 'Sans parking'
				}
				case 'surface':
				return this.app.cityscan.realty.area + "m²"
				default:
				break;
			}
		},
		
		DonneValeurPrix: function(val) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0) {
				return "-"
			} else {
				return euro.format(val)
			}
		},

		maj_graph: function() {
			let t = []
			let l = []
			let min = 0
			let max = 0
			for (let index = this.app.cityscan.adresse.priceTrends.length-1; index >= 0; index--) {
				if (min==0) min = this.app.cityscan.adresse.priceTrends[index].price
				if (max==0) max = this.app.cityscan.adresse.priceTrends[index].price
				if (this.app.cityscan.adresse.priceTrends[index].price < min) min = this.app.cityscan.adresse.priceTrends[index].price
				if (this.app.cityscan.adresse.priceTrends[index].price > max) max = this.app.cityscan.adresse.priceTrends[index].price
				t.push(this.app.cityscan.adresse.priceTrends[index].price)
				l.push(this.app.cityscan.adresse.priceTrends[index].date.slice(0, 7))
			}

			this.GraphPrixAncien = {
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: "#0bb985",
						name: "Evolution des prix de l'ancien",
						data: t,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, '#0bb985'],
								[1, '#0bb985']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					}					
				],
				xAxis: {
					type : 'category',
					categories : l,
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					min: min, 
					max: max,
					labels: {
						formatter: function () {
							return this.value + " €";
						},
					},
				},
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4,
					formatter: function () {
						return this.y + " €";
					},
				},
			},
			this.unan = ((this.app.cityscan.adresse.pm2_0-this.app.cityscan.adresse.pm2_12)/this.app.cityscan.adresse.pm2_12)*100
			this.deuxans = ((this.app.cityscan.adresse.pm2_0-this.app.cityscan.adresse.pm2_24)/this.app.cityscan.adresse.pm2_24)*100
		},
	},

	mounted: function() {
		this.reference.name						= this.app.cityscan.realty.name
		this.reference.typeId					= this.app.cityscan.realty.typeId
		if (this.reference.typeId != 1 && this.reference.typeId !=2) {
			this.reference.typeId = 2
		}
		this.reference.rooms					= this.app.cityscan.realty.rooms
		this.reference.buildingConstruction		= this.app.cityscan.realty.buildingConstruction
		this.reference.condition				= this.app.cityscan.realty.condition
		this.reference.parkingOpen				= this.app.cityscan.realty.parkingOpen
		this.reference.area						= this.app.cityscan.realty.area	
		let that = this
		that.maj_graph()
	},
	
	components: {  highcharts: Chart , Fieldset, Chip }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0

.snacks 
	display flex
	flex-wrap wrap  
	.snack 
		margin 0 4px 8px
	.snack
		display inline-block
		background var(--fade-grey-light-2)
		height 30px
		width auto
		line-height 30px
		padding-right 10px
		padding-left 10px
		border-radius 500px
		border 1px solid var(--fade-grey-dark-3)
		transition all 0.3s
		&:hover 
			box-shadow var(--light-box-shadow)
		&.is-white
			background var(--white)
		&.is-small
			height 30px
.snack-media
	height 32px
	width 32px
	margin-right 4px
	&.is-icon
		height 30px
		width 30px
		svg
			height 15px
			width 15px
		img
			height 30px
			width 30px
.snack-text
	font-size 0.9rem
.snack-action
	top -9px
	margin 0 10px 0 6px
.snack-media
	position relative
	top -1px
	height 40px
	width 40px
	display inline-block
	margin-right 6px
	.snack-icon 
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		svg 
			height 18px
			width 18px
			stroke-width 1.6px
			color var(--light-text)
			vertical-align 0 !important
			transform rotate(0) translate(-50%, -50%) !important
		img 
			display inline-block
			height 38px
			width 38px
			border-radius var(--radius-rounded)
	.snack-text 
		display inline-block
		position relative
		color var(--dark-text)
	.snack-action 
		position relative
		top -14px
		display inline-block
		margin 0 16px 0 10px
		cursor pointer
		svg 
			height 14px
			width 14px
			color var(--light-text)
.snack.is-small .snack-media.is-icon .fas, .snack.is-small .snack-media.is-icon .far, .snack.is-small .snack-media.is-icon .fad, .snack.is-small .snack-media.is-icon .fal, .snack.is-small .snack-media.is-icon .fab 
	font-size 13px
.snack.is-small .snack-media.is-icon .lnil, .snack.is-small .snack-media.is-icon .lnir 
	font-size 16px
.snack .snack-media.is-icon.is-solid .fas, .snack .snack-media.is-icon.is-solid .far, .snack .snack-media.is-icon.is-solid .fad, .snack .snack-media.is-icon.is-solid .fal, .snack .snack-media.is-icon.is-solid .fab, .snack .snack-media.is-icon.is-solid .lnil, .snack .snack-media.is-icon.is-solid .lnir 
	color var(--white) !important
.snack .snack-media.is-icon.is-primary .fas, .snack .snack-media.is-icon.is-primary .far, .snack .snack-media.is-icon.is-primary .fad, .snack .snack-media.is-icon.is-primary .fal, .snack .snack-media.is-icon.is-primary .fab, .snack .snack-media.is-icon.is-primary .lnil, .snack .snack-media.is-icon.is-primary .lnir 
	color var(--primary)
.snack .snack-media.is-icon.is-success .fas, .snack .snack-media.is-icon.is-success .far, .snack .snack-media.is-icon.is-success .fad, .snack .snack-media.is-icon.is-success .fal, .snack .snack-media.is-icon.is-success .fab, .snack .snack-media.is-icon.is-success .lnil, .snack .snack-media.is-icon.is-success .lnir 
	color var(--success)
.snack .snack-media.is-icon.is-info .fas, .snack .snack-media.is-icon.is-info .far, .snack .snack-media.is-icon.is-info .fad, .snack .snack-media.is-icon.is-info .fal, .snack .snack-media.is-icon.is-info .fab, .snack .snack-media.is-icon.is-info .lnil, .snack .snack-media.is-icon.is-info .lnir 
	color var(--info)
.snack .snack-media.is-icon.is-warning .fas, .snack .snack-media.is-icon.is-warning .far, .snack .snack-media.is-icon.is-warning .fad, .snack .snack-media.is-icon.is-warning .fal, .snack .snack-media.is-icon.is-warning .fab, .snack .snack-media.is-icon.is-warning .lnil, .snack .snack-media.is-icon.is-warning .lnir 
	color var(--warning)
.snack .snack-media.is-icon.is-danger .fas, .snack .snack-media.is-icon.is-danger .far, .snack .snack-media.is-icon.is-danger .fad, .snack .snack-media.is-icon.is-danger .fal, .snack .snack-media.is-icon.is-danger .fab, .snack .snack-media.is-icon.is-danger .lnil, .snack .snack-media.is-icon.is-danger .lnir 
	color var(--danger)
.snack .snack-media.is-icon .fas, .snack .snack-media.is-icon .far, .snack .snack-media.is-icon .fad, .snack .snack-media.is-icon .fal, .snack .snack-media.is-icon .fab 
	font-size 15px
	color var(--light-text)
.snack .snack-media.is-icon .lnil, .snack .snack-media.is-icon .lnir 
	font-size 18px
	color var(--light-text)
.is-dark 
.snack 
	background var(--dark-sidebar-light-2)
	border-color var(--dark-sidebar-light-4)
.snack-media 
	&.is-icon 
		&:not(.is-solid) 
			background var(--dark-sidebar-light-4)
	&.is-primary 
	&:not(.is-solid) 
		border-color var(--primary)
		svg 
			color var(--primary)
	&.is-solid 
		background var(--primary)
		border-color var(--primary)
.snack-text 
	color var(--dark-dark-text)
.is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fas, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .far, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fad, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fab, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fal, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnil, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnir 
	color var(--primary)

	
.dashboard-tile {
	width: 20%;
	min-width: 180px;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
	margin-right: 10px;
}
.dashboard-tile2 {
	padding: 10px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile .tile-head {
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.dashboard-tile .tile-body {
	font-size: 2rem;
	padding: 4px 0 8px;
	text-align: center
}
.dashboard-tile .tile-foot span:first-child {
	font-weight: 500;
}

.text-h-green {
	color: var(--green);
	font-size: 30px !important;	
}

.text-h-red {
	color: var(--red);
	font-size: 30px !important;
}

.text-h-p {
	color: #6c21f9;
	font-size: 24px;
}

.dashboard-tile .tile-foot span:nth-child(2) {
	color: var(--light-text);
	font-size: 0.9rem;
}

.is-4 {
	flex: none;
	width: 25%;
	padding: 0.75rem;
}
.is-2 {
	width: 50%;
}

.le {
	color: var(--light-text);
	font-size: 0.9rem;
	text-align: right;
	position: absolute;
	right: 0px;
}


.columns {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.columns:last-child {
	margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
	justify-content: center;
}
.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.columns.is-gapless > .column {
	margin: 0;
	padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
	margin-bottom: 0;
}
.columns.is-mobile {
	display: flex;
}
.columns.is-multiline {
	flex-wrap: wrap;
}
.columns.is-vcentered {
	align-items: center;
}
.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}

.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.chart-container {
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}
</style>
