<template>
	<transition name="pane-adresse-element">
		<div class="panecontent" v-bar>
			<div style="height:auto !important">
				
				<div class="unmodule_detail_titre">
					<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">Général</span>
							<span> > </span>
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: retour })">{{propObj.titre}}</span>
							<span> > </span>
							<span><b>{{titre}}</b></span>
						</div>

					<div class="noteTitre" :class="propObj.note">{{propObj.note}}</div>
				</div>
				<div class="programs-list">
					<div class="programs-list-content" v-bar>
						<div class="content" ref="scrollable_programs">
							<ul class="accordion-menu">
								
								<li class="unmodule_sous1">
									<div style="top:5px;"><b>{{propObj.nb}} {{propObj.temps}}</b></div>				
								</li>
								<li class="unmodule_sous1">
									<span style="top:5px;">{{ $t('afficher_lisochrone') }} {{propObj.temps}}</span>
									<label for="contour_iso_chk" class="checkbox" :class="{ checked: app.contour_iso }" style="right: 18px;top:10px">
										<input type="checkbox" id="contour_iso_chk" name="contour_iso_chk" :checked="app.contour_iso" @click.stop="setContourParam({duration: propObj.duration, transportType: propObj.transportType});setContour(!app.contour_iso)">
										<span class="chk"><icon file="check" :size="16" /></span>
										<span class="text"></span>
									</label>
									
								</li>
								<div v-if="propObj.nb">
									<li style="padding-top:20px;padding-left:16px;padding-bottom:16px;border: solid #eaeaea;border-width: 0 0 1px;" v-for="(el, index) in propObj.factors" v-bind:key="index">
										<div v-if="el.name" style="font-size:16px;padding-bottom:5px">{{el.name}}</div>                    
										<div v-else style="font-size:16px;padding-bottom:5px">{{titre}}</div>                    
										<div v-if="(detail=='transport' && el.miscellaneous.routes)" style="line-height:24px;font-size: .813rem;color: #707070;">{{el.miscellaneous.routes.length}} {{ $t('ligne') }}<span v-if="(el.miscellaneous.routes.length>1 && el.miscellaneous.routes)">s</span>
											<div v-for="uneligne in el.miscellaneous.routes" v-bind:key="uneligne.route_id" class="route" style="color: rgb(255, 255, 255);" :style="getstyle(uneligne.route_color)">{{uneligne.route_short_name}}</div>
										</div>
										<div v-if="(detail=='education') && el.miscellaneous && el.miscellaneous.sector" style="line-height:24px;font-size: .713rem;color: #707070;">{{el.miscellaneous.sector}}					
											<span v-for="n in getSuccess(el)" v-bind:key="n"><icon file="yellowstar" :size="12" /></span>
										</div>
										<div v-if="(detail=='commerce' || detail=='loisir') && el.miscellaneous && el.miscellaneous.cityscan_label" style="line-height:24px;font-size: .713rem;color: #707070;">{{el.miscellaneous.cityscan_label}}					
										</div>
									</li>
								</div>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaneAdresseElement',
	data: function() {
		return {
            
		}
	},
    props: {
        propObj: { type: Object, required: false},
        sclass: {
			default: ''
		},
        sstyle: {
			default: ''
		},
        titre: {
			default: ''
		},
        detail: {
			default: true
		},
        retour: {
			default: ''
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
		setContourParam: function(el) {
			this.$store.dispatch('set_contour_iso_param', el)
		},
		
		setContour: function(el) {
			this.$store.dispatch('set_contour_iso', { contour: el })
			if (!el) {
				this.$store.dispatch('set_contour_iso_param', {transportType: false, duration: this.app.cityscan.isochrone.duration})
			}
		},

		getSuccess: function(el) {
			let e = 0
			if (el.miscellaneous.success_indicators) {
				e = 4
				let n = parseFloat(el.miscellaneous.success_indicators.mention_rates)
				if (n < 50) {
					e = 0
				}
				if (n < 60) {
					e = 1
				}
				if (n < 70) {
					e = 2
				}
				if (n < 80) {
					e = 3
				}
			}
			return e
		},
		
        getstyle: function(color) {
            return "background-color: #" + color
        },
	},

	mounted: function() {

	},

	components: { }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-element-enter
	transform translateX(100%)
.pane-adresse-element-leave-to
	transform translateX(-100%)

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc

.poweredcityscan
	position absolute
	left 155px
	top 18px
	width 180px
	height 60px
	vertical-align middle

.pan-adresse-element
	position absolute
	top 0px
	float left
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.route
	margin-left 10px
	padding 0 3px
	height 24px
	width 24px
	font-size .75rem
	font-weight 700
	display -webkit-inline-box
	display -webkit-inline-flex
	display inline-flex
	-webkit-flex-shrink 0
	flex-shrink 0
	-webkit-box-align center
	-webkit-align-items center
	align-items center
	-webkit-box-pack center
	-webkit-justify-content center
	justify-content center

	
.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
