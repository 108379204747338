<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-teal-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-euro text-teal-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('évolution') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('des_prix_parking_inclus') }}</p>
				</div>
				<div class="ml-auto">
					<div>
						<Button v-if="app.tab == 'trimestres'" icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'prix_pi_1'})" />
						<Button v-else icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'prix_pi_2'})" />
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			
			<div v-if="app.tab == 'trimestres'" class="graph">
				<highcharts :options="chartOptions_trim" id='prix_pi_1'></highcharts>
			</div>
			<div v-if="app.tab == 'mois'" class="graph">
				<highcharts :options="chartOptions_mois" id='prix_pi_2'></highcharts>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import Button from 'primevue/button';

export default {
	name: 'GraphEvolutionPrixPi',

	data: function() {
		return {
			items: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'prix_pi_1'});} },
			],
			items2: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'prix_pi_2'});} },
			],
			chartOptions_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},					
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_mois: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},					
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		maj_mois: function() {
            let min = 0
			let max = 0
			let nboffre_pi = [
				this.app.livedata.prix_avec_stat.prix_offre.tableau[0], 
				this.app.livedata.prix_avec_stat.prix_offre.tableau[1], 
				this.app.livedata.prix_avec_stat.prix_offre.tableau[2], 
				this.app.livedata.prix_avec_stat.prix_offre.tableau[3], 
				this.app.livedata.prix_avec_stat.prix_offre.tableau[4],
				this.app.livedata.prix_avec_stat.prix_offre.tableau[5],
				this.app.livedata.prix_avec_stat.prix_offre.tableau[6],
				this.app.livedata.prix_avec_stat.prix_offre.tableau[7]
			]
			let nboffre_hp = [
				this.app.livedata.prix_hors_stat.prix_offre.tableau[0], 
				this.app.livedata.prix_hors_stat.prix_offre.tableau[1], 
				this.app.livedata.prix_hors_stat.prix_offre.tableau[2], 
				this.app.livedata.prix_hors_stat.prix_offre.tableau[3], 
				this.app.livedata.prix_hors_stat.prix_offre.tableau[4],
				this.app.livedata.prix_hors_stat.prix_offre.tableau[5],
				this.app.livedata.prix_hors_stat.prix_offre.tableau[6],
				this.app.livedata.prix_hors_stat.prix_offre.tableau[7]
			]
			let nbvente_pi = [
				this.app.livedata.prix_avec_stat.prix_vente.tableau[0], 
				this.app.livedata.prix_avec_stat.prix_vente.tableau[1], 
				this.app.livedata.prix_avec_stat.prix_vente.tableau[2], 
				this.app.livedata.prix_avec_stat.prix_vente.tableau[3], 
				this.app.livedata.prix_avec_stat.prix_vente.tableau[4],
				this.app.livedata.prix_avec_stat.prix_vente.tableau[5],
				this.app.livedata.prix_avec_stat.prix_vente.tableau[6],
				this.app.livedata.prix_avec_stat.prix_vente.tableau[7]
			]
			let nbvente_hp = [
				this.app.livedata.prix_hors_stat.prix_vente.tableau[0], 
				this.app.livedata.prix_hors_stat.prix_vente.tableau[1], 
				this.app.livedata.prix_hors_stat.prix_vente.tableau[2], 
				this.app.livedata.prix_hors_stat.prix_vente.tableau[3], 
				this.app.livedata.prix_hors_stat.prix_vente.tableau[4],
				this.app.livedata.prix_hors_stat.prix_vente.tableau[5],
				this.app.livedata.prix_hors_stat.prix_vente.tableau[6],
				this.app.livedata.prix_hors_stat.prix_vente.tableau[7]
			]

			this.chartOptions_mois.series[0].data = nboffre_pi
			this.chartOptions_mois.series[1].data = nbvente_pi
			this.chartOptions_mois.xAxis.categories= [
				this.app.livedata.prix_hors_stat.intitule_colonne[0], 
				this.app.livedata.prix_hors_stat.intitule_colonne[1], 
				this.app.livedata.prix_hors_stat.intitule_colonne[2], 
				this.app.livedata.prix_hors_stat.intitule_colonne[3], 
				this.app.livedata.prix_hors_stat.intitule_colonne[4],
				this.app.livedata.prix_hors_stat.intitule_colonne[5],
				this.app.livedata.prix_hors_stat.intitule_colonne[6],
				this.app.livedata.prix_hors_stat.intitule_colonne[7]
			]

			for (let j=0; j<8; j++) {
				if (min == 0 || min > nboffre_pi[j]) {
					min = nboffre_pi[j]
				}
				if (min == 0 || min > nboffre_hp[j]) {
					min = nboffre_hp[j]
				}
				if (min == 0 || min > nbvente_pi[j]) {
					min = nbvente_pi[j]
				}
				if (min == 0 || min > nbvente_hp[j]) {
					min = nbvente_hp[j]
				}

				if (max == 0 || max < nboffre_pi[j]) {
					max = nboffre_pi[j]
				}
				if (max == 0 || max < nboffre_hp[j]) {
					max = nboffre_hp[j]
				}
				if (max == 0 || max < nbvente_pi[j]) {
					max = nbvente_pi[j]
				}
				if (max == 0 || max < nbvente_hp[j]) {
					max = nbvente_hp[j]
				}
			}

			min = min - 100
            if (min < 0 ) {min = 0}
            max = max + 100
            this.chartOptions_mois.yAxis.min = min
            this.chartOptions_mois.yAxis.max = max
		},

		maj_trimestre: function() {
            let min = 0
			let max = 0
			let nboffre_pi = [this.app.livedata.prix_avec_stat_2.prix_offre.tableau[0], this.app.livedata.prix_avec_stat_2.prix_offre.tableau[1], this.app.livedata.prix_avec_stat_2.prix_offre.tableau[2]]
			let nboffre_hp = [this.app.livedata.prix_hors_stat_2.prix_offre.tableau[0], this.app.livedata.prix_hors_stat_2.prix_offre.tableau[1], this.app.livedata.prix_hors_stat_2.prix_offre.tableau[2]]
			let nbvente_pi = [this.app.livedata.prix_avec_stat_2.prix_vente.tableau[0], this.app.livedata.prix_avec_stat_2.prix_vente.tableau[1], this.app.livedata.prix_avec_stat_2.prix_vente.tableau[2]]
			let nbvente_hp = [this.app.livedata.prix_hors_stat_2.prix_vente.tableau[0], this.app.livedata.prix_hors_stat_2.prix_vente.tableau[1], this.app.livedata.prix_hors_stat_2.prix_vente.tableau[2]]

			this.chartOptions_trim.series[0].data = nboffre_pi
			this.chartOptions_trim.series[1].data = nbvente_pi
			this.chartOptions_trim.xAxis.categories= [this.app.livedata.prix_hors_stat_2.intitule_colonne[0], this.app.livedata.prix_hors_stat_2.intitule_colonne[1], this.app.livedata.prix_hors_stat_2.intitule_colonne[2]]

			for (let j=0; j<3; j++) {
				if (min == 0 || min > nboffre_pi[j]) {
					min = nboffre_pi[j]
				}
				if (min == 0 || min > nboffre_hp[j]) {
					min = nboffre_hp[j]
				}
				if (min == 0 || min > nbvente_pi[j]) {
					min = nbvente_pi[j]
				}
				if (min == 0 || min > nbvente_hp[j]) {
					min = nbvente_hp[j]
				}

				if (max == 0 || max < nboffre_pi[j]) {
					max = nboffre_pi[j]
				}
				if (max == 0 || max < nboffre_hp[j]) {
					max = nboffre_hp[j]
				}
				if (max == 0 || max < nbvente_pi[j]) {
					max = nbvente_pi[j]
				}
				if (max == 0 || max < nbvente_hp[j]) {
					max = nbvente_hp[j]
				}
			}

			min = min - 100
            if (min < 0 ) {min = 0}
            max = max + 100
            this.chartOptions_trim.yAxis.min = min
            this.chartOptions_trim.yAxis.max = max
		}
	},

	mounted: function() {
		this.maj_trimestre()
		this.maj_mois()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj_trimestre()
				this.maj_mois()
			}
		},
	},
	
	components: { highcharts: Chart, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.stat-box
	.stat-box-content
		padding 0 16px

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
