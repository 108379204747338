<template>
	<transition name="pane-ancien-mutation">
		<div class="pan-ancien-mutation unmodule" :id=mutation.propertyId>
			<div class="cs-widget-collection-card">
				<div class="cs-widget-transaction-card ng-star-inserted">
					<div>
						<table style="width:100%;height:auto;">
							<th style="display:none"></th>
							<tr style="height:5px;"><td colspan=4></td></tr>
							<tr style="height:25px;">
								<td style="width:24px;"><div class="icone_parcelle"><span class="cs-tile-icon cs-icon-thematic icon-1630-02 ng-star-inserted"></span></div></td>
								<td>{{mutation.propertyId}}</td>
								<td>{{getTransactionType(mutation.transactionType)}} le {{mutation.baseDate.substring(0,10)}}</td>
								<td style="text-align:right;font-weight:500;padding-right:5px;">{{getPrice(mutation.price)}}</td>
							</tr>
							<tr style="height:25px">
								<td colspan=3>&nbsp;</td>
								<td style="text-align:right;font-weight:normal;padding-right:5px;">{{getPrice(mutation.pricePerSquareMeter)}}/m²</td>
							</tr>
						</table>
					</div>
					<div class="cs-collection-card-content">
						<div class="cs-widget-dvf-card ng-star-inserted">
							<div class="cs-collection-data-tag" :class="GetClass(mutation.propertyType)"> {{GetLabel(mutation.propertyType)}}</div>
							<div class="cs-collection-data-row">
								<span class="cs-widget-dvf-surface-details ng-star-inserted">{{mutation.area}} m², {{mutation.rooms}} {{ $t('pieces') }}</span>&nbsp;
							</div>
							<div class="cs-collection-data-row flex" style="justify-content: space-between;"><div>{{mutation.fullAddress}}</div><div><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + mutation.location.coordinates[0] + ',' + mutation.location.coordinates[1]"><img alt="streetview" :title="$t('voir_sur_google_street_view')" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;</a></div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseFromWK } from 'wkt-parser-helper'

export default {
	name: 'PaneAncienMutation',
	data: function() {
		return {
			markerSub: false,
		}
	},

	props: {
        mutation: {
			default: false
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
        
		hover_parcelle: function(parcelle) {
            this.$store.select_parcelle.clearLayers()
            let geojson = parseFromWK(parcelle.parcels[0].geometry.wktGeometry)
            let tab = geojson.coordinates
            for (let n=0; n < tab[0].length; n++) {
                tab[0][n].reverse()
            }
            this.markerSub = L.polygon(tab, {color: '#000000'})
            this.markerSub.addTo(this.$store.select_parcelle)
		},

		unhover_parcelle: function() {
            this.$store.select_parcelle.clearLayers()
        },

		getTransactionType: function(type) {
			switch (type) {
				case 'sale':
					return 'Vendu'
				case 'under_construction_sale':
					return 'VEFA'
				default:
					break;
			}
		},

		GetClass: function(type) {
			switch (type) {
				case 'dependency':
					return 'dependance'
				case 'house':
					return 'maison'
				case 'apartment':
					return 'appartement'
				case 'land':
					return 'terrain'
				case 'industrial_commercial_premise':
					return 'local'
				default:
					break;
			}
		},

		GetLabel: function(type) {
			switch (type) {
				case 'dependency':
					return 'Dépendance'
				case 'house':
					return 'Maison'
				case 'apartment':
					return 'Appartement'
				case 'land':
					return 'Terrain'
				case 'industrial_commercial_premise':
					return 'Local industriel. commercial ou assimilé'
				default:
					break;
			}
		},

		getPrice: function(price) {
            const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (price==0) {
				return "-"
			} else {
                return euro.format(price)
			}
		},
	},
	
	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pan-ancien-mutation
	width PaneLeftSize

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.icone_parcelle
	font-size 1.2rem
	position absolute
	top 2px

.parcelle
	background #c72a2a
	height 24px
	width 150px
	padding-left 5px
	padding-right  5px
	padding-top 5px
	padding-bottom 5px
	border-radius 3px
	border 1px
	color #fff
	margin-right 5px
	margin-top 5px
	-webkit-transition .2s ease-in-out
	transition .2s ease-in-out

.unmodule
	height auto !important
	width PaneLeftSize !important

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
