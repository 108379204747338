<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="progencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;">
				<div class="pan-titre-programme" id="pan-titre-programme">
					<div class="module_ret">
						<div class="btns fermer" title="Fermer"  @click="expand_program();$store.dispatch('set_help_prog', {aide:false})"><icon file="fermer" :size="16" /></div>
						<div class="btns aide" :title="$t('aide')" @click="$store.dispatch('set_help_prog', {aide:!app.aide_prog})"><icon file="help-circle" :size="24" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateProgImage(expanded_program.id);"><icon file="download" :size="24" />&nbsp;</div>
<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div v-if="expanded_program.pc" class="indice_confiance" :class="{ pc : true }">
							{{ $t('fiche_projet') }}
						</div>
						<div v-if="expanded_program.indice_confiance >= 7 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ haut : true }">
							Indice de confiance : données optimales
						</div>
						<div v-if="expanded_program.indice_confiance >= 5 && expanded_program.indice_confiance < 7 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ milieu: expanded_program.indice_confiance >= 5 && expanded_program.indice_confiance < 7 }">
							Indice de confiance : données augmentées
						</div>
						<div v-if="expanded_program.indice_confiance < 5 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ bas: expanded_program.indice_confiance < 5 }">
							Indice de confiance : données standard
						</div>
						<div v-if="expanded_program.avant_premiere" class="indice_confiance" :class="{ bas: expanded_program.indice_confiance < 5 }">
							Indice de confiance : données non communicable, avant-première
						</div>
					</div>
					<br/>
				</div>
				<div><br><br><br><br></div>
				<div class="program" id="ProgEx" :class="{ inactif: expanded_program.commercialisation == 'termine', selected: expanded_program.selected }" style="background-color: white;">
					<div class="program-main" style="height:285px;" v-if="!expanded_program.pc">
						<div class="image">
							<div style="width: 100%; display:flex; justify-content: center;">
								<ImagePreview v-if="expanded_program.url_photo!='-'" :src="expanded_program.url_photo" alt="Image" height="285" preview />
							</div>
							<div class="flex align-items-center" style="display:flex; flex-direction:right; bottom:10px;left:0px;position:absolute" >
								<div v-if="expanded_program.qeb" style="">
									<Chip :label="expanded_program.qeb" icon="pi" class="mr-2 qeb"/>
								</div>
							</div>
							<div class="flex align-items-center" style="display:flex; flex-direction:right; bottom:10px;right:0px;position:absolute" >
								<Chip v-if="expanded_program.tva_reduite_prix_m2_parking_sans" label="5.5%" icon="pi pi-flag" class="mr-2 custom-chip" />
								<Chip v-if="expanded_program.psla" :label="$t('psla')" icon="pi pi-tag" class="mr-2 custom-chip" />
								<Chip v-if="expanded_program.brs" :label="$t('brs')" icon="pi pi-tag" class="mr-2 custom-chip" />
								<div style="cursor:pointer" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebacte()" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">
									<Chip :label="$t('VEB')" icon="pi pi-tag" class="mr-2 vebacte" v-tooltip.bottom="$t('VEB actée, cliquez pour avoir plus d’info')"/>
								</div>
								<div style="cursor:pointer" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebresa()" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">
									<Chip :label="$t('VEB')" icon="pi pi-tag" class="mr-2 vebresa" v-tooltip.bottom="$t('VEB reservée, cliquez pour avoir plus d’info')"/>
								</div>
							</div>
						</div>
					</div>
					<div class="program-main" v-if="!expanded_program.pc">


						<div class="program-detail">
							<div class="program-detail-content" style="padding-top:0px !important">
								<div class="program-detail-info">									
									<div class="program-detail-info-content" style="padding:15px;">
										<div style="display: flex;justify-content: space-between;padding-bottom: 10px;">
											<div class="nom"><strong>{{ expanded_program.nom }}</strong></div>
											<div>{{ expanded_program.adresse }} {{ expanded_program.codepostal }} {{ expanded_program.ville }}</div>
										</div>
										<div style="display: flex;justify-content: space-between">
											<div>Promoteur(s) : {{ expanded_program.promoteur }}</div>
											<div>{{ expanded_program.stock_initial }} logements</div>
											<div v-if="expanded_program.forme_urbaine">{{ expanded_program.forme_urbaine.toUpperCase() }}</div>
											<div v-if="expanded_program.nombre_etage">{{ expanded_program.nombre_etage }} étage<span v-if="expanded_program.nombre_etage>1">s</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>




						<div class="program-detail-content">
							<!--
							<p class="name" style="text-align: center;"><b><span class="nom">{{ expanded_program.nom }}</span></b><span v-if="expanded_program.promoteur"> | {{ expanded_program.promoteur }}</span> | {{ expanded_program.adresse }} | {{ expanded_program.codepostal }} {{ expanded_program.ville }} 
								<span v-if="!expanded_program.avant_premiere&&!expanded_program.pc">| {{ expanded_program.stock_initial }} logements </span> <span v-if="expanded_program.forme_urbaine">| {{ expanded_program.forme_urbaine.toUpperCase() }}</span>
								<span v-if="expanded_program.nombre_etage">| {{ expanded_program.nombre_etage }} étage(s)</span>
							</p>
							-->
						</div>
						<div class="program-content">
							<br/>
							<p style="text-align:center;" v-if="expanded_program">
								<span><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;{{ $t('voir_sur_google_street_view') }}</a></span>
							</p>
						</div>
						<div class="program-content" style="display: flex; justify-content: center;">
							<div style="text-wrap: nowrap;width:90%;">
								<Timeline :value="events1" align="bottom" layout="horizontal">
									<template #marker="slotProps">
										<span>
											<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
											<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
										</span>
									</template>
									<template #opposite="slotProps">
										<small class="p-text-secondary">{{slotProps.item.date}}</small>
									</template>
									<template #content="slotProps">
										{{slotProps.item.status}}
									</template>
								</Timeline>
							</div>
						</div>
					</div>
					<div v-if="expanded_program && !expanded_program.avant_premiere && !expanded_program.pc">
						<div class="program-detail">
							<div class="program-detail-content" style="padding-top:0px !important">
								<div class="program-detail-info">
									<div class="program-maj">
											<span v-if="expanded_program.datemaj">{{ $t('donnees_a_jour_au') }} {{expanded_program.datemaj.substring(8,10)}}/{{expanded_program.datemaj.substring(5,7)}}/{{expanded_program.datemaj.substring(0, 4)}} {{ $t('publiees_le') }} {{expanded_program.date_maj_elk.substring(8,10)}}/{{expanded_program.date_maj_elk.substring(5,7)}}/{{expanded_program.date_maj_elk.substring(0, 4)}}</span>
											<span v-else-if="expanded_program.date_maj_elk">{{ $t('donnees_publiees_le') }} {{expanded_program.date_maj_elk.substring(8,10)}}/{{expanded_program.date_maj_elk.substring(5,7)}}/{{expanded_program.date_maj_elk.substring(0, 4)}}</span>
									</div>
									<br>
									<div class="program-detail-info-filters">
										<span v-if="app.efocus_type=='A'" class="filter first" @click="filter4 = '3m'" :class="{ active: filter4 == '3m' }">{{ $t('3_premiers_mois') }}</span>
										<span v-if="app.efocus_type=='A'" class="filter" @click="filter4 = '90'" :class="{ active: filter4 == '90' }">{{ $t('90%_de_commercialisation') }}</span>
										<span class="filter" @click="filter4 = 'global'" :class="{ active: filter4 == 'global' }">{{ $t('rythme_de_croisiere') }}</span>
										<span v-if="app.efocus_type=='A'" class="filter last" @click="filter4 = 'moyen'" :class="{ active: filter4 == 'moyen' }">{{ $t('global') }}</span>
									</div>
									<div class="program-detail-info-content">
										<table>
											<tr class="titre1">
												<th></th>
												<th>{{ $t('vte_/_mois') }}</th>
												<th>{{ $t('taux_ecoul.') }}</th>
												<th>{{ $t('mois_de_com.') }} </th>
											</tr>
											<tr v-show="filter4 == 'global'">
												<td>{{ $t('chiffres_cles') }}</td>
												<td v-if="expanded_program.rythme.croisiere.vtemois">{{expanded_program.rythme.croisiere.vtemois}}</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.croisiere.te">{{expanded_program.rythme.croisiere.te}} %</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.croisiere.duree">{{expanded_program.rythme.croisiere.duree}} {{ $t('mois') }}</td>
												<td v-else>-</td>
											</tr>
											<tr v-show="filter4 == '3m'">
												<td>{{ $t('chiffres_cles') }}</td>
												<td v-if="expanded_program.rythme.lancement.vtemois">{{expanded_program.rythme.lancement.vtemois}}</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.lancement.te">{{expanded_program.rythme.lancement.te}} %</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.lancement.duree">{{expanded_program.rythme.lancement.duree}} {{ $t('mois') }}</td>
												<td v-else>-</td>
											</tr>
											<tr v-show="filter4 == '90'">
												<td>{{ $t('chiffres_cles') }}</td>
												<td v-if="expanded_program.rythme.sans_fin_prog.vtemois">{{expanded_program.rythme.sans_fin_prog.vtemois}}</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.sans_fin_prog.te">{{expanded_program.rythme.sans_fin_prog.te}} %</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.sans_fin_prog.duree">{{expanded_program.rythme.sans_fin_prog.duree}} {{ $t('mois') }}</td>
												<td v-else>-</td>
											</tr>
											<tr v-show="filter4 == 'moyen'">
												<td>{{ $t('chiffres_cles') }}</td>
												<td v-if="expanded_program.rythme.total.vtemois">{{expanded_program.rythme.total.vtemois}}</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.total.te">{{expanded_program.rythme.total.te}} %</td>
												<td v-else>-</td>
												<td v-if="expanded_program.rythme.total.duree">{{expanded_program.rythme.total.duree}} {{ $t('mois') }}</td>
												<td v-else>-</td>
											</tr>
											
										</table>
									</div>
								</div>
								<div class="program-detail-info">
									<div class="program-detail-info-filters">
										<span class="filter first" @click="filter_svo = 's'" :class="{ active: filter_svo == 's' }">{{ $t('tous_logements') }}</span>
										<span class="filter" @click="filter_svo = 'v'" :class="{ active: filter_svo == 'v' }">{{ $t('des_ventes') }}</span>
										<span class="filter last" @click="filter_svo = 'o'" :class="{ active: filter_svo == 'o' }">{{ $t('a_loffre') }}</span>
									</div>
									
									<div class="program-detail-info-filters">
										<span v-show="expanded_program.tva_pleine_libre_prix_m2_parking_avec+expanded_program.tva_pleine_libre_prix_m2_parking_sans>0 && app.efocus_type!='B'" class="filter first" @click="filter3 = 'tva_pleine_libre'; tva = 'tva_pleine_libre'; maj_graph();" :class="{ active: tva == 'tva_pleine_libre', last : expanded_program.tva_pleine_aide_prix_m2_parking_avec+expanded_program.tva_pleine_aide_prix_m2_parking_sans+expanded_program.tva_reduite_prix_m2_parking_avec+expanded_program.tva_reduite_prix_m2_parking_sans==0 }">{{ $t('tva_pleine_libre') }}</span>
										<span v-show="expanded_program.tva_pleine_aide_prix_m2_parking_avec+expanded_program.tva_pleine_aide_prix_m2_parking_sans>0 && app.efocus_type!='B'" class="filter" @click="filter3 = 'tva_pleine_aide'; tva = 'tva_pleine_aide'; maj_graph();" :class="{ active: tva == 'tva_pleine_aide',first: expanded_program.tva_pleine_libre_prix_m2_parking_avec+expanded_program.tva_pleine_libre_prix_m2_parking_sans==0, last: expanded_program.tva_reduite_prix_m2_parking_avec+expanded_program.tva_reduite_prix_m2_parking_sans }">{{ $t('tva_pleine_aidee') }}</span>
										<span :title = "$t('les_prix_prennent_en_compte_tous_les_types_de_tva_reduite_(psla,_anru,_brs,_...)')" v-show="expanded_program.tva_reduite_prix_m2_parking_avec+expanded_program.tva_reduite_prix_m2_parking_sans>0" class="filter" @click="filter3 = 'tva_reduite'; tva = 'tva_reduite'; maj_graph();" :class="{ active: tva == 'tva_reduite',last: app.efocus_type!='B',first: expanded_program.tva_pleine_libre_prix_m2_parking_avec+expanded_program.tva_pleine_libre_prix_m2_parking_sans+expanded_program.tva_pleine_aide_prix_m2_parking_avec+expanded_program.tva_pleine_aide_prix_m2_parking_sans==0 }">TVA Réduite</span>
									</div>
									
									
									<div class="program-detail-info-filters">
										<span v-show="expanded_program.tva_pleine_libre_prix_m2_parking_avec + expanded_program.tva_pleine_aide_prix_m2_parking_avec + expanded_program.tva_reduite_prix_m2_parking_avec>0" class="filter first" @click="pkg = 'prix_ap'; maj_graph();" :class="{ active: pkg == 'prix_ap', last: expanded_program.tva_pleine_libre_prix_m2_parking_sans + expanded_program.tva_pleine_aide_prix_m2_parking_sans + expanded_program.tva_reduite_prix_m2_parking_sans==0 }">{{ $t('parking_inclus') }}</span>
										<span v-show="expanded_program.tva_pleine_libre_prix_m2_parking_sans + expanded_program.tva_pleine_aide_prix_m2_parking_sans + expanded_program.tva_reduite_prix_m2_parking_sans>0" class="filter last" @click="pkg = 'prix_sp'; maj_graph();" :class="{ active: pkg == 'prix_sp', first: expanded_program.tva_pleine_libre_prix_m2_parking_avec + expanded_program.tva_pleine_aide_prix_m2_parking_avec + expanded_program.tva_reduite_prix_m2_parking_avec==0 }">{{ $t('hors_parking') }}</span>
									</div>
									
									<div class="program-detail-info-content">
										<table style="font-size: 12px;">
											<th></th>
											<tr>
												<td>{{ $t('prix_du_programme') }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_pleine_libre_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_pleine_libre_hors_parking') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_pleine_aidee_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_pleine_aidee_hors_parking') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_reduite_parking_inclus') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 's'">{{ $t('tous_les_logements_en_tva_reduite_hors_parking') }}</td>
												
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_pleine_libre_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_pleine_libre_hors_parking') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_pleine_aidee_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_pleine_aidee_hors_parking') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_reduite_parking_inclus') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 'v'">{{ $t('tous_les_logements_vendus_en_tva_reduite_hors_parking') }}</td>
												
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_pleine_libre_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_pleine_libre_hors_parking') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_pleine_aidee_parking_inclus') }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_pleine_aidee_hors_parking') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_reduite_parking_inclus') }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 'o'">{{ $t('tous_les_logements_a_loffre_en_tva_reduite_hors_parking') }}</td>
												
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAP_LIBRE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAP_LIBRE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAP_AIDE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAP_AIDE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAR_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 's'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Stock_TVAR_SPK, 10, "/m²") }}</td>
												
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAP_LIBRE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAP_LIBRE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAP_AIDE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAP_AIDE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAR_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 'v'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Vente_TVAR_SPK, 10, "/m²") }}</td>
												
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_ap' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAP_LIBRE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_libre' && pkg == 'prix_sp' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAP_LIBRE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_ap' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAP_AIDE_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_pleine_aide' && pkg == 'prix_sp' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAP_AIDE_SPK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_ap' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAR_APK, 10, "/m²") }}</td>
												<td v-show="tva == 'tva_reduite' && pkg == 'prix_sp' && filter_svo == 'o'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.Prix_Offre_TVAR_SPK, 10, "/m²") }}</td>
												
												
												
												<td v-show="tva == 'tva_reduite' && expanded_program.brs">{{ $t('redevance_brs') }}</td>
												<td v-show="tva == 'tva_reduite' && expanded_program.brs" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.brs_redevance, 0.01, "/m²") }}</td>
											</tr>
											<tr>
												<td colspan="2">{{ $t('prix_unitaire_moyen_dun_stationnement') }}<span v-if="tva == 'tva_reduite'"> en TVA réduite</span><span v-else> en TVA pleine</span></td>
												<td v-if="tva != 'tva_reduite'" style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.PUM_Stat, 100, "") }}</td>
												<td v-else style="font-weight: bold;font-size: 18px;">{{ DonneValeurPrix(expanded_program.pum_stat_reduite, 100, "") }}</td>
											</tr>
										</table>
									</div>
									<div class="refreshprog" v-if="app.refresh_prog">
										<div class="infinite-loader"><div class="loader"><icon file="loader" :size="128" style="color:#CCCCCC;" /></div></div>
									</div>
									<div v-else>
										<div v-if="mode_aff == 'historique'" class="program-detail-info">
											<div class="program-detail-info-content">
												<div><highcharts :options="chartOptions_trim"></highcharts></div>
											</div>
										</div>
										
										<div v-if="mode_aff == 'granulaire' && app.efocus_type=='A'" class="program-detail-info">
											<div class="program-detail-info-filters">
												<span class="filter first" @click="filter2 = 'typologie'; compteur=5; suffixe='T'" :class="{ active: filter2 == 'typologie' }">{{ $t('typologie') }}</span>
												<span class="filter last" @click="filter2 = 'echelle'; compteur=12; suffixe='S'" :class="{ active: filter2 == 'echelle' }">{{ $t('echelle_residentielle') }}</span>
											</div>
											
											<DataTable :value="data1" class="p-datatable-sm text-sm" ref="dt" id="granulometrie" :rowClass="rowClass" showGridlines>
												<template #header>
													<div style="text-align: left">
														<div class="flex align-items-center">
															<div style="width:48px;height:48px;border-radius:10px">
															</div>
															<div>
															</div>
															<div class="ml-auto">
																<div class="speeddial-tooltip-demo">
																	<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
																</div>
															</div>
														</div>
													</div>
												</template>
												<ColumnGroup type="header">
													<Row>
														<Column header="" :rowspan="2" />
														<Column header="Volumes" :colspan="3" />
														<Column :header="$t('surfaces_en_m²')" :colspan="3" />
														<Column :header="$t('prix')" :colspan="4" />
													</Row>
													<Row>
														<Column field="code" header="Stock Ini."></Column>
														<Column field="name" :header="$t('ventes')"></Column>
														<Column field="category" header="Offres"></Column>
														<Column field="quantity" :header="$t('min')"></Column>
														<Column field="quantity" :header="$t('moy.')"></Column>
														<Column field="quantity" :header="$t('max')"></Column>
														<Column field="quantity" :header="$t('min')"></Column>
														<Column field="quantity" :header="$t('moy.')"></Column>
														<Column field="quantity" :header="$t('max')"></Column>
														<Column field="quantity" header="Moy. m²"></Column>
													</Row>
												</ColumnGroup>
												<Column field="id"></Column>
												<Column field="si"></Column>
												<Column field="vte"></Column>
												<Column field="off"></Column>
												<Column field="smin"></Column>
												<Column field="smoy"></Column>
												<Column field="smax"></Column>
												<Column field="pmin"></Column>
												<Column field="pmoy"></Column>
												<Column field="pmax"></Column>
												<Column field="pmmoy"></Column>
											</DataTable>

											<table id='tableau_typo' style='visibility: collapse;'>
												<tbody>
													<tr>
														<th>Id</th>
														<th>Stock initial</th>
														<th>Ventes</th>
														<th>Offre</th>
														<th>Surface min en m²</th>
														<th>Surface moy en m²</th>
														<th>Surface max en m²</th>
														<th>Prix unitaire min en €</th>
														<th>Prix unitaire moy en €</th>
														<th>Prix unitaire max en €</th>
														<th>Prix en €/m²</th>
													</tr>
													<tr v-for="prog in data1" :key="prog.id">
														<td>{{prog.id}}</td>
														<td>{{prog.si}}</td>
														<td>{{prog.vte}}</td>
														<td>{{prog.off}}</td>
														<td>{{prog.smin}}</td>
														<td>{{prog.smoy}}</td>
														<td>{{prog.smax}}</td>
														<td>{{prog.pmin_xls}}</td>
														<td>{{prog.pmoy_xls}}</td>
														<td>{{prog.pmax_xls}}</td>
														<td>{{prog.pmmoy_xls}}</td>
													</tr>
												</tbody>
											</table>





										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="expanded_program.pc">
						<div class="program-detail">
							<div class="program-detail-content">
								<div class="program-detail-info">
									<div class="program-detail-info-filters">
										<h1>{{ $t('les_evenements') }}</h1>
									</div>
									<br/>
									<Timeline :value="events2" align="bottom" layout="horizontal" class="customized-timeline">
										<template #marker="slotProps">
											<span>
												<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
												<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
											</span>
										</template>
										<template #opposite="slotProps">
											<small class="p-text-secondary">{{AfficheDate(slotProps.item.date)}}</small>
										</template>
										<template #content="slotProps">
											{{slotProps.item.status}}
										</template>
									</Timeline>
									
									<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
										<div class="flex align-items-start mb-5">
											<div class="ml-3" style="text-align: left;">
												<span class="block text-900 mb-1 text-xl font-medium">{{ $t('permis_de_construire') }}</span>
												<p class="text-600 mt-0 mb-0"></p>
											</div>
										</div>
										<ul class="list-none p-0 m-0">
											<li class="mb-5" v-if="expanded_program.nopc">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-hashtag mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ $t('numero_de_permis_de_construire') }}</span>
															</span>
															<span class="text-purple-600 font-medium">{{expanded_program.nopc}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-user mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900" v-if="expanded_program.promoteur">{{expanded_program.promoteur}}</span><span class="font-medium text-900" v-else>{{ $t('n.c.') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_program.nature_depositaire">{{expanded_program.nature_depositaire}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-map mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ expanded_program.adresse }} | {{ expanded_program.codepostal }} {{ expanded_program.ville }}</span>
															</span>
															<span class="text-purple-600 font-medium"><span><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;{{ $t('voir_sur_google_street_view') }}</a><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<div class="forme-urbaine" v-if="expanded_program.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="16" style="color: var(--primary-500) !important" /></div>
																<div class="forme-urbaine" v-else><icon file="home" :size="16" style="color:var(--primary-500) !important" /></div>
																<span class="font-medium text-900">&nbsp;&nbsp;{{expanded_program.nb_log_pc}} {{ $t('logement') }}<span v-if="expanded_program.nb_log_pc>1">s</span> {{ $t('toutes_destinations_confondues') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_program.pdc_superficie">{{ $t('sur_un_terrain_de') }} {{expanded_program.pdc_superficie}} m²<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<Dialog header="Ventes en bloc " :modal="true" :visible.sync="veb">
				<p class="m-0" v-if="vebacte()">{{ vebacte() }} {{ $t('logements complémentaires en VEB actée') }}</p>
				<p class="m-0" v-if="vebresa()">{{ vebresa() }} {{ $t('logements complémentaires en VEB reservée') }}</p>
				<template #footer>
					<!---->
					<Button label="Retrouvez les tendances des VEB sur FIL" icon="pi pi-chart-line" onclick='window.open("https://residentiel.adequation.fr/login", "_blank");' @click="veb = !veb;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'fil'})" class="p-button-text"/>
					<!---->
					<Button label="Fermer" icon="pi pi-times" @click="veb = !veb"/>
				</template>
			</Dialog>

		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import Icon from './global/Icon.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Chip from 'primevue/chip';
import Timeline from 'primevue/timeline';
import ImagePreview from 'primevue/imagepreview';
import domtoimage from 'dom-to-image'
import SpeedDial from 'primevue/speeddial';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
	name: 'PaneProgram',

	data: function() {
		return {
			veb: false,
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.ExportTable();} },
			],
		data2: [
			{"id": "", "si": "", "vte": "", "off": "", "smin": "", "smoy": "", "smax": "", "pmin": "", "pmoy": "", "pmax": "", "pmmoy": ""},
		],
		chartOptions_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
					color: '#8BC34A',
					name: "Prix du programme",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(139,195,74,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				{
					color: '#f44336',
					name: "Prix de l'offre",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(244,67,54,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				{
					color: '#2196F3',
					name: "Prix des ventes",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(33,150,243,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					allowDecimals: true,
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					labels: {
						format: '{value:,.0f} €'
					}
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'typologie',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_program', 'selected_secteurs' ]), {
		events1: function() {
			let t = []
			if (this.expanded_program && this.expanded_program.date_liv) {

				let year = parseInt(this.expanded_program.date_liv.substring(0, 4));
				let month = parseInt(this.expanded_program.date_liv.substring(4, 6)) - 1; // Les mois en JavaScript sont de 0 à 11
				let day = parseInt(this.expanded_program.date_liv.substring(6, 8));
				let date_liv = new Date(year, month, day);
				
				let today = new Date();
				today.setHours(0, 0, 0, 0)

				t.push({status: 'Mise en vente', date: this.DonneTrimestre(this.expanded_program.date_com), icon: 'pi pi-check', color: '#22c55e'})
				if (this.expanded_program.date_fin != '0') {	
					if (this.expanded_program.date_fin < this.expanded_program.date_liv) {
						t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-check', color: '#22c55e'})
						if (date_liv < today) {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
						} else {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
						}					
					} else {
						if (date_liv < today) {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
						} else {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
						}					
						t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-check', color: '#22c55e'})
					}
				} else {
					if (date_liv < today) {
						t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
					} else {
						t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
					}					
					t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-circle', color: false})
				}
			}
			return t
		},

		events2: function() {
			let t = []
			if (this.expanded_program.evt) {
				for (let index = 0; index < this.expanded_program.evt.length; index++) {
					let lib = ''
					let icon = ''
					switch(this.expanded_program.evt[index].libelle)
					{
						case 'permis autorisé':
							lib = 'Autorisé'
							icon = 'pi pi-thumbs-up'
							break;
						case 'permis déposé':
							lib = 'Déposé'
							icon = 'pi pi-inbox'
							break;
						case 'permis non déposé':
							lib = 'Non déposé'
							icon = 'pi pi-eye-slash'
							break;
						case 'permis en recours':
							lib = 'En recours'
							icon = 'pi pi-megaphone'
							break;
						case 'permis en instruction':
							lib = 'En instruction'
							icon = 'pi pi-clock'
							break;
						case 'permis en sursis à statuer':
							lib = 'En sursis à statuer'
							icon = 'pi pi-exclamation-triangle'
							break;
						case 'permis annulé':
							lib = 'Annulé'
							icon = 'pi pi-ban'
							break;
						default:
							lib = this.expanded_program.evt[index].libelle;
							icon = 'pi pi-check'
					}
					t.push({status: lib, date: this.expanded_program.evt[index].dates, icon: icon, color: '#22c55e'})
				}
				t.push({status: '', date: '', icon: '', color: ''})
				t.sort((a, b) => {
					if (a.date < b.date) {
						return -1;
					}
					if (a.date > b.date) {
						return 1;
					}
					return 0;
				});
				t.push({status: '', date: '', icon: '', color: ''})
			}
			return t
		},

		data1: function() {
			let d = []
			for (let i = 1; i <= this.compteur; i++) {
				let z = {"id": this.getTypoLib(i, this.filter2), "si": " - ", "vte": " - ", "off": " - ", "smin": " - ", "smoy": " - ", "smax": " - ", "si_xls": "", "vte_xls": "", "off_xls": "", "smin_xls": "", "smoy_xls": "", "smax_xls": "", "pmin": " - ", "pmoy": " - ", "pmax": " - ", "pmmoy": " - ", "pmin_xls": "", "pmoy_xls": "", "pmax_xls": "", "pmmoy_xls": ""}
				let w = this._getTypo_All(i, this.filter2);
				if (w.stock) {
					z.si = w.stock
					z.vte =w.vente
					z.off = w.offre
					z.si_xls = w.stock
					z.vte_xls =w.vente
					z.off_xls = w.offre
					if (this._getTypo(i, this.filter2)) {
						z.smin = Math.round(this._getTypo(i, this.filter2).s.n)
						z.smoy = Math.round(this._getTypo(i, this.filter2).s.y)
						z.smax = Math.round(this._getTypo(i, this.filter2).s.x)
						z.smin_xls = Math.round(this._getTypo(i, this.filter2).s.n)
						z.smoy_xls = Math.round(this._getTypo(i, this.filter2).s.y)
						z.smax_xls = Math.round(this._getTypo(i, this.filter2).s.x)
					}
					if (this._getTypo(i, this.filter2, this.pkg)) {
						z.pmin = this.DonneValeurPrix(this._getTypo(i, this.filter2, this.pkg).p.n, 1000, "")
						z.pmoy = this.DonneValeurPrix(this._getTypo(i, this.filter2, this.pkg).p.y, 1000, "")
						z.pmax = this.DonneValeurPrix(this._getTypo(i, this.filter2, this.pkg).p.x, 1000, "") 
						if (this._getTypo(i, this.filter2, this.pkg).p.s > 0) z.pmmoy = this.DonneValeurPrix(this._getTypo(i, this.filter2, this.pkg).p.s / this._getTypo(i, this.filter2, this.pkg).s.s,10,"")

						z.pmin_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.filter2, this.pkg).p.n, 1000)
						z.pmoy_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.filter2, this.pkg).p.y, 1000)
						z.pmax_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.filter2, this.pkg).p.x, 1000) 
						if (this._getTypo(i, this.filter2, this.pkg).p.s > 0) z.pmmoy_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.filter2, this.pkg).p.s / this._getTypo(i, this.filter2, this.pkg).s.s,10)
					}
				}
				d.push(z)
			}
			return d
		},
	}),

	watch: {
		'app.histo_program': function(newValue) {
			if (newValue) {
				this.maj_graph()
			}
		},

		'app.expanded_program': function(newValue) {
			if (newValue) {
				this.filter_svo = "s"
				if (this.app.efocus_type=='B') {
					this.filter3 = 'tva_reduite';
					this.tva = 'tva_reduite';
				} else {
					if (this.expanded_program.tva_reduite_prix_m2_parking_avec + this.expanded_program.tva_reduite_prix_m2_parking_sans > 0) {
						this.filter3 = 'tva_reduite';
						this.tva = 'tva_reduite';
					} else {
						if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec + this.expanded_program.tva_pleine_libre_prix_m2_parking_sans > 0) {
							this.filter3 = 'tva_pleine_libre';
							this.tva = 'tva_pleine_libre';
						} else {
							this.filter3 = 'tva_pleine_aide';
							this.tva = 'tva_pleine_aide';
						}
					}
				}
				if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec + this.expanded_program.tva_pleine_aide_prix_m2_parking_avec + this.expanded_program.tva_reduite_prix_m2_parking_avec>0) {
					this.pkg = 'prix_ap'; 
				} else {
					this.pkg = 'prix_sp';
				}
				this.maj_graph();
			}
		},
	},

	methods: {
		vebacte: function() {
			let n = 0
			if (this.expanded_program.liste_veb && this.expanded_program.liste_veb.length) {
				for (let index = 0; index < this.expanded_program.liste_veb.length; index++) {
					if (this.expanded_program.liste_veb[index].tra_veb_date_acte) n += this.expanded_program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},

		vebresa: function() {
			let n = 0
			if (this.expanded_program.liste_veb && this.expanded_program.liste_veb.length) {
				for (let index = 0; index < this.expanded_program.liste_veb.length; index++) {
					if (this.expanded_program.liste_veb[index].tra_veb_date_reservation && !this.expanded_program.liste_veb[index].tra_veb_date_acte) n += this.expanded_program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},

		ExportTable() {
			let sTva = ''
			if (this.tva == 'tva_pleine_libre') sTva = 'TVA pleine libre'
			if (this.tva == 'tva_pleine_aide') sTva = 'TVA pleine aidé'
			if (this.tva == 'tva_reduite') sTva = 'TVA réduite'
			let sPrk = ''
			if (this.pkg == 'prix_sp') sPrk = 'Hors parking'
			if (this.pkg == 'prix_ap') sPrk = 'Parking inclus'
			let sSvo = ''
			if (this.filter_svo == 's') sSvo = 'de tous les logements'
			if (this.filter_svo == 'v') sSvo = 'des ventes'
			if (this.filter_svo == 'o') sSvo = 'del \'offre'

			let s = ''
			s = s + '<?xml version="1.0"?>' + '\r\n'
			s = s + '<?mso-application progid="Excel.Sheet"?>' + '\r\n'
			s = s + '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">' + '\r\n'
			s = s + '<OfficeDocumentSettings xmlns="urn:schemas-microsoft-com:office:office"><AllowPNG/></OfficeDocumentSettings>' + '\r\n'
			s = s + '<ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel"><ProtectStructure>False</ProtectStructure><ProtectWindows>False</ProtectWindows></ExcelWorkbook>' + '\r\n'
			s = s + '<Styles>' + '\r\n'
			s = s + '<Style ss:ID="Default" ss:Name="Normal"><Alignment ss:Vertical="Bottom"/><Borders/><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="12" ss:Color="#000000"/><Interior/><NumberFormat/><Protection/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s18" ss:Name="Monétaire"><NumberFormat ss:Format="_-* #,##0.00\\ &quot;€&quot;_-;\\-* #,##0.00\\ &quot;€&quot;_-;_-* &quot;-&quot;??\\ &quot;€&quot;_-;_-@_-"/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s62"><NumberFormat ss:Format="#,##0"/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s67" ss:Parent="s18"> <Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="12" ss:Color="#000000"/> <NumberFormat ss:Format="_-* #,##0\\ &quot;€&quot;_-;\\-* #,##0\\ &quot;€&quot;_-;_-* &quot;-&quot;??\\ &quot;€&quot;_-;_-@_-"/></Style>' + '\r\n'
			s = s + '</Styles>' + '\r\n'
			s = s + '<Worksheet ss:Name="tableau_typo"><Table>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Chiffre clé ' + sSvo + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">' + sTva + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">' + sPrk + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell ss:Index="2"><Data ss:Type="String">Stock initial</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Ventes</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Offre</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface min en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface moy en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface max en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire min en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire moy en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire max en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix en €/m²</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'

			for (let index = 0; index < this.data1.length; index++) {
				const element = this.data1[index];
				s = s + '<Row>' + '\r\n'
				s = s + '<Cell><Data ss:Type="String">' + element.id +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.si_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.vte_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.off_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smin_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smax_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmin_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmax_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmmoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '</Row>' + '\r\n'
			}
			s = s + '</Table>' + '\r\n'
			s = s + '<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel"><ProtectObjects>False</ProtectObjects><ProtectScenarios>False</ProtectScenarios></WorksheetOptions>' + '\r\n'
			s = s + '</Worksheet>' + '\r\n'
			s = s + '</Workbook>'

			// Créer un Blob à partir du texte
			const blob = new Blob([s], { type: 'text/plain' });

			// Créer un lien pour le téléchargement
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			const accentsMap = {
			'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
			'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
			'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o',
			'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ý': 'y', 'þ': 'th', 'ÿ': 'y',
			'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
			'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
			'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ø': 'O',
			'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ý': 'Y', 'Þ': 'Th', 'ß': 'ss',
			'Ÿ': 'Y'
			};

			let normalizedStr = this.expanded_program.nom.split('').map(char => accentsMap[char] || char).join('');

			// Supprimer tous les caractères spéciaux
			normalizedStr = normalizedStr.replace(/[^a-zA-Z0-9 ]/g, "");

			link.download = normalizedStr + '.xls'; // Nom du fichier à télécharger
			link.click();

			// Nettoyage de l'URL après le téléchargement
			URL.revokeObjectURL(link.href);

		},

		AfficheDate(dateString){
			if (dateString) {
				const parts = dateString.split('-');
				return `${parts[2]}/${parts[1]}/${parts[0]}`;
			} else return '';
		},

		rowClass(data) {
            return (data.si>0) ? 'row-accessories': 'row-accessories2';
        },

		// eslint-disable-next-line
		generateProgImage(id) {
			document.getElementById("pan-titre-programme").style.visibility = 'hidden';



			const elementToExport = document.getElementById('ProgEx');
		let options = {
			height: elementToExport.scrollHeight,
			width: elementToExport.scrollWidth,
			style: {
				'transform': 'scale(1)',
				'transformOrigin': 'top left',
			},
		};
	
		domtoimage.toPng(elementToExport, options)
		.then((dataUrl) => {
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = 'ProgEx.png';
			link.click();
		})
		.catch((error) => {
			console.error('Erreur lors de la génération de l\'image', error);
		});

			document.getElementById("pan-titre-programme").style.visibility = 'visible';
		},
/*
		maj_graph: function() {
			if (this.app.histo_program && this.app.histo_program.hits.hits.length) {
				
				let min = 0
				let max = 0
				let fn;
				fn = function setMinMax(Val) {
					if (Val > 0) {
						if (min==0 || min>Val) {
							min = Val
						}
						if (max==0 || max<Val) {
							max = Val
						}
					}
				}
				let t_Prog = []
				let t_Offre = []
				let t_Vente = []
				if (this.tva == 'tva_pleine_libre' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_libre_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_libre_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_libre_prix_m2_parking_avec
				}
				if (this.tva == 'tva_pleine_libre' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_libre_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_libre_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_libre_prix_m2_parking_sans
				}
				if (this.tva == 'tva_pleine_aide' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_aide_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_aide_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_aide_prix_m2_parking_avec
				}
				if (this.tva == 'tva_pleine_aide' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_aide_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_aide_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_aide_prix_m2_parking_sans
				}
				if (this.tva == 'tva_reduite' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_reduite_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_reduite_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_reduite_prix_m2_parking_avec
				}
				if (this.tva == 'tva_reduite' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_reduite_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_reduite_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_reduite_prix_m2_parking_sans
				}
				for (let index = 0; index < this.app.histo_program.hits.hits[0]._source.libelle.length; index++) {
					fn(t_Prog[index])
					fn(t_Offre[index])
					fn(t_Vente[index])
					if (t_Prog[index]==0) t_Prog[index] = null
					if (t_Offre[index]==0) t_Offre[index] = null
					if (t_Vente[index]==0) t_Vente[index] = null
				}
				
				this.chartOptions_trim.yAxis.min = min
				this.chartOptions_trim.yAxis.max = max
				
				this.chartOptions_trim.series[0].data = t_Prog
				this.chartOptions_trim.series[1].data = t_Offre
				this.chartOptions_trim.series[2].data = t_Vente
				this.chartOptions_trim.xAxis.categories= this.app.histo_program.hits.hits[0]._source.libelle
			}
		},
*/
		maj_graph: function() {
			if (this.app.histo_program && this.app.histo_program.hits.hits.length) {
				let min = 0, max = 0;

				const setMinMax = (val) => {
					if (val > 0) {
						min = min === 0 || min > val ? val : min;
						max = max === 0 || max < val ? val : max;
					}
				};

				const dataMapping = {
					'tva_pleine_libre_prix_ap': 'tva_pleine_libre_prix_m2_parking_avec',
					'tva_pleine_libre_prix_sp': 'tva_pleine_libre_prix_m2_parking_sans',
					'tva_pleine_aide_prix_ap': 'tva_pleine_aide_prix_m2_parking_avec',
					'tva_pleine_aide_prix_sp': 'tva_pleine_aide_prix_m2_parking_sans',
					'tva_reduite_prix_ap': 'tva_reduite_prix_m2_parking_avec',
					'tva_reduite_prix_sp': 'tva_reduite_prix_m2_parking_sans'
				};

				const key = `${this.tva}_${this.pkg}`;
				const source = this.app.histo_program.hits.hits[0]._source;
				const t_Prog = source.prix_prog[dataMapping[key]] || [];
				const t_Offre = source.offre[dataMapping[key]] || [];
				const t_Vente = source.ventes[dataMapping[key]] || [];

				source.libelle.forEach((label, index) => {
					[t_Prog[index], t_Offre[index], t_Vente[index]].forEach(setMinMax);
					if (t_Prog[index] === 0) t_Prog[index] = null;
					if (t_Offre[index] === 0) t_Offre[index] = null;
					if (t_Vente[index] === 0) t_Vente[index] = null;
				});

				this.chartOptions_trim.yAxis.min = min;
				this.chartOptions_trim.yAxis.max = max;

				this.chartOptions_trim.series[0].data = t_Prog;
				this.chartOptions_trim.series[1].data = t_Offre;
				this.chartOptions_trim.series[2].data = t_Vente;
				this.chartOptions_trim.xAxis.categories = source.libelle;
			}
		},

		DonneTrimestre: function(val) {
			let DateTrim
			let t = String(val).substr(4,2)
			let a = String(val).substr(0,4)
			switch(parseInt(t))
			{
				case 1:
				case 2:
				case 3:
				DateTrim = "1T " + a; 
				break;
				case 4:
				case 5:
				case 6:
				DateTrim = "2T " + a; 
				break;
				case 7:
				case 8:
				case 9:
				DateTrim = "3T " + a; 
				break;
				case 10:
				case 11:
				case 12:
				DateTrim = "4T " + a; 
				break;
			}
			return DateTrim
		},

		DonneValeurPrix: function(val, num, fin) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0 || val=='NaN') {
				return "-"
			} else {
				val = Math.round(val/num)*num
				if (val==0 || val=='NaN') {
					return "-"
				}
				let formatted = euro.format(val)+fin
				formatted = formatted.replace(/\u00A0/g, ' ');
				return formatted
			}
		},
		DonneValeurPrix_xls: function(val, num) {
			if (val==0 || val=='NaN') {
				return ""
			} else {
				val = Math.round(val/num)*num
				return val
			}
		},

		DonneValeurPrixArr: function(val, num) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0 || val=='NaN') {
				return "-"
			} else {
				val = Math.round(val/num)*num
				return euro.format(val)
			}
		},

		getTypoLib: function(t, a) {
			if (a!='typologie') {
				switch(parseInt(t))
				{
					case 1:
					return "< 34m²"
					case 2:
					return "34m²-39m²"
					case 3:
					return "39m²-44m²"
					case 4:
					return "44m²-47m²"
					case 5:
					return "47m²-50m²"
					case 6:
					return "50m²-56m²"
					case 7:
					return "56m²-62m²"
					case 8:
					return "62m²-72m²"
					case 9:
					return "72m²-78m²"
					case 10:
					return "78m²-84m²"
					case 11:
					return "84m²-89m²"
					case 12:
					return ">= 89m²"
				}
			} else {
				return "T" + t 
			}
		},

		_getTypo_All: function(t, a) {
			let v = false
			let w = false
			let stock = 0
			let vente = 0
			let offre = 0

			if (this.app.active_segmentation) {
				if (a=='echelle') {
					w = this.app.active_segmentation[1]._source.d_surf
				} else {
					w = this.app.active_segmentation[0]._source.d_typo
				}
				if (w) {
					v = w.ini.vt
					if (a=='echelle') {
						v = v['s' + t.toString()]
					} else {
						v = v['t' + t.toString()]
					}
					if (v && v.dt && v.dt.lt && v.dt.lt.pn)	stock = v.dt.lt.pn.n

					if (w.vte) {
						v = w.vte.vt
						if (v) {
							if (a=='echelle') {
								v = v['s' + t.toString()]
							} else {
								v = v['t' + t.toString()]
							}
						}
					}
					if (v && v.dt && v.dt.lt && v.dt.lt.pn)	vente = v.dt.lt.pn.n

					if (w.ofr) {
						v = w.ofr.vt
						if (v) {
							if (a=='echelle') {
								v = v['s' + t.toString()]
							} else {
								v = v['t' + t.toString()]
							}						
							if (v && v.dt && v.dt.lt && v.dt.lt.pn)	offre = v.dt.lt.pn.n
						}
					}
				}

			}
			return {stock: stock, vente: vente, offre: offre}
		},
		_getTypo: function(t, a, p) {
			let v = false
			if (this.app.active_segmentation) {
				if (a=='echelle') {
					v = this.app.active_segmentation[1]._source.d_surf
				} else {
					v = this.app.active_segmentation[0]._source.d_typo
				}
				switch(this.filter_svo)
				{
					case "s":
					v = v.ini
					break;
					case "v":
					v = v.vte
					break
					case "o":
					v = v.ofr
					break
				}
				if (v) {
					switch(this.tva)
					{
						case "tva_pleine_libre":
						v = v.vp
						break;
						case "tva_pleine_aide":
						v = v.vp
						break
						case "tva_reduite":
						v = v.vr
						break
					}
				}
				if (v) {
					if (a=='echelle') {
						v = v['s' + t.toString()]						
					} else {
						v = v['t' + t.toString()]
					}
				}
				if (v) {
					v = v.dt
					if (v) {
						switch(this.tva)
						{
							case "tva_pleine_libre":
							v = v.ll
							break;
							case "tva_pleine_aide":
							v = v.la
							break
							case "tva_reduite":
							v = v.la
							break
						}
					}
					if (v) {
						let w = false
						if (p=="prix_ap") {
							w = v.po
							if (!w) {
								if (this.expanded_program.forme_urbaine.toUpperCase() == 'INDIVIDUEL') {
									w = v.pn
								}
							}
						} else {
							w = v.pn
						}
						if (w) {
							v = w
						} else {
							v = false
						}
					}
				}				
			}
			return v
		},

		getEchelle: function() {
			let v = false
			return v
		},
		
		expand_program: function() {
			this.$router.push({ name: 'neuf'}).catch(()=>{});
			if (this.expanded_program != this.program) {
				this.$store.dispatch('expand_program', { program: this.program })
			} else {
				this.$store.dispatch('expand_program', { program: false })
			}
		},

		select_program: function() {
			this.$store.dispatch('select_program', { program: this.program })
		},

		show_program_on_map: function() {
			this.$store.dispatch('center_map', { coords: { lat: this.expanded_program.location.lat, lng: this.expanded_program.location.lon }, zoom: 16 })
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "progencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_program.nom, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-program' })
		}
	},

	mounted: function() {
		let that = this
		that.maj_graph()
	},

	components: { highcharts: Chart, Icon, DataTable, Column, ColumnGroup, Row, Chip, Timeline, ImagePreview, SpeedDial, Dialog, Button }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					text-align center
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							&:last-child
								border none
							&:hover
								td
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight bold
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0


.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 560px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	left 120px
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear


.sp
	margin-right 10px !important
	padding-left 10px
	padding-right 10px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 16px
	line-height 28px
	color rgba(0, 0, 0, 0.87)
	margin-right 2px
	height 28px
	right 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 14px
	width auto
	
	

.speeddial-tooltip-demo {
	right: 50px;
	top: -25px;
}

:deep(.speeddial-tooltip-demo) {
	.p-speeddial-direction-up {
		&.speeddial-left {
			left: 0;
			bottom: 0;
		}
		&.speeddial-right {
			right: 0;
			bottom: 0;
		}
	}
	.p-speeddial-button.p-button.p-button-icon-only {
		width: 3rem !important;
		height: 3rem !important;
	}

}	

:deep(.row-accessories2) {
	background-color: #eee !important;
}

:deep(p-datatable-tbody) {
	font-size: 10px;
}

.p-chip.custom-chip {
	background: #3B82F6;
	font-size:12px;
	color: var(--primary-color-text);
}
.p-chip.vebacte {
	background: #fd943a;
	font-size:12px;
	color: var(--primary-color-text);
}
.p-chip.vebresa {
	background: #fdc13a;
	font-size:12px;
	color: var(--primary-color-text);
}

.p-chip.qeb {
	background: #13A10E;
	font-size:12px;
	color: #FFFFFF;
}

.custom-marker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	border-radius: 50%;
	z-index: 1;
}

:deep(.p-panel-content) {
	background: #f0f2f4 !important;	
}



@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
