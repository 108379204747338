<template>
	<div class="notificationone" :class="notificationone.status" @click="remove_notificationone()">
		<icon class="icon-error" file="alert-triangle" :size="32" />
		<icon class="icon-success" file="check" :size="32" />
		<icon class="icon-warning" file="alert-triangle" :size="32" />
		<icon class="icon-new" file="message-circle" :size="64" />

		{{ notificationone.message }}

		<div class="md" v-if="notificationone.message2">
			<br/>
			{{ notificationone.message2 }}
			<br/>
			<a class="oir_but" target="_blank" :href="notificationone.url">Je découvre</a>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Notificationone',
	data () {
		return {
			
		}
	},

	props: [ 'notificationone' ],

	methods: {
		remove_notificationone: function() {
			this.$store.dispatch('remove_notificationone', { notificationone: this.notificationone })
		},

		clickOutsideHandlerCompte(event) {
			if (!this.$el.contains(event.target)) {
				try {
					this.$store.dispatch('remove_notificationone', { notificationone: this.notificationone })
				} catch (error) {
					// Erreur
				}
			}
		},
	},

	mounted() {

	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.notificationone
	display block
	left calc(50% - 250px)
	top calc(50% - 250px)
	background #999
	width 500px
	height 200px
	padding 24px 16px 24px 94px
	border-radius radius
	cursor pointer
	font-weight 500
	line-height 20px
	color #fff
	transition 0.05s easeOutQuart
	z-index 5001
	&.error
		background-color red
		.icon-error
			display block
	&.info
		background teal
		.icon-new
			display block
	&.success
		background-color darken(lime, 12%)
		.icon-success
			display block
	&.warning
		background-color orange
		.icon-warning
			display block
	.icon
		display none
		position absolute
		left 20px
		top calc(50% - 32px)
	.md
		text-align justify
	.oir_but
		position absolute
		width 250px
		height 50px
		top 100px
		left 40px
		background #F0E63C
		border-radius 77px
		font-family 'Montserrat', sans-serif
		font-style normal
		font-weight 500
		font-size 18px
		line-height 50px
		align-items center
		text-align center
		letter-spacing 0.01em
		color #000000
		
</style>
