<template>
	<transition name="slide">
		<div class="pane pane-layer" :class="app.viewlayer" v-if="app.viewlayer">
			<div class="header">
				<div class="back-button" @click="set_view('')"><icon file="x" :size="32" /></div>
				<div class="print-button" @click="generateProgImage();"><icon file="download" :size="32" /></div>
				<div class="texte" v-if="app.viewlayer == 'layer'">{{ $t('couches_dinformations') }}</div>
				<div class="texte" v-if="app.viewlayer == 'insee'">{{ $t('Fiche Territoire') }}</div>
				<div class="texte" v-if="app.viewlayer == 'legende'">{{ $t('legendes_programmes_neufs') }}</div>
				<div class="texte" v-if="app.viewlayer == 'isochrone'">{{ $t('isochrone') }}</div>
			</div>
			<div class="right-bar p-1">
				<div class="right-bar-content" v-bar>
					<div class="content">
						<div v-if="app.viewlayer == 'layer'">
							<div class="group_layer">
								<br/>
								<div class="choix" style="height:32px">
									<label class="checkbox" :class="{ checked: zac }">
										<input type="checkbox" :checked="zac" @click.stop="zac=!zac;setcontours('zac', zac)">
										<span class="chk"><icon file="check" :size="16" /></span>
										<span class="text">&nbsp;&nbsp;{{ $t('ZAC') }}</span>
									</label>
								</div>
								<div class="choix" style="height:32px">
									<label class="checkbox" :class="{ checked: qpv }">
										<input type="checkbox" :checked="qpv" @click.stop="qpv=!qpv;setcontours('qpv', qpv)">
										<span class="chk"><icon file="check" :size="16" /></span>
										<span class="text">&nbsp;&nbsp;{{ $t('quartiers_prioritaires_de_la_ville') }}</span>
									</label>
								</div>
								<div class="choix" style="height:32px">
									<label class="checkbox" :class="{ checked: qpv_trois }">
										<input type="checkbox" :checked="qpv_trois" @click.stop="qpv_trois=!qpv_trois;setcontours('qpv_trois', qpv_trois)">
										<span class="chk"><icon file="check" :size="16" /></span>
										<span class="text">&nbsp;&nbsp;{{ $t('300m_autour_des_qpv') }}</span>
									</label>
								</div>
								<div class="choix" style="height:32px">
									<label class="checkbox" :class="{ checked: contour_com }">
										<input type="checkbox" :checked="contour_com" @click.stop="contour_com=!contour_com;setcontours('commune', contour_com)">
										<span class="chk"><icon file="check" :size="16" /></span>
										<span class="text">&nbsp;&nbsp;{{ $t('communes_ou_quartiers') }}</span>
									</label>
								</div>
							</div>
							<div class="group_layer" style="display: flex;justify-content: space-around;padding-bottom: 12px;">
								<table style="width:100%">
									<th style="display: none"></th>
									<tr>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==1 }">
													<input type="checkbox" :checked="fond==1" @click.stop="fond=1;setf()">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('gris') }}</span>
												</label>
											</div>
										</td>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==2 }">
													<input type="checkbox" :checked="fond==2" @click.stop="fond=2;setf()">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('couleurs') }}</span>
												</label>
											</div>
										</td>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==3 }">
													<input type="checkbox" :checked="fond==3" @click.stop="fond=3;setf()">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('satellite') }}</span>
												</label>
											</div>
										</td>
									</tr>
								</table>
							</div>
							<p>&nbsp;</p>
							<p>{{ $t('vous_pouvez_selectionner_les_poi') }}</p><p>{{ $t('en_deroulant_la_liste_ci-dessous') }}</p>
							<ScrollPanel style="width: 100%; height: calc(100vh - 550px)">
								<div style="padding-top: 20px;padding-right: 10px;padding-bottom: 20px;">
									<Tree :value="options2" selectionMode="checkbox" :selectionKeys.sync="selectedKey1"
									@node-select="poiSelect2" @node-unselect="poiDeselect2" style="font-size: 14px;padding:0 !important"></Tree>
								</div>
							</ScrollPanel>
						</div>
						<div id="FicheTerritoire" v-if="app.viewlayer == 'insee'">
							<br/>
							<div class="surface-card border-round p-2" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="mb-2 mt-2">
									<div class="ml-2" style="text-align: left;display:flex;justify-content: space-between;">
										<span class="mr-2" style="font-size: 1rem; line-height:36px">{{ app.adr_init.territoire.replace('Arrondissement', 'arr') }}</span>
										<div>
											<Chip style="font-size: 0.7rem;" v-if="app.adr_init.zonage" :label="'Zone ' + app.adr_init.zonage" class="mr-2" :class="{ zabis: app.adr_init.zonage=='Abis', za: app.adr_init.zonage=='A', zb1: app.adr_init.zonage=='B1', zb2: app.adr_init.zonage=='B2', zc: app.adr_init.zonage=='C' }" />
											<Chip v-tooltip.left="$t('legende_zonage')" style="font-size: 0.7rem;" v-if="app.adr_init.zonage_social" :label="'Zone ' + convertToRoman(app.adr_init.zonage_social)" class="mr-2" :class="{ z1b: app.adr_init.zonage_social=='01 bis', z1: app.adr_init.zonage_social=='1', z2: app.adr_init.zonage_social=='2', z3: app.adr_init.zonage_social=='3' }" />
										</div>
									</div>
								</div>
							</div>
							<br/>
							<div class="surface-card border-round p-1" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-2 mt-2">
									<div class="ml-2" style="text-align: left;" v-tooltip.left="'INSEE 2020 & SITADEL 2020-2022'">
										<p class="text-600 mt-0 mb-0">{{ $t('Dynamique territoriale') }}</p>
									</div>
								</div>
								<div class="surface-border border-1 border-round p-1 mb-2">
									<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: space-between;">
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;Habitants</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600" style="font-size: 1.5rem;">
													<icon file="person" style="color:#666;height:40px;width:40px;"/>
													<div style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].population.population)}}</div>
												</div>&nbsp;
												<div style="margin-top: 0.75rem !important;">
													<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate>0"></i>
													<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.population.evolution_rate<0"></i>
													<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate>0">+ {{(app.insee_kpi[0].data.population.evolution_rate*100).toFixed(2)}} %</span>
													<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate<0">{{(app.insee_kpi[0].data.population.evolution_rate*100).toFixed(2)}} %</span>
												</div>


											</div>
										</div>
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;Ménages</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600" style="font-size: 1.5rem;">
													<icon file="group" style="color:#666;height:40px;width:40px;"/>
													<div style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].household.total_household)}}</div>
												</div>&nbsp;
												<div style="margin-top: 0.75rem !important;">
													<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate>0"></i>
													<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.household.evolution_rate<0"></i>
													<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate>0">+ {{(app.insee_kpi[0].data.household.evolution_rate*100).toFixed(2)}} %</span>
													<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate<0">{{(app.insee_kpi[0].data.household.evolution_rate*100).toFixed(2)}} %</span>
												</div>
											</div>
										</div>
									</div>
									<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: space-between;">
										<div class="pb-2" style="vertical-align:bottom; width: 100%">
											<div class='kpi_label mb-2'>&nbsp;Répartion des ménages</div>
											<ul class="list-none p-0 m-0">
												<li class="flex" style="height:42px; align-items: center;">
													<div class="mt-2" style="width:150px; height: 10px; border-radius: 5px;margin-top: 1.1rem !important;">
														<div style="background-color: #6b23f1; height: 10px; border-radius: 5px" :style="{ width : Math.round(chartDataMen[0].nb / chartDataMen[0].nb * 100) + '%'}"></div>
													</div>
													<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #6b23f1;font-size:2rem">{{chartDataMen[0].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[0].label }}</div>
												</li>
												<li class="flex" style="height:22px; align-items: center;">
													<div class="mt-2" style="height: 10px; border-radius: 5px;margin-top: 0.8rem !important;" :style="{ width : Math.round(chartDataMen[1].nb / chartDataMen[0].nb * 150) + 'px'}">
														<div style="background-color: #c2abed; height: 10px; border-radius: 5px"></div>
													</div>
													<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #c2abed;font-size:1.5rem">{{chartDataMen[1].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[1].label }}</div>
												</li>
												<li class="flex" style="height:42px; align-items: center;">
													<div class="mt-2" style="height: 10px; border-radius: 5px" :style="{ width : Math.round(chartDataMen[2].nb / chartDataMen[0].nb * 150) + 'px'}">
														<div style="background-color: #d5c5f0; height: 10px; border-radius: 5px"></div>
													</div>
													<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #d5c5f0;font-size:1rem">{{chartDataMen[2].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[2].label }}</div>
												</li>
											</ul>
										</div>
									</div>

									<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: space-between;">
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;Migration résidentielle</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600" style="font-size: 1.5rem;">
													<icon file="truck" style="color:#666;height:40px;width:40px;"/>
													<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee_kpi[0].data.household.last_year_rotation.total)}}</div>
													<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.45rem !important;" v-tooltip="'Emménagement par an'">
														Emm.<br>par an
													</div>
												</div>



											</div>
										</div>
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;Construction</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600" style="font-size: 1.5rem;">
													<icon file="domainadd" style="color:#666;height:40px;width:40px;"/>
													<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee_sitadel[0].data.construction_total)}}</div>
													<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.45rem !important;" v-tooltip="'Logements par an'">
														Log.<br>par an
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: space-between;">
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;Emplois</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600 mr-1" style="font-size: 1.5rem;">
													<icon file="business" style="color:#666;height:40px;width:40px;"/>
													<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].employment.total)}}</div>
												</div>

												<div style="margin-top: 0.75rem !important;">
													<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate>0"></i>
													<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.employment.evolution_rate<0"></i>
													<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate>0">+ {{(app.insee_kpi[0].data.employment.evolution_rate*100).toFixed(2)}} %</span>
													<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate<0">{{(app.insee_kpi[0].data.employment.evolution_rate*100).toFixed(2)}} %</span>
												</div>

											</div>
										</div>
										<div class="pb-2" style="vertical-align:bottom">
											<div class='kpi_label mb-2'>&nbsp;</div>
											<div class="flex align-items-center pb-2" style="vertical-align:bottom">
												<div class="flex text-purple-600" style="font-size: 1.5rem;">
													<icon file="work_history" style="color:#666;height:40px;width:40px;"/>
													<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb2(app.insee_kpi[0].data.employment.indice_concentration)}}</div>
												</div>
												<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.15rem !important;">
													Emploi<span v-if="app.insee_kpi[0].data.employment.indice_concentration>=2">s</span><br>pour 1 actif
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


							<br/>

							<div class="surface-card border-round p-1" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-2 mt-2">
									<div class="ml-2" style="text-align: left;" v-tooltip.left="'INSEE 2020'">
										<p class="text-600 mt-0 mb-0">{{ $t('Parc de logement') }}</p>
									</div>
								</div>



								<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: center;">
									<div class="pb-2" style="vertical-align:bottom">
										<div class="flex align-items-center pb-2" style="vertical-align:bottom">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="apartment" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.6rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.total_lodgments)}}</div>
											</div>

											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem">
												Logements
											</div>
										</div>
									</div>
								</div>

								<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: space-between;height:260px">
									<div class="pb-2" style="position: absolute;
	top: 120px;
	left: 80px;
	color: #fff;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
">
										<div style="padding-top:5px; padding-bottom: 10px;text-align: center">
											<div class="flex">
												<Knob v-model="chartDataForme[0].nb" :size="140" readonly valueTemplate="{value}%" />
												<div style="line-height:80px; padding-top: 12px;"></div>
											</div>
											<div class="text-purple-600" style="font-size: 1.5rem;">
												{{ chartDataForme[0].label }}
											</div>
										</div>
									</div>

									<div class="pb-2" style="position: absolute;
	top: 140px;
	left: 240px;
	color: #fff;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none;">
										<div class="flex align-items-center pb-2" style="vertical-align:bottom">
											<div class="text-purple-600" style="font-size: 1.5rem;">
												<div class="flex">
													<Chart type="polarArea" :data="chartDataOccupationGraph" :options="polarOption" :width="280" :height="280"/>
												</div>
											</div>
										</div>
									</div>
									<div class="overlay1">
										<span style="font-size:2rem">{{ chartDataOccupation[0].nb }}%</span><br>{{ chartDataOccupation[0].label }}
									</div>
									<div class="overlay2">
										<span style="font-size:1.5rem">{{ chartDataOccupation[1].nb }}%</span><br>{{ chartDataOccupation[1].label }}
									</div>
									<div class="overlay3">
										<span style="font-size:1rem">{{ chartDataOccupation[2].nb }}%</span><br>{{ chartDataOccupation[2].label }}
									</div>
									<div class="overlay4">
										<span style="font-size:0.8rem">{{ chartDataOccupation[3].nb }}%</span><br>{{ chartDataOccupation[3].label }}
									</div>
								</div>
							</div>
							<br/>
							<div class="surface-card border-round p-1" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-2 mt-2">
									<div class="ml-2" style="text-align: left;" v-tooltip.left="'SNE 2022'">
										<p class="text-600 mt-0 mb-0">{{ $t('Pression de la demande en logement social') }}</p>
									</div>
								</div>



								<div class="flex align-items-center pb-2" style="vertical-align:bottom;justify-content: center;">
									<div class="pb-2" style="vertical-align:bottom">
										<div class="flex align-items-center pb-2" style="vertical-align:bottom">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="real_estate_agent" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.6rem !important;">{{formatnb(Math.round(app.insee_sne[0].data.social_demand_pressure*10,1)/10)}}</div>
											</div>

											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem">
												demandes pour 1 attribution
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<br/>
							<div class="surface-card border-round p-1" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-4" v-tooltip.left="'FILOSOFI 2020'">
									<div class="ml-2" style="text-align: left;">
										<p class="text-600 mt-0 mb-0">{{ $t('revenu_median_mensuel_des_menages') }}</p><p class="text-600 mt-0 mb-0">{{ $t('par_tranches_dâges_en') }} {{ app.insee[0].data[app.insee[0].data.length-1].year}}</p>
									</div>
								</div>
								<ul class="list-none p-0 m-0">
									<li class="mb-3">
										<div class="flex justify-content-between align-items-center">
											<span class="text-900 inline-flex justify-content-between align-items-center">
												<span class="font-medium text-900">- {{ $t('median') }}</span>
											</span>
											<span class="text-purple-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household }} €/mois</span>
										</div>
										<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
											<div class="bg-purple-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household) / max_mediane * 100) + '%'}"></div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_30_ans_et_moins') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_30_a_39_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_40_a_49_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_50_a_59_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_60_a_74_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-3">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_75_ans_et_plus') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income }} €/mois</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<br><br><br>
						</div>
						<div v-if="app.viewlayer == 'legende'">
							<br/>
							<div class="choix">
								<label class="radiobox" :class="{ checked: app.pin_type=='commercialisation' }">
									<input type="checkbox" :checked="app.pin_type=='commercialisation'" @click.stop="click('commercialisation')">
									<span class="chk"><icon file="check" :size="16" /></span>
									<span class="text">{{ $t('Etat de commercialisation') }} </span>
								</label>
								<br/><br/>
								<table style="width:100%">
									<th style="display: none"></th>
									<tr>
										<td style="text-align: center;width:33%;"><div class="mrk projet"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk actif"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk termine"></div></td>
									</tr>
									<tr>
										<td style="text-align: center;">{{ $t('projet') }}</td>
										<td style="text-align: center;">{{ $t('actif') }}</td>
										<td style="text-align: center;">{{ $t('termine') }}</td>
									</tr>
								</table>
							</div>
							<div class="choix">
								<label class="radiobox" :class="{ checked: app.pin_type=='prix' }">
									<input type="checkbox" :checked="app.pin_type=='prix'" @click.stop="click('prix')">
									<span class="chk"><icon file="check" :size="16" /></span>
									<span class="text">{{ $t('gamme_de_prix_au_m²') }}</span>
								</label>
								<br/><br/>
								<table style="width:100%">
									<th style="display: none"></th>
									<tr>
										<td style="text-align: center;width:33%;"><div class="mrk entree"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk milieu"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk haut"></div></td>
									</tr>
									<tr>
										<td style="text-align: center;">{{ $t('entree_de_gamme') }}</td>
										<td style="text-align: center;">{{ $t('milieu_de_gamme') }}</td>
										<td style="text-align: center;">{{ $t('haut_de_gamme') }}</td>
									</tr>
								</table>
							</div>
						</div>
						<div v-if="app.viewlayer == 'isochrone'">
							<br/>
							<div class="">{{ $t('moyen_de_transport') }}</div>
							<br/>
							<div class="choix" style="height:100px">
								<div class="box-content">
									<div class="switches">
										<switch-button size2="80px" icon="block" divise="five" @click.native="toggle_switch(false);setContour(app.cityscan.isochrone.transportType)" :class="{ active: !app.cityscan.isochrone.transportType }" />
										<switch-button size2="80px" icon="directions_bus" divise="five" @click.native="toggle_switch('TRANSIT');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='TRANSIT' }" />
										<switch-button size2="80px" icon="directions_car" divise="five" @click.native="toggle_switch('DRIVING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='DRIVING' }" />
										<switch-button size2="80px" icon="directions_bike" divise="five" @click.native="toggle_switch('BIKING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='BIKING' }" />
										<switch-button size2="80px" icon="directions_walk" divise="five" @click.native="toggle_switch('WALKING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='WALKING' }" />
									</div>
								</div>
							</div>
							<div class="">{{ $t('temps_en_minutes') }}</div>
							<br/>
							<div class="choix" style="height:120px">
								<div class="box-content">
									<div class="switches">
										<switch-button label="5" divise="six" @click.native="toggle_switch_two(5);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==5 }" />
										<switch-button label="10" divise="six" @click.native="toggle_switch_two(10);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==10 }" />
										<switch-button label="15" divise="six" @click.native="toggle_switch_two(15);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==15 }" />
										<switch-button label="20" divise="six" @click.native="toggle_switch_two(20);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==20 }" />
										<switch-button label="25" divise="six" @click.native="toggle_switch_two(25);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==25 }" />
										<switch-button label="30" divise="six" @click.native="toggle_switch_two(30);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==30, inactive:(app.cityscan.isochrone.transportType=='TRANSIT') }" />										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Tree from 'primevue/tree';
import ScrollPanel from 'primevue/scrollpanel';
import SwitchButton from './global/SwitchButton'
// eslint-disable-next-line
import Chart from 'primevue/chart';
import Chip from 'primevue/chip';
import Knob from 'primevue/knob';
import domtoimage from 'dom-to-image'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

export default {
	name: 'PaneLayer',

	data () {
		return {
			series: [51, 33, 14, 2],
			chartOptionsTest: {
				chart: {
				width: 380,
				type: 'polarArea'
				},
				labels: ['Rose A', 'Rose B', 'Rose C', 'Rose D'],
				fill: {
				opacity: 1
				},
				stroke: {
				width: 1,
				colors: undefined
				},
				yaxis: {
				show: false
				},
				legend: {
					display: false
				},
				plotOptions: {
				polarArea: {
					rings: {
					strokeWidth: 0
					},
					spokes: {
					strokeWidth: 0
					},
				}
				},
				theme: {
				monochrome: {
					enabled: true,
					shadeTo: 'light',
					shadeIntensity: 0.6
				}
				}
			},
			
			polarOption: {
				scales: {
					r: {
						display: false, 
					}
				},
				plugins: {
					datalabels: {
						color: (context) => {
							return context.dataIndex === 0 ? '#FFF' : '#000'; // Couleur du label
						},
						font: (context) => {
							const value = context.dataset.data[context.dataIndex];
							return {
							size: value < 100 ? 14 : 18, // Taille de la police selon la valeur
							weight: 'bold'
							};
						},
						anchor: 'center', // Positionnement des labels
						align: 'center',
						offset: 10, // Décalage pour éviter les chevauchements
						// eslint-disable-next-line
						formatter: (value, context) => {
							// eslint-disable-next-line
							const label = context.chart.data.labels[context.dataIndex];
							return ``;
						},
						clamp: true, // Assure que les labels ne sont pas coupés
						clip: false // Permet aux labels de dépasser les bords de la zone
					},

					legend: {
						display: false,
					},
					tooltip: {
						enabled: false				
					},
				},
				maintainAspectRatio: true,
				rotation: -15 * (Math.PI / 180),
			},
			chartOptionsF: {
				legend: {
                    labels: {
                        fontColor: '#495057'
                    }
                }
			},

			chartOptionsP: {
				cutout: '70%', // Gérer l'épaisseur du graphique doughnut
				rotation: -0.5 * Math.PI, // Gérer l'orientation
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false				
					},
					// Plugin personnalisé pour afficher du texte au centre
					beforeDraw: function (chart) {
					const width = chart.width;
					const height = chart.height;
					const ctx = chart.ctx;
					ctx.restore();
					const fontSize = (height / 114).toFixed(2);
					ctx.font = fontSize + "em sans-serif";
					ctx.textBaseline = "middle";

					const text = "75%"; // Le texte à afficher
					const textX = Math.round((width - ctx.measureText(text).width) / 2);
					const textY = height / 2;

					ctx.fillText(text, textX, textY);
					ctx.save();
					}
				}
			},
			chartData1: {
				datasets: [{
					data: [
						11,
						16,
						7,
						3,
						14
					],
					backgroundColor: [
						"#6366f1",
                        "#f97316",
                        "#3b82f6",
						"#a855f7",
						"#eab308",
                        "#06b6d4"
					],
					label: 'My dataset'
				}],
				labels: [
					"30 ans et moins",
					"30 à 39 ans",
					"40 à 49 ans",
					"50 à 59 ans",
					"60 à 74 ans",
					"75 ans et plus"
				]
			},
			chartOptions: {
				indexAxis: 'y',
                plugins: {
                    legend: {
						display: false,
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
			},
			basicOptions: {
				indexAxis: 'y',
                plugins: {
                    legend: {
						display: false,
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },

			iso: false,
			ligne_gpe: false,
			MarkerStyle:1,
			contour_com: false,
			qpv: false,
			zac: false,
			qpv_trois: false,
			fond: 2,
			fond_loyer:1,
			metro: false,
			tramway: false,
			train: false,
			poi: false,
			ecole: false,
			visible: false,
			transports:false,
			education:false,
			commerces:false,
			loisirs:false,
			services:false,
			value: [],
			selectedKey1: "",
			chartOptions_men_tranche: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [{data: [	{name: 'Agriculteur', y: 0},{name: 'Entrepreneur', y: 0},{name: 'Sans activité', y: 0},{name: 'Sénior', y: 0},{name: 'Employé', y: 0},{name: 'Travailleur indépendant', y: 0},{name: 'Professions libérales', y: 0},{name: 'Retiré', y: 0}],}],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			options2: [
				{
					key: "Transports",
					label: "Transports",
					data: "Transports",
					icon: "pi pi-fw pi-car",
					children: [
					{ key: "Bus", label: "Bus", data: "Bus"},
					{ key: "Tramway", label: "Tramway", data: "Tramway"},
					{ key: "Métro", label: "Métro", data: "Métro"},
					{ key: "Gare", label: "Gare", data: "Gare"},
					{ key: "Aéroport", label: "Aéroport", data: "Aéroport"},
					{ key: "GPE", label: "Grand Paris Express", data: "GPE"},
					]
				},
				{
					key: "Alimentaire",
					label: "Alimentaire",
					data: "Alimentaire",
					icon: "pi pi-fw pi-shopping-cart",
					children: [
					{ key: "Boulangerie", label: "Boulangerie", data: "Boulangerie"},
					{ key: "Epicerie", label: "Epicerie", data: "Epicerie"},
					{ key: "Supermarché", label: "Supermarché", data: "Supermarché"},
					{ key: "Hypermarché", label: "Hypermarché", data: "Hypermarché"},
					{ key: "Centre commercial", label: "Centre commercial", data: "Centre commercial"},
					{ key: "Boucher", label: "Boucher", data: "Boucher"},
					{ key: "Primeur", label: "Primeur", data: "Primeur"},
					]
				},
				{
					key: "Café - Hôtel - Restaurant",
					label: "Café - Hôtel - Restaurant",
					data: "Café - Hôtel - Restaurant",
					icon: "pi pi-fw pi-sun",
					children: [
					{ key: "Hôtel", label: "Hôtel", data: "Hôtel"},
					{ key: "Bar", label: "Bar", data: "Bar"},
					{ key: "Café", label: "Café", data: "Café"},
					{ key: "Restaurant", label: "Restaurant", data: "Restaurant"},
					{ key: "Camping", label: "Camping", data: "Camping"},
					{ key: "Camping caravane", label: "Camping caravane", data: "Camping caravane"},
					]
				},
				{
					key: "Culture",
					label: "Culture",
					data: "Culture",
					icon: "pi pi-fw pi-eye",
					children: [
					{ key: "Librairie", label: "Librairie", data: "Librairie"},
					{ key: "Marchand de journaux", label: "Marchand de journaux", data: "Marchand de journaux"},
					{ key: "Château", label: "Château", data: "Château"},
					{ key: "Musée", label: "Musée", data: "Musée"},
					{ key: "Parc", label: "Parc", data: "Parc"},
					{ key: "Théâtre", label: "Théâtre", data: "Théâtre"},
					]
				},
				{
					key: "Loisirs",
					label: "Loisirs",
					data: "Loisirs",
					icon: "pi pi-fw pi-ticket",
					children: [
					{ key: "Magasin de jouets", label: "Magasin de jouets", data: "Magasin de jouets"},
					{ key: "Fleuriste", label: "Fleuriste", data: "Fleuriste"},
					{ key: "Boutique de souvenirs", label: "Boutique de souvenirs", data: "Boutique de souvenirs"},
					{ key: "Magasin de sport", label: "Magasin de sport", data: "Magasin de sport"},
					{ key: "Papeterie", label: "Papeterie", data: "Papeterie"},
					{ key: "Tabac - Presse", label: "Tabac - Presse", data: "Tabac - Presse"},
					{ key: "Cinéma", label: "Cinéma", data: "Cinéma"},
					{ key: "Terrain de golf", label: "Terrain de golf", data: "Terrain de golf"},
					{ key: "Patinoire", label: "Patinoire", data: "Patinoire"},
					{ key: "Terrain de sport", label: "Terrain de sport", data: "Terrain de sport"},
					{ key: "Aire de jeux", label: "Aire de jeux", data: "Aire de jeux"},
					{ key: "Centre sportif", label: "Centre sportif", data: "Centre sportif"},
					{ key: "Stade", label: "Stade", data: "Stade"},
					{ key: "Piscine", label: "Piscine", data: "Piscine"},
					{ key: "Zoo", label: "Zoo", data: "Zoo"},
					]
				},
				{
					key: "Equipement de la maison",
					label: "Equipement de la maison",
					data: "Equipement de la maison",
					icon: "pi pi-fw pi-home",
					children: [
					{ key: "Magasin de bricolage", label: "Magasin de bricolage", data: "Magasin de bricolage"},
					{ key: "Jardinerie", label: "Jardinerie", data: "Jardinerie"},
					{ key: "Magasin de meubles", label: "Magasin de meubles", data: "Magasin de meubles"},
					]
				},
				{
					key: "Equipement de la personne",
					label: "Equipement de la personne",
					data: "Equipement de la personne",
					icon: "pi pi-fw pi-user",
					children: [
					{ key: "Magasin de chaussures", label: "Magasin de chaussures", data: "Magasin de chaussures"},
					{ key: "Bijouterie", label: "Bijouterie", data: "Bijouterie"},
					{ key: "Magasin de vêtements", label: "Magasin de vêtements", data: "Magasin de vêtements"},
					]
				},
				{
					key: "Equipement public",
					label: "Equipement public",
					data: "Equipement public",
					icon: "pi pi-fw pi-envelope",
					children: [
					{ key: "Police", label: "Police", data: "Police"},
					{ key: "Hôtel de ville", label: "Hôtel de ville", data: "Hôtel de ville"},
					{ key: "Tribunal", label: "Tribunal", data: "Tribunal"},
					{ key: "Bureau de poste", label: "Bureau de poste", data: "Bureau de poste"},
					{ key: "Enseignement", label: "Enseignement", data: "Enseignement"},
					{ key: "Université", label: "Université", data: "Université"},
					{ key: "Prison", label: "Prison", data: "Prison"},
					{ key: "Bâtiment public", label: "Bâtiment public", data: "Bâtiment public"},
					]
				},
				{
					key: "Santé - Beauté",
					label: "Santé - Beauté",
					data: "Santé - Beauté",
					icon: "pi pi-fw pi-id-card",
					children: [
					{ key: "Hôpital", label: "Hôpital", data: "Hôpital"},
					{ key: "Pharmacie", label: "Pharmacie", data: "Pharmacie"},
					{ key: "Cosmétique", label: "Cosmétique", data: "Cosmétique"},
					{ key: "Opticien", label: "Opticien", data: "Opticien"},
					{ key: "Coiffeur", label: "Coiffeur", data: "Coiffeur"},
					{ key: "Dentiste", label: "Dentiste", data: "Dentiste"},
					{ key: "Salon de beauté", label: "Salon de beauté", data: "Salon de beauté"},
					{ key: "Médecins", label: "Médecins", data: "Médecins"},
					{ key: "Maison de repos", label: "Maison de repos", data: "Maison de repos"},
					]
				},
				{
					key: "Services",
					label: "Services",
					data: "Services",
					icon: "pi pi-fw pi-money-bill",
					children: [
					{ key: "Vendeur auto", label: "Vendeur auto", data: "Vendeur auto"},
					{ key: "Banque", label: "Banque", data: "Banque"},
					{ key: "Magasin de téléphonie", label: "Magasin de téléphonie", data: "Magasin de téléphonie"},
					{ key: "Cimetière", label: "Cimetière", data: "Cimetière"},
					{ key: "Garderie", label: "Garderie", data: "Garderie"},
					]
				},
			],
			optionsSave: [
				{
					key: "Transports",
					label: "Transports",
					data: "Transports",
					icon: "pi pi-fw pi-car",
					children: [
					{ key: "Bus", label: "Bus", data: "Bus"},
					{ key: "Tramway", label: "Tramway", data: "Tramway"},
					{ key: "Métro", label: "Métro", data: "Métro"},
					{ key: "Gare", label: "Gare", data: "Gare"},
					{ key: "Aéroport", label: "Aéroport", data: "Aéroport"},
					{ key: "GPE", label: "Grand Paris Express", data: "GPE"},
					]
				},
				{
					key: "Education",
					label: "Education",
					data: "Education",
					icon: "pi pi-fw pi-book",
					children: [
					{ key: "Collége", label: "Collége", data: "Collége"},
					{ key: "Ecole élémentaire", label: "Ecole élémentaire", data: "Ecole élémentaire"},
					{ key: "Ecole maternelle", label: "Ecole maternelle", data: "Ecole maternelle"},
					{ key: "Lycée", label: "Lycée", data: "Lycée"},
					{ key: "Section d'enseignement", label: "Section d'enseignement", data: "Section d'enseignement"},
					{ key: "Enseignement spécialisé", label: "Enseignement spécialisé", data: "Enseignement spécialisé"},
					{ key: "Enseignement supérieur", label: "Enseignement supérieur", data: "Enseignement supérieur"},
					]
				},
				{
					key: "Alimentaire",
					label: "Alimentaire",
					data: "Alimentaire",
					icon: "pi pi-fw pi-shopping-cart",
					children: [
					{ key: "Boulangerie", label: "Boulangerie", data: "Boulangerie"},
					{ key: "Epicerie", label: "Epicerie", data: "Epicerie"},
					{ key: "Supermarché", label: "Supermarché", data: "Supermarché"},
					{ key: "Hypermarché", label: "Hypermarché", data: "Hypermarché"},
					{ key: "Boucher", label: "Boucher", data: "Boucher"},
					{ key: "Primeur", label: "Primeur", data: "Primeur"},
					]
				},
				{
					key: "Café - Hôtel - Restaurant",
					label: "Café - Hôtel - Restaurant",
					data: "Café - Hôtel - Restaurant",
					icon: "pi pi-fw pi-sun",
					children: [
					{ key: "Hôtel", label: "Hôtel", data: "Hôtel"},
					{ key: "Bar", label: "Bar", data: "Bar"},
					{ key: "Café", label: "Café", data: "Café"},
					{ key: "Restaurant", label: "Restaurant", data: "Restaurant"},
					{ key: "Club", label: "Club", data: "Club"},
					]
				},
				{
					key: "Achats de proximité",
					label: "Achats de proximité",
					data: "Achats de proximité",
					icon: "pi pi-fw pi-gift",
					children: [
					{ key: "Magasin de jouets", label: "Magasin de jouets", data: "Magasin de jouets"},
					{ key: "Fleuriste", label: "Fleuriste", data: "Fleuriste"},
					{ key: "Librairie", label: "Librairie", data: "Librairie"},
					{ key: "Marchand de journaux", label: "Marchand de journaux", data: "Marchand de journaux"},
					{ key: "Tabac - Presse", label: "Tabac - Presse", data: "Tabac - Presse"},
					]
				},
				{
					key: "Shopping",
					label: "Shopping",
					data: "Shopping",
					icon: "pi pi-fw pi-shopping-bag",
					children: [
					{ key: "Magasin de bricolage", label: "Magasin de bricolage", data: "Magasin de bricolage"},
					{ key: "Jardinerie", label: "Jardinerie", data: "Jardinerie"},
					{ key: "Magasin de meubles", label: "Magasin de meubles", data: "Magasin de meubles"},
					{ key: "Marchand de vêtements", label: "Marchand de vêtements", data: "Marchand de vêtements"},
					{ key: "Friperie", label: "Friperie", data: "Friperie"},
					{ key: "Magasin de chaussures", label: "Magasin de chaussures", data: "Magasin de chaussures"},
					{ key: "Bijouterie", label: "Bijouterie", data: "Bijouterie"},
					{ key: "Vendeur de vélo", label: "Vendeur de vélo", data: "Vendeur de vélo"},
					{ key: "Magasin de sport", label: "Magasin de sport", data: "Magasin de sport"},
					]
				},
				{
					key: "Equipement public",
					label: "Equipement public",
					data: "Equipement public",
					icon: "pi pi-fw pi-envelope",
					children: [
					{ key: "Police", label: "Police", data: "Police"},
					{ key: "Ambassade", label: "Ambassade", data: "Ambassade"},
					{ key: "Hôtel de ville", label: "Hôtel de ville", data: "Hôtel de ville"},
					{ key: "Tribunal", label: "Tribunal", data: "Tribunal"},
					{ key: "Bureau de poste", label: "Bureau de poste", data: "Bureau de poste"},
					]
				},
				{
					key: "Santé",
					label: "Santé",
					data: "Santé",
					icon: "pi pi-fw pi-inbox",
					children: [
					{ key: "Hôpital", label: "Hôpital", data: "Hôpital"},
					{ key: "Pharmacie", label: "Pharmacie", data: "Pharmacie"},
					{ key: "Opticien", label: "Opticien", data: "Opticien"},
					{ key: "Dentiste", label: "Dentiste", data: "Dentiste"},
					{ key: "Médecin", label: "Médecin", data: "Médecin"},
					]
				},
				{
					key: "Beauté",
					label: "Beauté",
					data: "Beauté",
					icon: "pi pi-fw pi-user",
					children: [
					{ key: "Cosmétique", label: "Cosmétique", data: "Cosmétique"},
					{ key: "Coiffeur", label: "Coiffeur", data: "Coiffeur"},
					{ key: "Salon de beauté", label: "Salon de beauté", data: "Salon de beauté"},
					]
				},
				{
					key: "Services",
					label: "Services",
					data: "Services",
					icon: "pi pi-fw pi-money-bill",
					children: [
					{ key: "Distributeurs", label: "Distributeurs", data: "Distributeurs"},
					{ key: "Location de voiture", label: "Location de voiture", data: "Location de voiture"},
					{ key: "Vétérinaire", label: "Vétérinaire", data: "Vétérinaire"},
					{ key: "Vendeur auto", label: "Vendeur auto", data: "Vendeur auto"},
					{ key: "Agence de voyage", label: "Agence de voyage", data: "Agence de voyage"},
					{ key: "Garage", label: "Garage", data: "Garage"},
					{ key: "Blanchisserie", label: "Blanchisserie", data: "Blanchisserie"},
					{ key: "Banque", label: "Banque", data: "Banque"},
					]
				},
			],
			root: [
				{
					key: "0",
					label: "Documents",
					data: "Documents Folder",
					icon: "pi pi-fw pi-inbox",
					children: [{
						key: "0-0",
						label: "Work",
						data: "Work Folder",
						icon: "pi pi-fw pi-cog",
						children: [{ "key": "0-0-0", "label": "Expenses.doc", "icon": "pi pi-fw pi-file", "data": "Expenses Document" }, { "key": "0-0-1", "label": "Resume.doc", "icon": "pi pi-fw pi-file", "data": "Resume Document" }]
					}]
				},
			],
			options: [
				{
					id: 'Transports',
					label: 'Transports',
					children: [ {
						id: 'Bus',
						label: 'Bus',
					}, {
						id: 'Tramway',
						label: 'Tramway',
					}, {
						id: 'Métro',
						label: 'Métro',
					}, 
					{
						id: 'Gare',
						label: 'Gare',
					}, {
						id: 'Aéroport',
						label: 'Aéroport',
					}
					],
				},
				{
					id: 'Education',
					label: 'Education',
					children: [ {
						id: 'Collége',
						label: 'Collége',
					}, {
						id: 'Ecole élémentaire',
						label: 'Ecole élémentaire',
					}, {
						id: 'Ecole maternelle',
						label: 'Ecole maternelle',
					}, {
						id: 'Lycée',
						label: 'Lycée',
					}, {
						id: 'Section d\'enseignement',
						label: 'Section d\'enseignement',
					}, {
						id: 'Enseignement spécialisé',
						label: 'Enseignement spécialisé',
					}, {
						id: 'Enseignement supérieur',
						label: 'Enseignement supérieur',
					},
					],
				},
				{
					id: 'Alimentaire',
					label: 'Alimentaire',
					children: [ {
						id: 'Boulangerie',
						label: 'Boulangerie',
					}, {
						id: 'Epicerie',
						label: 'Epicerie',
					}, {
						id: 'Supermarché',
						label: 'Supermarché',
					}, {
						id: 'Hypermarché',
						label: 'Hypermarché',
					}, {
						id: 'Boucher',
						label: 'Boucher',
					}, {
						id: 'Primeur',
						label: 'Primeur',
					}],
				},
				{
					id: 'Café - Hôtel - Restaurant',
					label: 'Café - Hôtel - Restaurant',
					children: [ {
						id: 'Hôtel',
						label: 'Hôtel',
					}, {
						id: 'Bar',
						label: 'Bar',
					}, {
						id: 'Café',
						label: 'Café',
					}, {
						id: 'Restaurant',
						label: 'Restaurant',
					}, {
						id: 'Club',
						label: 'Club',
					}],
				},
				{
					id: 'Achats de proximité',
					label: 'Achats de proximité',
					children: [ {
						id: 'Magasin de jouets',
						label: 'Magasin de jouets',
					}, {
						id: 'Fleuriste',
						label: 'Fleuriste',
					}, {
						id: 'Librairie',
						label: 'Librairie',
					}, {
						id: 'Marchand de journaux',
						label: 'Marchand de journaux',
					}, {
						id: 'Tabac - Presse',
						label: 'Tabac - Presse',
					}],
				},
				{
					id: 'Shopping',
					label: 'Shopping',
					children: [{
						id: 'Magasin de bricolage',
						label: 'Magasin de bricolage',
					}, {
						id: 'Jardinerie',
						label: 'Jardinerie',
					}, {
						id: 'Magasin de meubles',
						label: 'Magasin de meubles',
					}, {
						id: 'Magasin de vêtements',
						label: 'Magasin de vêtements',
					}, {
						id: 'Friperie',
						label: 'Friperie',
					}, {
						id: 'Magasin de chaussures',
						label: 'Magasin de chaussures',
					}, {
						id: 'Bijouterie',
						label: 'Bijouterie',
					}, {
						id: 'Vendeur de vélo',
						label: 'Vendeur de vélo',
					}, {
						id: 'Magasin de sport',
						label: 'Magasin de sport',
					}],
				},
				{
					id: 'Equipement public',
					label: 'Equipement public',
					children: [ {
						id: 'Police',
						label: 'Police',
					}, {
						id: 'Ambassade',
						label: 'Ambassade',
					}, {
						id: 'Hôtel de ville',
						label: 'Hôtel de ville',
					}, {
						id: 'Tribunal',
						label: 'Tribunal',
					}, {
						id: 'Bureau de poste',
						label: 'Bureau de poste',
					}],
				},
				{
					id: 'Santé',
					label: 'Santé',
					children: [ {
						id: 'Hôpital',
						label: 'Hôpital',
					}, {
						id: 'Pharmacie',
						label: 'Pharmacie',
					}, {
						id: 'Opticien',
						label: 'Opticien',
					}, {
						id: 'Dentiste',
						label: 'Dentiste',
					}, {
						id: 'Médecin',
						label: 'Médecin',
					}],
				},
				{
					id: 'Beauté',
					label: 'Beauté',
					children: [{
						id: 'Cosmétique',
						label: 'Cosmétique',
					}, {
						id: 'Coiffeur',
						label: 'Coiffeur',
					}, {
						id: 'Salon de beauté',
						label: 'Salon de beauté',
					}],
				},
				{
					id: 'Services',
					label: 'Services',
					children: [ {
						id: 'Distributeurs',
						label: 'Distributeurs',
					}, {
						id: 'Location de voiture',
						label: 'Location de voiture',
					}, {
						id: 'Vétérinaire',
						label: 'Vétérinaire',
					}, {
						id: 'Vendeur auto',
						label: 'Vendeur auto',
					}, {
						id: 'Agence de voyage',
						label: 'Agence de voyage',
					}, {
						id: 'Garage',
						label: 'Garage',
					}, {
						id: 'Blanchisserie',
						label: 'Blanchisserie',
					}, {
						id: 'Banque',
						label: 'Banque',
					}],
				}
			],
		}
	},

	watch: {
		'app.viewlayer': function() {
			this.handleResize();
		},

		'app.viewlayer_poi': function(v) {
			if (v) {
				// Nothing
			}
		},

		'app.contours_time': function(v) {
			if (v) {
				this.UpdateLayer()
			}
		},
	},

	methods: {
		generateProgImage() {

			const elementToExport = document.getElementById('FicheTerritoire');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};
		
			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'FicheTerritoire.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});
		},

		formatnb: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		formatnb2: function(p) {
			let nPrice=p
			nPrice = Math.round(nPrice*100)/100
			const euro = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		convertToRoman: function(num) {
			if (num=='01 bis' || num=='1 bis') return 'I bis'
			if (num=='1' || num=='01') return 'I'
			if (num=='2' || num=='02') return 'II'
			if (num=='3' || num=='03') return 'III' 
			return 'NC'
		},
		getInseePopulation: function() {
			let dif = this.app.insee[0].data[this.app.insee[0].data.length-1].population.population - this.app.insee[0].data[0].population.population;
			let difp = Math.round((dif/this.app.insee[0].data[0].population.population) * 100,2)
			let difpn = difp
			if (dif > 0) dif = "+" + dif.toString()
			else dif = dif.toString()
			if (difp > 0) difp = "+" + difp.toString()
			else difp = difp.toString()
			difp = difp + "%"
			return { g: dif, p: difp, pn:difpn};
		},

		maj_age: function() {
			if (this.app.insee && this.app.insee.length) {
				this.chartOptions_men_tranche.series[0].data = [
					{name: 'Agriculteur', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.farmer_rate},
					{name: 'Entrepreneur', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.entrepreneur_rate},
					{name: 'Sans activité', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.without_activity_rate},
					{name: 'Sénior', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.senior_executive_rate},
					{name: 'Employé', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.employee_rate},
					{name: 'Travailleur indépendant', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.worker_rate},
					{name: 'Professions libérales', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.intermediaite_profession_rate},
					{name: 'Retiré', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.retired_rate}
				]
			}
		},

		poiChange: function (value, instanceId) {
			if (!(instanceId || !instanceId)) console.log(instanceId)
			gtag('event', 'map_layer_poi', {'event_category': 'map','event_label': 'map_layer_poi'});
			this.$store.dispatch('send_event_backend', {module: 'layer', action: 'poi', option: value});
			this.$store.dispatch('setContours', {type: 'poi', contours: value})
		},

		poiChange2: function () {
			this.$store.dispatch('set_view_layer_poi', {view: true})			
			let value = [];
			for (let attribut in this.selectedKey1) {
				// eslint-disable-next-line 
				if (this.selectedKey1.hasOwnProperty(attribut)) {
					if (this.selectedKey1[attribut].checked) value.push(attribut)
				}
			}
			gtag('event', 'map_layer_poi', {'event_category': 'map','event_label': 'map_layer_poi'});
			this.$store.dispatch('send_event_backend', {module: 'layer', action: 'poi', option: value});
			console.log(value)
			this.$store.dispatch('setContours', {type: 'poi', contours: value})

			this.$store.dispatch('set_view_layer_poi', {view: true})			
		},

		poiSelect: function () {			
			if (!(instanceId || !instanceId)) console.log(instanceId)
		},

		poiDeselect: function () {			
			if (!(instanceId || !instanceId)) console.log(instanceId)
		},

		poiSelect2: function () {			
			this.poiChange2();
		},

		poiDeselect2: function () {			
			this.poiChange2();
		},

		setf: function() {
			this.$store.dispatch('set_fond_carte', {fond: this.fond})
		},

		setfl: function() {
			this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: this.fond_loyer})
		},	

		setcontours: function(type, b) {
			gtag('event', 'map_layer_zone', {'event_category': 'map','event_label': 'map_layer_zone'});
			this.$store.dispatch('send_event_backend', {module: 'layer', action: 'zone', option: type});
			this.$store.dispatch('setContours', {type: type, contours: b})
		},

		setContour: function(el) {
			this.$store.dispatch('set_contour_iso', { contour: el })
		},

		toggle_switch_two: function(duration) {
			this.$store.dispatch('set_contour_iso_param', {transportType: this.app.cityscan.isochrone.transportType, duration: duration})			
		},

		toggle_switch: function(transportType) {
			this.$store.dispatch('set_contour_iso', {contours: false})
			this.$store.dispatch('set_contour_iso_param', {transportType: transportType, duration: this.app.cityscan.isochrone.duration})
		},

		click: function(pin_type) {
			this.visible=false
			this.$store.dispatch('pin_type', {pin_type: pin_type})
		},

		set_view: function() {
			this.$store.dispatch('set_view_layer', { view: '' })
		},

		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		},

		handleResize() {
			this.setElementMaxHeight();
		},

		setElementMaxHeight() {
			// Nothing
		},
/*
		UpdateLayer() {
			this.contour_com = this.app.contour_commune
			this.qpv = this.app.contour_qpv
			this.zac = this.app.contour_zac
			this.qpv_trois = this.app.contour_qpv_trois
			this.fond = this.app.fond_carte

			this.selectedKey1 = []
			for (let attribut in this.app.contour_poi) {
				// eslint-disable-next-line 
				let sa = this.app.contour_poi[attribut]
				this.selectedKey1[sa] = {partialChecked: false, checked: true}
				for (let i in this.options2) {
					for (let j in this.options2[i].children) {
						if (this.options2[i].children[j].key == sa) {
							this.selectedKey1[this.options2[i].key] = {partialChecked: true, checked: false}
						}
					}
				}
			}
			
			for (let i in this.options2) {
				let b = true
				for (let j in this.options2[i].children) {
					if (this.selectedKey1[this.options2[i].children[j].key]) {
						if (!this.selectedKey1[this.options2[i].children[j].key].checked) {
							b = false
							break;
						}
					} else {
						b = false
						break;
					}
				}
				if (b) {
					this.selectedKey1[this.options2[i].key] = {partialChecked: false, checked: true}
				}
			}

		}
*/
		UpdateLayer: function() {
			const app = this.app;

			this.contour_com = app.contour_commune;
			this.qpv = app.contour_qpv;
			this.zac = app.contour_zac;
			this.qpv_trois = app.contour_qpv_trois;
			this.fond = app.fond_carte;

			this.selectedKey1 = [];

			// Initialize selected keys and update partialChecked
			if (app.contour_poi) {
				app.contour_poi.forEach(sa => {
					this.selectedKey1[sa] = { partialChecked: false, checked: true };
					this.options2.forEach(option => {
						option.children.forEach(child => {
							if (child.key === sa) {
								this.selectedKey1[option.key] = { partialChecked: true, checked: false };
							}
						});
					});
				});
			}

			// Update checked status for parent keys
			this.options2.forEach(option => {
				let allChecked = option.children.every(child =>
					this.selectedKey1[child.key] && this.selectedKey1[child.key].checked
				);
				if (allChecked) {
					this.selectedKey1[option.key] = { partialChecked: false, checked: true };
				}
			});
		}

	},

	mounted() {
		this.UpdateLayer()

		this.maj_age()
		window.addEventListener('resize', this.handleResize);
	},

	beforeDestroy() {
		// Nothing
	},
	
	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs' ]), {
		chartDataMen:function() {
			let t = [
				{label: "29 ans et moins", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number/this.max_pop*100)},
				{label: "de 30 à 39 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number/this.max_pop*100)},
				{label: "de 40 à 49 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number/this.max_pop*100)},
				{label: "de 50 à 59 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number/this.max_pop*100)},
				{label: "de 60 à 74 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number/this.max_pop*100)},
				{label: "75 ans et plus", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number/this.max_pop*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataForme:function() {
			let t = [
				{label: "Collectif", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100)},
				{label: "Individuel", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataFormeGraph:function() {
			
			let t = [
				{label: "Collectif", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100)},
				{label: "Individuel", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});

			let j = {
                labels: [t[0].label,t[1].label],
                datasets: [
                    {
                        data: [t[0].nb, t[1].nb],
                        backgroundColor: [
							"#6b23f1",
                            "transparent",
                        ],
                        hoverBackgroundColor: [
                            "#6b23f1",
                        ]
                    }
                ]
            }

			return j
		},		

		chartDataOccupationGraph:function() {
			
			let t = [
				{label: "Propriétaire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100)},
				{label: "Locataire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100)},
				{label: "Social", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100)},
				{label: "Gratuit", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});

			let j = {
                labels: [t[0].label,t[1].label,t[2].label,t[3].label],
                datasets: [
                    {
                        data: [t[0].nb, t[1].nb, t[2].nb, t[3].nb],
                        backgroundColor: [
						"#6a25e9",
						"#c2abed",
						"#d5c5f0",
						"#e9d3fc",
                        ],
                        hoverBackgroundColor: [
                            "#6b23f1",
                        ]
                    }
                ]
            }

			return j
		},		

		chartDataOccupation:function() {
			let t = [
			{label: "Propriétaire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100)},
			{label: "Locataire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100)},
			{label: "Social", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100)},
			{label: "Gratuit", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataFU: function() {
			let v = {
				labels: ['Collectif','Individuel'],
				datasets: [
					{
						data: [this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100, this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100],
						backgroundColor: [
                            "#42A5F5",
                            "#66BB6A"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784"
                        ]
					}
				]
			}
			return v
		},

		chartOptionsFU: function() {
			return {
				legend: {
					display:false
                }
			}
		},
			
		chartDataOcc: function() {
			let v = {
				labels: ['Propriétaire','Locataire libre', 'Locataire social', 'à titre gratuit'],
				datasets: [
					{
						data: [
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100
						],
						backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
							"#FFA726",
							"#FF6384"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D",
							"#FF6384"
                        ]
					}
				]
			}
			return v
		},

		chartOptionsOcc: function() {
			return {
				legend: {
					display:false
                }
			}
		},
			
		rep_30: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number) / this.max_pop * 100)
		},

		rep_39: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number) / this.max_pop * 100)
		},

		rep_49: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number) / this.max_pop * 100)
		},

		rep_59: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number) / this.max_pop * 100)
		},

		rep_74: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number) / this.max_pop * 100)
		},
		
		rep_75: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number) / this.max_pop * 100)
		},

		max_mediane:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income
			return ((Math.round(n/1000,0)+1) * 1000)

		},

		max_pop:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number
			return n
		},

		max_em:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.same_lodgment
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_lodgment_same_city
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_agglomeration
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_department
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_departement_same_region
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_region_mainland
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_no_mainland
			return n
		},

		chartData2:function() {
			let chartDataLocal = {
				datasets: [{
					data: [
						0,
						0,
						0,
						0,
						0
					],
					backgroundColor: [
						"#6366f1",
                        "#f97316",
                        "#3b82f6",
						"#a855f7",
						"#eab308",
                        "#06b6d4",
                        "#06b6d4"
					],
					label: 'My dataset'
				}],
				labels: [
					"Même logement",
					"Même ville",
					"Même agglomération",
					"Même département",
					"Même région",
					"Autre région",
					"Etranger"
				]
			}

			chartDataLocal.datasets[0] = {
				data: [
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.same_lodgment,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_lodgment_same_city,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_agglomeration,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_department,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_departement_same_region,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_region_mainland,
					this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_no_mainland,
				],
					backgroundColor: [
						"#6366f1",
                        "#f97316",
                        "#3b82f6",
						"#a855f7",
						"#eab308",
                        "#06b6d4"
					]}
			return chartDataLocal
		},

		chartData:function() {
			let chartDataLocal = {
				datasets: [
					{
					data: [
						0,
						0,
						0,
						0,
						0,
						0
					],
					backgroundColor: [
						"#6366f1",
                        "#f97316",
                        "#3b82f6",
						"#a855f7",
						"#eab308",
                        "#06b6d4"
					],
					label: 'My dataset'
				}],
				labels: [
					"30 ans et moins",
					"30 à 39 ans",
					"40 à 49 ans",
					"50 à 59 ans",
					"60 à 74 ans",
					"75 ans et plus"
				]
			}
			chartDataLocal.datasets[0] = {
				data: [
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number,
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number,
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number,
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number,
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number,
						this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number
					],
					backgroundColor: [
						"#6366f1",
                        "#f97316",
                        "#3b82f6",
						"#a855f7",
						"#eab308",
                        "#06b6d4"
					]
				}

			return chartDataLocal
		},

	}),

	// eslint-disable-next-line
	components: { Tree, ScrollPanel, SwitchButton, Chart, Chip, Knob, domtoimage }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.vue-treeselect
	line-height 32px !important

.vue-treeselect__control
	line-height 32px !important

.vue-treeselect__value-container
	line-height 32px !important

.group_layer
	padding-bottom 20px
	border-bottom 1px solid #c0c0c0

.pane-layer
	position fixed
	left 100%
	top headersize
	bottom 0
	height calc(100% - 155px)
	width 400px
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	z-index 500
	&.layer
		transform translateX(-100%)
	&.isochrone
		transform translateX(-100%)
	&.legende
		transform translateX(-100%)
	&.insee
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.header
		background secondary
		height 60px
		.back-button
			position absolute
			right 10px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.print-button
			position absolute
			right 68px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.texte
			top 22px
			left 10px
			height 48px
			width 250px
			color white
		.tabs
			padding 0 32px
			color #fff
			&:after
				content ''
				display block
				clear both
			.tab
				float left
				margin 0 32px 0 0
				height 96px
				width 96px
				display flex
				align-items center
				justify-content center
				flex-direction column
				opacity 0.5
				font-size 12px
				font-weight 500
				border-bottom 4px solid transparent
				text-align center
				text-transform uppercase
				cursor pointer
				&:hover
					opacity 0.75
				&.active
					opacity 1
					border-color light
				.icon
					display block
					margin 0 0 4px 0
				
	.right-bar
		padding-left 10px
		height 100%
		background white
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1

.checkbox
	position absolute
	left 0px
	top 4px
	.text
		padding-left 20px
		display block
		line-height 24px
		white-space nowrap
	&.checked
		background-color transparent !important
		.chk .icon
			color palette0
			display block
	&:hover
		border-color palette2
		.chk
			background-color #e5e5e5
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
.choix
	padding-bottom 20px

.mrk
	left 33%
	width 24px
	height 24px
	color #f0f2f4
	font-weight 600
	border-radius 100%
	text-align center
	line-height 24px
	cursor pointer
	-webkit-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	&.projet
		background #ccc
	&.actif
		background #29b6f6
	&.termine
		background #0086c3
	&.entree
		background #fcc434
	&.milieu
		background #ee8d3e
	&.haut
		background #d74a22
	&.col
		background #ff4081
	&.ind
		background #f8bbd0

.zon
	border-radius 5px
	padding-right 5px
	padding-left 5px
	line-height 24px
	margin-right 5px
.z1b
	background #d92c21
	color #fff
.z1
	background #efae1f
	color #fff
.z2
	background #7ab2d5
	color #fff
.z3
	background #fdfdfd
.zabis
	background #d92c21
	color #fff
.za	
	background #f5aa21
	color #fff
.zb1
	background #649770
	color #fff
.zb2
	background #aacde1
	color #fff
.zc
	background #ffffff
.red
	color red
.green
	color #32CD32
.kpi_label
	height 1.5rem
	padding-top 7px


.vue-treeselect--open-above .vue-treeselect__menu {
}
	
:deep(.p-tooltip) {
	max-width: 18rem !important;
}

:deep(.p-tooltip-text) {
	font-size:10pt !important;
}

.p-tooltip-text {
	font-size:10pt !important;
}
.p-tooltip {
	max-width: 18rem !important;
}

.overlay1 {
	position: absolute;
	top: 80px;
	left: 290px;
	color: #fff;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay2 {
	position: absolute;
	top: 170px;
	left: 288px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay3 {
	position: absolute;
	top: 168px;
	left: 212px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: right;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay4 {
	position: absolute;
	top: 110px;
	left: 212px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: right;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}
</style>
