<template>
	<transition name="pane-programs">
		<div class="paneleft pane pane-programs" :class="app.view">
			<div class="module_ret">
				<div class="head_filter" @click.stop="$store.dispatch('set_filters_rs', {filters_rs:!app.filters_rs})" title="Filtrer ma recherche" :class="{ idf: !app.module_actif }">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
				<div class="res">{{ app.rss_filter.length }} résidence<span v-show="app.rss_filter.length > 1">s</span> {{ $t('trouvee') }}<span v-show="app.rss_filter.length > 1">s</span></div>

				<div v-if="app.authorized&&!app.nolive" class="field-checkbox"  style="position:absolute; right: 16px; top: 28%; top: 34%; right: 6px;">
					<Checkbox id="city4" name="city" value="San Francisco" v-model="toggle_all" :binary="true" @click="toggle_select_all(!toggle_all)" />
				</div>
			</div>
			<div class="panecontent programs" :class="{ 'empty': !app.programs.length }">
				<div class="programs-list" :class="{nolive: app.nolive}">
					<div class="programs-list-content" v-bar>
						<div class="content" ref="scrollable_programs">
							
							
							<div class="single-accordion">
								<Panel :toggleable="true" :collapsed="false" class="accordion-item" open v-for="commune in app.rs_communes" v-bind:key="commune">
									<template #header>
										{{ commune }}
										<div v-if="app.authorized&&!app.nolive" class="field-checkbox"  style="position:absolute; right: 60px; top: 28%;">
											<Checkbox id="city" name="city" :value=commune v-model="toggles_communes" @click="toggle_select_commune(commune)" />
										</div>
									</template>
									<div class="accordion-content" v-show="commune_visible(commune)" :id=commune style="width: 100%">
										<rs
										v-for="rs in getRssCommune(commune)"
										@mouseenter.native="hover_program(rs)"
										@mouseleave.native="unhover_program(rs)"
										:key="rs.id"
										:id="'rs-' + rs.id"
										:rs="rs"
										:class="{ disabled: app.loading || !app.authorized }"
										/>
									</div>
								</Panel>
							</div>
						</div>
					</div>
				</div>
				<div class="programs-no-result" v-show="!app.rss_filter.length || app.api_calls.load_rss.state == 'error'">
					<div class="infinite-loader" v-if="app.loading">
						<div class="text">
							<p>Recherche des résidences services</p>
							<p>{{ $t('en_cours') }} ...</p>
							<p>&nbsp;</p>
						</div>
						<div class="loader"><icon file="loader" :size="64" /></div>
					</div>
					<div class="no-result-content" v-show="app.api_calls.load_rss.state == 'error' && !app.loading">
						<icon file="dead" :size="128" />
						<div class="text">
							<p>{{ $t('oups_!') }}</p>
							<p>{{ $t('il_semblerait_quil_y_ait_un_probleme_de_connexion.') }}</p>
							<p>{{ $t('êtes-vous_bien_connecte_a_internet_?') }}</p>
						</div>
					</div>
					<div class="no-result-content" v-show="app.api_calls.load_programs.state == 'success' && app.authorized && map.zoom < map.options.zoom_level_to_change_view && !app.loading">
						<icon file="analyze" :size="128" />
						<div class="text">
							<p>{{ $t('zoomer_sur_la_carte_pour_voir_les_programmes_de_cette_zone.') }}</p>
						</div>
						<div class="button" @click="zoom()">Zoom</div>
					</div>
					<div class="no-result-content" v-show="app.api_calls.load_programs.state == 'success' && app.authorized && map.zoom >= map.options.zoom_level_to_change_view && !app.loading">
						<icon file="direction" :size="128" />
						<div class="text">
							<p>{{ $t('hum...') }}</p>
							<p>{{ $t('il_ny_a_aucun_programme_observe_dans_cette_zone...') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Rs from './pane-rss/Rs'
import Panel from 'primevue/panel';
import Checkbox from 'primevue/checkbox';

export default {
	name: 'PaneRss',

	data: function() {
		return {
			//communes: [],
			lienfilresidentiel: 'https://www.myadequation.fr/EFOCUS/fil_residentiel.html?' + _.now(),
			revenueOptions : {
				series: [
				{
					name: "Prix du neuf - ventes",
					data: [0,0,0,0,0],
				},
				{
					name: "Prix du neuf - offre",
					data: [0,0,0,0,0],
				},
				],
				chart: {
					height: 250,
					type: 'area',
					offsetY: -10,
					toolbar: {
						show: false,
					},
				},
				colors: ['#8BC34A', '#f44336'],
				legend: {
					position: 'bottom',
					horizontalAlign: 'center',
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2, 2, 2],
					curve: 'smooth',
				},
				xaxis: {
					categories: [
					'2020-09-19T00:00:00.000Z',
					'2020-09-20T01:30:00.000Z',
					'2020-09-21T02:30:00.000Z',
					'2020-09-22T03:30:00.000Z',
					'2020-09-23T04:30:00.000Z',
					],
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], 
						opacity: 0.5
					}
				},
				tooltip: {
					x: {
						format: 'dd/MM/yy',
					},
					y: {
					},
				},
			},
			chartOptions_trim: {
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
					color: '#8BC34A',
					name: "Prix du neuf - ventes",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(139,195,74,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				{
					color: '#f44336',
					name: "Prix du neuf - offre",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(244,67,54,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					allowDecimals: true,
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					labels: {
						format: '{value:,.0f} €'
					}
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			nbp:0,
			nbSelect: 0,
			communes: [],
			toggle_all: false,
			toggles_communes: [],
			location: "",
			searches: [ 'Lyon', '31 rue Mazenod 69003 Lyon', 'Lyon Part-Dieu' ],
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		nbrss:function(){
			let n = 0
			for (let i=0; i<this.app.rss_filter.length; i++){
				if (!this.app.rss_filter[i].pc) n +=1
			}
			return n
		},

		rss_filter:function(){
			let t = []
			for (let n=0; n<this.app.rss.length;n++) {
				if (this.app.rss[n].filter) {
					t.push(this.app.rss[n])
				}
			}
			return t
		},
		getToggles_communes:function(commune) {
			return _.indexOf(this.toggles_communes, commune) > -1;
		}
	}),

	watch: {
		'app.api_calls.load_programs': function() {
			this.toggle_select_all(false)
			this.toggles_communes = []
		},

		'app.expanded_rs': function(newValue) {
			if (newValue) {
				let that = this
				let $scroll = $(that.$refs.scrollable_programs)
				let $program = $scroll.find('#rs-' + newValue)

				let x = $program[0].offsetTop;
				let element = $program[0].offsetParent;
				while (element) {
					x += element.offsetTop
					try {
						x -= 20
						element = element.offsetParent;
					} catch (error) {
						break;
					}
				}
				$scroll.animate({ scrollTop: x }, 250)
			}
		},

		'app.dateheure_recharge': function() {
			this.maj_graph()
		}
	},

	methods: {
		getRssCommune: function(commune) {
			let t = []
			for (let n=0; n<this.app.rss_filter.length;n++) {
				if (this.app.rss_filter[n].ville == commune) {
					t.push(this.app.rss_filter[n])
				}
			}
			return t
		},

		commune_checked: function(commune){
			return _.indexOf(this.toggles_communes, commune) > -1
		},

		prog_commune: function(commune) {
			let n = 0
			for(let program in this.app.programs_filter){
				if (this.app.programs_filter[program].ville == commune) {
					n = n+1
				}
			}
			return n
		},


		commune_visible: function(commune){
			return _.indexOf(this.communes, commune) == -1
		},
		
		plier_commune: function(commune) {			
			let n = _.indexOf(this.communes, commune)
			if (n == -1) {
				this.communes.push(commune)
			} else {
				this.communes.splice(n,1)
			}
		},

		getProgrammCommune : function(Commune) {
			let programs = []
			for(let program in this.programs){
				if (program.ville == Commune) {
					programs.push(program)
				}
			}
			return programs
		},

		set_location: function() {
			let that = this
			that.$store.dispatch('set_loading', { loading: true })
			that.$store.dispatch('set_view', { view: 'list' })
			let api = 'https://nominatim.openstreetmap.org/?format=json&addressdetails=0&q=' + this.location + '&limit=1'
			this.axios.get(api).then(function(response) {
				if (response.status == 200 && response.data && response.data[0]) {
					let point = response.data[0]
					let coords = { lat: point.lat, lng: point.lon }
					that.$store.dispatch('center_map', { coords: coords, boundingbox: point.boundingbox })
					that.$store.dispatch('set_loading', { loading: false })
				}
			})
		},

		fire_last_search: function(search) {
			this.location = search
			this.set_location()
		},

		hover_program: function(program) {
			if (program.marker) {
				program.marker.classList.add('hover')
				program.marker.classList.add('hover-from-list')
			}
		},

		unhover_program: function(program) {
			if (program.marker) {
				program.marker.classList.remove('hover')
				program.marker.classList.remove('hover-from-list')
			}
		},

		toggle_select_all: function(b) {
			this.toggle_all = b
			let selected = this.toggle_all
			if (selected) {
				this.$store.dispatch('set_nbresidenceselect', {nb: this.nbrss})
			} else  {
				this.$store.dispatch('set_nbresidenceselect', {nb : 0})
			}
			let i
			for (i in this.app.rss_filter) {
				if (selected) {
					if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville)) {
						document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville).className = 'checkbox checked'
					}					
				} else  {
					if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville)) {
						document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville).className = 'checkbox'
					}					
				}
			}
			for (i in this.app.rss) {
				if (this.app.rss[i].marker) {
					if (selected) {
						this.app.rss[i].marker.classList.add('selected')
						this.app.rss[i].marker.classList.remove('invisible')
					} else {
						this.app.rss[i].marker.classList.remove('selected')
						if (this.app.uniquementselection) this.app.rss[i].marker.classList.add('invisible')
					}
				}
			}
			
			for (i in this.app.communes) {
				this.toggle_select_commune_force(this.app.communes[i], b)
			}
		},
		
		toggle_select_commune_force: function(commune, b) {
			let n = _.indexOf(this.toggles_communes, commune)
			
			if (n==-1 && b) {
				this.toggles_communes.push(commune)
			} else {
				if (n>-1 && !b) {
					this.toggles_communes.splice(n,1)
				}
			}
			let i
			for (i in this.app.rss_filter) {
				if (document.getElementById(this.app.rss_filter[i].id + "-" + commune)) {
					if (b) {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + commune).className != 'checkbox checked') {
							document.getElementById(this.app.rss_filter[i].id + "-" + commune).className = 'checkbox checked'
							if (!this.app.rss_filter[i].pc) this.$store.dispatch('inc_nbresidenceselect', {})
						}
					} else  {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + commune).className != 'checkbox') {
							document.getElementById(this.app.rss_filter[i].id + "-" + commune).className = 'checkbox'
							if (!this.app.rss_filter[i].pc) this.$store.dispatch('dec_nbresidenceselect', {})
						}
					}
				}
			}
			for (i in this.app.rss) {
				if (this.app.rss[i].ville==commune) {
					if (this.app.rss[i].marker) {
						if (b) {
							this.app.rss[i].marker.classList.add('selected')
							this.app.rss[i].marker.classList.remove('invisible')
						} else {
							this.app.rss[i].marker.classList.remove('selected')
							if (this.app.uniquementselection) this.app.rss[i].marker.classList.add('invisible')
						}
					}
				}
			}
		},
		
		toggle_select_commune: function(commune) {
			let b = false
			let n = _.indexOf(this.toggles_communes, commune)
			
			if (n == -1) {
				this.toggles_communes.push(commune)
				b = true
			} else {
				this.toggles_communes.splice(n,1)
				b = false
			}
			let i
			for (i in this.app.rss_filter) {
				if (document.getElementById(this.app.rss_filter[i].id + "-" + commune)) {
					if (b) {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + commune).className != 'checkbox checked') {
							document.getElementById(this.app.rss_filter[i].id + "-" + commune).className = 'checkbox checked'
							if (!this.app.rss_filter[i].pc) this.$store.dispatch('inc_nbresidenceselect', {})
						}
					} else  {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + commune).className != 'checkbox') {
							document.getElementById(this.app.rss_filter[i].id + "-" + commune).className = 'checkbox'
							if (!this.app.rss_filter[i].pc) this.$store.dispatch('dec_nbresidenceselect', {})
						}
					}
				}
			}
			for (i in this.app.rss) {
				if (this.app.rss[i].ville==commune) {
					if (this.app.rss[i].marker) {
						if (b) {
							this.app.rss[i].marker.classList.add('selected')
							this.app.rss[i].marker.classList.remove('invisible')
						} else {
							this.app.rss[i].marker.classList.remove('selected')
							if (this.app.uniquementselection) this.app.rss[i].marker.classList.add('invisible')
						}
					}
				}
			}
		},

		zoom: function() {
			this.$store.dispatch('center_map', { zoom: this.map.options.zoom_level_to_change_view })
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_programs)
				let $program = $scroll.find('#' + id)
				if ($program) {
				try {
					$scroll.animate({ scrollTop: $program[0].offsetTop }, 250)
				} catch (error) {
					console.log(error)
				}
			}
			}, 100)
		},

		maj_graph: function() {
			if (this.app.stats && this.app.stats.prix_avec_stat_2 && this.app.stats.prix_avec_stat_2.prix_vente && this.app.stats.prix_avec_stat_2.prix_vente.tableau) {
				let min = this.app.stats.prix_avec_stat_2.prix_vente.tableau[0]
				let max = this.app.stats.prix_avec_stat_2.prix_vente.tableau[0]
				
				if (this.app.stats.prix_avec_stat_2.prix_vente.tableau[1] < min)
				min = this.app.stats.prix_avec_stat_2.prix_vente.tableau[1]
				
				if (this.app.stats.prix_avec_stat_2.prix_vente.tableau[2] < min)
				min = this.app.stats.prix_avec_stat_2.prix_vente.tableau[2]
				
				if (this.app.stats.prix_avec_stat_2.prix_vente.tableau[1] > max)
				max = this.app.stats.prix_avec_stat_2.prix_vente.tableau[1]
				
				if (this.app.stats.prix_avec_stat_2.prix_vente.tableau[2] > max)
				max = this.app.stats.prix_avec_stat_2.prix_vente.tableau[2]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[0] < min)
				min = this.app.stats.prix_avec_stat_2.prix_offre.tableau[0]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[1] < min)
				min = this.app.stats.prix_avec_stat_2.prix_offre.tableau[1]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[2] < min)
				min = this.app.stats.prix_avec_stat_2.prix_offre.tableau[2]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[0] > max)
				max = this.app.stats.prix_avec_stat_2.prix_offre.tableau[0]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[1] > max)
				max = this.app.stats.prix_avec_stat_2.prix_offre.tableau[1]
				
				if (this.app.stats.prix_avec_stat_2.prix_offre.tableau[2] > max)
				max = this.app.stats.prix_avec_stat_2.prix_offre.tableau[2]
				
				this.chartOptions_trim.yAxis.min = min
				this.chartOptions_trim.yAxis.max = max
				this.chartOptions_trim.series[0].data = [
				this.app.stats.prix_avec_stat_2.prix_vente.tableau[0], 
				this.app.stats.prix_avec_stat_2.prix_vente.tableau[1], 
				this.app.stats.prix_avec_stat_2.prix_vente.tableau[2], 
				]
				this.chartOptions_trim.series[1].data = [
				this.app.stats.prix_avec_stat_2.prix_offre.tableau[0], 
				this.app.stats.prix_avec_stat_2.prix_offre.tableau[1], 
				this.app.stats.prix_avec_stat_2.prix_offre.tableau[2], 
				]
				
				
				this.revenueOptions.series = 
				[
				{
					name: "Prix du neuf - ventes",
					data: [this.app.stats.prix_avec_stat.prix_vente.tableau[0], this.app.stats.prix_avec_stat.prix_vente.tableau[1], this.app.stats.prix_avec_stat.prix_vente.tableau[2], this.app.stats.prix_avec_stat.prix_vente.tableau[3], this.app.stats.prix_avec_stat.prix_vente.tableau[4]],
				},
				{
					name: "Prix du neuf - offre",
					data: [this.app.stats.prix_avec_stat.prix_offre.tableau[0], this.app.stats.prix_avec_stat.prix_offre.tableau[1], this.app.stats.prix_avec_stat.prix_offre.tableau[2], this.app.stats.prix_avec_stat.prix_offre.tableau[3], this.app.stats.prix_avec_stat.prix_offre.tableau[4]],
				},
				],
				this.revenueOptions.xaxis = {
					categories: [
					this.app.stats.prix_avec_stat.intitule_colonne[3],
					this.app.stats.prix_avec_stat.intitule_colonne[4],
					this.app.stats.prix_avec_stat.intitule_colonne[5],
					this.app.stats.prix_avec_stat.intitule_colonne[6],
					this.app.stats.prix_avec_stat.intitule_colonne[7],
					],
				},
				this.chartOptions_trim.xAxis.categories= [this.app.stats.prix_avec_stat_2.intitule_colonne[0], this.app.stats.prix_avec_stat_2.intitule_colonne[1], this.app.stats.prix_avec_stat_2.intitule_colonne[2]]
			}
		},
	},

	components: { Rs, Panel, Checkbox }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.aide
	width 60px
	left 0px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		left 0px !important


.select-all-co
	position absolute
	right 16px
	top 28%
	width 24px
	height 24px
	border-radius radius
	border 2px solid #CCC
	display block
	cursor pointer
	display flex
	background-color #FFF !important
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		background-color palette0 !important
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap
	

.select-all
	position absolute
	right 16px
	top 22px
	width 24px
	height 24px
	border-radius radius
	border 2px solid palette0
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		border-color palette2
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap

.pane-programs-enter
	transform translateX(100%)
.pane-programs-leave-to
	transform translateX(-100%)

.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc

.loading_graph
	.infinite-loader
		position absolute
		left calc(50% - 32px)
		top calc(50% - 64px)
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #ccc
			.icon
				color #ccc
				display block
				animation rotate 1.5s infinite linear

.pane-programs
	position absolute
	top headersize
	float left
	height calc(100% - 94px)	
	width PaneLeftSize
	background-color green
	transition 0.5s easeOutQuart
	.infinite-loader
		position absolute
		left calc(50% - 32px)
		top calc(50% - 64px)
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #fff
			.icon
				display block
				animation rotate 1.5s infinite linear
	.programs
		top 0px
		height 100%
		overflow hidden
		background #fff
		&.empty
			.programs-no-result
				display flex
		.programs-footer
			bottom 0px
			height 68px
			padding 16px
			font-weight 600
			border-top 1px solid #ccc
		.programs-header
			padding 16px
			width PaneLeftSize
			font-weight 600
			border-bottom 1px solid #ccc
			> p
				line-height 16px
			.select-all-save
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background-color #999
					border 2px solid #fff
			.select-allA
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background #fff url(../assets/img/checked.png) center center no-repeat
					background-size 14px 14px

		.programs-list
			height calc(100% - 25px) !important
		.programs-no-result
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			height 100%
			padding 0 32px
			display none
			flex-direction column
			justify-content center
			align-items center
			background #fff
			color #999
			font-family volte, sans-serif
			text-align center
			font-weight 600
			font-size 20px
			line-height 24px
			user-select none
			.icon
				color #aaa
			.text
				margin 24px 0 0 0
				p
					padding 0 0 8px 0
					&:last-child
						padding 0
			.button
				display inline-block
				height 48px
				margin 24px 0 0 0
				padding 0 24px
				border-radius radius
				cursor pointer
				background-color blue
				font-weight 500
				font-size 20px
				color #fff
				line-height @height
				&:hover
					background-color darken(blue, 10%)
				&:active
					background-color darken(blue, 20%)
					transform translateY(1px)
.nolive
	height calc(100% - 50px) !important

.commune
	background secondary
	padding 16px
	overflow hidden
	cursor pointer
	height 48px
	width 100%
	text-align center
	color white
	border-bottom 1px solid white

.buttonv
	border 0
	border-radius 0
	background primaryA400
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important



.neuf
	width 60px;
	height 60px;
	position absolute
	left 60px
	border-right 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important
		




.buttonv_inactive
	right 0
	border 0
	border-radius 0
	background #AAAAAA
	border-radius 18px
	font-style normal
	font-weight 500
	font-size 12px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 200px
	height 36px
.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	cursor pointer

.panecontent
	border-right 1px solid #cccccc
	height calc(100% - 60px) !important
.paneleft
	border 0 !important

.loading_graph
	width PaneLeftSize
	height 280px

.stat
	width 60px;
	height 60px;
	position absolute
	right 180px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 120px
	&.city
		right 120px
	&.citynotidf
		right 60px !important

.res
	position absolute
	width auto
	right 50px
	top 25px
	font-weight 500
	font-size 14px
	

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9

.column {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: var(--radius-large);
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;	
}

.dashboard-card:not(:last-child) {
	margin-bottom: 1.5rem;
}
.dashboard-card.flex-chart {
	height: calc(50% - 0.75rem);
}

.dashboard-card .card-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.dashboard-card .card-head h3 {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .revenue-stats {
	display: flex;
	margin-bottom: 10px;
}
.dashboard-card .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.dashboard-card .revenue-stats .revenue-stat span {
	display: block;
}
.dashboard-card .revenue-stats .revenue-stat span:first-child {
	color: var(--light-text);
	font-size: 0.9rem;
}
.dashboard-card .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
}
.dashboard-card .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}
.dashboard-card .chart-media {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dashboard-card .chart-media .meta {
	margin-right: 30px;
	align-items: center;
	font-family: var(--font), sans-serif;
}
.dashboard-card .chart-media .meta h4 {
	font-family: var(--font-alt), sans-serif;
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .chart-media .meta span {
	display: block;
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 8px;
}
.dashboard-card .chart-media .meta p {
	font-size: 0.9rem;
}
.dashboard-card .chart-media .chart-container {
	min-width: 110px;
}
.dashboard-card .chart-group {
	display: flex;
}
.dashboard-card .chart-group .group {
	text-align: center;
	width: 33.3%;
}
.dashboard-card .chart-group .group .chart-container {
	margin-top: -5px;
}
.dashboard-card .chart-group .group span {
	display: block;
	margin-top: -25px;
	font-family: var(--font), sans-serif;
	color: var(--dark-text);
	font-weight: 600;
	font-size: 1.2rem;
}
.dashboard-card .chart-group .group p {
	font-size: 0.9rem;
}
	
.oir_but {
	position: absolute;
width: 250px;
height: 50px;
bottom:10px;
left:calc(50% - 125px);
background: #F0E63C;
border-radius: 77px;

font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 50px;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #000000;

}

:deep(.p-panel-content) {
	padding: 0px !important;
}

:deep(.p-panel-header) {
	border-top-right-radius: 0px !important;
	border-top-left-radius: 0px !important;
	border-bottom: 0px !important;
	border-right: 0px !important;
	border-left: 0px !important;
	background: #eee !important;
	font-weight: 700 !important;
	font-size: 0.9rem !important;
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
}
		
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
