<template>
	<transition name="slide">
		<div class="pane pane-filters-ancien" :class="{vi : filter_visible, left: !app.menuvisible}">
			<div class="right-bar">
				<div class="box right-bar-content" v-bar>
					<div class="content" ref="scrollable_aide" style="padding-left:10px;padding-right:20px">
						<div>
							<p class="box-title2">Forme Urbaine</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('forme', 1)" class="filtre" :class="{ active: reference.typeId==1 }">{{ $t('maison') }}</div>
									<div @click="toggle_switch('forme', 2)" class="filtre" :class="{ active: reference.typeId==2 }">{{ $t('appartement') }}</div>
								</div>
							</div>
							<br/><br/>
							<p class="box-title2">{{ $t('typologie') }}</p>
							<div class="group_filtre">
								<div @click="toggle_switch('typologie', 1)" class="filtre" :class="{ active: reference.rooms==1 }">{{ $t('t1') }}</div>
								<div @click="toggle_switch('typologie', 2)" class="filtre" :class="{ active: reference.rooms==2 }">{{ $t('t2') }}</div>
								<div @click="toggle_switch('typologie', 3)" class="filtre" :class="{ active: reference.rooms==3 }">{{ $t('t3') }}</div>
								<div @click="toggle_switch('typologie', 4)" class="filtre" :class="{ active: reference.rooms==4 }">{{ $t('t4') }}</div>
								<div @click="toggle_switch('typologie', 5)" class="filtre" :class="{ active: reference.rooms==5 }">{{ $t('t5') }}</div>
							</div>
							<br/><br/>
							<p class="box-title2">{{ $t('surface') }}</p>
							<div class="box-content">
								<div class="switches">
									<InputNumber inputStyle="text-align:right" id="integeronly" v-model="reference.area" suffix=" m²" />
								</div>
							</div>
							<br/><br/>
							<p class="box-title2">{{ $t('parking') }}</p>
							<div class="group_filtre">
								<div @click="toggle_switch('parking', 0)" class="filtre" :class="{ active: reference.parkingOpen==0 }">{{ $t('sans') }}</div>
								<div @click="toggle_switch('parking', 1)" class="filtre" :class="{ active: reference.parkingOpen==1 }">{{ $t('avec') }}</div>
							</div>
							<br/><br/>
							<p class="box-title2">{{ $t('etat_du_bien') }}</p>
							<div class="group_filtre">
								<div @click="toggle_switch('etat', 1)" class="filtre" :class="{ active: reference.condition==1 }">{{ $t('refait_a_neuf') }}</div>
								<div @click="toggle_switch('etat', 2)" class="filtre" :class="{ active: reference.condition==2 }">{{ $t('rafraîchi') }}</div>
								<div @click="toggle_switch('etat', 3)" class="filtre" :class="{ active: reference.condition==3 }">{{ $t('standard') }}</div>
								<div @click="toggle_switch('etat', 4)" class="filtre" :class="{ active: reference.condition==4 }">{{ $t('a_rafraichir') }}</div>
								<div @click="toggle_switch('etat', 5)" class="filtre" :class="{ active: reference.condition==5 }">{{ $t('a_refaire') }}</div>
							</div>
							<br><br>
							<div class="group_filtre">
								<div class="app_filtre" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import InputNumber from 'primevue/inputnumber';

export default {
	name: 'PaneFiltersAncien',

	data: function() {
		return {
			filter_visible: false,
			reference : {
				name:'Logement T3', 
				typeId:2, 
				rooms: 3, 
				buildingConstruction: 7, 
				condition: 3, 
				parkingOpen: 1, 
				area: 60
			},

		}
	},

	watch: {
		'app.theme': function(newValue) {
			if (newValue) {
				this.scroll(newValue)
			}
		},

		'app.filters_ancien': function(newValue) {
			this.filter_visible = newValue
		},

		'app.cityscan.realty.area': function(v) {
			this.reference.area = v
		},

		'app.cityscan.realty.rooms': function(v) {
			this.reference.rooms = v
		},

		'app.cityscan.realty.typeId': function(v) {
			this.reference.typeId = v
		},

		'app.cityscan.realty.condition': function(v) {
			this.reference.condition = v
		},
	},

	mounted: function() {

		this.reference.rooms = this.app.cityscan.realty.rooms
		this.reference.typeId = this.app.cityscan.realty.typeId
		this.reference.condition = this.app.cityscan.realty.condition
		this.reference.parkingOpen = this.app.cityscan.realty.parkingOpen
		this.reference.area = this.app.cityscan.realty.area

	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		valide_surface: function() {
			this.valide_estimation()
		},

		applique_filtre: function() {
			this.valide_estimation()
			this.$store.dispatch('set_filters_ancien', {filters_ancien:!this.app.filters_ancien})
		},

		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
			
		toggle_switch: function(filter, value) {
			switch (filter) {
				case 'typologie':
					gtag('event', 'filtre_ancien_typologie', {'event_category': 'filtre','event_label': 'filtre_ancien_typologie'});
					this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'typologie'});
					this.reference.rooms = value
					break;
				case 'forme':
					gtag('event', 'filtre_ancien_fu', {'event_category': 'filtre','event_label': 'filtre_ancien_fu'});
					this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'forme urbaine'});
					this.reference.typeId = value
					break;
				case 'etat':
					gtag('event', 'filtre_ancien_etat', {'event_category': 'filtre','event_label': 'filtre_ancien_etat'});
					this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'etat'});
					this.reference.condition = value
					break;
				case 'parking':
					gtag('event', 'filtre_ancien_parking', {'event_category': 'filtre','event_label': 'filtre_ancien_parking'});
					this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'parking'});
					this.reference.parkingOpen = value
					break;
				default:
					break;
			}			
		},

		valide_estimation: function() {
			this.reference.area = parseFloat(this.reference.area)
			this.$store.dispatch('set_cityscan_realty', { realty: this.reference })
			this.$store.dispatch('app_cityscan_realty', {callback: function() {
				// Après connexion, on charge le contenu en fonction de la route (load_by_route)
			}})
		},
	},
	components: { InputNumber }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px

.right-bar
	padding-left 10px
	height 100%
	background #fcfcfc
	width 100%
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%

	.scroll-dots
		position absolute
		left -40px
		top 24px
		width 32px
		background alpha(green, 50%)
		border-radius radius
		z-index 2
		transition 0.25s easeOutQuart
		&:hover
			background alpha(green, 75%)
		.dot
			display block
			width 8px
			height 8px
			margin 12px
			background #fff
			opacity 0.25
			border-radius 100%
			transition 0.25s easeOutQuart
			cursor pointer
			&:hover, &.active
				opacity 1

.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
	.middle
		top 20px

.pane-filters-ancien
	position fixed
	top headersize
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 126px)
	width 0px
	background-color #333
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fcfcfc;
	transition 0.5s easeOutQuart
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		width 350px
		border-right solid 1px #ccc
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

.label
	display block
	margin 0 0 2px 0
	color #666
	text-transform uppercase
	font-size 11px
	font-weight 500
.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both


@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
