<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<span class="title">{{ $t('chiffres_cles_du_territoire') }} {{ app.adr_init.territoire }}</span>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div v-if="app.livedata.insee && app.livedata.insee.length" class="stat-box-content" style="font-size: 14px;text-align: center; vertical-align: middle;">
			<div class="">
				<div class="shadow-2 surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important">
					<div class="flex align-items-start mb-5">
						<div class="ml-3" style="text-align: left;">
							<span class="block text-900 mb-1 text-xl font-medium">{{ app.adr_init.territoire }}</span>
							<p class="text-600 mt-0 mb-0">{{ $t('chiffres_cles_du_territoire') }}</p>
						</div>
					</div>
					<ul class="list-none p-0 m-0">
						<li class="mb-5">
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-euro mr-2"></i>
									<span class="font-medium text-900">{{ $t('revenu_median_mensuel_par_tranches_dâges') }} : {{ $t('de_60_a_74_ans') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].household.age_division.age_60_74.median_income }} €/mois</span>
							</div>
						</li>
						<li class="mb-5">
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-euro mr-2"></i>
									<span class="font-medium text-900">{{ $t('revenu_median_mensuel_par_tranches_dâges') }} : {{ $t('de_75_ans_et_plus') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].household.age_division.age_75_more.median_income }} €/mois</span>
							</div>
						</li>
						<li class="mb-5">
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-users mr-2"></i>
									<span class="font-medium text-900">{{ $t('population_globale') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.population }} habitants</span>
							</div>
						</li>
						<li class="mb-5">
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-user-plus mr-2"></i>
									<span class="font-medium text-900">{{ $t('part_de_seniors_dans_la_population_globale') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{ Math.round((app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_75_89 + app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_90_more) / app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.population * 100) }} %</span>
							</div>
							<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
								<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_75_89 + app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_90_more) / app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.population * 100) + '%'}"></div>
							</div>
						</li>
						<li class="mb-5">
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-id-card mr-2"></i>
									<span class="font-medium text-900">{{ $t('taux_de_chomage') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{ Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].employment.unemployment_rate * 100) }} %</span>
							</div>
							<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
								<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].employment.unemployment_rate * 100) + '%'}"></div>
							</div>
						</li>
						<li>
							<div class="flex justify-content-between align-items-center">
								<span class="text-900 inline-flex justify-content-between align-items-center">
									<i class="pi pi-wrench mr-2"></i>
									<span class="font-medium text-900">{{ $t('nombre_dequipements_medicaux_pour_1_000_habitants') }}</span>
								</span>
								<span class="text-teal-600 font-medium">{{Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].equipment.medical_rate, 2)}}</span>
							</div>
							<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
								<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].equipment.medical_rate, 2)/10 + '%'}"></div>
							</div>
						</li>
					</ul>
				</div>
				<table id="insee_rs_chiffres" style="display:none">
					<tr class="titre1">
						<th></th>
						<th>{{ $t('chiffres_cles') }}</th>
					</tr>
					<tr>
						<td>{{ $t('revenu_median_mensuel_par_tranches_dâges') }} : {{ $t('de_60_a_74_ans') }}</td>
						<td>{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].household.age_division.age_60_74.median_income }} €/mois</td>
					</tr>
					<tr>
						<td>{{ $t('revenu_median_mensuel_par_tranches_dâges') }} : {{ $t('de_75_ans_et_plus') }}</td>
						<td>{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].household.age_division.age_75_more.median_income }} €/mois</td>
					</tr>
					<tr>
						<td>{{ $t('population_globale') }}</td>
						<td>{{ app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.population }} habitants</td>
					</tr>
					<tr>
						<td>{{ $t('part_de_seniors_dans_la_population_globale') }}</td>
						<td>{{ Math.round((app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_75_89 + app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.age_division.age_90_more) / app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].population.population * 100) }} %</td>
					</tr>
					<tr>
						<td>{{ $t('taux_de_chomage') }}</td>
						<td>{{ Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].employment.unemployment_rate * 100) }} %</td>
					</tr>
					<tr>
						<td>{{ $t('nombre_dequipements_medicaux_pour_1_000_habitants') }}</td>
						<td>{{Math.round(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].equipment.medical_rate, 2)}}</td>
					</tr>
				</table>
			</div>			
			<br/>
		</div>





	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'ArrayChiffrescles',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'insee_rs_chiffres', name: 'Chiffres clés'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'insee_rs_chiffres_img'});} },
			]
			,
			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
		
	}),

	methods: {
	},

	components: { SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'


.program-detail-info-content
	padding 8px 0
	background #f5f5f5
	border-radius radius
	overflow hidden
	table
		width 100%
		border-collapse collapse
		tr
			&:last-child
				border none
			td, th
				padding 4px 8px
			th
				font-size 12px
				font-weight 500
			td
				text-align center
				&:first-child
					text-align left
					font-size 12px
					font-weight bold
					padding-left 16px

</style>
