<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="rplsencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;" id="OffreEx">
				<div class="pan-titre-programme" id="pan-titre-rpls">
					<div class="module_ret">
						<div class="btns fermer" title="Fermer"  @click="expand_offre();"><icon file="fermer" :size="16" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateImage(expanded_offre.id);"><icon file="download" :size="24" />&nbsp;</div>
<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div class="indice_confiance" :class="{ pc : true }">
							<div v-if="expanded_offre.publicationDate">
								<div v-if="expanded_offre.publicationDate.substring(0, 4)!='1970'">
									<span>Annonce du {{expanded_offre.publicationDate.substring(8,10)}}/{{expanded_offre.publicationDate.substring(5,7)}}/{{expanded_offre.publicationDate.substring(0, 4)}}</span><span v-if="expanded_offre.publicationDate.substring(8,10)/expanded_offre.publicationDate.substring(5,7)/expanded_offre.publicationDate.substring(0, 4)!=expanded_offre.modificationDate.substring(8,10)/expanded_offre.modificationDate.substring(5,7)/expanded_offre.modificationDate.substring(0, 4)"> mise à jour au {{expanded_offre.modificationDate.substring(8,10)}}/{{expanded_offre.modificationDate.substring(5,7)}}/{{expanded_offre.modificationDate.substring(0, 4)}}</span>
								</div>
							</div>
							<div v-else>
								<div v-if="expanded_offre.modificationDate">
									<span>Annonce du {{expanded_offre.modificationDate.substring(8,10)}}/{{expanded_offre.modificationDate.substring(5,7)}}/{{expanded_offre.modificationDate.substring(0, 4)}}</span>
								</div>
								
							</div>
							
						</div>
					</div>
					<br/>
				</div>
				<div><br><br><br><br></div>
				<div>
					<div class="program" :class="{ inactif: false }" style="height: 350px">
						<div class="program-main" style="height:285px;">
							<Carousel :value="photos" :numVisible="1" >
								<template #item="slotProps">
									<div class="image">
										<img alt="Annonce" :src="slotProps.data.image"  style="width:100%;height:100%;" />
									</div>
								</template>
							</Carousel>
						</div>
					</div>
					<div class="program-detail">
						<div class="program-detail-content" style="padding-top: 8px !important;">
							<div class="program-detail-info">
								<div class="program-maj" v-if="expanded_offre.dateindex">
								</div>
								<ul class="list-none p-2 m-0">
									<li style="background-color: #f5f5f5;margin-top: 10px;margin-bottom:10px;">
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_offre.description">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" style="font-size:1.2rem;justify-content: space-between">
													<div>
														<b>
															<span v-if="expanded_offre.propertyType!='flat'">MAISON</span><span v-else>APPARTEMENT</span> - {{ expanded_offre.roomsQuantity }} piéces de {{ expanded_offre.surfaceArea }} m²
														</b>
													</div>
													<div>
														<b>
															{{ getPrice(expanded_offre.price) }}
														</b>
													</div>
												</a>
											</li>
											<li v-if="expanded_offre.surfaceArea">
												<a v-ripple class="flex align-items-center cursor-default pl-3 pb-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<span class="font-medium text-700">{{ getPrice(expanded_offre.price/expanded_offre.surfaceArea) }}/m²</span>
												</a>
											</li>
										</ul>
									</li>
									<li style="background-color: #f5f5f5;margin-top: 10px;margin-bottom: 10px;">
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_offre.description">
												<a v-ripple class="flex align-items-center cursor-default p-1 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<div class="desc" v-html="formattedText"></div>
												</a>
											</li>
										</ul>
									</li>
									<li>
										<ul class="list-none p-0 m-0 overflow-hidden flex-list">
											<li v-if="expanded_offre.roomsQuantity">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-box mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_offre.roomsQuantity}} piece</span><span v-if="expanded_offre.roomsQuantity>1">s</span>
												</a>
											</li>
											<li v-if="expanded_offre.surfaceArea">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-check-square mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_offre.surfaceArea}} m²</span>
												</a>
											</li>
											<li v-if="expanded_offre.price">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-euro mr-2 text-700"></i>
													<span class="font-medium text-700">{{ getPrice(expanded_offre.price) }}</span>
												</a>
											</li>
											<li v-if="expanded_offre.floor">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-bars mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_offre.floor}} étage</span>
												</a>
											</li>
											<li v-if="expanded_offre.exposition">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-sun mr-2 text-700"></i>
													<span class="font-medium text-700">Exposé {{expanded_offre.exposition}}</span>
												</a>
											</li>
											<li v-if="expanded_offre.heating">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-bolt mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_offre.heating}}</span>
												</a>
											</li>
											<li v-if="expanded_offre.parkingPlacesQuantity">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-car mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_offre.parkingPlacesQuantity}} stationnement</span><span v-if="expanded_offre.parkingPlacesQuantity>1">s</span>
												</a>
											</li>
											<li v-if="expanded_offre.hasElevator">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-arrows-v mr-2 text-700"></i>
													<span class="font-medium text-700">Ascenseur</span>
												</a>
											</li>
											<li v-if="expanded_offre.hasTerrace">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-window-maximize mr-2 text-700"></i>
													<span class="font-medium text-700">Terrasse</span>
												</a>
											</li>
											<li v-if="expanded_offre.hasBalcony">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-window-maximize mr-2 text-700"></i>
													<span class="font-medium text-700">Balcon</span>
												</a>
											</li>
											<li v-if="expanded_offre.hasGarden">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-image mr-2 text-700"></i>
													<span class="font-medium text-700">Jardin</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>

								<div v-if="expanded_offre.greenhouseGazClassification || expanded_offre.energyClassification" style="margin-bottom: 10px;display:flex;justify-content: space-around;" class="ng-star-inserted">
									<div v-if="expanded_offre.energyClassification && expanded_offre.energyClassification!='NS'" _ngcontent-laq-c321="" fxlayout="row" fxlayout.lt-md="column" style="flex-direction: row; box-sizing: border-box; display: flex;" class="ng-star-inserted">
										<div _ngcontent-laq-c321="" fxlayout="column" fxflex="50" style="flex-direction: column; box-sizing: border-box; display: flex; flex: 1 1 100%; max-width: 50%;" class="ng-star-inserted">
											<div class="app-property-energy-indicator" style="text-align: center;" _ngcontent-laq-c321="" _nghost-laq-c320="">
												<h3 _ngcontent-laq-c320="" class="ng-star-inserted">{{ $t('diagnostic_de_performance_energetique') }}</h3>
												<div _ngcontent-laq-c320="" class="value ng-star-inserted">
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='A'}" style="background-color: rgb(50, 153, 50);">
														<p _ngcontent-laq-c320="">A</p>
														<div v-if="expanded_offre.energyClassification=='A'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='B'}" style="background-color: rgb(51, 204, 51);">
														<p _ngcontent-laq-c320="">B</p>
														<div v-if="expanded_offre.energyClassification=='B'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='C'}" style="background-color: rgb(181, 226, 47);">
														<p _ngcontent-laq-c320="">C</p>
														<div v-if="expanded_offre.energyClassification=='C'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='D'}" style="background-color: rgb(224, 224, 0);">
														<p _ngcontent-laq-c320="">D</p>
														<div v-if="expanded_offre.energyClassification=='D'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='E'}" style="background-color: rgb(255, 204, 0);">
														<p _ngcontent-laq-c320="">E</p>
														<div v-if="expanded_offre.energyClassification=='E'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='F'}" style="background-color: rgb(255, 154, 51);">
														<p _ngcontent-laq-c320="">F</p>
														<div v-if="expanded_offre.energyClassification=='F'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.energyClassification=='G'}" style="background-color: rgb(255, 0, 0);">
														<p _ngcontent-laq-c320="">G</p>
														<div v-if="expanded_offre.energyClassification=='G'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.energyValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="expanded_offre.greenhouseGazClassification && expanded_offre.greenhouseGazClassification!='NS'" _ngcontent-laq-c321="" fxlayout="row" fxlayout.lt-md="column" style="flex-direction: row; box-sizing: border-box; display: flex;" class="ng-star-inserted">
										<div _ngcontent-laq-c321="" fxlayout="column" fxflex="50" style="flex-direction: column; box-sizing: border-box; display: flex; flex: 1 1 100%; max-width: 50%;" class="ng-star-inserted">
											<div class="app-property-energy-indicator" style="text-align: center;" _ngcontent-laq-c321="" _nghost-laq-c320="">
												<h3 _ngcontent-laq-c320="" class="ng-star-inserted">{{ $t('indice_demission_de_gaz_a_effet_de_serre') }}</h3>
												<div _ngcontent-laq-c320="" class="value ng-star-inserted">
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='A'}" style="background-color: rgb(253, 234, 254);">
														<p _ngcontent-laq-c320="">A</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='A'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='B'}" style="background-color: rgb(255, 222, 253);">
														<p _ngcontent-laq-c320="">B</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='B'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='C'}" style="background-color: rgb(249, 190, 253);">
														<p _ngcontent-laq-c320="">C</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='C'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='D'}" style="background-color: rgb(251, 126, 255);">
														<p _ngcontent-laq-c320="">D</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='D'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='E'}" style="background-color: rgb(246, 82, 252);">
														<p _ngcontent-laq-c320="">E</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='E'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='F'}" style="background-color: rgb(228, 40, 244);">
														<p _ngcontent-laq-c320="">F</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='F'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_offre.greenhouseGazClassification=='G'}" style="background-color: rgb(180, 21, 185);">
														<p _ngcontent-laq-c320="">G</p>
														<div v-if="expanded_offre.greenhouseGazClassification=='G'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_offre.greenhouseGazValue}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>							
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Carousel from 'primevue/carousel';
// eslint-disable-next-line
import domtoimage from 'dom-to-image'
import Button from 'primevue/button';

export default {
	name: 'PaneOffre',

	data: function() {
		return {
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_offre', 'selected_rpls_secteurs' ]), {
		formattedText() {
			return this.expanded_offre.description.replace(/\n/g, '<br>');
		},
		photos: function() {
			let v = []
			if (this.expanded_offre && this.expanded_offre.photos) {
				for (let index = 0; index < this.expanded_offre.photos.length; index++) {
					v.push({image: this.expanded_offre.photos[index].url_photo})
				}
			}
			return v
		},
	}),

	watch: {
		'app.expanded_offre': function(newValue) {
			if (newValue) {
				this.filter_svo = "s"
			}
		},
	},
	
	methods: {
        
		getPrice: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(Math.round(nPrice))
			}
		},

		generateImage(id) {



			const elementToExport = document.getElementById('OffreEx');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};
		
			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'OffreAncien_' + id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});

		},

		getTxtTaux: function(value) {
			switch (value) {
				case 'Faible':
					return '< 70 %'
				case 'Moyen':
					return '70 - 90 %'
				case 'Fort':
					return 'plus de 90 %'
				default:
					return value
			}
		},
		
		getTxtTauxClass: function(value) {
			if (value < 0.7) return 'Faible'
			else {
				if (value < 0.9) return 'Moyen'
				else return 'Fort'
			}
		},

		getTxtClass: function(value) {
			switch (value) {
				case '< 70%':
					return 'Faible'
				case '70 - 90%':
					return 'Moyen'
				case '> 90%':
					return 'Fort'
				default:
					return ''
			}
		},

		expand_offre: function() {
			this.$router.push({ name: 'offre'}).catch(()=>{});
			if (this.expanded_offre != this.rpls) {
				this.$store.dispatch('expand_offre', { offre: this.offre })
			} else {
				this.$store.dispatch('expand_offre', { offre: false })
			}
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "rplsencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_offre.COMPLGEO, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-rpls' })
		}
	},

	// eslint-disable-next-line
	components: { Carousel, domtoimage, Button}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	height calc(100% - 120px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 130px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important


.value
	height 60px
.tile
	display inline-block
	vertical-align top
	width 43px
	height 20px
	line-height 20px
	font-weight 700
	text-align center

.tile.selected
	position relative
	width 43px
	height 43px
	margin-top -11px
	margin-left -5px
	margin-right -5px
	line-height 40px
	border 2px solid white
	border-radius 50%
	z-index 999
.spanlib
	padding 12px 0 0
	line-height 18px
	font-weight 400
	font-size x-small

.app-property-energy-indicator
	width 380px
	h3
		font-weight 400
		font-size 13px

.desc
	white-space pre-line
	position relative
	text-align justify
	font-size 15px
	color #495960
	margin-top 20px
	padding 0 20px 20px
	overflow hidden
	line-height 22px


:deep(.p-carousel-items-content){
	height: 285px !important;
}

.grid-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
}

.grid-list li {
	list-style: none;
}	

.flex-list {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.flex-list li {
	list-style: none;
	flex: 1 1 auto;
	box-sizing: border-box;
}

.flex-list li a {
	display: flex;
	align-items: center;
	white-space: nowrap;
}
@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none



</style>
