<template>
	<transition name="pane-adresse-vie">
		<div class="paneleft pan-adresse-vie">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" aide="1" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">Général</span>
							<span> > </span>
							<span><b>{{ $t('Qualité de vie') }}</b></span>
						</div>
						<div class="noteTitre" :class="app.cityscan.adresse.note_qualite">{{app.cityscan.adresse.note_qualite}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('confort_de_vie') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.sonore.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('nuisance_sonore') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.sonore.note">{{this.app.cityscan.adresse.sonore.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.sonore.note, 'sonore')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_sonore" class="checkbox" :class="{ checked: adresse_sonore }" >
															<input type="checkbox" :checked="adresse_sonore" id="adresse_sonore" @click=" select_adresse('adresse_sonore')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.olfactive.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('nuisance_olfactive') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.olfactive.note">{{this.app.cityscan.adresse.olfactive.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.olfactive.note, 'olfactive')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_olfactive" class="checkbox" :class="{ checked: adresse_olfactive }">
															<input type="checkbox" :checked="adresse_olfactive"  id="adresse_olfactive" @click=" select_adresse('adresse_olfactive')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('espace_vital') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.saturation_population.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('saturation_population') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.saturation_population.note">{{this.app.cityscan.adresse.saturation_population.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.saturation_population.note, 'saturation_population')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_saturation_population" class="checkbox" :class="{ checked: adresse_saturation_population }" >
															<input type="checkbox" :checked="adresse_saturation_population" id="adresse_saturation_population" @click=" select_adresse('adresse_saturation_population')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.saturation_vehicules.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('saturation_vehicule') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.saturation_vehicules.note">{{this.app.cityscan.adresse.saturation_vehicules.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.saturation_vehicules.note, 'saturation_vehicules')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_saturation_vehicule" class="checkbox" :class="{ checked: adresse_saturation_vehicule }">
															<input type="checkbox" :checked="adresse_saturation_vehicule"  id="adresse_saturation_vehicule" @click=" select_adresse('adresse_saturation_vehicule')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('pollution') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.air.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('pollution_de_lair') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.air.note">{{this.app.cityscan.adresse.air.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.air.note, 'air')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_air" class="checkbox" :class="{ checked: adresse_air }">
															<input type="checkbox" :checked="adresse_air"  id="adresse_air" @click=" select_adresse('adresse_air')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.electromagnetique.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('pollution_electromagnetique') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.electromagnetique.note">{{this.app.cityscan.adresse.electromagnetique.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.electromagnetique.note, 'electromagnetique')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_electromagnetique" class="checkbox" :class="{ checked: adresse_electromagnetique }">
															<input type="checkbox" :checked="adresse_electromagnetique"  id="adresse_electromagnetique" @click=" select_adresse('adresse_electromagnetique')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.radon.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('radon') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.radon.note">{{this.app.cityscan.adresse.radon.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.radon.note, 'radon')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_radon" class="checkbox" :class="{ checked: adresse_radon }">
															<input type="checkbox" :checked="adresse_radon"  id="adresse_radon" @click=" select_adresse('adresse_radon')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseVie',
	data: function() {
		return {
			adresse_radon: true,
			adresse_electromagnetique: true,
			adresse_air: true,
			adresse_saturation_vehicule: true,
			adresse_saturation_population: true,
			adresse_olfactive: true,
			adresse_sonore: true,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_radon = (this.app.preferences.adresse_niv2.indexOf('adresse_radon')!==-1)
		//	this.adresse_electromagnetique = (this.app.preferences.adresse_niv2.indexOf('adresse_electromagnetique')!==-1)
		//	this.adresse_air = (this.app.preferences.adresse_niv2.indexOf('adresse_air')!==-1)
		//	this.adresse_saturation_vehicule = (this.app.preferences.adresse_niv2.indexOf('adresse_saturation_vehicule')!==-1)
		//	this.adresse_saturation_population = (this.app.preferences.adresse_niv2.indexOf('adresse_saturation_population')!==-1)
		//	this.adresse_olfactive = (this.app.preferences.adresse_niv2.indexOf('adresse_olfactive')!==-1)
		//	this.adresse_sonore = (this.app.preferences.adresse_niv2.indexOf('adresse_sonore')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},

		getlibellenote: function(note, theme) {
			switch (theme) {
				case 'sonore':
					switch (note) {
						case 'A': return 'Très calme'
						case 'B': return 'Calme'
						case 'C': return 'Assez calme'
						case 'D': return 'Potentiellement Bruyant'
						case 'E': return 'Potentiellement très bruyant'
						default: return ''
					}
				case 'air':
					switch (note) {
						case 'A': return 'Trés peu pollué'
						case 'B': return 'Peu pollué'
						case 'C': return 'Assez peu pollué'
						case 'D': return 'Potentiellement pollué'
						case 'E': return 'Potentiellement très pollué'
						default: return ''
					}
				case 'electromagnetique':
					switch (note) {
						case 'A': return 'Trés peu exposé'
						case 'B': return 'Peu exposé'
						case 'C': return 'Assez peu exposé'
						case 'D': return 'Potentiellement exposé'
						case 'E': return 'Potentiellement très exposé'
						default: return ''
					}
				default:
					switch (note) {
						case 'A': return 'Très favorable'
						case 'B': return 'Favorable'
						case 'C': return 'Assez favorable'
						case 'D': return 'Potentiellement défavorable'
						case 'E': return 'Potentiellement très défavorable'
						default: return ''
					}
			}

		},

		getimagenote: function(note) {
			switch (note) {
				case 'A': return 'level_one'
				case 'B': return 'level_two'
				case 'C': return 'level_three'
				case 'D': return 'level_four'
				case 'E': return 'level_five'
				default: return ''
			}
		},		
	},
	
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/cityscan.css'

.pane-adresse-vie-enter
	transform translateX(100%)
.pane-adresse-vie-leave-to
	transform translateX(-100%)

.pan-adresse-vie
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.level_one
	background url(../../assets/img/level_one.png) center center no-repeat
.level_two
	background url(../../assets/img/level_two.png) center center no-repeat
.level_three
	background url(../../assets/img/level_three.png) center center no-repeat
.level_four
	background url(../../assets/img/level_four.png) center center no-repeat
.level_five
	background url(../../assets/img/level_five.png) center center no-repeat
.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
