<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-indigo-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-shield text-indigo-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('optimisation_de_votre_projet') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('en_fonction_de_votre_selection_de_programmes') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<div class="program-detail-info">
				<div class="table">
					<div class="program-detail-info-filters" style="text-align : left">
						<table>
							<th style="display:none"></th>
							<tr>
								<td style="width:250px">
									<span>&nbsp;{{ $t('analyse_par') }} : </span>
								</td>
								<td>
									<div class="p-selectbutton p-buttonset p-component">
										<div class="p-button p-component pd05" @click="filter2 = 'typologie'; compteur=5; suffixe='T'" :class="{ 'p-highlight': filter2 == 'typologie' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('typologie') }}</span>
											<span class="p-ink" style="height: 68px; width: 68px; top: -10.1875px; left: -5.57812px;"></span>
										</div>
										<div class="p-button p-component pd05" @click="filter2 = 'echelle'; compteur=12; suffixe='S'" :class="{ 'p-highlight': filter2 == 'echelle' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('echelle_residentielle') }}</span>
											<span class="p-ink"></span>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="program-detail-info-filters" style="text-align : left">
						<table>
							<th style="display:none"></th>
							<tr>
								<td style="width:250px">
									<span>&nbsp;{{ $t('filtrer_la_destination') }} : </span>
								</td>
								<td>
									<div class="p-selectbutton p-buttonset p-component">
										<div class="p-button p-component pd05" @click="inv = 'toute'" :class="{ 'p-highlight': inv == 'toute' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('toutes_destinations') }}</span>
											<span class="p-ink" style="height: 68px; width: 68px; top: -10.1875px; left: -5.57812px;"></span>
										</div>
										<div class="p-button p-component pd05" @click="inv = 'inv'" :class="{ 'p-highlight': inv == 'inv' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('ventes_investisseurs') }}</span>
											<span class="p-ink"></span>
										</div>
										<div class="p-button p-component pd05" @click="inv = 'occ'" :class="{ 'p-highlight': inv == 'occ' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('ventes_occupants') }}</span>
											<span class="p-ink"></span>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="program-detail-info-filters" style="text-align : left">
						<table>
							<th style="display:none"></th>
							<tr>
								<td style="width:250px">
									<span>&nbsp;{{ $t('filtrer_le_financement') }} : </span>
								</td>
								<td>
									<div class="p-selectbutton p-buttonset p-component">
										<div class="p-button p-component pd05" @click="filter3 = 'tva_pleine_libre'" :class="{ 'p-highlight': filter3 == 'tva_pleine_libre' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('tva_pleine_libre') }}</span>
											<span class="p-ink" style="height: 68px; width: 68px; top: -10.1875px; left: -5.57812px;"></span>
										</div>
										<div class="p-button p-component pd05" @click="filter3 = 'tva_pleine_aide'" :class="{ 'p-highlight': filter3 == 'tva_pleine_aide' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('tva_pleine_aidee') }}</span>
											<span class="p-ink"></span>
										</div>
										<div class="p-button p-component pd05" @click="filter3 = 'tva_reduite'" :class="{ 'p-highlight': filter3 == 'tva_reduite' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('tva_reduite') }}</span>
											<span class="p-ink"></span>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="program-detail-info-filters" style="text-align : left">
						<table>
							<th style="display:none"></th>
							<tr>
								<td style="width:250px">
									<span>&nbsp;{{ $t('gestion_du_prix') }} : </span>
								</td>
								<td>
									<div class="p-selectbutton p-buttonset p-component">
										<div class="p-button p-component pd05" @click="filter5 = 'prix_ap'" :class="{ 'p-highlight': filter5 == 'prix_ap' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('prix_parking_inclus') }}</span>
											<span class="p-ink" style="height: 68px; width: 68px; top: -10.1875px; left: -5.57812px;"></span>
										</div>
										<div class="p-button p-component pd05" @click="filter5 = 'prix_sp'" :class="{ 'p-highlight': filter5 == 'prix_sp' }" style="min-width:190px; text-align: center;">
											<span style="width:100%; text-align: center;">{{ $t('prix_hors_parking') }}</span>
											<span class="p-ink"></span>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<DataTable :value="synojson" showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" dataKey="id" sortMode="single" sortField="secteur" :sortOrder="1" responsiveLayout="scroll"
				class="synoptique" id="optim_proj" :rowClass="rowClass">
				<ColumnGroup type="header">
					<Row>
						<Column field="id" :header="$t('entree')" :rowspan="2" :sortable="true"/>
						<Column :header="$t('repartition_initiale')" :colspan="2" />
						<Column :header="$t('offre')" :colspan="2" />
						<Column :header="$t('rythme')" :colspan="2" />
						<Column :header="$t('ventes')" :colspan="2" />
						<Column field="rythme_vtemois" :header="$t('ventes_/_mois')" :rowspan="2"/>
						<Column :header="$t('surface')" :colspan="3" />
						<Column :header="$t('prix_unitaire')" :colspan="3" />
						<Column :header="$t('prix_/_m²')" field="pm2moy" :rowspan="2" :sortable="true"/>
					</Row>
					<Row>
						<Column field="si" header="%" :sortable="true"/>
						<Column field="si_nb" :header="$t('nb')" :sortable="true"/>
						<Column field="offre" header="%" :sortable="true"/>
						<Column field="offre_nb" :header="$t('nb')" :sortable="true"/>
						
						<Column field="rythme" :header="$t('taux_decoulement')" :sortable="true"/>
						<Column field="rythme_mois" :header="$t('mois_de_com.')" :sortable="true"/>
						
						<Column field="vente" header="%" :sortable="true"/>
						<Column field="vente_nb" :header="$t('nb')" :sortable="true"/>
						
						<Column field="surfmin" :header="$t('minimum')" :sortable="true"/>
						<Column field="surfmoy" :header="$t('moyenne')" :sortable="true"/>
						<Column field="surfmax" :header="$t('maximum')" :sortable="true"/>
						
						<Column field="pummin" :header="$t('minimum')" :sortable="true"/>
						<Column field="pummoy" :header="$t('moyenne')" :sortable="true"/>
						<Column field="pummax" :header="$t('maximum')" :sortable="true"/>
					</Row>
				</ColumnGroup>
				
				<Column field="id" :header="$t('entree')">
					<template #body="{data}">
						<div style="text-align: center">{{ data.lib }}</div>
					</template>
				</Column>
				<Column field="si" header="%">
					<template #body="{data}">
						<div style="text-align: right">{{ data.si }}</div>
					</template>
				</Column>
				<Column field="si_nb" :header="$t('nb')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.si_nb }}</div>
					</template>
				</Column>
				<Column field="offre" header="%">
					<template #body="{data}">
						<div style="text-align: right">{{ data.offre }}</div>
					</template>
				</Column>
				<Column field="offre_nb" :header="$t('nb')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.offre_nb }}</div>
					</template>
				</Column>
				<Column field="rythme" :header="$t('taux_decoulement')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.rythme }}</div>
					</template>
				</Column>
				<Column field="rythme_mois" :header="$t('mois_de_com.')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.rythme_mois }}</div>
					</template>
				</Column>
				<Column field="vente" header="%">
					<template #body="{data}">
						<div style="text-align: right">{{ data.vente }}</div>
					</template>
				</Column>
				<Column field="vente_nb" :header="$t('nb')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.vente_nb }}</div>
					</template>
				</Column>
				<Column field="rythme_vtemois" :header="$t('ventes_/_mois')">
					<template #body="{data}">
						<div style="text-align: right">{{ data.rythme_vtemois }}</div>
					</template>
				</Column>
				<Column field="surfmin" :header="$t('minimum')">
					<template #body="{data}">
						<div style="text-align: right">{{ ensurface(data.surfmin) }}</div>
					</template>
				</Column>
				<Column field="surfmoy" :header="$t('moyenne')">
					<template #body="{data}">
						<div style="text-align: right">{{ ensurface(data.surfmoy) }}</div>
					</template>
				</Column>
				<Column field="surfmax" :header="$t('maximum')">
					<template #body="{data}">
						<div style="text-align: right">{{ ensurface(data.surfmax) }}</div>
					</template>
				</Column>
				<Column field="pummin" :header="$t('minimum')">
					<template #body="{data}">
						<div style="text-align: right">{{ eneuro(data.pummin) }}</div>
					</template>
				</Column>
				<Column field="pummoy" :header="$t('moyenne')">
					<template #body="{data}">
						<div style="text-align: right">{{ eneuro(data.pummoy) }}</div>
					</template>
				</Column>
				<Column field="pummax" :header="$t('maximum')">
					<template #body="{data}">
						<div style="text-align: right">{{ eneuro(data.pummax) }}</div>
					</template>
				</Column>
				<Column field="pm2moy" :header="$t('maximum')">
					<template #body="{data}">
						<div style="text-align: right">{{ eneuro(data.pm2moy) }}</div>
					</template>
				</Column>
			</DataTable>
			
			<table id='datatable_optimisation' style="display: none">
				<tbody>
					<tr>
						<th class="center" v-if="filter2 == 'echelle'">{{ $t('echelle_residentielle') }}</th>
						<th class="center" v-else>{{ $t('typologie') }}</th>
						<th class="center">{{ $t('repartition_initiale') }} - en %</th>
						<th class="center">{{ $t('repartition_initiale') }} - en Nb</th>
						<th class="center">{{ $t('offre') }} - en %</th>
						<th class="center">{{ $t('offre') }} - en Nb</th>
						<th class="center">{{ $t('rythme') }} - en Tx écou.</th>
						<th class="center">{{ $t('rythme') }} - {{ $t('mois_de_com.') }}</th>
						<th class="center">{{ $t('ventes') }} - en %</th>
						<th class="center">{{ $t('ventes') }} - en Nb</th>
						<th class="center">{{ $t('ventes') }} - par mois</th>
						<th class="center">{{ $t('surface_unitaire_minimum') }}</th>
						<th class="center">{{ $t('surface_unitaire_moyenne') }}</th>
						<th class="center">{{ $t('surface_unitaire_maximum') }}</th>
						<th class="center">{{ $t('prix_unitaire_minimum') }}</th>
						<th class="center">{{ $t('prix_unitaire_moyen') }}</th>
						<th class="center">{{ $t('prix_unitaire_maximum') }}</th>
						<th class="center">{{ $t('prix_/_m²') }} moyen</th>
					</tr>
					<tr v-for="i in compteur" v-bind:key="i">
						<td class="center" :id="getId('opt_lib_', i)">{{getTypoLib(i)}}</td>
						<td class="center" :id="getId('opt_si_', i)" v-if="stock_ini_total">{{percent(GetValueProgrammeTypo(5,i) / stock_ini_total * 100)}}</td><td class="center" :id="getId('opt_si_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_si_nb_', i)" v-if="stock_ini_total">{{GetValueProgrammeTypo(5,i)}}</td><td class="center" :id="getId('opt_si_nb_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_offre_', i)" v-if="offre_total">{{percent(GetValueProgrammeTypo(1,i) / offre_total * 100)}}</td><td class="center" :id="getId('opt_offre_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_offre_nb_', i)" v-if="offre_total">{{GetValueProgrammeTypo(1,i)}}</td><td class="center" :id="getId('opt_offre_nb_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_rythme_', i)">{{percent(GetValueProgrammeTypo(7,i) * 100,2)}}</td>
						<td class="center" :id="getId('opt_rythme_mois_', i)">{{enmois(GetValueProgrammeTypo(6,i))}}</td>
						<td class="center" :id="getId('opt_ventes_', i)" v-if="ventes_totales">{{percent(GetValueProgrammeTypo(2,i) / ventes_totales * 100)}}</td><td class="center" :id="getId('opt_ventes_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_ventes_nb_', i)" v-if="ventes_totales">{{GetValueProgrammeTypo(2,i)}}</td><td class="center" :id="getId('opt_ventes_nb_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_rythme_vtemois_', i)">{{GetValueProgrammeTypo(14,i)}}</td>
						<td class="center" :id="getId('opt_surfmin_', i)" v-if="GetValueProgrammeTypo(10,i)">{{GetValueProgrammeTypo(10,i)}} m²</td><td class="center" :id="getId('opt_surfmin_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_surfmoy_', i)" v-if="GetValueProgrammeTypo(8,i)">{{GetValueProgrammeTypo(8,i)}} m²</td><td class="center" :id="getId('opt_surfmoy_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_surfmax_', i)" v-if="GetValueProgrammeTypo(11,i)">{{GetValueProgrammeTypo(11,i)}} m²</td><td class="center" :id="getId('opt_surfmax_', i)" v-else>-</td>
						<td class="center" :id="getId('opt_pummin_', i)">{{eneuro(GetValueProgrammeTypo(12,i))}}</td>
						<td class="center" :id="getId('opt_pummoy_', i)">{{eneuro(GetValueProgrammeTypo(9,i))}}</td>
						<td class="center" :id="getId('opt_pummax_', i)">{{eneuro(GetValueProgrammeTypo(13,i))}}</td>
						<td class="center" :id="getId('opt_pummax_', i)">{{eneuro(GetValueProgrammeTypo(15,i))}}</td>
					</tr>
					<tr class="total">
						<td class="center" :id="getId('opt_lib_', compteur+1)">{{ $t('total_/_moyenne') }}</td>
						<td class="center" id='opt_si_tot'>100%</td>
						<td class="center" :id="getId('opt_si_nb_', compteur+1)" v-if="stock_ini_total">{{GetValueProgrammeTypoTotal(5)}}</td><td class="center" :id="getId('opt_si_nb_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_offre_', compteur+1)" v-if="offre_total">{{percent(GetValueProgrammeTypoTotal(1) / offre_total * 100)}}</td><td class="center" :id="getId('opt_offre_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_offre_nb_', compteur+1)" v-if="offre_total">{{GetValueProgrammeTypoTotal(1)}}</td><td class="center" :id="getId('opt_offre_nb_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_rythme_', compteur+1)">{{percent(GetValueProgrammeTypoTotal(7) * 100,2)}}</td>
						<td class="center" :id="getId('opt_rythme_mois_', compteur+1)">{{enmois(GetValueProgrammeTypoTotal(6))}}</td>
						<td class="center" :id="getId('opt_ventes_', compteur+1)" v-if="ventes_totales">{{percent(GetValueProgrammeTypoTotal(2) / ventes_totales * 100)}}</td><td class="center" :id="getId('opt_ventes_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_ventes_nb_', compteur+1)" v-if="ventes_totales">{{GetValueProgrammeTypoTotal(2)}}</td><td class="center" :id="getId('opt_ventes_nb_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_rythme_vtemois_', compteur+1)">{{GetValueProgrammeTypoTotal(14)}}</td>
						<td class="center" :id="getId('opt_surfmin_', compteur+1)" v-if="GetValueProgrammeTypoTotal(10)">{{GetValueProgrammeTypoTotal(10)}} m²</td><td class="center" :id="getId('opt_surfmin_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_surfmoy_', compteur+1)" v-if="GetValueProgrammeTypoTotal(8)">{{GetValueProgrammeTypoTotal(8)}} m²</td><td class="center" :id="getId('opt_surfmoy_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_surfmax_', compteur+1)" v-if="GetValueProgrammeTypoTotal(11)">{{GetValueProgrammeTypoTotal(11)}} m²</td><td class="center" :id="getId('opt_surfmax_', compteur+1)" v-else>-</td>
						<td class="center" :id="getId('opt_pummin_', compteur+1)">{{eneuro(GetValueProgrammeTypoTotal(12))}}</td>
						<td class="center" :id="getId('opt_pummoy_', compteur+1)">{{eneuro(GetValueProgrammeTypoTotal(9))}}</td>
						<td class="center" :id="getId('opt_pummax_', compteur+1)">{{eneuro(GetValueProgrammeTypoTotal(13))}}</td>
						<td class="center" :id="getId('opt_pummax_', compteur+1)">{{eneuro(GetValueProgrammeTypoTotal(15))}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'ArrayProgrammesGranulometrie',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_optimisation', name: 'Optimisation de votre projet'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'optim_proj'});} },
			],
			tab: 'mois',
			tva: 'tva_pleine_libre',
			pkg: 'prix_parking_avec',
			filter: 'volume',
			filter2: 'typologie',
			suffixe: 'T',
			filter3: 'tva_pleine_libre',
			filter5: 'prix_sp',
			filter4: 'te',
			inv: 'toute',
			compteur: 5,
			ul: {
				lib: null,
				si: null,
				si_nb: null,
				offre: null,
				offre_nb: null,
				rythme: null,
				rythme_mois: null,
				vente: null,
				vente_nb: null,
				rythme_vtemois: null,
				surfmin: null,
				surfmoy: null,
				surfmax: null,
				pummin: null,
				pummoy: null,
				pummax: null,
			}
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		synojson:function(){
			let taboptim = []
			let j;
			for(let i = 1; i <= this.compteur; i++){
				j = i;
				taboptim.push(
					{
						id			: i,
						lib			: this.getTypoLib(i),
						si 			: this.percent(this.GetValueProgrammeTypo(5,i) / this.stock_ini_total * 100),
						si_nb		: this.GetValueProgrammeTypo(5,i),
						offre		: this.percent(this.GetValueProgrammeTypo(1,i) / this.offre_total * 100),
						offre_nb	: this.GetValueProgrammeTypo(1,i),
						rythme		: this.percent(this.GetValueProgrammeTypo(7,i) * 100,2),
						rythme_mois	: this.enmois(this.GetValueProgrammeTypo(6,i)),
						vente		: this.percent(this.GetValueProgrammeTypo(2,i) / this.ventes_totales * 100),
						vente_nb	: this.GetValueProgrammeTypo(2,i),
						rythme_vtemois	: this.GetValueProgrammeTypo(14,i),
						surfmin		: this.GetValueProgrammeTypo(10,i),
						surfmoy		: this.GetValueProgrammeTypo(8,i),
						surfmax		: this.GetValueProgrammeTypo(11,i),
						pummin		: this.GetValueProgrammeTypo(12,i),
						pummoy		: this.GetValueProgrammeTypo(9,i),
						pummax		: this.GetValueProgrammeTypo(13,i),
						pm2moy		: this.GetValueProgrammeTypo(15,i),
					}
				);
			}
			j = j + 1;
			taboptim.push(
				{
					id			: j,
					lib			: 'TOTAL',
					si 			: this.percent(this.GetValueProgrammeTypoTotal(5) / this.stock_ini_total * 100),
					si_nb		: this.GetValueProgrammeTypoTotal(5),
					offre		: this.percent(this.GetValueProgrammeTypoTotal(1) / this.offre_total * 100),
					offre_nb	: this.GetValueProgrammeTypoTotal(1),
					rythme		: this.percent(this.GetValueProgrammeTypoTotal(7) * 100,2),
					rythme_mois	: this.enmois(this.GetValueProgrammeTypoTotal(6)),
					vente		: this.percent(this.GetValueProgrammeTypoTotal(2) / this.ventes_totales * 100),
					vente_nb	: this.GetValueProgrammeTypoTotal(2),
					rythme_vtemois	: this.GetValueProgrammeTypoTotal(14),
					surfmin		: this.GetValueProgrammeTypoTotal(10),
					surfmoy		: this.GetValueProgrammeTypoTotal(8),
					surfmax		: this.GetValueProgrammeTypoTotal(11),
					pummin		: this.GetValueProgrammeTypoTotal(12),
					pummoy		: this.GetValueProgrammeTypoTotal(9),
					pummax		: this.GetValueProgrammeTypoTotal(13),
					pm2moy		: this.GetValueProgrammeTypoTotal(15),
				}
			);
			return taboptim
		},

		offre_total: function() {
			let n = 0
			if (this.app.livedata.detailtypo.t1.offre) {n += this.app.livedata.detailtypo.t1.offre}
			if (this.app.livedata.detailtypo.t2.offre) {n += this.app.livedata.detailtypo.t2.offre}
			if (this.app.livedata.detailtypo.t3.offre) {n += this.app.livedata.detailtypo.t3.offre}
			if (this.app.livedata.detailtypo.t4.offre) {n += this.app.livedata.detailtypo.t4.offre}
			if (this.app.livedata.detailtypo.t5.offre) {n += this.app.livedata.detailtypo.t5.offre}
			return n
		},

		stock_ini_total: function() {
			let n = 0
			if (this.app.livedata.detailtypo.t1.si) {n += this.app.livedata.detailtypo.t1.si}
			if (this.app.livedata.detailtypo.t2.si) {n += this.app.livedata.detailtypo.t2.si}
			if (this.app.livedata.detailtypo.t3.si) {n += this.app.livedata.detailtypo.t3.si}
			if (this.app.livedata.detailtypo.t4.si) {n += this.app.livedata.detailtypo.t4.si}
			if (this.app.livedata.detailtypo.t5.si) {n += this.app.livedata.detailtypo.t5.si}
			return n
		},

		ventes_totales: function() {
			let n = 0
			switch (this.inv) {
				case "occ":
				switch (this.filter3) {
					case "tva_pleine_aide":
					if (this.app.livedata.detailtypo.t1.vte.occ.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.occ.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.occ.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.occ.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.occ.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.occ.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.occ.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.occ.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.occ.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.occ.pleine_aide.nb_hp}
					break;
					case "tva_reduite":
					if (this.app.livedata.detailtypo.t1.vte.occ.reduite.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.occ.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.occ.reduite.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.occ.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.occ.reduite.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.occ.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.occ.reduite.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.occ.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.occ.reduite.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.occ.reduite.nb_hp}
					break;
					default:
					if (this.app.livedata.detailtypo.t1.vte.occ.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.occ.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.occ.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.occ.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.occ.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.occ.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.occ.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.occ.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.occ.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.occ.pleine_libre.nb_hp}
					break;
				}
				break;
				case "inv":
				switch (this.filter3) {
					case "tva_pleine_aide":
					if (this.app.livedata.detailtypo.t1.vte.inv.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.inv.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.inv.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.inv.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.inv.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.inv.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.inv.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.inv.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.inv.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.inv.pleine_aide.nb_hp}
					break;
					case "tva_reduite":
					if (this.app.livedata.detailtypo.t1.vte.inv.reduite.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.inv.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.inv.reduite.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.inv.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.inv.reduite.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.inv.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.inv.reduite.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.inv.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.inv.reduite.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.inv.reduite.nb_hp}
					break;
					default:
					if (this.app.livedata.detailtypo.t1.vte.inv.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.inv.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.inv.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.inv.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.inv.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.inv.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.inv.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.inv.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.inv.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.inv.pleine_libre.nb_hp}
					break;
				}
				break;
				default:
				switch (this.filter3) {
					case "tva_pleine_aide":
					if (this.app.livedata.detailtypo.t1.vte.tout.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.tout.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.tout.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.tout.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.tout.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.tout.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.tout.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.tout.pleine_aide.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.tout.pleine_aide.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.tout.pleine_aide.nb_hp}
					break;
					case "tva_reduite":
					if (this.app.livedata.detailtypo.t1.vte.tout.reduite.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.tout.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.tout.reduite.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.tout.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.tout.reduite.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.tout.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.tout.reduite.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.tout.reduite.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.tout.reduite.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.tout.reduite.nb_hp}
					break;
					default:
					if (this.app.livedata.detailtypo.t1.vte.tout.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t1.vte.tout.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t2.vte.tout.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t2.vte.tout.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t3.vte.tout.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t3.vte.tout.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t4.vte.tout.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t4.vte.tout.pleine_libre.nb_hp}
					if (this.app.livedata.detailtypo.t5.vte.tout.pleine_libre.nb_hp) {n += this.app.livedata.detailtypo.t5.vte.tout.pleine_libre.nb_hp}
					break;
				}
				break;
			}
			return n
		},
	}),

	watch: {
		'filter2': function(newValue) {
			this.$store.dispatch('set_optimisation', { entree: newValue, ryhtme: this.filter4, destination: this.inv, financement: this.filter3, prix: this.filter5 })
		},

		'filter4': function(newValue) {
			this.$store.dispatch('set_optimisation', {entree: this.filter2, ryhtme: newValue, destination: this.inv, financement: this.filter3, prix: this.filter5})
		},

		'inv': function(newValue) {
			this.$store.dispatch('set_optimisation', {entree: this.filter2, ryhtme: this.filter4, destination: newValue, financement: this.filter3, prix: this.filter5})
		},

		'filter3': function(newValue) {
			this.$store.dispatch('set_optimisation', {entree: this.filter2, ryhtme: this.filter4, destination: this.inv, financement: newValue, prix: this.filter5})
		},

		'filter5': function(newValue) {
			this.$store.dispatch('set_optimisation', {entree: this.filter2, ryhtme: this.filter4, destination: this.inv, financement: this.filter3, prix: newValue})
		}
	},
	methods: {
		rowClass(data) {
            return data.lib === 'TOTAL' ? 'row-accessories': null;
        },
		
		getId: function(t,i) {
			return t+i
		},

		getTypoLib: function(t) {
			if (this.filter2 != 'typologie') {
				switch(parseInt(t))
				{
					case 1:
					return "< 34m²"
					case 2:
					return "34m²-39m²"
					case 3:
					return "39m²-44m²"
					case 4:
					return "44m²-47m²"
					case 5:
					return "47m²-50m²"
					case 6:
					return "50m²-56m²"
					case 7:
					return "56m²-62m²"
					case 8:
					return "62m²-72m²"
					case 9:
					return "72m²-78m²"
					case 10:
					return "78m²-84m²"
					case 11:
					return "84m²-89m²"
					case 12:
					return ">= 89m²"
				}
			} else {
				return "T" + t 
			}
		},

		GetVentesEchelleTypo: function(Typo) {
			if (this.filter2 == 'typologie') {
				return this.app.livedata.typo[Typo-1].ventes
			} else {
				return this.app.livedata.echelle[Typo-1].ventes
			}
		},

		GetValueProgrammeTypoTotal: function(NoMesure) {
			let nret = 0
			let vTypo
			let si_tot = 0
			let nret2 = 0
			for (let index = 1; index < 6; index++) {
				switch (index) {
					case 1:
					vTypo = this.app.livedata.detailtypo.t1
					break;
					case 2:
					vTypo = this.app.livedata.detailtypo.t2
					break;
					case 3:
					vTypo = this.app.livedata.detailtypo.t3
					break;
					case 4:
					vTypo = this.app.livedata.detailtypo.t4
					break;
					default:
					vTypo = this.app.livedata.detailtypo.t5
					break;
					
				}
				si_tot += vTypo.si
				switch (NoMesure) {
					case 1:
					nret += vTypo.offre
					break;
					case 2:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.occ.pleine_aide.nb_hp) {nret += vTypo.vte.occ.pleine_aide.nb_hp}
							break;
							case "tva_reduite":
							if (vTypo.vte.occ.reduite.nb_hp) {nret += vTypo.vte.occ.reduite.nb_hp}
							break;
							default:
							if (vTypo.vte.occ.pleine_libre.nb_hp) {nret += vTypo.vte.occ.pleine_libre.nb_hp}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.inv.pleine_aide.nb_hp) {nret += vTypo.vte.inv.pleine_aide.nb_hp}
							break;
							case "tva_reduite":
							if (vTypo.vte.inv.reduite.nb_hp) {nret += vTypo.vte.inv.reduite.nb_hp}
							break;
							default:
							if (vTypo.vte.inv.pleine_libre.nb_hp) {nret += vTypo.vte.inv.pleine_libre.nb_hp}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.tout.pleine_aide.nb_hp) {nret += vTypo.vte.tout.pleine_aide.nb_hp}
							break;
							case "tva_reduite":
							if (vTypo.vte.tout.reduite.nb_hp) {nret += vTypo.vte.tout.reduite.nb_hp}
							break;
							default:
							if (vTypo.vte.tout.pleine_libre.nb_hp) {nret += vTypo.vte.tout.pleine_libre.nb_hp}
							break;
						}
						break;
					}
					break;
					case 5:
					nret += vTypo.si
					break;
					case 6:
					case 7:
					nret += (vTypo.vte.tout.pleine_aide.vpm + vTypo.vte.tout.pleine_libre.vpm + vTypo.vte.tout.reduite.vpm)
					break;
					case 14:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.occ.pleine_aide.vpm) {nret += (vTypo.vte.occ.pleine_aide.vpm * vTypo.vte.occ.pleine_aide.nb_hp)}
							nret2 += vTypo.vte.occ.pleine_aide.nb_hp
							break;
							case "tva_reduite":
							if (vTypo.vte.occ.reduite.vpm) {nret += (vTypo.vte.occ.reduite.vpm * vTypo.vte.occ.reduite.nb_hp)}
							nret2 += vTypo.vte.occ.reduite.nb_hp
							break;
							default:
							if (vTypo.vte.occ.pleine_libre.vpm) {nret += (vTypo.vte.occ.pleine_libre.vpm * vTypo.vte.occ.pleine_libre.nb_hp)}
							nret2 += vTypo.vte.occ.pleine_libre.nb_hp
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.inv.pleine_aide.vpm) {nret += (vTypo.vte.inv.pleine_aide.vpm * vTypo.vte.inv.pleine_aide.nb_hp)}
							nret2 += vTypo.vte.inv.pleine_aide.nb_hp
							break;
							case "tva_reduite":
							if (vTypo.vte.inv.reduite.vpm) {nret += (vTypo.vte.inv.reduite.vpm * vTypo.vte.inv.reduite.nb_hp)}
							nret2 += vTypo.vte.inv.reduite.nb_hp
							break;
							default:
							if (vTypo.vte.inv.pleine_libre.vpm) {nret += (vTypo.vte.inv.pleine_libre.vpm * vTypo.vte.inv.pleine_libre.nb_hp)}
							nret2 += vTypo.vte.inv.pleine_libre.nb_hp
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.tout.pleine_aide.vpm) {nret += (vTypo.vte.tout.pleine_aide.vpm * vTypo.vte.tout.pleine_aide.nb_hp)}
							nret2 += vTypo.vte.tout.pleine_aide.nb_hp
							break;
							case "tva_reduite":
							if (vTypo.vte.tout.reduite.vpm) {nret += (vTypo.vte.tout.reduite.vpm * vTypo.vte.tout.reduite.nb_hp)}
							nret2 += vTypo.vte.tout.reduite.nb_hp
							break;
							default:
							if (vTypo.vte.tout.pleine_libre.vpm) {nret += (vTypo.vte.tout.pleine_libre.vpm * vTypo.vte.tout.pleine_libre.nb_hp)}
							nret2 += vTypo.vte.tout.pleine_libre.nb_hp
							break;
						}
						break;
					}
					break;
					case 8:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.occ.pleine_aide.sum_moy_hp) {
								nret += vTypo.vte.occ.pleine_aide.nb_hp * vTypo.vte.occ.pleine_aide.sum_moy_hp
								nret2 += vTypo.vte.occ.pleine_aide.nb_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.occ.reduite.sum_moy_hp) {
								nret += vTypo.vte.occ.reduite.nb_hp * vTypo.vte.occ.reduite.sum_moy_hp
								nret2 += vTypo.vte.occ.reduite.nb_hp
							}
							break;
							default:
							if (vTypo.vte.occ.pleine_libre.sum_moy_hp) {
								nret += vTypo.vte.occ.pleine_libre.nb_hp * vTypo.vte.occ.pleine_libre.sum_moy_hp
								nret2 += vTypo.vte.occ.pleine_libre.nb_hp
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.inv.pleine_aide.sum_moy_hp) {
								nret += vTypo.vte.inv.pleine_aide.nb_hp * vTypo.vte.inv.pleine_aide.sum_moy_hp
								nret2 += vTypo.vte.inv.pleine_aide.nb_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.inv.reduite.sum_moy_hp) {
								nret += vTypo.vte.inv.reduite.nb_hp * vTypo.vte.inv.reduite.sum_moy_hp
								nret2 += vTypo.vte.inv.reduite.nb_hp
							}
							break;
							default:
							if (vTypo.vte.inv.pleine_libre.sum_moy_hp) {
								nret += vTypo.vte.inv.pleine_libre.nb_hp * vTypo.vte.inv.pleine_libre.sum_moy_hp
								nret2 += vTypo.vte.inv.pleine_libre.nb_hp
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.tout.pleine_aide.sum_moy_hp) {
								nret += vTypo.vte.tout.pleine_aide.nb_hp * vTypo.vte.tout.pleine_aide.sum_moy_hp
								nret2 += vTypo.vte.tout.pleine_aide.nb_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.tout.reduite.sum_moy_hp) {
								nret += vTypo.vte.tout.reduite.nb_hp * vTypo.vte.tout.reduite.sum_moy_hp
								nret2 += vTypo.vte.tout.reduite.nb_hp
							}
							break;
							default:
							if (vTypo.vte.tout.pleine_libre.sum_moy_hp) {
								nret += vTypo.vte.tout.pleine_libre.nb_hp * vTypo.vte.tout.pleine_libre.sum_moy_hp
								nret2 += vTypo.vte.tout.pleine_libre.nb_hp
							}
							break;
						}
						break;
					}
					break;
					case 15:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_aide.pm2_moy_ap) {
									nret += vTypo.vte.occ.pleine_aide.nb_ap * vTypo.vte.occ.pleine_aide.pm2_moy_ap
									nret2 += vTypo.vte.occ.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_aide.pm2_moy_hp) {
									nret += vTypo.vte.occ.pleine_aide.nb_hp * vTypo.vte.occ.pleine_aide.pm2_moy_hp
									nret2 += vTypo.vte.occ.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.reduite.pm2_moy_ap) {
									nret += vTypo.vte.occ.reduite.nb_ap * vTypo.vte.occ.reduite.pm2_moy_ap
									nret2 += vTypo.vte.occ.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.occ.reduite.pm2_moy_hp) {
									nret += vTypo.vte.occ.reduite.nb_hp * vTypo.vte.occ.reduite.pm2_moy_hp
									nret2 += vTypo.vte.occ.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_libre.pm2_moy_ap) {
									nret += vTypo.vte.occ.pleine_libre.nb_ap * vTypo.vte.occ.pleine_libre.pm2_moy_ap
									nret2 += vTypo.vte.occ.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_libre.pm2_moy_hp) {
									nret += vTypo.vte.occ.pleine_libre.nb_hp * vTypo.vte.occ.pleine_libre.pm2_moy_hp
									nret2 += vTypo.vte.occ.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_aide.pm2_moy_ap) {
									nret += vTypo.vte.inv.pleine_aide.nb_ap * vTypo.vte.inv.pleine_aide.pm2_moy_ap
									nret2 += vTypo.vte.inv.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_aide.pm2_moy_hp) {
									nret += vTypo.vte.inv.pleine_aide.nb_hp * vTypo.vte.inv.pleine_aide.pm2_moy_hp
									nret2 += vTypo.vte.inv.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.reduite.pm2_moy_ap) {
									nret += vTypo.vte.inv.reduite.nb_ap * vTypo.vte.inv.reduite.pm2_moy_ap
									nret2 += vTypo.vte.inv.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.inv.reduite.pm2_moy_hp) {
									nret += vTypo.vte.inv.reduite.nb_hp * vTypo.vte.inv.reduite.pm2_moy_hp
									nret2 += vTypo.vte.inv.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_libre.pm2_moy_ap) {
									nret += vTypo.vte.inv.pleine_libre.nb_ap * vTypo.vte.inv.pleine_libre.pm2_moy_ap
									nret2 += vTypo.vte.inv.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_libre.pm2_moy_hp) {
									nret += vTypo.vte.inv.pleine_libre.nb_hp * vTypo.vte.inv.pleine_libre.pm2_moy_hp
									nret2 += vTypo.vte.inv.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_aide.pm2_moy_ap) {
									nret += vTypo.vte.tout.pleine_aide.nb_ap * vTypo.vte.tout.pleine_aide.pm2_moy_ap
									nret2 += vTypo.vte.tout.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_aide.pm2_moy_hp) {
									nret += vTypo.vte.tout.pleine_aide.nb_hp * vTypo.vte.tout.pleine_aide.pm2_moy_hp
									nret2 += vTypo.vte.tout.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.reduite.pm2_moy_ap) {
									nret +=  vTypo.vte.tout.reduite.nb_ap * vTypo.vte.tout.reduite.pm2_moy_ap
									nret2 += vTypo.vte.tout.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.tout.reduite.pm2_moy_hp) {
									nret += vTypo.vte.tout.reduite.nb_hp * vTypo.vte.tout.reduite.pm2_moy_hp
									nret2 += vTypo.vte.tout.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_libre.pm2_moy_ap) {
									nret += vTypo.vte.tout.pleine_libre.nb_ap * vTypo.vte.tout.pleine_libre.pm2_moy_ap
									nret2 += vTypo.vte.tout.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_libre.pm2_moy_hp) {
									nret += vTypo.vte.tout.pleine_libre.nb_hp * vTypo.vte.tout.pleine_libre.pm2_moy_hp
									nret2 += vTypo.vte.tout.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
					}
					break;
					case 9:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_aide.pum_moy_ap) {
									nret += vTypo.vte.occ.pleine_aide.nb_ap * vTypo.vte.occ.pleine_aide.pum_moy_ap
									nret2 += vTypo.vte.occ.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_aide.pum_moy_hp) {
									nret += vTypo.vte.occ.pleine_aide.nb_hp * vTypo.vte.occ.pleine_aide.pum_moy_hp
									nret2 += vTypo.vte.occ.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.reduite.pum_moy_ap) {
									nret += vTypo.vte.occ.reduite.nb_ap * vTypo.vte.occ.reduite.pum_moy_ap
									nret2 += vTypo.vte.occ.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.occ.reduite.pum_moy_hp) {
									nret += vTypo.vte.occ.reduite.nb_hp * vTypo.vte.occ.reduite.pum_moy_hp
									nret2 += vTypo.vte.occ.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_libre.pum_moy_ap) {
									nret += vTypo.vte.occ.pleine_libre.nb_ap * vTypo.vte.occ.pleine_libre.pum_moy_ap
									nret2 += vTypo.vte.occ.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_libre.pum_moy_hp) {
									nret += vTypo.vte.occ.pleine_libre.nb_hp * vTypo.vte.occ.pleine_libre.pum_moy_hp
									nret2 += vTypo.vte.occ.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_aide.pum_moy_ap) {
									nret += vTypo.vte.inv.pleine_aide.nb_ap * vTypo.vte.inv.pleine_aide.pum_moy_ap
									nret2 += vTypo.vte.inv.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_aide.pum_moy_hp) {
									nret += vTypo.vte.inv.pleine_aide.nb_hp * vTypo.vte.inv.pleine_aide.pum_moy_hp
									nret2 += vTypo.vte.inv.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.reduite.pum_moy_ap) {
									nret += vTypo.vte.inv.reduite.nb_ap * vTypo.vte.inv.reduite.pum_moy_ap
									nret2 += vTypo.vte.inv.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.inv.reduite.pum_moy_hp) {
									nret += vTypo.vte.inv.reduite.nb_hp * vTypo.vte.inv.reduite.pum_moy_hp
									nret2 += vTypo.vte.inv.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_libre.pum_moy_ap) {
									nret += vTypo.vte.inv.pleine_libre.nb_ap * vTypo.vte.inv.pleine_libre.pum_moy_ap
									nret2 += vTypo.vte.inv.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_libre.pum_moy_hp) {
									nret += vTypo.vte.inv.pleine_libre.nb_hp * vTypo.vte.inv.pleine_libre.pum_moy_hp
									nret2 += vTypo.vte.inv.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_aide.pum_moy_ap) {
									nret += vTypo.vte.tout.pleine_aide.nb_ap * vTypo.vte.tout.pleine_aide.pum_moy_ap
									nret2 += vTypo.vte.tout.pleine_aide.nb_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_aide.pum_moy_hp) {
									nret += vTypo.vte.tout.pleine_aide.nb_hp * vTypo.vte.tout.pleine_aide.pum_moy_hp
									nret2 += vTypo.vte.tout.pleine_aide.nb_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.reduite.pum_moy_ap) {
									nret +=  vTypo.vte.tout.reduite.nb_ap * vTypo.vte.tout.reduite.pum_moy_ap
									nret2 += vTypo.vte.tout.reduite.nb_ap
								}
							} else {
								if (vTypo.vte.tout.reduite.pum_moy_hp) {
									nret += vTypo.vte.tout.reduite.nb_hp * vTypo.vte.tout.reduite.pum_moy_hp
									nret2 += vTypo.vte.tout.reduite.nb_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_libre.pum_moy_ap) {
									nret += vTypo.vte.tout.pleine_libre.nb_ap * vTypo.vte.tout.pleine_libre.pum_moy_ap
									nret2 += vTypo.vte.tout.pleine_libre.nb_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_libre.pum_moy_hp) {
									nret += vTypo.vte.tout.pleine_libre.nb_hp * vTypo.vte.tout.pleine_libre.pum_moy_hp
									nret2 += vTypo.vte.tout.pleine_libre.nb_hp
								}
							}
							break;
						}
						break;
					}
					break;
					case 10:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.occ.pleine_aide.sum_min_hp) {
								if (vTypo.vte.occ.pleine_aide.sum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.pleine_aide.sum_min_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.occ.reduite.sum_min_hp) {
								if (vTypo.vte.occ.reduite.sum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.reduite.sum_min_hp
							}
							break;
							default:
							if (vTypo.vte.occ.pleine_libre.sum_min_hp) {
								if (vTypo.vte.occ.pleine_libre.sum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.pleine_libre.sum_min_hp
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.inv.pleine_aide.sum_min_hp) {
								if (vTypo.vte.inv.pleine_aide.sum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.pleine_aide.sum_min_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.inv.reduite.sum_min_hp) {
								if (vTypo.vte.inv.reduite.sum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.reduite.sum_min_hp
							}
							break;
							default:
							if (vTypo.vte.inv.pleine_libre.sum_min_hp) {
								if (vTypo.vte.inv.pleine_libre.sum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.pleine_libre.sum_min_hp
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.tout.pleine_aide.sum_min_hp) {
								if (vTypo.vte.tout.pleine_aide.sum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.pleine_aide.sum_min_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.tout.reduite.sum_min_hp) {
								if (vTypo.vte.tout.reduite.sum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.reduite.sum_min_hp
							}
							break;
							default:
							if (vTypo.vte.tout.pleine_libre.sum_min_hp) {
								if (vTypo.vte.tout.pleine_libre.sum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.pleine_libre.sum_min_hp
							}
							break;
						}
						break;
					}
					break;
					case 11:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.occ.pleine_aide.sum_max_hp) {
								if (vTypo.vte.occ.pleine_aide.sum_max_hp > nret || nret == 0) nret = vTypo.vte.occ.pleine_aide.sum_max_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.occ.reduite.sum_max_hp) {
								if (vTypo.vte.occ.reduite.sum_max_hp > nret || nret == 0) nret = vTypo.vte.occ.reduite.sum_max_hp
							}
							break;
							default:
							if (vTypo.vte.occ.pleine_libre.sum_max_hp) {
								if (vTypo.vte.occ.pleine_libre.sum_max_hp > nret || nret == 0) nret = vTypo.vte.occ.pleine_libre.sum_max_hp
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.inv.pleine_aide.sum_max_hp) {
								if (vTypo.vte.inv.pleine_aide.sum_max_hp > nret || nret == 0) nret = vTypo.vte.inv.pleine_aide.sum_max_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.inv.reduite.sum_max_hp) {
								if (vTypo.vte.inv.reduite.sum_max_hp > nret || nret == 0) nret = vTypo.vte.inv.reduite.sum_max_hp
							}
							break;
							default:
							if (vTypo.vte.inv.pleine_libre.sum_max_hp) {
								if (vTypo.vte.inv.pleine_libre.sum_max_hp > nret || nret == 0) nret = vTypo.vte.inv.pleine_libre.sum_max_hp
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (vTypo.vte.tout.pleine_aide.sum_max_hp) {
								if (vTypo.vte.tout.pleine_aide.sum_max_hp > nret || nret == 0) nret = vTypo.vte.tout.pleine_aide.sum_max_hp
							}
							break;
							case "tva_reduite":
							if (vTypo.vte.tout.reduite.sum_max_hp) {
								if (vTypo.vte.tout.reduite.sum_max_hp > nret || nret == 0) nret = vTypo.vte.tout.reduite.sum_max_hp
							}
							break;
							default:
							if (vTypo.vte.tout.pleine_libre.sum_max_hp) {
								if (vTypo.vte.tout.pleine_libre.sum_max_hp > nret || nret == 0) nret = vTypo.vte.tout.pleine_libre.sum_max_hp
							}
							break;
						}
						break;
					}
					break;
					case 12:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_aide.pum_min_ap) {
									if (vTypo.vte.occ.pleine_aide.pum_min_ap < nret || nret == 0) nret = vTypo.vte.occ.pleine_aide.pum_min_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_aide.pum_min_hp) {
									if (vTypo.vte.occ.pleine_aide.pum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.pleine_aide.pum_min_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.reduite.pum_min_ap) {
									if (vTypo.vte.occ.reduite.pum_min_ap < nret || nret == 0) nret = vTypo.vte.occ.reduite.pum_min_ap
								}
							} else {
								if (vTypo.vte.occ.reduite.pum_min_hp) {
									if (vTypo.vte.occ.reduite.pum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.reduite.pum_min_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_libre.pum_min_ap) {
									if (vTypo.vte.occ.pleine_libre.pum_min_ap < nret || nret == 0) nret = vTypo.vte.occ.pleine_libre.pum_min_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_libre.pum_min_hp) {
									if (vTypo.vte.occ.pleine_libre.pum_min_hp < nret || nret == 0) nret = vTypo.vte.occ.pleine_libre.pum_min_hp
								}
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_aide.pum_min_ap) {
									if (vTypo.vte.inv.pleine_aide.pum_min_ap < nret || nret == 0) nret = vTypo.vte.inv.pleine_aide.pum_min_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_aide.pum_min_hp) {
									if (vTypo.vte.inv.pleine_aide.pum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.pleine_aide.pum_min_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.reduite.pum_min_ap) {
									if (vTypo.vte.inv.reduite.pum_min_ap < nret || nret == 0) nret = vTypo.vte.inv.reduite.pum_min_ap
								}
							} else {
								if (vTypo.vte.inv.reduite.pum_min_hp) {
									if (vTypo.vte.inv.reduite.pum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.reduite.pum_min_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_libre.pum_min_ap) {
									if (vTypo.vte.inv.pleine_libre.pum_min_ap < nret || nret == 0) nret = vTypo.vte.inv.pleine_libre.pum_min_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_libre.pum_min_hp) {
									if (vTypo.vte.inv.pleine_libre.pum_min_hp < nret || nret == 0) nret = vTypo.vte.inv.pleine_libre.pum_min_hp
								}
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_aide.pum_min_ap) {
									if (vTypo.vte.tout.pleine_aide.pum_min_ap < nret || nret == 0) nret = vTypo.vte.tout.pleine_aide.pum_min_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_aide.pum_min_hp) {
									if (vTypo.vte.tout.pleine_aide.pum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.pleine_aide.pum_min_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.reduite.pum_min_ap) {
									if (vTypo.vte.tout.reduite.pum_min_ap < nret || nret == 0) nret = vTypo.vte.tout.reduite.pum_min_ap
								}
							} else {
								if (vTypo.vte.tout.reduite.pum_min_hp) {
									if (vTypo.vte.tout.reduite.pum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.reduite.pum_min_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_libre.pum_min_ap) {
									if (vTypo.vte.tout.pleine_libre.pum_min_ap < nret || nret == 0) nret = vTypo.vte.tout.pleine_libre.pum_min_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_libre.pum_min_hp) {
									if (vTypo.vte.tout.pleine_libre.pum_min_hp < nret || nret == 0) nret = vTypo.vte.tout.pleine_libre.pum_min_hp
								}
							}
							break;
						}
						break;
					}
					break;
					case 13:
					switch (this.inv) {
						case "occ":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_aide.pum_max_ap) {
									if (vTypo.vte.occ.pleine_aide.pum_max_ap > nret) nret = vTypo.vte.occ.pleine_aide.pum_max_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_aide.pum_max_hp) {
									if (vTypo.vte.occ.pleine_aide.pum_max_hp > nret) nret = vTypo.vte.occ.pleine_aide.pum_max_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.reduite.pum_max_ap) {
									if (vTypo.vte.occ.reduite.pum_max_ap > nret) nret = vTypo.vte.occ.reduite.pum_max_ap
								}
							} else {
								if (vTypo.vte.occ.reduite.pum_max_hp) {
									if (vTypo.vte.occ.reduite.pum_max_hp > nret) nret = vTypo.vte.occ.reduite.pum_max_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.occ.pleine_libre.pum_max_ap) {
									if (vTypo.vte.occ.pleine_libre.pum_max_ap > nret) nret = vTypo.vte.occ.pleine_libre.pum_max_ap
								}
							} else {
								if (vTypo.vte.occ.pleine_libre.pum_max_hp) {
									if (vTypo.vte.occ.pleine_libre.pum_max_hp > nret) nret = vTypo.vte.occ.pleine_libre.pum_max_hp
								}
							}
							break;
						}
						break;
						case "inv":
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_aide.pum_max_ap) {
									if (vTypo.vte.inv.pleine_aide.pum_max_ap > nret) nret = vTypo.vte.inv.pleine_aide.pum_max_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_aide.pum_max_hp) {
									if (vTypo.vte.inv.pleine_aide.pum_max_hp > nret) nret = vTypo.vte.inv.pleine_aide.pum_max_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.reduite.pum_max_ap) {
									if (vTypo.vte.inv.reduite.pum_max_ap > nret) nret = vTypo.vte.inv.reduite.pum_max_ap
								}
							} else {
								if (vTypo.vte.inv.reduite.pum_max_hp) {
									if (vTypo.vte.inv.reduite.pum_max_hp > nret) nret = vTypo.vte.inv.reduite.pum_max_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.inv.pleine_libre.pum_max_ap) {
									if (vTypo.vte.inv.pleine_libre.pum_max_ap > nret) nret = vTypo.vte.inv.pleine_libre.pum_max_ap
								}
							} else {
								if (vTypo.vte.inv.pleine_libre.pum_max_hp) {
									if (vTypo.vte.inv.pleine_libre.pum_max_hp > nret) nret = vTypo.vte.inv.pleine_libre.pum_max_hp
								}
							}
							break;
						}
						break;
						default:
						switch (this.filter3) {
							case "tva_pleine_aide":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_aide.pum_max_ap) {
									if (vTypo.vte.tout.pleine_aide.pum_max_ap > nret) nret = vTypo.vte.tout.pleine_aide.pum_max_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_aide.pum_max_hp) {
									if (vTypo.vte.tout.pleine_aide.pum_max_hp > nret) nret = vTypo.vte.tout.pleine_aide.pum_max_hp
								}
							}
							break;
							case "tva_reduite":
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.reduite.pum_max_ap) {
									if (vTypo.vte.tout.reduite.pum_max_ap > nret) nret = vTypo.vte.tout.reduite.pum_max_ap
								}
							} else {
								if (vTypo.vte.tout.reduite.pum_max_hp) {
									if (vTypo.vte.tout.reduite.pum_max_hp > nret) nret = vTypo.vte.tout.reduite.pum_max_hp
								}
							}
							break;
							default:
							if (this.filter5 == 'prix_ap') {
								if (vTypo.vte.tout.pleine_libre.pum_max_ap) {
									if (vTypo.vte.tout.pleine_libre.pum_max_ap > nret) nret = vTypo.vte.tout.pleine_libre.pum_max_ap
								}
							} else {
								if (vTypo.vte.tout.pleine_libre.pum_max_hp) {
									if (vTypo.vte.tout.pleine_libre.pum_max_hp > nret) nret = vTypo.vte.tout.pleine_libre.pum_max_hp
								}
							}
							break;
						}
						break;
					}
					break;
					default:
					
				}
			}
			
			switch (NoMesure) {
				// Durée de commercialisation
				case 6:
				if (nret>0) {
					nret = (si_tot / nret)
				} else {
					nret = 0
				}
				break;
				// Taux d'écoulement
				case 7:
				if (si_tot>0) {
					nret = (nret / si_tot)
				} else {
					nret = 0
				}
				break;
				// Ventes par mois
				case 14:
				if (nret2 > 0) {
					nret = nret / nret2
					nret = Math.round(nret * 100) / 100
				} else {
					nret = 0
				}
				break;
				case 8:
				if (nret2 > 0) nret = Math.round(nret / nret2)
				break;
				case 9:
				if (nret2 > 0) nret = Math.round(nret / nret2)
				break;
				case 15:
				if (nret2 > 0) nret = Math.round(nret / nret2)
				break;
				
			}
			
			return nret
		},

		GetValueProgrammeTypo: function(NoMesure, Typo) {
			let nret = 0
			let vTypo
			if (this.filter2 == 'typologie') {
				switch (Typo) {
					case 1:
					vTypo = this.app.livedata.detailtypo.t1
					break;
					case 2:
					vTypo = this.app.livedata.detailtypo.t2
					break;
					case 3:
					vTypo = this.app.livedata.detailtypo.t3
					break;
					case 4:
					vTypo = this.app.livedata.detailtypo.t4
					break;
					default:
					vTypo = this.app.livedata.detailtypo.t5
					break;
					
				}
			} else {
				switch (Typo) {
					case 1:
					vTypo = this.app.livedata.detailechelle.s1
					break;
					case 2:
					vTypo = this.app.livedata.detailechelle.s2
					break;
					case 3:
					vTypo = this.app.livedata.detailechelle.s3
					break;
					case 4:
					vTypo = this.app.livedata.detailechelle.s4
					break;
					case 5:
					vTypo = this.app.livedata.detailechelle.s5
					break;
					case 6:
					vTypo = this.app.livedata.detailechelle.s6
					break;
					case 7:
					vTypo = this.app.livedata.detailechelle.s7
					break;
					case 8:
					vTypo = this.app.livedata.detailechelle.s8
					break;
					case 9:
					vTypo = this.app.livedata.detailechelle.s9
					break;
					case 10:
					vTypo = this.app.livedata.detailechelle.s10
					break;
					case 11:
					vTypo = this.app.livedata.detailechelle.s11
					break;
					default:
					vTypo = this.app.livedata.detailechelle.s12
					break;
					
				}
			}
			
			switch (NoMesure) {
				case 1:
				nret = vTypo.offre
				break;
				case 2:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.occ.pleine_aide.nb_hp) {nret = vTypo.vte.occ.pleine_aide.nb_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.occ.reduite.nb_hp) {nret = vTypo.vte.occ.reduite.nb_hp}
						break;
						default:
						if (vTypo.vte.occ.pleine_libre.nb_hp) {nret = vTypo.vte.occ.pleine_libre.nb_hp}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.inv.pleine_aide.nb_hp) {nret = vTypo.vte.inv.pleine_aide.nb_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.inv.reduite.nb_hp) {nret = vTypo.vte.inv.reduite.nb_hp}
						break;
						default:
						if (vTypo.vte.inv.pleine_libre.nb_hp) {nret = vTypo.vte.inv.pleine_libre.nb_hp}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.tout.pleine_aide.nb_hp) {nret = vTypo.vte.tout.pleine_aide.nb_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.tout.reduite.nb_hp) {nret = vTypo.vte.tout.reduite.nb_hp}
						break;
						default:
						if (vTypo.vte.tout.pleine_libre.nb_hp) {nret = vTypo.vte.tout.pleine_libre.nb_hp}
						break;
					}
					break;
				}
				break;
				case 5:
				nret = vTypo.si
				break;
				case 6:
				case 7:
				nret = (vTypo.vte.tout.pleine_aide.vpm + vTypo.vte.tout.pleine_libre.vpm + vTypo.vte.tout.reduite.vpm)
				break;
				case 14:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.occ.pleine_aide.vpm) {nret = vTypo.vte.occ.pleine_aide.vpm}
						break;
						case "tva_reduite":
						if (vTypo.vte.occ.reduite.vpm) {nret = vTypo.vte.occ.reduite.vpm}
						break;
						default:
						if (vTypo.vte.occ.pleine_libre.vpm) {nret = vTypo.vte.occ.pleine_libre.vpm}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.inv.pleine_aide.vpm) {nret = vTypo.vte.inv.pleine_aide.vpm}
						break;
						case "tva_reduite":
						if (vTypo.vte.inv.reduite.vpm) {nret = vTypo.vte.inv.reduite.vpm}
						break;
						default:
						if (vTypo.vte.inv.pleine_libre.vpm) {nret = vTypo.vte.inv.pleine_libre.vpm}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.tout.pleine_aide.vpm) {nret = vTypo.vte.tout.pleine_aide.vpm}
						break;
						case "tva_reduite":
						if (vTypo.vte.tout.reduite.vpm) {nret = vTypo.vte.tout.reduite.vpm}
						break;
						default:
						if (vTypo.vte.tout.pleine_libre.vpm) {nret = vTypo.vte.tout.pleine_libre.vpm}
						break;
					}
					break;
				}
				break;
				case 8:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.occ.pleine_aide.sum_moy_hp) {nret = vTypo.vte.occ.pleine_aide.sum_moy_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.occ.reduite.sum_moy_hp) {nret = vTypo.vte.occ.reduite.sum_moy_hp}
						break;
						default:
						if (vTypo.vte.occ.pleine_libre.sum_moy_hp) {nret = vTypo.vte.occ.pleine_libre.sum_moy_hp}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.inv.pleine_aide.sum_moy_hp) {nret = vTypo.vte.inv.pleine_aide.sum_moy_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.inv.reduite.sum_moy_hp) {nret = vTypo.vte.inv.reduite.sum_moy_hp}
						break;
						default:
						if (vTypo.vte.inv.pleine_libre.sum_moy_hp) {nret = vTypo.vte.inv.pleine_libre.sum_moy_hp}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.tout.pleine_aide.sum_moy_hp) {nret = vTypo.vte.tout.pleine_aide.sum_moy_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.tout.reduite.sum_moy_hp) {nret = vTypo.vte.tout.reduite.sum_moy_hp}
						break;
						default:
						if (vTypo.vte.tout.pleine_libre.sum_moy_hp) {nret = vTypo.vte.tout.pleine_libre.sum_moy_hp}
						break;
					}
					break;
				}
				break;
				case 9:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_aide.pum_moy_ap) {nret = vTypo.vte.occ.pleine_aide.pum_moy_ap}
						} else {
							if (vTypo.vte.occ.pleine_aide.pum_moy_hp) {nret = vTypo.vte.occ.pleine_aide.pum_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.reduite.pum_moy_ap) {nret = vTypo.vte.occ.reduite.pum_moy_ap}
						} else {
							if (vTypo.vte.occ.reduite.pum_moy_hp) {nret = vTypo.vte.occ.reduite.pum_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_libre.pum_moy_ap) {nret = vTypo.vte.occ.pleine_libre.pum_moy_ap}
						} else {
							if (vTypo.vte.occ.pleine_libre.pum_moy_hp) {nret = vTypo.vte.occ.pleine_libre.pum_moy_hp}
						}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_aide.pum_moy_ap) {nret = vTypo.vte.inv.pleine_aide.pum_moy_ap}
						} else {
							if (vTypo.vte.inv.pleine_aide.pum_moy_hp) {nret = vTypo.vte.inv.pleine_aide.pum_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.reduite.pum_moy_ap) {nret = vTypo.vte.inv.reduite.pum_moy_ap}
						} else {
							if (vTypo.vte.inv.reduite.pum_moy_hp) {nret = vTypo.vte.inv.reduite.pum_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_libre.pum_moy_ap) {nret = vTypo.vte.inv.pleine_libre.pum_moy_ap}
						} else {
							if (vTypo.vte.inv.pleine_libre.pum_moy_hp) {nret = vTypo.vte.inv.pleine_libre.pum_moy_hp}
						}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_aide.pum_moy_ap) {nret = vTypo.vte.tout.pleine_aide.pum_moy_ap}
						} else {
							if (vTypo.vte.tout.pleine_aide.pum_moy_hp) {nret = vTypo.vte.tout.pleine_aide.pum_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.reduite.pum_moy_ap) {nret = vTypo.vte.tout.reduite.pum_moy_ap}
						} else {
							if (vTypo.vte.tout.reduite.pum_moy_hp) {nret = vTypo.vte.tout.reduite.pum_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_libre.pum_moy_ap) {nret = vTypo.vte.tout.pleine_libre.pum_moy_ap}
						} else {
							if (vTypo.vte.tout.pleine_libre.pum_moy_hp) {nret = vTypo.vte.tout.pleine_libre.pum_moy_hp}
						}
						break;
					}
					break;
				}
				break;
				case 15:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_aide.pm2_moy_ap) {nret = vTypo.vte.occ.pleine_aide.pm2_moy_ap}
						} else {
							if (vTypo.vte.occ.pleine_aide.pm2_moy_hp) {nret = vTypo.vte.occ.pleine_aide.pm2_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.reduite.pm2_moy_ap) {nret = vTypo.vte.occ.reduite.pm2_moy_ap}
						} else {
							if (vTypo.vte.occ.reduite.pm2_moy_hp) {nret = vTypo.vte.occ.reduite.pm2_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_libre.pm2_moy_ap) {nret = vTypo.vte.occ.pleine_libre.pm2_moy_ap}
						} else {
							if (vTypo.vte.occ.pleine_libre.pm2_moy_hp) {nret = vTypo.vte.occ.pleine_libre.pm2_moy_hp}
						}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_aide.pm2_moy_ap) {nret = vTypo.vte.inv.pleine_aide.pm2_moy_ap}
						} else {
							if (vTypo.vte.inv.pleine_aide.pm2_moy_hp) {nret = vTypo.vte.inv.pleine_aide.pm2_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.reduite.pm2_moy_ap) {nret = vTypo.vte.inv.reduite.pm2_moy_ap}
						} else {
							if (vTypo.vte.inv.reduite.pm2_moy_hp) {nret = vTypo.vte.inv.reduite.pm2_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_libre.pm2_moy_ap) {nret = vTypo.vte.inv.pleine_libre.pm2_moy_ap}
						} else {
							if (vTypo.vte.inv.pleine_libre.pm2_moy_hp) {nret = vTypo.vte.inv.pleine_libre.pm2_moy_hp}
						}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_aide.pm2_moy_ap) {nret = vTypo.vte.tout.pleine_aide.pm2_moy_ap}
						} else {
							if (vTypo.vte.tout.pleine_aide.pm2_moy_hp) {nret = vTypo.vte.tout.pleine_aide.pm2_moy_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.reduite.pm2_moy_ap) {nret = vTypo.vte.tout.reduite.pm2_moy_ap}
						} else {
							if (vTypo.vte.tout.reduite.pm2_moy_hp) {nret = vTypo.vte.tout.reduite.pm2_moy_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_libre.pm2_moy_ap) {nret = vTypo.vte.tout.pleine_libre.pm2_moy_ap}
						} else {
							if (vTypo.vte.tout.pleine_libre.pm2_moy_hp) {nret = vTypo.vte.tout.pleine_libre.pm2_moy_hp}
						}
						break;
					}
					break;
				}
				break;
				case 10:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.occ.pleine_aide.sum_min_hp) {nret = vTypo.vte.occ.pleine_aide.sum_min_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.occ.reduite.sum_min_hp) {nret = vTypo.vte.occ.reduite.sum_min_hp}
						break;
						default:
						if (vTypo.vte.occ.pleine_libre.sum_min_hp) {nret = vTypo.vte.occ.pleine_libre.sum_min_hp}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.inv.pleine_aide.sum_min_hp) {nret = vTypo.vte.inv.pleine_aide.sum_min_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.inv.reduite.sum_min_hp) {nret = vTypo.vte.inv.reduite.sum_min_hp}
						break;
						default:
						if (vTypo.vte.inv.pleine_libre.sum_min_hp) {nret = vTypo.vte.inv.pleine_libre.sum_min_hp}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.tout.pleine_aide.sum_min_hp) {nret = vTypo.vte.tout.pleine_aide.sum_min_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.tout.reduite.sum_min_hp) {nret = vTypo.vte.tout.reduite.sum_min_hp}
						break;
						default:
						if (vTypo.vte.tout.pleine_libre.sum_min_hp) {nret = vTypo.vte.tout.pleine_libre.sum_min_hp}
						break;
					}
					break;
				}
				break;
				case 11:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.occ.pleine_aide.sum_max_hp) {nret = vTypo.vte.occ.pleine_aide.sum_max_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.occ.reduite.sum_max_hp) {nret = vTypo.vte.occ.reduite.sum_max_hp}
						break;
						default:
						if (vTypo.vte.occ.pleine_libre.sum_max_hp) {nret = vTypo.vte.occ.pleine_libre.sum_max_hp}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.inv.pleine_aide.sum_max_hp) {nret = vTypo.vte.inv.pleine_aide.sum_max_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.inv.reduite.sum_max_hp) {nret = vTypo.vte.inv.reduite.sum_max_hp}
						break;
						default:
						if (vTypo.vte.inv.pleine_libre.sum_max_hp) {nret = vTypo.vte.inv.pleine_libre.sum_max_hp}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (vTypo.vte.tout.pleine_aide.sum_max_hp) {nret = vTypo.vte.tout.pleine_aide.sum_max_hp}
						break;
						case "tva_reduite":
						if (vTypo.vte.tout.reduite.sum_max_hp) {nret = vTypo.vte.tout.reduite.sum_max_hp}
						break;
						default:
						if (vTypo.vte.tout.pleine_libre.sum_max_hp) {nret = vTypo.vte.tout.pleine_libre.sum_max_hp}
						break;
					}
					break;
				}
				break;
				case 12:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_aide.pum_min_ap) {nret = vTypo.vte.occ.pleine_aide.pum_min_ap}
						} else {
							if (vTypo.vte.occ.pleine_aide.pum_min_hp) {nret = vTypo.vte.occ.pleine_aide.pum_min_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.reduite.pum_min_ap) {nret = vTypo.vte.occ.reduite.pum_min_ap}
						} else {
							if (vTypo.vte.occ.reduite.pum_min_hp) {nret = vTypo.vte.occ.reduite.pum_min_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_libre.pum_min_ap) {nret = vTypo.vte.occ.pleine_libre.pum_min_ap}
						} else {
							if (vTypo.vte.occ.pleine_libre.pum_min_hp) {nret = vTypo.vte.occ.pleine_libre.pum_min_hp}
						}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_aide.pum_min_ap) {nret = vTypo.vte.inv.pleine_aide.pum_min_ap}
						} else {
							if (vTypo.vte.inv.pleine_aide.pum_min_hp) {nret = vTypo.vte.inv.pleine_aide.pum_min_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.reduite.pum_min_ap) {nret = vTypo.vte.inv.reduite.pum_min_ap}
						} else {
							if (vTypo.vte.inv.reduite.pum_min_hp) {nret = vTypo.vte.inv.reduite.pum_min_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_libre.pum_min_ap) {nret = vTypo.vte.inv.pleine_libre.pum_min_ap}
						} else {
							if (vTypo.vte.inv.pleine_libre.pum_min_hp) {nret = vTypo.vte.inv.pleine_libre.pum_min_hp}
						}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_aide.pum_min_ap) {nret = vTypo.vte.tout.pleine_aide.pum_min_ap}
						} else {
							if (vTypo.vte.tout.pleine_aide.pum_min_hp) {nret = vTypo.vte.tout.pleine_aide.pum_min_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.reduite.pum_min_ap) {nret = vTypo.vte.tout.reduite.pum_min_ap}
						} else {
							if (vTypo.vte.tout.reduite.pum_min_hp) {nret = vTypo.vte.tout.reduite.pum_min_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_libre.pum_min_ap) {nret = vTypo.vte.tout.pleine_libre.pum_min_ap}
						} else {
							if (vTypo.vte.tout.pleine_libre.pum_min_hp) {nret = vTypo.vte.tout.pleine_libre.pum_min_hp}
						}
						break;
					}
					break;
				}
				break;
				case 13:
				switch (this.inv) {
					case "occ":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_aide.pum_max_ap) {nret = vTypo.vte.occ.pleine_aide.pum_max_ap}
						} else {
							if (vTypo.vte.occ.pleine_aide.pum_max_hp) {nret = vTypo.vte.occ.pleine_aide.pum_max_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.reduite.pum_max_ap) {nret = vTypo.vte.occ.reduite.pum_max_ap}
						} else {
							if (vTypo.vte.occ.reduite.pum_max_hp) {nret = vTypo.vte.occ.reduite.pum_max_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.occ.pleine_libre.pum_max_ap) {nret = vTypo.vte.occ.pleine_libre.pum_max_ap}
						} else {
							if (vTypo.vte.occ.pleine_libre.pum_max_hp) {nret = vTypo.vte.occ.pleine_libre.pum_max_hp}
						}
						break;
					}
					break;
					case "inv":
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_aide.pum_max_ap) {nret = vTypo.vte.inv.pleine_aide.pum_max_ap}
						} else {
							if (vTypo.vte.inv.pleine_aide.pum_max_hp) {nret = vTypo.vte.inv.pleine_aide.pum_max_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.reduite.pum_max_ap) {nret = vTypo.vte.inv.reduite.pum_max_ap}
						} else {
							if (vTypo.vte.inv.reduite.pum_max_hp) {nret = vTypo.vte.inv.reduite.pum_max_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.inv.pleine_libre.pum_max_ap) {nret = vTypo.vte.inv.pleine_libre.pum_max_ap}
						} else {
							if (vTypo.vte.inv.pleine_libre.pum_max_hp) {nret = vTypo.vte.inv.pleine_libre.pum_max_hp}
						}
						break;
					}
					break;
					default:
					switch (this.filter3) {
						case "tva_pleine_aide":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_aide.pum_max_ap) {nret = vTypo.vte.tout.pleine_aide.pum_max_ap}
						} else {
							if (vTypo.vte.tout.pleine_aide.pum_max_hp) {nret = vTypo.vte.tout.pleine_aide.pum_max_hp}
						}
						break;
						case "tva_reduite":
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.reduite.pum_max_ap) {nret = vTypo.vte.tout.reduite.pum_max_ap}
						} else {
							if (vTypo.vte.tout.reduite.pum_max_hp) {nret = vTypo.vte.tout.reduite.pum_max_hp}
						}
						break;
						default:
						if (this.filter5 == 'prix_ap') {
							if (vTypo.vte.tout.pleine_libre.pum_max_ap) {nret = vTypo.vte.tout.pleine_libre.pum_max_ap}
						} else {
							if (vTypo.vte.tout.pleine_libre.pum_max_hp) {nret = vTypo.vte.tout.pleine_libre.pum_max_hp}
						}
						break;
					}
					break;
				}
				break;
				default:
				
			}
			
			switch (NoMesure) {
				// Durée de commercialisation
				case 6:
				if (nret>0) {
					nret = (vTypo.si / nret)
				} else {
					nret = 0
				}
				break;
				// Taux d'écoulement
				case 7:
				if (vTypo.si>0) {
					nret = (nret / vTypo.si)
				} else {
					nret = 0
				}
				break;
				// Ventes par mois
				case 14:
				nret = Math.round(nret * 100) / 100
				break;
				
			}
			
			return nret
		},

		percent: function(str, m) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				if (m) {
					return Math.round(n*100)/100 + ' %'
				} else {
					return Math.round(n) + ' %'
				}
			}
			return ""
		},
		ensurface: function(str) {
			if (str==0) {
				return "-"
			} else {
				return str + " m²"
			}
		},

		eneuro: function(str) {
			new Intl.NumberFormat('fr-FR').format(str)
			
			if (str==0) {
				return "-"
			} else {
				return Intl.NumberFormat('fr-FR').format(str) + " €"
			}
		},

		enmois: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return Math.round(n) + ' mois'
			}
			return ""
		}
	},

	components: { DataTable, Column, ColumnGroup, Row, SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.total
	background var(--primary) !important
	color white !important
	font-weight 500
.program-detail-info
	padding 8px 0
	.program-detail-info-filters
		text-align center
		margin 0 0 8px 0
.right
	text-align right
.center
	text-align center
.left
	text-align left
.prix
	text-align right
	font-weight 800

:deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}


:deep(.p-datatable.synoptique) {
	.p-rowgroup-header{
		background-color: #eeeeee !important;
		color: #333 !important;
		font-size: 1rem;
	}
	.p-rowgroup-header .image-text {
		margin-left: 10px;
	}
    .p-datatable-header {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
    }
	.p-column-title {
		text-align: center;
		font-size: 0.9rem;
	}
	.p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-datatable-tbody {
		font-size: 0.75rem;
	}
    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: center;
		padding: 0.5rem 0.5rem
		font-size: 0.75rem;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
		padding: 0.5rem 0.5rem
    }
}

.product-image {
	text-align: center;
	width: auto;
	max-height : 50px
}

:deep(.row-accessories) {
    background-color: #eef2ff !important;
	color: #4338ca !important;
	font-weight: 700 !important;
}
</style>
