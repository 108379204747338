<template>
	<transition name="pane-adresse">
		<div class="paneleft pan-adresse">
			<pane-adresse-retour accueil="" retour="" cityscan="true" aide="1" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'transport' })">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-12-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('acces_aux_transports') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_transports">{{app.cityscan.adresse.note_transports}}</div>
											<div class="soustitre_module">{{ $t('bus_(5_min),_metro_(10_min),_tram_(5_min)_...') }}</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_transport" class="checkbox" :class="{ checked: adresse_transport }" >
												<input type="checkbox" :checked="adresse_transport" id="adresse_transport" @click="select_adresse('adresse_transport')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'education' })">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-14-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('acces_a_leducation') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_education">{{app.cityscan.adresse.note_education}}</div>
											<div class="soustitre_module">{{ $t('creche_(10_min),_maternelle_(10_min),_...') }}
</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_education" class="checkbox" :class="{ checked: adresse_education }" >
												<input type="checkbox" :checked="adresse_education" id="adresse_education" @click=" select_adresse('adresse_education')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'commerces' })">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-21-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('acces_aux_commerces') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_commerces">{{app.cityscan.adresse.note_commerces}}</div>
											<div class="soustitre_module">{{ $t('proximite_aux_besoins_essentiels_...') }}</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_commerces" class="checkbox" :class="{ checked: adresse_commerces }" >
												<input type="checkbox" :checked="adresse_commerces" id="adresse_commerces" @click=" select_adresse('adresse_commerces')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'vie' })">
											<div class="icone_module"></div>
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-10-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('qualite_de_vie') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_qualite">{{app.cityscan.adresse.note_qualite}}</div>
											<div class="soustitre_module">{{ $t('plutot_calme_(sonore),_assez_peu_pollue_(air)_...') }}</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_vie" class="checkbox" :class="{ checked: adresse_vie }" >
												<input type="checkbox" :checked="adresse_vie" id="adresse_vie" @click=" select_adresse('adresse_vie')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'loisirs' })">
											<div class="icone_module"></div>
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-22-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('acces_aux_loisirs_et_tourisme') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_loisirs">{{app.cityscan.adresse.note_loisirs}}</div>
											<div class="soustitre_module">{{ $t('bibliotheques,_cinemas_...') }}</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_loisirs" class="checkbox" :class="{ checked: adresse_loisirs }" >
												<input type="checkbox" :checked="adresse_loisirs" id="adresse_loisirs" @click=" select_adresse('adresse_loisirs')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'numerique' })">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-15-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('qualite_numerique') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_numerique">{{app.cityscan.adresse.note_numerique}}</div>
											<div class="soustitre_module">{{ $t('batiment_fibre,_borne_4g_...') }}</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_numerique" class="checkbox" :class="{ checked: adresse_numerique }" >
												<input type="checkbox" :checked="adresse_numerique" id="adresse_numerique" @click=" select_adresse('adresse_numerique')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
									<div class="divider"></div>
									<li class="unmodule_detail">
										<div @click="$store.dispatch('set_view', { view: 'services' })">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-23-02 ng-star-inserted" style=""></span></div>
											<div class="titre_module">{{ $t('services_publics_&_sante') }}</div>
											<div class="note" :class="app.cityscan.adresse.note_services">{{app.cityscan.adresse.note_services}}</div>
											<div class="soustitre_module">{{ $t('securite,_sante,_services_...') }}
</div>
										</div>
										<div class="cz" style="top:18px !important;">
											<label for="adresse_services" class="checkbox" :class="{ checked: adresse_services }" >
												<input type="checkbox" :checked="adresse_services"  id="adresse_services" @click=" select_adresse('adresse_services')">
												<span class="chk"><icon file="check" :size="16" /></span>
												<span class="text"></span>
											</label>
										</div>
									</li>
								</ul>
								<div style="background: #f0f0f0;padding: 10px;width: 100%;">
									<div class="s-card">
										<apexchart type="radialBar" height="350" :options="chartOptions" :series="series" id="score_adresse"></apexchart>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresse',
	data: function() {
		return {
			adresse_services: true,
			adresse_numerique: true,
			adresse_loisirs: true,
			adresse_vie: true,
			adresse_commerces: true,
			adresse_education: true,
			adresse_transport: true,
			detail: '',
			series: [0, 0, 0, 0, 0, 0, 0],
			chartOptions: {
				chart: {
					height: 390,
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						offsetY: 0,
						startAngle: 0,
						endAngle: 270,
						hollow: {
							margin: 5,
							size: '30%',
							background: 'transparent',
							image: undefined,
						},
						dataLabels: {
							name: {
								show: false,
							},
							value: {
								show: false,
							}
						}
					}
				},
				colors: [ '#d10059', '#aa00d1', '#8100e5', '#5150f8', '#0082fa', '#00b0ee', '#00eeee'],
				labels: ['Transport', 'Education', 'Commerce', 'qualite_de_vie', 'Loisirs', 'Numérique', 'Services'],
				legend: {
					show: true,
					floating: true,
					fontSize: '12px',
					position: 'left',
					offsetX: 10,
					offsetY: -20,
					labels: {
						useSeriesColors: true,
					},
					markers: {
						size: 0
					},
					formatter: function(seriesName) {
						return seriesName
					},
					itemMargin: {
						vertical: 3
					}
				},
				responsive: [{
					breakpoint: 480,
					options: {
						legend: {
							show: false
						}
					}
				}]
			},
			radar : {
				series: [
				{
					name: 'Score',
					data: [0, 0, 0, 0, 0, 0, 0],
				},
				],
				chart: {
					height: 350,
					type: 'radar',
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true
				},
				plotOptions: {
					radar: {
						size: 140,
						polygons: {
							strokeColors: '#e9e9e9',
							fill: {
								colors: ['#f8f8f8', '#fff']
							}
						}
					}
				},
				markers: {
					size: 4,
					colors: ['#fff'],
					strokeColor: '#aa00d1',
					strokeWidth: 2,
				},
				colors: ['#aa00d1'],
				title: {
					text: undefined,
				},
				xaxis: {
					categories: ['Transport', 'Education', 'Commerce', 'Qualité de vie', 'Loisirs', 'Numérique', 'Services'],
				},
				yaxis: {
					tickAmount: 7,
					labels: {
						formatter: function(val, i) {
							if (i % 2 === 0) {
								return val
							} else {
								return ''
							}
						}
					}
				}
			}
		}
	},
	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),
	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_services = (this.app.preferences.adresse_niv1.indexOf('adresse_services')!==-1 || this.app.preferences.adresse_niv1.indexOf('services')!==-1)
		//	this.adresse_numerique = (this.app.preferences.adresse_niv1.indexOf('adresse_numerique')!==-1 || this.app.preferences.adresse_niv1.indexOf('numerique')!==-1)
		//	this.adresse_loisirs = (this.app.preferences.adresse_niv1.indexOf('adresse_loisirs')!==-1 || this.app.preferences.adresse_niv1.indexOf('loisirs')!==-1)
		//	this.adresse_vie = (this.app.preferences.adresse_niv1.indexOf('adresse_vie')!==-1 || this.app.preferences.adresse_niv1.indexOf('vie')!==-1)
		//	this.adresse_commerces = (this.app.preferences.adresse_niv1.indexOf('adresse_commerces')!==-1 || this.app.preferences.adresse_niv1.indexOf('commerces')!==-1)
		//	this.adresse_education = (this.app.preferences.adresse_niv1.indexOf('adresse_education')!==-1 || this.app.preferences.adresse_niv1.indexOf('education')!==-1)
		//	this.adresse_transport = (this.app.preferences.adresse_niv1.indexOf('adresse_transport')!==-1 || this.app.preferences.adresse_niv1.indexOf('transports')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			let t = []
			if (e=='adresse_transport') {
				t= ['adresse_transport_bus', 'adresse_transport_metro', 'adresse_transport_tram', 'adresse_transport_route_rapide', 'adresse_transport_parking', 'adresse_transport_gare', 'adresse_transport_aeroport',
					'adresse_transport_port', 'adresse_transport_velo', 'adresse_transport_auto', 'adresse_transport_borne'];
			}
			if (e=='adresse_education') {
				t= ['adresse_ecole_creche', 'adresse_ecole_maternelle', 'adresse_ecole_elementaire', 'adresse_ecole_college', 'adresse_ecole_lycee', 'adresse_ecole_lycee_pro', 'adresse_ecole_universite',
					'adresse_ecole_superieur', 'adresse_ecole_formation'];
			}
			if (e=='adresse_commerces') {
				t= ['adresse_commerce_alimentation', 'adresse_commerce_bouche', 'adresse_commerce_utilitaire', 'adresse_commerce_shopping', 'adresse_commerce_soin', 'adresse_commerce_logement', 'adresse_commerce_vehicule'];
			}
			if (e=='adresse_vie') {
				t= ['adresse_sonore', 'adresse_olfactive', 'adresse_saturation_population', 'adresse_saturation_vehicule', 'adresse_air', 'adresse_electromagnetique', 'adresse_radon'];
			}
			if (e=='adresse_loisirs') {
				t= ['adresse_loisir_bibliotheque', 'adresse_loisir_theatre', 'adresse_loisir_musee', 'adresse_loisir_cinema', 'adresse_loisir_bar', 'adresse_loisir_restaurant', 'adresse_loisir_discotheque',
					'adresse_loisir_jardin', 'adresse_loisir_sport_collectif', 'adresse_loisir_sport_individuel', 'adresse_loisir_hotel', 'adresse_loisir_hebergement'];
			}
			if (e=='adresse_numerique') {
				t= ['adresse_haut_debit', 'adresse_fibre', 'adresse_mobile_4G', 'adresse_mobile_3G'];
			}
			if (e=='adresse_services') {
				t= ['adresse_service_commissariat', 'adresse_service_gendarmerie', 'adresse_service_pompier', 'adresse_service_hopital', 'adresse_service_laboratoire', 'adresse_service_agees', 'adresse_service_pharmacie',
					'adresse_service_medecin', 'adresse_service_specialiste', 'adresse_service_poste', 'adresse_service_recyclage', 'adresse_service_dechet'];
			}
			if (this[e]) {
				for (let index = 0; index < t.length; index++) {
					const element = document.getElementById(t[index]);
					element.checked = true;
					element.parentNode.className = 'checkbox checked';
				}
			}
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv1', {niv1: e, niv2: t, select: this[e]})
		},
		getscore: function(score,r) {
			let score_name= 'Nutri-score-A'
			let letter='A'
			
			if (r=='letter') {
				return letter
			} else {
				return score_name
			}
		},
		
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		},
		
		maj_graph: function() {
			let t = []
			
			t.push(this.app.cityscan.adresse.score_transports)
			t.push(this.app.cityscan.adresse.score_education)
			t.push(this.app.cityscan.adresse.score_commerces)
			t.push(this.app.cityscan.adresse.score_qualite)
			t.push(this.app.cityscan.adresse.score_loisirs)
			t.push(this.app.cityscan.adresse.score_numerique)
			t.push(this.app.cityscan.adresse.score_services)
			this.radar.series = [
			{
				name: "Score",
				data: t
			},
			]
			
			let u = []
			u.push(this.app.cityscan.adresse.score_transports)
			u.push(this.app.cityscan.adresse.score_education)
			u.push(this.app.cityscan.adresse.score_commerces)
			u.push(this.app.cityscan.adresse.score_qualite)
			u.push(this.app.cityscan.adresse.score_loisirs)
			u.push(this.app.cityscan.adresse.score_numerique)
			u.push(this.app.cityscan.adresse.score_services)
			
			this.series = u
		},
	},
	watch: {
		'app.view': function(newValue) {
			if (newValue=='adresse') {
				this.maj_graph()
			}
		},
	},
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-enter
	transform translateX(100%)

.pane-adresse-leave-to
	transform translateX(-100%)

.pan-adresse
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.unmodule
	height 70px

.s-card {
	flex: 1;
	display: inline-block;
	width: 330px;
	padding-top: 20px;
	background-color: var(--white);
	border-radius: var(--radius-large);
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
}

.programs-list
	height 100%


.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.panecontent
	top 0px !important
	overflow hidden
	height 100% !important

.programs-list-content{
	background-color: white !important;
}

.apexcharts-legend-text {
	font-size: 12px !important;
}

.checkbox {
	width: 20px !important;
	height: 20px !important;
}

</style>
