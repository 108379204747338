<template>
	<transition name="pane-adresse-loisir-musee">
		<div class="paneleft pan-adresse-loisir-musee">
			<pane-adresse-retour accueil="" retour="loisirs" cityscan="true" v-show="false"/>
			<pane-adresse-element 
				:propObj="this.app.cityscan.adresse.musees" 
				:sstyle="'color: rgb(0, 143, 179)'" 
				:sclass="'cs-tile-icon cs-icon-thematic icon-1352-02 ng-star-inserted'" 
				:titre="'Musées'"
				:detail="'loisir'"
				:retour="'loisirs'"
			/>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'
import PaneAdresseElement from './PaneAdresseElement.vue'

export default {
	name: 'PaneAdresseLoisirMusee',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},
	
	components: { PaneAdresseRetour, PaneAdresseElement }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-loisir-musee-enter
	transform translateX(100%)
.pane-adresse-loisir-musee-leave-to
	transform translateX(-100%)

.pan-adresse-loisir-musee
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
