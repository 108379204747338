<template>
	<div class="tab-report" id='report-secteurs'>
		<div class="tab-content">
			<Fieldset legend="Statistiques secteurs" :toggleable="true" class="surface-ground">
				<div class="part" id="tabsecteurnom">
					<div class="part-content">
						<stat-box name="array-secteurs" />
					</div>
				</div>
				<div class="part" id="tabsecteuretat" ref="tabsecteuretat" v-observe-visibility="onVisibilityChange_tabsecteuretat">
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-historique" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-evolution-offre" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part" id="tabsecteurinv" ref="tabsecteurinv" v-observe-visibility="onVisibilityChange_tabsecteurinv">
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-destination-ventes" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-destination-ventes" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part" id="tabsecteurqpv" ref="tabsecteurqpv" v-observe-visibility="onVisibilityChange_tabsecteurqpv">
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-dispositif-qpv" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-dispositif-qpv" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part" id="tabsecteuroffre" ref="tabsecteuroffre" v-observe-visibility="onVisibilityChange_tabsecteuroffre">
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-offre" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-offre" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part" id="tabsecteurprix" ref="tabsecteurprix" v-observe-visibility="onVisibilityChange_tabsecteurprix">
					<div class="part-title">{{ $t('y_a_til_une_tension_particuliere_sur_les_prix_de_vente') }}*</div>
					<div class="part-aste">* {{ $t('seuls_les_logements_qualifies_libres_sont_pris_en_compte') }}</div>
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-rythme" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-evolution-rythme" />
								</div>
							</div>
						</div>
					</div>
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-prix-parkinginclus" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-evolution-prix-pi" />
								</div>
							</div>
						</div>
					</div>
					<div class="surface-ground">
						<div class="grid">
							<div class="col-12 lg:col-8 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="array-prix-parkingsans" />
								</div>
							</div>
							<div class="col-12 lg:col-4 p-3">
								<div class="p-3 border-round shadow-2 align-items-center surface-card">
									<stat-box name="graph-evolution-prix-hp" />
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatBox from './report/StatBox'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';

export default {
	name: 'TabSecteur',

	data: function() {
		return {
			activeSection2 : ''
			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		windowHeight() {
			return window.innerHeight;
		}
	}),

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		scrollToElement2(elementId) {
			const element2 = document.getElementById(elementId);
			element2.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
		},

		onVisibilityChange_tabsecteuretat(isVisible) {
			if (isVisible) {
				this.activeSection2 = 'tabsecteuretat'
			} else {
				if (this.activeSection2 == 'tabsecteuretat') {
					this.activeSection2 = ''
				}
			}
		},

		onVisibilityChange_tabsecteurinv(isVisible) {
			if (isVisible) {
				this.activeSection2 = 'tabsecteurinv'
			} else {
				if (this.activeSection2 == 'tabsecteurinv') {
					this.activeSection2 = ''
				}
			}
		},

		onVisibilityChange_tabsecteurqpv(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'tabsecteurqpv'
			} else {
				if (this.activeSection2 == 'tabsecteurqpv') {
					this.activeSection2 = ''
				}
			}
		},

		onVisibilityChange_tabsecteuroffre(isVisible) {
			if (isVisible) {
				this.activeSection2 = 'tabsecteuroffre'
			} else {
				if (this.activeSection2 == 'tabsecteuroffre') {
					this.activeSection2 = ''
				}
			}
		},

		onVisibilityChange_tabsecteurprix(isVisible) {
			if (isVisible) {
				this.activeSection2 = 'tabsecteurprix'
			} else {
				if (this.activeSection2 == 'tabsecteurprix') {
					this.activeSection2 = ''
				}
			}
		},
	},
	components: { StatBox, Fieldset }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
		.part-aste
			font-size 12px
			padding-bottom 5px
		
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0
	
</style>
