<template>
	<div>
		<div class="table">
			<table>
				<tbody>
					<tr>
						<th>{{ $t('ventes_en_t.v.a._pleine') }}</th>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="tabletwotitles">
			<table>
				<tbody>
					<tr>
						<th id="tvap_11" rowspan=2 class="tailledouze"></th>
						<th id="tvap_12" rowspan=2 class="tailledouze">Ventes</th>
						<th id="tvap_13" rowspan=2 class="tailledouze">{{ $t('surface') }}</th>
						<th id="tvap_14" colspan=2 class="tailleseize">{{ $t('pum_libre') }}</th>
						<th id="tvap_15" colspan=2 class="tailleseize">{{ $t('pum_aide') }}</th>
					</tr>
					<tr>
						<th id="tvap_21">{{ $t('h.p.') }}</th>
						<th id="tvap_22">{{ $t('p.i.') }}</th>
						<th id="tvap_23">{{ $t('h.p.') }}</th>
						<th id="tvap_24">{{ $t('p.i.') }}</th>
					</tr>
					<tr>
						<th id="tvap_31">{{ $t('t1') }}</th>
						<td>{{percent(ventes_T1 / ventes_totales * 100)}}</td>							
						<td>{{m2(surface_T1 / ventes_T1)}}</td>
						<td>{{eurodiv(prix_libre_hp_T1, ventes_libre_hp_T1)}}</td>
						<td>{{eurodiv(prix_libre_ap_T1, ventes_libre_ap_T1)}}</td>
						<td>{{eurodiv(prix_aide_hp_T1, ventes_aide_hp_T1)}}</td>
						<td>{{eurodiv(prix_aide_ap_T1, ventes_aide_ap_T1)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_41">{{ $t('t1') }} {{ $t('occ.') }}</th>
						<td>{{ventes_occ_T1}}</td>							
						<td>{{m2(surface_occ_T1 / ventes_occ_T1)}}</td>
						<td>{{eurodiv(prix_libre_occ_hp_T1, ventes_libre_occ_hp_T1)}}</td>
						<td>{{eurodiv(prix_libre_occ_ap_T1, ventes_libre_occ_ap_T1)}}</td>
						<td>{{eurodiv(prix_aide_occ_hp_T1, ventes_aide_occ_hp_T1)}}</td>
						<td>{{eurodiv(prix_aide_occ_ap_T1, ventes_aide_occ_ap_T1)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_51">{{ $t('t1') }} {{ $t('inv.') }}</th>
						<td>{{ventes_inv_T1}}</td>							
						<td>{{m2(surface_inv_T1 / ventes_inv_T1)}}</td>
						<td>{{eurodiv(prix_libre_inv_hp_T1, ventes_libre_inv_hp_T1)}}</td>
						<td>{{eurodiv(prix_libre_inv_ap_T1, ventes_libre_inv_ap_T1)}}</td>
						<td>{{eurodiv(prix_aide_inv_hp_T1, ventes_aide_inv_hp_T1)}}</td>
						<td>{{eurodiv(prix_aide_inv_ap_T1, ventes_aide_inv_ap_T1)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_61">{{ $t('t2') }}</th>
						<td>{{percent(ventes_T2 / ventes_totales * 100)}}</td>							
						<td>{{m2(surface_T2 / ventes_T2)}}</td>
						<td>{{eurodiv(prix_libre_hp_T2, ventes_libre_hp_T2)}}</td>
						<td>{{eurodiv(prix_libre_ap_T2, ventes_libre_ap_T2)}}</td>
						<td>{{eurodiv(prix_aide_hp_T2, ventes_aide_hp_T2)}}</td>
						<td>{{eurodiv(prix_aide_ap_T2, ventes_aide_ap_T2)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_71">{{ $t('t2') }} {{ $t('occ.') }}</th>
						<td>{{ventes_occ_T2}}</td>							
						<td>{{m2(surface_occ_T2 / ventes_occ_T2)}}</td>
						<td>{{eurodiv(prix_libre_occ_hp_T2, ventes_libre_occ_hp_T2)}}</td>
						<td>{{eurodiv(prix_libre_occ_ap_T2, ventes_libre_occ_ap_T2)}}</td>
						<td>{{eurodiv(prix_aide_occ_hp_T2, ventes_aide_occ_hp_T2)}}</td>
						<td>{{eurodiv(prix_aide_occ_ap_T2, ventes_aide_occ_ap_T2)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_81">{{ $t('t2') }} {{ $t('inv.') }}</th>
						<td>{{ventes_inv_T2}}</td>							
						<td>{{m2(surface_inv_T2 / ventes_inv_T2)}}</td>
						<td>{{eurodiv(prix_libre_inv_hp_T2, ventes_libre_inv_hp_T2)}}</td>
						<td>{{eurodiv(prix_libre_inv_ap_T2, ventes_libre_inv_ap_T2)}}</td>
						<td>{{eurodiv(prix_aide_inv_hp_T2, ventes_aide_inv_hp_T2)}}</td>
						<td>{{eurodiv(prix_aide_inv_ap_T2, ventes_aide_inv_ap_T2)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_91">{{ $t('t3') }}</th>
						<td>{{percent(ventes_T3 / ventes_totales * 100)}}</td>							
						<td>{{m2(surface_T3 / ventes_T3)}}</td>
						<td>{{eurodiv(prix_libre_hp_T3, ventes_libre_hp_T3)}}</td>
						<td>{{eurodiv(prix_libre_ap_T3, ventes_libre_ap_T3)}}</td>
						<td>{{eurodiv(prix_aide_hp_T3, ventes_aide_hp_T3)}}</td>
						<td>{{eurodiv(prix_aide_ap_T3, ventes_aide_ap_T3)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_101">{{ $t('t3') }} {{ $t('occ.') }}</th>
						<td>{{ventes_occ_T3}}</td>							
						<td>{{m2(surface_occ_T3 / ventes_occ_T3)}}</td>
						<td>{{eurodiv(prix_libre_occ_hp_T3, ventes_libre_occ_hp_T3)}}</td>
						<td>{{eurodiv(prix_libre_occ_ap_T3, ventes_libre_occ_ap_T3)}}</td>
						<td>{{eurodiv(prix_aide_occ_hp_T3, ventes_aide_occ_hp_T3)}}</td>
						<td>{{eurodiv(prix_aide_occ_ap_T3, ventes_aide_occ_ap_T3)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_111">{{ $t('t3') }} {{ $t('inv.') }}</th>
						<td>{{ventes_inv_T3}}</td>							
						<td>{{m2(surface_inv_T3 / ventes_inv_T3)}}</td>
						<td>{{eurodiv(prix_libre_inv_hp_T3, ventes_libre_inv_hp_T3)}}</td>
						<td>{{eurodiv(prix_libre_inv_ap_T3, ventes_libre_inv_ap_T3)}}</td>
						<td>{{eurodiv(prix_aide_inv_hp_T3, ventes_aide_inv_hp_T3)}}</td>
						<td>{{eurodiv(prix_aide_inv_ap_T3, ventes_aide_inv_ap_T3)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_131">{{ $t('t4') }}</th>
						<td>{{percent(ventes_T4 / ventes_totales * 100)}}</td>							
						<td>{{m2(surface_T4 / ventes_T4)}}</td>
						<td>{{eurodiv(prix_libre_hp_T4, ventes_libre_hp_T4)}}</td>
						<td>{{eurodiv(prix_libre_ap_T4, ventes_libre_ap_T4)}}</td>
						<td>{{eurodiv(prix_aide_hp_T4, ventes_aide_hp_T4)}}</td>
						<td>{{eurodiv(prix_aide_ap_T4, ventes_aide_ap_T4)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_131">{{ $t('t4') }} {{ $t('occ.') }}</th>
						<td>{{ventes_occ_T4}}</td>							
						<td>{{m2(surface_occ_T4 / ventes_occ_T4)}}</td>
						<td>{{eurodiv(prix_libre_occ_hp_T4, ventes_libre_occ_hp_T4)}}</td>
						<td>{{eurodiv(prix_libre_occ_ap_T4, ventes_libre_occ_ap_T4)}}</td>
						<td>{{eurodiv(prix_aide_occ_hp_T4, ventes_aide_occ_hp_T4)}}</td>
						<td>{{eurodiv(prix_aide_occ_ap_T4, ventes_aide_occ_ap_T4)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_141">{{ $t('t4') }} {{ $t('inv.') }}</th>
						<td>{{ventes_inv_T4}}</td>							
						<td>{{m2(surface_inv_T4 / ventes_inv_T4)}}</td>
						<td>{{eurodiv(prix_libre_inv_hp_T4, ventes_libre_inv_hp_T4)}}</td>
						<td>{{eurodiv(prix_libre_inv_ap_T4, ventes_libre_inv_ap_T4)}}</td>
						<td>{{eurodiv(prix_aide_inv_hp_T4, ventes_aide_inv_hp_T4)}}</td>
						<td>{{eurodiv(prix_aide_inv_ap_T4, ventes_aide_inv_ap_T4)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_151">{{ $t('t5') }}</th>
						<td>{{percent(ventes_T5 / ventes_totales * 100)}}</td>							
						<td>{{m2(surface_T5 / ventes_T5)}}</td>
						<td>{{eurodiv(prix_libre_hp_T5, ventes_libre_hp_T5)}}</td>
						<td>{{eurodiv(prix_libre_ap_T5, ventes_libre_ap_T5)}}</td>
						<td>{{eurodiv(prix_aide_hp_T5, ventes_aide_hp_T5)}}</td>
						<td>{{eurodiv(prix_aide_ap_T5, ventes_aide_ap_T5)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_161">{{ $t('t5') }} {{ $t('occ.') }}</th>
						<td>{{ventes_occ_T5}}</td>							
						<td>{{m2(surface_occ_T5 / ventes_occ_T5)}}</td>
						<td>{{eurodiv(prix_libre_occ_hp_T5, ventes_libre_occ_hp_T5)}}</td>
						<td>{{eurodiv(prix_libre_occ_ap_T5, ventes_libre_occ_ap_T5)}}</td>
						<td>{{eurodiv(prix_aide_occ_hp_T5, ventes_aide_occ_hp_T5)}}</td>
						<td>{{eurodiv(prix_aide_occ_ap_T5, ventes_aide_occ_ap_T5)}}</td>
					</tr>
					
					<tr>
						<th id="tvap_171">{{ $t('t5') }} {{ $t('inv.') }}</th>
						<td>{{ventes_inv_T5}}</td>							
						<td>{{m2(surface_inv_T5 / ventes_inv_T5)}}</td>
						<td>{{eurodiv(prix_libre_inv_hp_T5, ventes_libre_inv_hp_T5)}}</td>
						<td>{{eurodiv(prix_libre_inv_ap_T5, ventes_libre_inv_ap_T5)}}</td>
						<td>{{eurodiv(prix_aide_inv_hp_T5, ventes_aide_inv_hp_T5)}}</td>
						<td>{{eurodiv(prix_aide_inv_ap_T5, ventes_aide_inv_ap_T5)}}</td>
					</tr>
				</tbody>
			</table>
			<br>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ArrayProgrammesTvap',

	data: function() {
		return {
			tab: 'mois',
			TypePrix : 10
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		offre_total: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {
						nret += typo.volume.offre
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		stock_ini_total: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {
						nret += typo.volume.stock
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		ventes_totales: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {						
						if (typo.vente) {
							nret += typo.vente.tva_pleine.tout.nb
						}
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		surface_totales: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {						
						if (typo.vente) {
							nret += typo.vente.tva_pleine.tout.sigma_surface
						}
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		surface_inv_totales: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {						
						if (typo.vente) {
							nret += typo.vente.tva_pleine.investisseur.sigma_surface
						}
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		surface_occ_totales: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				for (let j=1; j<6; j++) {
					if (j==1) {
						typo = prog.t1
					} else if (j==2) {
						typo = prog.t2
					} else if (j==3) {
						typo = prog.t3
					} else if (j==4) {
						typo = prog.t4
					} else {
						typo = prog.t5
					}
					if (typo) {						
						if (typo.vente) {
							nret += typo.vente.tva_pleine.occupant.sigma_surface
						}
					}
				}
			}
			if (nret == 0) {nret = 1}
			return nret
		},

		surface_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_surface
					}
				}
			}
			return nret
		},

		surface_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_surface
					}
				}
			}
			return nret
		},

		surface_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_surface
					}
				}
			}
			return nret
		},

		surface_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_surface
					}
				}
			}
			return nret
		},

		surface_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_surface
					}
				}
			}
			return nret
		},

		surface_occ_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_surface
					}
				}
			}
			return nret
		},

		surface_occ_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_surface
					}
				}
			}
			return nret
		},

		surface_occ_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_surface
					}
				}
			}
			return nret
		},

		surface_occ_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_surface
					}
				}
			}
			return nret
		},

		surface_occ_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_surface
					}
				}
			}
			return nret
		},

		surface_inv_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_surface
					}
				}
			}
			return nret
		},

		surface_inv_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_surface
					}
				}
			}
			return nret
		},

		surface_inv_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_surface
					}
				}
			}
			return nret
		},

		surface_inv_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_surface
					}
				}
			}
			return nret
		},

		surface_inv_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_surface
					}
				}
			}
			return nret
		},

		ventes_occ_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb
					}
				}
			}
			return nret
		},

		ventes_occ_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb
					}
				}
			}
			return nret
		},

		ventes_occ_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb
					}
				}
			}
			return nret
		},

		ventes_occ_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb
					}
				}
			}
			return nret
		},

		ventes_occ_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb
					}
				}
			}
			return nret
		},

		ventes_inv_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb
					}
				}
			}
			return nret
		},

		ventes_inv_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb
					}
				}
			}
			return nret
		},

		ventes_inv_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb
					}
				}
			}
			return nret
		},

		ventes_inv_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb
					}
				}
			}
			return nret
		},

		ventes_inv_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb
					}
				}
			}
			return nret
		},

		ventes_libre_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},
		
		ventes_libre_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_occ_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_occ_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_occ_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_occ_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_inv_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_inv_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_inv_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_inv_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_occ_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_occ_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_occ_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_occ_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_inv_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_inv_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_inv_hp_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_inv_ap_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_occ_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_occ_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_occ_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_occ_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_inv_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_inv_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_inv_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_inv_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},
		
		prix_libre_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_occ_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_occ_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_occ_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_occ_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_inv_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_inv_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_inv_hp_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_inv_ap_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},
		
		ventes_libre_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_occ_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_occ_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_occ_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_occ_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_inv_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_inv_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_inv_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_inv_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_occ_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_occ_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_occ_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_occ_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_inv_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_inv_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_inv_hp_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_inv_ap_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_occ_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_occ_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_occ_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_occ_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_inv_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_inv_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_inv_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_inv_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},
		
		prix_libre_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_occ_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_occ_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_occ_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_occ_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_inv_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_inv_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_inv_hp_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_inv_ap_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_occ_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_occ_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_occ_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_occ_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_libre_inv_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_libre_inv_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_libre_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_aide_inv_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_sans
					}
				}
			}
			return nret
		},

		ventes_aide_inv_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.nb_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_occ_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_occ_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_occ_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_occ_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.occupant.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		prix_libre_inv_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_sans
					}
				}
			}
			return nret
		},

		prix_libre_inv_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_libre_pakging_avec
					}
				}
			}
			return nret
		},

		prix_aide_inv_hp_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_sans
					}
				}
			}
			return nret
		},

		prix_aide_inv_ap_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.investisseur.sigma_prix_aide_pakging_avec
					}
				}
			}
			return nret
		},

		ventes_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb
					}
				}
			}
			return nret
		},

		ventes_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb
					}
				}
			}
			return nret
		},

		ventes_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb
					}
				}
			}
			return nret
		},

		ventes_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb
					}
				}
			}
			return nret
		},

		ventes_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					if (typo.vente) {
						nret += typo.vente.tva_pleine.tout.nb
					}
				}
			}
			return nret
		},

		stock_ini_T1: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t1
				if (typo) {
					nret += typo.volume.stock
				}
			}
			return nret
		},
		stock_ini_T2: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t2
				if (typo) {
					nret += typo.volume.stock
				}
			}
			return nret
		},

		stock_ini_T3: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t3
				if (typo) {
					nret += typo.volume.stock
				}
			}
			return nret
		},

		stock_ini_T4: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t4
				if (typo) {
					nret += typo.volume.stock
				}
			}
			return nret
		},

		stock_ini_T5: function() {
			let nret = 0
			let prog
			let typo
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				typo = prog.t5
				if (typo) {
					nret += typo.volume.stock
				}
			}
			return nret
		},
		
	}),

	methods: {
		GetValueProgrammeTypo: function(NoMesure, Typo) {
			let nret = 0
			let prog
			let vTypo
			let stock = 0
			for (let i in this.app.selected_programs_syno) {				
				prog = this.app.selected_programs_syno[i]
				switch (Typo) {
					case 1:
					vTypo = prog.t1
					break;
					case 2:
					vTypo = prog.t2
					break;
					case 3:
					vTypo = prog.t3
					break;
					case 4:
					vTypo = prog.t4
					break;
					case 5:
					vTypo = prog.t5
					break;
					default:
					
				}
				
				if (vTypo) {
					switch (NoMesure) {
						// Offre Totale
						case 1:
						if (vTypo.volume.offre) {
							nret += vTypo.volume.offre
						}
						break;
						// Vente Totale
						case 2:
						if (vTypo.volume.vente) {
							nret += vTypo.volume.vente
						}
						break;
						// Vente investisseur Totale
						case 3:
						if (vTypo.vente) {
							nret += vTypo.vente.tva_pleine.investisseur.nb
							nret += vTypo.vente.tva_reduite.investisseur.nb
						}
						break;
						// Vente occupant Totale
						case 4:
						if (vTypo.vente) {
							nret += vTypo.vente.tva_pleine.occupant.nb
							nret += vTypo.vente.tva_reduite.occupant.nb
						}
						break;
						// Vente totale tva pleine
						case 5:
						if (vTypo.vente) {
							nret += typo.vente.tva_pleine.tout.nb
						}
						break;							
						// Vente totale tva réduite
						case 6:
						if (vTypo.vente) {
							nret += typo.vente.tva_reduite.tout.nb
						}
						break;							
						// sigma surface tva pleine
						case 7:
						if (vTypo.vente) {
							nret += typo.vente.tva_pleine.tout.sigma_surface
						}
						break;							
						// sigma surface tva réduite
						case 8:
						if (vTypo.vente) {
							nret += typo.vente.tva_reduite.tout.sigma_surface
						}
						break;							
						// sigma surface tva pleine investisseur
						case 9:
						if (vTypo.vente) {
							nret += typo.vente.tva_pleine.investisseur.sigma_surface
						}
						break;							
						// sigma surface tva réduite investisseur
						case 10:
						if (vTypo.vente) {
							nret += typo.vente.tva_reduite.investisseur.sigma_surface
						}
						break;							
						// sigma surface tva pleine occupant
						case 9: /* eslint-disable-line */
						if (vTypo.vente) {
							nret += typo.vente.tva_pleine.occupant.sigma_surface
						}
						break;							
						// sigma surface tva réduite occupant
						case 10: /* eslint-disable-line */
						if (vTypo.vente) {
							nret += typo.vente.tva_reduite.occupant.sigma_surface
						}
						break;							
						// Stock Initial Totale
						case 5: /* eslint-disable-line */
						if (vTypo.volume.stock) {
							nret += vTypo.volume.stock
						}
						break;
						// Durée moyenne de commercialisation
						case 6: /* eslint-disable-line */
						if (vTypo.volume.stock) {
							stock += vTypo.volume.stock
							if (vTypo.vente) {
								nret += vTypo.vente.vteparmois
							}
						}
						break;
						// Taux d'écoulement							
						case 7: /* eslint-disable-line */
						if (vTypo.volume.stock) {
							stock += vTypo.volume.stock
							if (vTypo.vente) {
								nret += vTypo.vente.vteparmois
							}
						}
						break;
						default:
						
					}
				}
			}
			
			// Durée de commercialisation
			if (NoMesure==6) {
				if (nret>0) {
					nret = (stock / nret)
				} else {
					nret = 0
				}
			} else {
				// Taux d'écoulement
				if (NoMesure==7) {
					if (stock>0) {
						nret = (nret / stock)
					} else {
						nret = 0
					}
				}
			}
			
			return nret
		},
		
		percent: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return Math.round(n) + ' %'
			}
			return ""
		},

		m2: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return Math.round(n) + ' m²'
			}
			return ""
		},

		euro: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return Math.round(n).toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
			return ""
		},
		
		eurodiv: function(n1, n2) {
			if (n2) {
				return Math.round(n1/n2).toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
			return ""
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.tailleseize
	width 32%

.tailledouze
	width 12%


</style>
