<template>
	<transition name="slide">
		<div class="pane pane-preferences-promotion" :class="{vi : app.preferences_promotion, left: !app.menuvisible}">
			<div class="right-bar">
				<div class="box right-bar-content" v-bar>
					<div class="content" ref="scrollable_aide" style="padding-left:10px;padding-right:20px">
						<div v-if="app.onglet=='onglet_prog'">
							<br/>
							<p class="box-title2">Préférences d'affichage des prix</p>
							<br/>
							<br/>
							<p class="box-title2">{{ $t('etat') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_etat', 'tout')" class="filtre" :class="{ active: app.preferences.neuf.listing.etat == 'tout' }">{{ $t('Tout')}}</div>
									<div @click="toggle_switch('affichage_prix_etat', 'vente')" class="filtre" :class="{ active: app.preferences.neuf.listing.etat == 'vente' }">{{ $t('des ventes') }}</div>									
									<div @click="toggle_switch('affichage_prix_etat', 'offre')" class="filtre" :class="{ active: app.preferences.neuf.listing.etat == 'offre' }">{{ $t('de l\'offre') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('Financement') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_tva', 'reduite')" class="filtre" :class="{ active: app.preferences.neuf.listing.tva == 'reduite' }">TVA réduite</div>
									<div @click="toggle_switch('affichage_prix_tva', 'aide')" class="filtre" :class="{ active: app.preferences.neuf.listing.tva == 'aide' }">aidé</div>									
									<div @click="toggle_switch('affichage_prix_tva', 'pleine')" class="filtre" :class="{ active: app.preferences.neuf.listing.tva == 'pleine' }">libre</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('parking') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_pk', 'inclus')" class="filtre" :class="{ active: app.preferences.neuf.listing.parking == 'inclus' }">parking inclus</div>
									<div @click="toggle_switch('affichage_prix_pk', 'sans')" class="filtre" :class="{ active: app.preferences.neuf.listing.parking == 'sans' }">hors parking</div>									
								</div>
							</div>
							<br/>
						</div>
						<div v-if="app.onglet=='onglet_pc'">
							
							<br><br>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PanePreferencesPromotion',

	data: function() {
		return {
			affichage_prix_etat : 'tout',
			affichage_prix_tva : 'pleine',
			affichage_prix_pk : 'inclus',
		}
	},

	watch: {

	},

	mounted: function() {
		this.affichage_prix_etat = this.app.preferences.neuf.listing.etat
		this.affichage_prix_tva = this.app.preferences.neuf.listing.tva
		this.affichage_prix_pk = this.app.preferences.neuf.listing.parking
	},

	computed: Object.assign(mapGetters([ 'app', 'ventilationprix' ]), {
		
	}),

	methods: {
		toggle_switch: function(filter, value) {
			switch (filter) {
				case 'affichage_prix_etat':
					this.affichage_prix_etat = value
					break;
				case 'affichage_prix_tva':
					this.affichage_prix_tva = value
					break;
				case 'affichage_prix_pk':
					this.affichage_prix_pk = value
					break;
				default:
				break;
			}
			this.$store.dispatch('set_filters_preferences', { affichage_prix_etat: this.affichage_prix_etat, affichage_prix_tva: this.affichage_prix_tva, affichage_prix_pk: this.affichage_prix_pk })
		},

	},
	
	components: { }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px

.right-bar
	padding-left 10px
	height 100%
	background #FcFcFc
	width 100%
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%

	.scroll-dots
		position absolute
		left -40px
		top 24px
		width 32px
		background alpha(green, 50%)
		border-radius radius
		z-index 2
		transition 0.25s easeOutQuart
		&:hover
			background alpha(green, 75%)
		.dot
			display block
			width 8px
			height 8px
			margin 12px
			background #fff
			opacity 0.25
			border-radius 100%
			transition 0.25s easeOutQuart
			cursor pointer
			&:hover, &.active
				opacity 1

.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
	.middle
		top 20px

.pane-preferences-promotion
	font-family 'Montserrat', sans-serif
	position fixed
	top headersize
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 122px)
	width 0px
	background-color #333
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	transition 0.5s easeOutQuart
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		border-right solid 1px #ccc
		width 350px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

.label
	display block
	margin 0 0 2px 0
	color #666
	text-transform uppercase
	font-size 11px
	font-weight 500
.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px
	.box-content
		display block
		padding 0 5px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both


.infinite-loader
	position relative !important
	right 0 !important
	top 0 !important
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #536c78
		.icon
			display block
			animation rotate 1.5s infinite linear

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
