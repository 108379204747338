<template>
	<div class="stat-box" id="rs_repa_tr">
		<div class="stat-box-header">
			<p class="title">{{ $t('analyse_de_la_demande_sur_le_territoire') }} {{ app.adr_init.territoire }}</p>
			<span class="tabs">
				<a id="export09" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'rs_repa_tr', exportid: 'export09'});"><span><icon file="image" /></span></a>
			</span>
		</div>
		<div class="stat-box-content">
			<div class="row">
				<div class="col half">
					<div class="graph">
						{{ $t('repartition_des_menages_par_tranches_dâges') }}
						<apexchart
							id="chartOptions_men_tranche"
							ref="chartOptions_men_tranche"
							type="bar"
							:options="chartOptions2"
							:series="Nbdata"
						></apexchart>
					</div>
				</div>
				<div class="col half">
					<div class="graph" v-if="app.livedata.insee && app.livedata.insee.length" :title="'Pour la tranche d\'âge de ménages correspondante, sur la période ' + (parseInt(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year)-5).toString() + ' ' + 'a' + ' ' + (app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year) + ' (les 2 derniers recensements INSEE), il y a eu sur le territoire ' + app.adr_init.territoire + ' X personnes en plus (si valeur positive) ou en moins (si résultat négatif) par an'">						
						{{ $t('évolution_annuelle_du_nombre_de_menages_:_gains_et_pertes_annuelles_entre') }} {{app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year-5}} {{ $t('et') }} {{app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year}}
						<apexchart
							id="chartOptions_men_tranche_gain"
							ref="chartOptions_men_tranche_gain"
							type="bar"
							:options="chartOptions"
							:series="Gaindata"
						></apexchart>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chart from 'primevue/chart';

import VueApexCharts from 'vue-apexcharts';

export default {
	name: 'GraphEvolutionInseeAge',

	data: function() {
		return {
			series: [{
				data: [10, -15, 20, -25, 30, -35]
			}],
			chartOptions: {
				chart: {
					type: 'bar',
					style: {
						fontFamily: 'Nunito',
					},
					height: 300
				},
				colors: ['#eee', '#eee', '#eee', '#eee', '#000', '#000'], // Initialisé vide, sera défini dynamiquement
				plotOptions: {
					bar: {
						distributed: true, // Active la distribution pour la personnalisation des couleurs
						dataLabels: {
							position: 'top', // top, center, bottom
						},
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						let s = ""
						if (val > 0) s = "+"
						return s + val.toString() + '/an';
					},
					offsetY: -20,
					style: {
						fontSize: '12px',
						colors: ["#304758"]
					}
				},
				xaxis: {
					categories: ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"],
				},
			},
			chartOptions2: {
				chart: {
					type: 'bar',
					style: {
							fontFamily: 'Nunito',
					},
					height: 300
				},
				plotOptions: {
					bar: {
						distributed: true, // Active la distribution pour la personnalisation des couleurs
						dataLabels: {
							position: 'top', // top, center, bottom
						},
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val.toString() + ' mén.';
					},
					offsetY: -20,
					style: {
						fontSize: '12px',
						colors: ["#304758"]
					}
				},
				xaxis: {
					categories: ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"],
				},
			},
			chartOptions_men_tranche: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#2196F3',
						name: "Offre commerciale",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_men_tranche_gain: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true,
							align: 'right',
							formatter: function() {
								// Positionne l'étiquette de données au-dessus ou en dessous de la barre
								let yPos = this.y > 0 ? -30 : 30;
								this.dataLabel.y = yPos;
								return this.y;
							},
							inside: false,
							style: {
								fontWeight: 'bold'
							}
						},
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {

		Nbdata:function() {
			let series = [{
				data: [0, 0, 0, 0, 0, 0]
			}]
			series[0].data = [
			this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.number, 
			];

			return series
		},

		Gaindata:function() {
			let series = [{
				data: [0, 0, 0, 0, 0, 0]
			}]
			series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.annual_evolution, 
			];

			return series
		},
	}),

	methods: {
		generateColors() {
			let v = []
			let v2 = []
			if ((this.app.view == 'report_E') && (this.app.efocus_type!= 'efocus-parcours-personnalises-tous')) {
				v = ['#ddd', '#ddd', '#ddd', '#ddd']
				v2 = ['#ddd', '#ddd', '#ddd', '#ddd', '#609af8', '#609af8']
			} else {
				if (this.Gaindata[0].data[0] > 0) v.push('#00E396'); else v.push('#FF4560');
				if (this.Gaindata[0].data[1] > 0) v.push('#00E396'); else v.push('#FF4560');
				if (this.Gaindata[0].data[2] > 0) v.push('#00E396'); else v.push('#FF4560');
				if (this.Gaindata[0].data[3] > 0) v.push('#00E396'); else v.push('#FF4560');
				v2 = ['#609af8', '#609af8', '#609af8', '#609af8', '#609af8', '#609af8']
			}
			if (this.Gaindata[0].data[4] > 0) v.push('#00E396'); else v.push('#FF4560');
			if (this.Gaindata[0].data[5] > 0) v.push('#00E396'); else v.push('#FF4560');

			this.$refs.chartOptions_men_tranche_gain.updateOptions({
				colors: v
			});
			this.$refs.chartOptions_men_tranche.updateOptions({
				colors: v2
			});
		},

		maj_age: function() {
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
			this.chartOptions_men_tranche.series[0].name = this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year;
			this.chartOptions_men_tranche.series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.number, 
			];
			this.chartOptions_men_tranche.xAxis.categories= ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"]
			}
		},

		maj_gain: function() {			
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
				let l = (this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year-5).toString() + ' - ' + (this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year).toString();
			
			this.chartOptions.xaxis.categories = ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"]
			this.series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.annual_evolution, 
			];
			
			this.chartOptions_men_tranche_gain.series[0].name = l

			this.chartOptions_men_tranche_gain.series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.annual_evolution, 
			];
			
			this.generateColors();
			this.chartOptions_men_tranche_gain.xAxis.categories= ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"]
			this.chartOptions_men_tranche_gain.plotOptions = {
					series: {
						dataLabels: {
							enabled: true,
							align: 'right',
							formatter: function() {
								return this.y > 0 ? ' ' + this.y : this.y;
							},
							inside: false,
							style: {
								fontWeight: 'bold'
							}
						},
					}
				}
			
		}
		}
	},

	mounted: function() {
		this.maj_age()
		this.maj_gain()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B' || this.app.view == 'report_E') {
				this.maj_age()
				this.maj_gain()
			}
		},
	},
	
	// eslint-disable-next-line 
	components: { Chart, apexchart: VueApexCharts }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'

.stat-box
	.stat-box-content
		padding 0 16px
		text-align center

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
