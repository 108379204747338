<template>
	<transition name="pane-adresse-commerces">
		<div class="paneleft pan-adresse-commerces">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">Général</span>
							<span> > </span>
							<span><b>{{ $t('commerces') }}</b></span>
						</div>

						<div class="noteTitre" :class="app.cityscan.adresse.note_commerces">{{app.cityscan.adresse.note_commerces}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('commerces_alimentaires') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-alimentation' }); select_adresse_iso(app.cityscan.adresse.alimentation)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1330-02 ng-star-inserted" style="color: rgb(124, 0, 41);"></span></div>
														<div class="titre_module">{{ $t('alimentation_generale') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.alimentation.note">{{this.app.cityscan.adresse.alimentation.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.alimentation.nb}} {{this.app.cityscan.adresse.alimentation.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_alimentation" class="checkbox" :class="{ checked: adresse_commerce_alimentation }">
															<input type="checkbox" :checked="adresse_commerce_alimentation"  id="adresse_commerce_alimentation" @click=" select_adresse('adresse_commerce_alimentation', app.cityscan.adresse.alimentation)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-bouche' }); select_adresse_iso(app.cityscan.adresse.commerces_bouche)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1332-02 ng-star-inserted" style="color: rgb(110, 0, 55);"></span></div>
														<div class="titre_module">{{ $t('commerce_de_bouche') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.commerces_bouche.note">{{this.app.cityscan.adresse.commerces_bouche.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.commerces_bouche.nb}} {{this.app.cityscan.adresse.commerces_bouche.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_bouche" class="checkbox" :class="{ checked: adresse_commerce_bouche }" >
															<input type="checkbox" :checked="adresse_commerce_bouche"  id="adresse_commerce_bouche" @click=" select_adresse('adresse_commerce_bouche', app.cityscan.adresse.commerces_bouche)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('commerces_non_alimentaires') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-utilitaire' }); select_adresse_iso(app.cityscan.adresse.commerces_utilitaires)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1333-02 ng-star-inserted" style="color: rgb(224, 52, 36);"></span></div>
														<div class="titre_module">{{ $t('commerce_utilitaire') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.commerces_utilitaires.note">{{this.app.cityscan.adresse.commerces_utilitaires.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.commerces_utilitaires.nb}} {{this.app.cityscan.adresse.commerces_utilitaires.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_utilitaire" class="checkbox" :class="{ checked: adresse_commerce_utilitaire }" >
															<input type="checkbox" :checked="adresse_commerce_utilitaire" id="adresse_commerce_utilitaire" @click=" select_adresse('adresse_commerce_utilitaire', app.cityscan.adresse.commerces_utilitaires)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-shopping' }); select_adresse_iso(app.cityscan.adresse.shopping)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1331-02 ng-star-inserted" style="color: rgb(208, 17, 66);"></span></div>
														<div class="titre_module">{{ $t('shopping') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.shopping.note">{{this.app.cityscan.adresse.shopping.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.shopping.nb}} {{this.app.cityscan.adresse.shopping.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_shopping" class="checkbox" :class="{ checked: adresse_commerce_shopping }" >
															<input type="checkbox" :slot-scope="adresse_commerce_shopping" id="adresse_commerce_shopping" @click=" select_adresse('adresse_commerce_shopping', app.cityscan.adresse.shopping)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-soin' }); select_adresse_iso(app.cityscan.adresse.soins_beaute)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1334-02 ng-star-inserted" style="color: rgb(216, 35, 51);"></span></div>
														<div class="titre_module">{{ $t('soins_et_beaute') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.soins_beaute.note">{{this.app.cityscan.adresse.soins_beaute.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.soins_beaute.nb}} {{this.app.cityscan.adresse.soins_beaute.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_soin" class="checkbox" :class="{ checked: adresse_commerce_soin }" >
															<input type="checkbox" :checked="adresse_commerce_soin" id="adresse_commerce_soin" @click=" select_adresse('adresse_commerce_soin', app.cityscan.adresse.soins_beaute)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('fournitures_et_reparations') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-logement' }); select_adresse_iso(app.cityscan.adresse.services_logement)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1370-02 ng-star-inserted" style="color: rgb(231, 47, 52);"></span></div>
														<div class="titre_module">{{ $t('services_pour_le_logement') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.services_logement.note">{{this.app.cityscan.adresse.services_logement.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.services_logement.nb}} {{this.app.cityscan.adresse.services_logement.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_logement" class="checkbox" :class="{ checked: adresse_commerce_logement }" >
															<input type="checkbox" :checked="adresse_commerce_logement" id="adresse_commerce_logement" @click=" select_adresse('adresse_commerce_logement', app.cityscan.adresse.services_logement)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'commerce-vehicule' }); select_adresse_iso(app.cityscan.adresse.services_vehicule)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1231-02 ng-star-inserted" style="color: rgb(243, 124, 46);"></span></div>
														<div class="titre_module">{{ $t('services_pour_le_vehicule') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.services_vehicule.note">{{this.app.cityscan.adresse.services_vehicule.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.services_vehicule.nb}} {{this.app.cityscan.adresse.services_vehicule.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_commerce_vehicule" class="checkbox" :class="{ checked: adresse_commerce_vehicule }">
															<input type="checkbox" :checked="adresse_commerce_vehicule"  id="adresse_commerce_vehicule" @click=" select_adresse('adresse_commerce_vehicule', app.cityscan.adresse.services_vehicule)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseCommerces',
	data: function() {
		return {
			adresse_commerce_vehicule: true,
			adresse_commerce_logement: true,
			adresse_commerce_soin: true,
			adresse_commerce_shopping: true,
			adresse_commerce_utilitaire: true,
			adresse_commerce_bouche: true,
			adresse_commerce_alimentation: true,
			detail: '',
		}
	},
	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),
	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_commerce_vehicule = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_vehicule')!==-1)
		//	this.adresse_commerce_logement = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_logement')!==-1)
		//	this.adresse_commerce_soin = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_soin')!==-1)
		//	this.adresse_commerce_shopping = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_shopping')!==-1)
		//	this.adresse_commerce_utilitaire = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_utilitaire')!==-1)
		//	this.adresse_commerce_bouche = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_bouche')!==-1)
		//	this.adresse_commerce_alimentation = (this.app.preferences.adresse_niv2.indexOf('adresse_commerce_alimentation')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},
		getscore: function(score) {
			let score_name= ''
			if (score >= 80) {
				score_name = "Nutri-score-A"
			} else {
				if (score >= 60) {
					score_name = "Nutri-score-B"
				} else {
					if (score >= 40) {
						score_name = "Nutri-score-C"
					} else {
						if (score >= 20) {
							score_name = "Nutri-score-D"
						} else {
							score_name = "Nutri-score-E"
						}
					}
				}
			}
			return score_name
		},
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		}
	},
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-commerces-enter
	transform translateX(100%)
.pane-adresse-commerces-leave-to
	transform translateX(-100%)

.pan-adresse-commerces
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
