<template>
	<div class="header">
		<div class="bg-primary-500 py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="max-height: 60px; padding-left:10px !important">
			<ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-primary-400 lg:border-top-none">
				<li>
					<a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple" v-styleclass="{ selector: '#menu', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
						<i class="pi pi-th-large text-base lg:text-2xl mr-2 lg:mr-0"></i>
						<span class="block lg:hidden font-medium">{{ $t('menu') }}</span>
					</a>
				</li>
				<li>
					<icon file="e-focus2" style="color:#ffffff;width:150px;height:50px;" />
				</li>
			</ul>
			<a v-ripple class="cursor-pointer block lg:hidden text-primary-50 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
				<i class="pi pi-bars text-4xl"></i>
			</a>
			<div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-primary-500 left-0 top-100 z-1 shadow-2 lg:shadow-none">
				<ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
					<div class="adresse" v-if="app.adr_init" style="display: flex; align-items: left; vertical-align: middle;">
						<div class="logo" v-if="ggsv"><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + app.adr_init.latlng.lat + ',' + app.adr_init.latlng.lng"><img alt="streetview" :src="ggsv"/></a></div>
						<table>
							<th style="display:none"></th>
							<tr>
								<td>&nbsp;&nbsp;&nbsp;{{app.adr_init.name}}, {{app.adr_init.postcode}} {{app.adr_init.city}}</td>
								<td>&nbsp;&nbsp;&nbsp;</td>
								<td>
									<li v-if="app.view!='home'" @click="$store.dispatch('set_help', {aide:false}); $store.dispatch('set_help_prog', {aide:false}); $store.dispatch('set_help_live', {aide:false}); $store.dispatch('set_view', { view: 'home' });RetourRecherche()">
										<a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-search text-base lg:text-2xl mr-2 lg:mr-0"></i>
											<span class="block lg:hidden font-medium">{{ $t('chercher') }}</span>
										</a>
									</li>
									<li v-else-if="app.adr_init.city" @click="$store.dispatch('reset_view', {});">
										<a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-map text-base lg:text-2xl mr-2 lg:mr-0"></i>
											<span class="block lg:hidden font-medium">{{ $t('chercher') }}</span>
										</a>
									</li>
								</td>
								<td v-if="false">
									<li v-if="app.view!='home'" @click="OuvreFicheTerritoire();">
										<a v-ripple class="flex lg:px-3 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple" style="height:40px;">
											<icon class="text-base lg:text-2xl mr-2 lg:mr-0" style="width:32px;height:32px" file="travel" />
											<span class="block lg:hidden font-medium">{{ $t('Fiche Territoire') }}</span>
										</a>
									</li>
								</td>
							</tr>

						</table>
					</div>
				</ul>
				<ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-primary-400 lg:border-top-none">
					<li v-if="app.progress_bar">
						<div class="loader">
							<icon file="loader" :size="24" style="color:#FFF;width:56px;" />
						</div>
					</li>
					<li>
						<a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"  v-styleclass="{ selector: '#aide', enterClass: 'hidden', enterActiveClass: 'fadeinright', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright', hideOnOutsideClick: true }">
							<i class="pi pi-question-circle text-base lg:text-2xl mr-2 lg:mr-0"></i>
							<span class="block lg:hidden font-medium">aide</span>
						</a>
					</li>
					<li>
						<a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-primary-100 hover:text-primary-50 hover:bg-primary-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"  v-styleclass="{ selector: '#user', enterClass: 'hidden', enterActiveClass: 'fadeinright', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright', hideOnOutsideClick: true }">
							<i class="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
							<span class="block lg:hidden font-medium">{{ $t('mon_compte') }}</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="user">
			<div id="user" class="hidden surface-overlay absolute top-0 right-0 shadow-2 w-20rem h-full">
				<div class="flex flex-column p-4 h-full">
					<div class="flex align-items-center justify-content-between mb-4">
						<span class="text-900 text-xl font-medium">{{ $t('compte') }}</span>
						<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
						v-styleclass="{ selector: '#user', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"></Button>
					</div>
					<div class="flex-auto overflow-y-auto">
						<div class="text-center mb-4">
							<img alt="logo" :src=user.logo style="max-width:200px;max-height:70px" />
							<div class="text-900 mt-3 mb-2 text-xl font-medium">{{user.NomPrenom}}</div>
							<span class="text-700"></span>
						</div>
						<ul class="list-none p-0 m-0">
							<li class="surface-100 text-sm text-700 font-medium p-2 border-round mb-1">{{ $t('nom') }}</li>
							<li class="text-900 p-2">{{user.NomPrenom}}</li>
							<li class="surface-100 text-sm text-700 font-medium p-2 border-round mt-4 mb-1">{{ $t('acces') }}</li>
							<li>
								<ul class="list-none p-0 m-0">

									<li class="py-2 border-bottom-1 surface-border" @click="$store.dispatch('set_view', { view: 'user' });$store.dispatch('get_list_report', {});" v-styleclass="{ selector: '#user', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple">
											<i class="mr-3 flex-shrink-0 pi pi-user text-900 text-xl" style="font-size: 2rem"></i>
											<div>
												<span class="block text-900 mb-1">{{ $t('mon_compte') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<!--
									// Gestion préférences
									<li class="py-2 border-bottom-1 surface-border" @click="$store.dispatch('record_pref', {  });" v-styleclass="{ selector: '#preferences', enterClass: 'hidden', enterActiveClass: 'fadeinright', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright', hideOnOutsideClick: true }">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple">
											<i class="mr-3 flex-shrink-0 pi pi-cog text-900 text-xl" style="font-size: 2rem"></i>
											<div>
												<span class="block text-900 mb-1">{{ $t('mes_preferences') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									-->
									<li class="py-2 border-bottom-1 surface-border">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#user', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }" target="_blank" href="https://www.myadequation.fr/EFOCUS/aide.html">
											<i class="mr-3 flex-shrink-0 pi pi-question text-900 text-xl" style="font-size: 2rem"></i>
											<div>
												<span class="block text-900 mb-1">{{ $t('faq') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#user', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }" @click="$store.dispatch('set_view', { view: 'home' });signout()">
											<i class="mr-3 flex-shrink-0 pi pi-sign-out text-900 text-xl" style="font-size: 2rem"></i>
											<div>
												<span class="block text-900 mb-1">{{ $t('deconnexion') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="aide">
			<div id="aide" class="hidden surface-overlay absolute top-0 right-0 shadow-2 w-20rem h-full">
				<div class="flex flex-column p-4 h-full">
					<div class="flex align-items-center justify-content-between mb-2">
						<span class="text-900 text-xl font-medium">{{ $t('aide') }}</span>
						<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
						v-styleclass="{ selector: '#aide', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"></Button>
					</div>
					<div>
						<ul class="list-none p-0 m-0">
							<li>
								<ul class="list-none p-0 m-0">
									<li class="py-2 border-bottom-1 surface-border" v-styleclass="{ selector: '#aide', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" :href='getLienTutoriel()' target='_blank'>
											<div>
												<span class="block text-900 mb-1">{{ $t('tutoriels') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2 border-bottom-1 surface-border">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#aide', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }" :href='getLienAide()' target='_blank'>
											<div>
												<span class="block text-900 mb-1">{{ $t('aide') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2 border-bottom-1 surface-border" @click="ContactVisible = true">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#aide', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
											<div>
												<span class="block text-900 mb-1">{{ $t('contact') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
								</ul>
							</li>
						</ul>
						<div style="padding-top:20px">
							<a href="https://outlook-sdf.office.com/bookwithme/user/2325c1a654174c54ac7892d691387fa4%40adequation-france.com/meetingtype/5242d0f8-016a-4af7-a62a-e471b6f87900?anonymous" target="blank"><Button icon="pi pi-video" label="Organiser une formation" class="p-button-rounded p-button-success" /> </a>
						</div>
					</div>
					<br>
<!--
					<br><br><br><br><br>
					<div class="flex mb-4 md:mb-0 md:px-1 py-4 md:py-0">
						<div class="shadow-2 surface-card border-round relative">
							<img alt="consultant" src="../assets/img/BDM2.png" width="100" height="100" class="absolute left-50 shadow-2" style="margin-left:-50px; top: -60px; border-radius: 50% !important; border-color: var(--border-radius);">
							
							<div class="px-4 pb-4 pt-8 relative">
								<p class="text-900 font-italic line-height-3 text-lg m-0 pb-4 border-bottom-1 surface-border">
									“{{ $t('au_plaisir_de_vous_y_retrouver.') }}”
								</p>
								<div class="text-900 font-semibold line-height-3 mt-4">Brice de Marcillac</div>
								<div class="text-600 line-height-3 text-sm">07 69 81 10 19</div>
								<div>{{ $t('Organiser') }} <a class="bleu" @click="ContactVisible=false" href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Foutlook.office.com%2Fbookwithme%2Fuser%2F1b008e036b8c42e98d4ecf08116335c5%40adequation-france.com%2Fmeetingtype%2FSVRwCe7HMUGxuT6WGxi68g2%3Fanonymous%26ep%3Dmlink&data=05%7C01%7Cb.demarcillac%40adequation-france.com%7Cc364b91f4dfa4043f25708dbb52ec058%7Cb6bfe1d8f6aa4701833e2b1995fd19bd%7C0%7C0%7C638302984632232605%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=21yt04MwNExCTqVLWS4C2OjDbaO9WuswVa4qKgiZO8I%3D&reserved=0" target="blank">{{ $t('une formation') }}</a></div>
							</div>
						</div>
					</div>
-->					
				</div>
			</div>
		</div>
		<!--
		// Gestion préférences
		<div class="aide">
			<div id="preferences" class="hidden surface-overlay absolute top-0 right-0 shadow-2 w-30rem h-full">
				<div class="flex flex-column p-2 h-full">
					<div class="p-2" style="height: calc(100vh - 220px)" v-bar>
						<div style="height:100%">
							<div class="flex p-4 align-items-center justify-content-between mb-2">
								<span class="text-900 text-xl font-medium">{{ $t('Mes préférences') }}</span>
								<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
								v-styleclass="{ selector: '#preferences', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"></Button>
							</div>
							<hr>
							<br/>
							<p class="box-title2">Préférences d'affichage des programmes</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="legende='commercialisation';modif=true;" class="filtre" :class="{ active: legende == 'commercialisation' }">{{ $t('Commercialisation')}}</div>
									<div @click="legende='prix';modif=true;" class="filtre" :class="{ active: legende == 'prix' }">{{ $t('Gamme de prix') }}</div>									
								</div>
							</div>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">Préférences d'affichage des prix</p>
							<br/>
							<p class="box-title2">{{ $t('etat') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_etat', 'tout')" class="filtre" :class="{ active: affichage_prix_etat == 'tout' }">{{ $t('Tout')}}</div>
									<div @click="toggle_switch('affichage_prix_etat', 'vente')" class="filtre" :class="{ active: affichage_prix_etat == 'vente' }">{{ $t('des ventes') }}</div>									
									<div @click="toggle_switch('affichage_prix_etat', 'offre')" class="filtre" :class="{ active: affichage_prix_etat == 'offre' }">{{ $t('de l\'offre') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('Financement') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_tva', 'reduite')" class="filtre" :class="{ active: affichage_prix_tva == 'reduite' }">TVA réduite</div>
									<div @click="toggle_switch('affichage_prix_tva', 'aide')" class="filtre" :class="{ active: affichage_prix_tva == 'aide' }">aidé</div>									
									<div @click="toggle_switch('affichage_prix_tva', 'pleine')" class="filtre" :class="{ active: affichage_prix_tva == 'pleine' }">libre</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('parking') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('affichage_prix_pk', 'inclus')" class="filtre" :class="{ active: affichage_prix_pk == 'inclus' }">parking inclus</div>
									<div @click="toggle_switch('affichage_prix_pk', 'sans')" class="filtre" :class="{ active: affichage_prix_pk == 'sans' }">hors parking</div>									
								</div>
							</div>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">Filtre du neuf</p>
							<br/>
							<p class="box-title2">{{ $t('forme_urbaine') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('forme', 'collectif')" class="filtre" :class="{ active: col }">{{ $t('collectif') }}</div>
									<div @click="toggle_switch('forme', 'individuel')" class="filtre" :class="{ active: ind }">{{ $t('individuel') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">Cycle de vie</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('commercialisation', 'actif')" class="filtre" :class="{ active: actif }">Actifs</div>
									<div @click="toggle_switch('commercialisation', 'projet')" class="filtre" :class="{ active: projet }">{{ $t('projets') }}</div>									
									<div @click="toggle_switch('commercialisation', 'termine')" class="filtre" :class="{ active: termine }">{{ $t('termines') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('tva') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('tva', 'reduite')" class="filtre" :class="{ active: tvar }">5,5 %</div>
									<div @click="toggle_switch('tva', 'pleine')" class="filtre" :class="{ active: tvap }">20 %</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('VEB') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('veb', 'avec')" class="filtre" :class="{ active: veb_avec }">avec</div>
									<div @click="toggle_switch('veb', 'sans')" class="filtre" :class="{ active: veb_sans }">sans</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('financements') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('financement', 'psla')" class="filtre" :class="{ active: psla }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">{{ $t('psla') }}</div>
									<div @click="toggle_switch('financement', 'brs')" class="filtre" :class="{ active: brs }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">{{ $t('brs') }}</div>									
									<div @click="toggle_switch('financement', 'anru')" class="filtre" :class="{ active: anru }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">ANRU</div>									
									<div @click="toggle_switch('financement', 'autres')" class="filtre" :class="{ active: autres }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_un_autre_type_de_financement_aide')">{{ $t('autres') }}</div>									
									<div @click="toggle_switch('financement', 'aucun')" class="filtre" :class="{ active: aucun }" :title="$t('affiche_les_programmes_proposant_au_moins_1_lot_sans_aide_au_financement')">{{ $t('aucun') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('programmes_actifs') }}</p>
							<div class="box-content" v-if="app.gestionhistorique">
								<p class="box-title" style="text-align:center" v-if="this.nbannee>1">{{ $t('durant_les') }} {{this.nbannee}} {{ $t('dernieres_annees') }}</p>
								<p class="box-title" style="text-align:center" v-else>{{ $t('depuis_la_derniere_annee') }}</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<vue-slider
								v-model="nbannee"
								:adsorb="true"
								:interval="1"
								:max="10"
								:min="1"
								:marks="true"
								:bgStyle="{ backgroundColor: '#ddd', height: '3px' }"
								:tooltipStyle="{ backgroundColor: '#651FFF', borderColor: '#651FFF', fontSize: '12px', fontWeight: '600', color: '#f0f2f4' }"
								:processStyle="{ backgroundColor: '#651FFF' }"
								@change='sliderChangeAnnee($event, "annees")'
								></vue-slider>
							</div>
							<br/>
							<br/>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">Filtre des résidences service</p>
							<br/>
							<p class="box-title2">{{ $t('Cible') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="seniors=!seniors;modif=true;" class="filtre" :class="{ active: seniors }">{{ $t('seniors') }}</div>
									<div @click="etudiants=!etudiants;modif=true;" class="filtre" :class="{ active: etudiants }">{{ $t('etudiants') }}</div>
									<div @click="jeunes=!jeunes;modif=true;" class="filtre" :class="{ active: jeunes }">{{ $t('jeunes') }}</div>
									<div @click="sociales=!sociales;modif=true;" class="filtre" :class="{ active: sociales }">{{ $t('sociales') }}</div>
									<div @click="tourisme=!tourisme;modif=true;" class="filtre" :class="{ active: tourisme }">{{ $t('tourisme') }}</div>
									<div @click="toutage=!toutage;modif=true;" class="filtre" :class="{ active: toutage }">{{ $t('toutage') }}</div>
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('Nature') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="beguinage=!beguinage;modif=true;" class="filtre" :class="{ active: beguinage }">{{ $t('beguinage') }}</div>
									<div @click="copropriete=!copropriete;modif=true;" class="filtre" :class="{ active: copropriete }">{{ $t('copropriete') }}</div>
									<div @click="ephad=!ephad;modif=true;" class="filtre" :class="{ active: ephad }">{{ $t('EHPAD') }}</div>
									<div @click="maison_repos=!maison_repos;modif=true;" class="filtre" :class="{ active: maison_repos }">{{ $t('Maison de repos') }}</div>
									<div @click="maison_repos_soin=!maison_repos_soin;modif=true;" class="filtre" :class="{ active: maison_repos_soin }">{{ $t('Maison de repos et soin') }}</div>
									<div @click="res_autonomie=!res_autonomie;modif=true;" class="filtre" :class="{ active: res_autonomie }">{{ $t('Résidence autonomie') }}</div>
									<div @click="rss_libre=!rss_libre;modif=true;" class="filtre" :class="{ active: rss_libre }">{{ $t('Résidence seniors libre') }}</div>
									<div @click="village_senior_prive=!village_senior_prive;modif=true;" class="filtre" :class="{ active: village_senior_prive }">{{ $t('Village sénior libre') }}</div>
									<div @click="village_senior_social=!village_senior_social;modif=true;" class="filtre" :class="{ active: village_senior_social }">{{ $t('Village sénior social') }}</div>
									<div @click="marpa=!marpa;modif=true;" class="filtre" :class="{ active: marpa }">{{ $t('MARPA') }}</div>
									<div @click="rse_social=!rse_social;modif=true;" class="filtre" :class="{ active: rse_social }">{{ $t('rse_social') }}</div>
									<div @click="rse_mixte=!rse_mixte;modif=true;" class="filtre" :class="{ active: rse_mixte }">{{ $t('rse_mixte') }}</div>
									<div @click="rse_libre=!rse_libre;modif=true;" class="filtre" :class="{ active: rse_libre }">{{ $t('rse_libre') }}</div>
									<div @click="fjt=!fjt;modif=true;" class="filtre" :class="{ active: fjt }">{{ $t('fjt') }}</div>
									<div @click="chrs=!chrs;modif=true;" class="filtre" :class="{ active: chrs }">{{ $t('chrs') }}</div>
									<div @click="hotel=!hotel;modif=true;" class="filtre" :class="{ active: hotel }">{{ $t('hotel') }}</div>
									<div @click="res_tourisme=!res_tourisme;modif=true;" class="filtre" :class="{ active: res_tourisme }">{{ $t('res_tourisme') }}</div>
									<div @click="hotel_residence=!hotel_residence;modif=true;" class="filtre" :class="{ active: hotel_residence }">{{ $t('hotel_residence') }}</div>
									<div @click="coliving=!coliving;modif=true;" class="filtre" :class="{ active: coliving }">{{ $t('coliving') }}</div>
									<div @click="habitat_partage=!habitat_partage;modif=true;" class="filtre" :class="{ active: habitat_partage }">{{ $t('habitat_partage') }}</div>
									<div @click="colocation=!colocation;modif=true;" class="filtre" :class="{ active: colocation }">{{ $t('colocation') }}</div>
								</div>
							</div>
							<br/>
							<br/>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">Filtre de l'ancien</p>
							<br/>
							<p class="box-title2">Forme Urbaine</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="typeId=1;modif=true;" class="filtre" :class="{ active: typeId==1 }">{{ $t('maison') }}</div>
									<div @click="typeId=2;modif=true;" class="filtre" :class="{ active: typeId==2 }">{{ $t('appartement') }}</div>
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('Typo') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="rooms=1;modif=true;" class="filtre" :class="{ active: rooms==1 }">{{ $t('t1') }}</div>
									<div @click="rooms=2;modif=true;" class="filtre" :class="{ active: rooms==2 }">{{ $t('t2') }}</div>
									<div @click="rooms=3;modif=true;" class="filtre" :class="{ active: rooms==3 }">{{ $t('t3') }}</div>
									<div @click="rooms=4;modif=true;" class="filtre" :class="{ active: rooms==4 }">{{ $t('t4') }}</div>
									<div @click="rooms=5;modif=true;" class="filtre" :class="{ active: rooms==5 }">{{ $t('t5') }}</div>
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('surface') }}</p>
							<div class="box-content">
								<div class="switches" style="text-align: center">
									<InputNumber inputStyle="text-align:right" id="integeronly" v-model="area" suffix=" m²" />
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('Etat') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="condition=1;modif=true;" class="filtre" :class="{ active: condition==1 }">{{ $t('refait_a_neuf') }}</div>
									<div @click="condition=2;modif=true;" class="filtre" :class="{ active: condition==2 }">{{ $t('rafraîchi') }}</div>
									<div @click="condition=3;modif=true;" class="filtre" :class="{ active: condition==3 }">{{ $t('standard') }}</div>
									<div @click="condition=4;modif=true;" class="filtre" :class="{ active: condition==4 }">{{ $t('a_rafraichir') }}</div>
									<div @click="condition=5;modif=true;" class="filtre" :class="{ active: condition==5 }">{{ $t('a_refaire') }}</div>
								</div>
							</div>
							<br/>
							
							<br/>
							<br/>
							<br/>
							<hr>
							<br/>
							
							<div class="group_layer">
								<br/>
								<p class="box-title2">Couches d'information</p>
								<div class="box-content">
									<div class="group_filtre">
										<div @click="zac=!zac;setcontours('zac', zac);modif=true;" class="filtre" :class="{ active: zac }">{{ $t('ZAC') }}</div>
										<div @click="qpv=!qpv;setcontours('qpv', qpv);modif=true;" class="filtre" :class="{ active: qpv }">{{ $t('quartiers_prioritaires_de_la_ville') }}</div>									
										<div @click="qpv_trois=!qpv_trois;setcontours('qpv_trois', qpv_trois);modif=true;" class="filtre" :class="{ active: qpv_trois }">{{ $t('300m_autour_des_qpv') }}</div>									
										<div @click="contour_com=!contour_com;setcontours('commune', contour_com);modif=true;" class="filtre" :class="{ active: contour_com }">{{ $t('communes_ou_quartiers') }}</div>									
									</div>
								</div>
							</div>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">Fond de carte</p>
							<div class="" style="display: flex;justify-content: space-around;padding-bottom: 12px;">
								<table style="width:100%">
									<th style="display:none"></th>
									<tr>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==1 }">
													<input type="checkbox" :checked="fond==1" @click.stop="fond=1;setf();modif=true;">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('gris') }}</span>
												</label>
											</div>
										</td>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==2 }">
													<input type="checkbox" :checked="fond==2" @click.stop="fond=2;setf();modif=true;">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('couleurs') }}</span>
												</label>
											</div>
										</td>
										<td style="width:33%">
											<div style="height:32px">
												<label class="radiobox" :class="{ checked: fond==3 }">
													<input type="checkbox" :checked="fond==3" @click.stop="fond=3;setf();modif=true;">
													<span class="chk"><icon file="check" :size="16" /></span>
													<span class="text">{{ $t('satellite') }}</span>
												</label>
											</div>
										</td>
									</tr>
								</table>
							</div>
							<br/>
							<hr>
							<br/>
							<p class="box-title2">POI</p>
							<ScrollPanel style="width: 100%; height: 570px">
								<div style="padding-top: 20px;padding-right: 10px;padding-bottom: 20px;">
									<Tree :value="options2" selectionMode="checkbox" :selectionKeys.sync="selectedKey1"
									@node-select="poiSelect2" @node-unselect="poiDeselect2" style="font-size: 14px;padding:0 !important"></Tree>
								</div>
							</ScrollPanel>
						</div>
					</div>
					<div>
						<br>
						<div style="text-align: center;width: 100%;display: flex;justify-content: center;">
							<Button label="Réinitialiser les valeurs par défaut" icon="pi pi-replay" class="p-button-text" @click="ChargeDefaut()" />
						</div>	
						<br>					
						<div style="text-align: center;width: 100%;display: flex;justify-content: center;">
							<span class="p-buttonset">
								
								
								<Button v-if="modif" label="Enregistrer mes préférences" icon="pi pi-check" @click="record_pref();" v-styleclass="{ selector: '#preferences', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"/>
								<Button v-else disabled="disabled" label="Enregistrer mes préférences" icon="pi pi-check"/>
								<Button v-if="modif" label="Annuler" icon="pi pi-times" @click="$store.dispatch('set_preferences', { preferences: app.preferences_init, init : false });modif=false;" v-styleclass="{ selector: '#preferences', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"/>
								<Button v-else disabled="disabled" label="Annuler" icon="pi pi-times" />
							</span>
							
						</div>						
						<br/>
					</div>
				</div>
			</div>
		</div>
		-->
		<div class="menu">
			<div id="menu" class="hidden surface-overlay absolute top-0 left-0 shadow-2 w-20rem h-full">
				<div class="flex flex-column p-4 h-full">
					<div class="flex align-items-center justify-content-between mb-4">
						<span class="text-900 text-xl font-medium">Liens Adequation</span>
						<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
						v-styleclass="{ selector: '#menu', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft' }"></Button>
					</div>
					<div class="flex-auto overflow-y-auto">
						<ul class="list-none p-0 m-0">
							<li>
								<ul class="list-none p-0 m-0">
									<li class="py-2 border-bottom-1 surface-border" v-styleclass="{ selector: '#mebnu', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
										<a v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" href='https://residentiel.adequation.fr/login' target='_blank'>
											<div>
												<span class="block text-900 mb-1">{{ $t('fil_residentiel') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2 border-bottom-1 surface-border">
										<a target="_blank" href="https://fil.adequation.fr/login" v-ripple class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#menu', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
											<div>
												<span class="block text-900 mb-1">Fil en ligne</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2 border-bottom-1 surface-border">
										<a v-ripple target="_blank" href="https://media.adequation.fr/" class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#menu', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
											<div>
												<span class="block text-900 mb-1">{{ $t('media_adequation') }}</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
									<li class="py-2">
										<a v-ripple target="_blank" href="https://www.adequation.fr/" class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple" v-styleclass="{ selector: '#menu', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }">
											<div>
												<span class="block text-900 mb-1">Adequation</span>
											</div>
											<i class="ml-auto pi pi-chevron-right text-600 text-sm"></i>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div v-show="Aidevisible" class="dropdown">
				<div class="dropdown-menu" role="menu" style="right: 40px !important;">
					<div class="dropdown-content" style="border: 1px solid #ccc">
						<a style="margin: 0.5rem 0" role="menuitem" class="dropdown-item is-media m" :href='getLienTutoriel()' target='_blank' title="Tutoriel">
							<table>
								<th style="display:none"></th>
								<tr>
									<td style="width:34px"><span><icon file="tuto" :size="18" /></span></td>
									<td><span><p>{{ $t('tutoriels') }}</p></span></td>
								</tr>
							</table>
						</a>
						<hr class="dropdown-divider">
						<a :href='getLienAide()' target='_blank' class="dropdown-item is-media m">
							<table>
								<th style="display:none"></th>
								<tr>
									<td style="width:34px"><span><icon file="help-circle" :size="18" /></span></td>
									<td><span><p>{{ $t('aide') }}</p></span></td>
								</tr>
							</table>
						</a>
						<hr class="dropdown-divider">
						<a href="#"  @click="ContactVisible=true" class="dropdown-item is-media m">
							<table>
								<th style="display:none"></th>
								<tr>
									<td style="width:34px"><span><icon file="message-circle" :size="18" /></span></td>
									<td><span><p>{{ $t('contact') }}</p></span></td>
								</tr>
							</table>
						</a>
						<hr class="dropdown-divider">
						<a href="#"  @click="ContactVisible=true" class="dropdown-item is-media m">
							<table>
								<th style="display:none"></th>
								<tr>
									<td style="width:34px"><span><icon file="message-circle" :size="18" /></span></td>
									<td><span><p>{{ $t('formations') }}</p></span></td>
								</tr>
							</table>
						</a>
					</div>
				</div>
			</div>
		</div>
		<Dialog :header="$t('merci_dutiliser_e-focus')" :modal="true" :visible.sync="ContactVisible" :containerStyle="{width: '70vw', background: '#eff3f8'}">
			<table style="text-align: center; vertical-align: middle;width:100%;height:100%;">
				<th style="display:none"></th>
				<tr>
					<td colspan="3">
						<table style="text-align: center; vertical-align: middle;width:100%;">
							<th style="display:none"></th>
							<tr>
								<td style="width:64px;">&nbsp;</td>
								<td style="width:672px;font-size:20px;">
									<b>
										<br/>
										<p>{{ $t('merci_dutiliser_e-focus') }}</p>
										<p>{{ $t('une_equipe_a_votre_disposition,_surtout_contactez-nous_!') }}</p>
									</b>
								</td>
								<td style="width:64px;vertical-align: top;"><div style="cursor: pointer;padding-top:10px; padding-right:10px;"></div></td>
							</tr>
						</table>
					</td>
				</tr>
				<tr style="">
					<td style="padding-top:10px;padding-bottom:10px;width:33%">{{ $t('acces_utilisateur,_administration') }}</td>
					<td style="padding-top:10px;padding-bottom:10px;width:33%">{{ $t('Relation client') }}, {{ $t('formation') }}, {{ $t('démo') }}, {{ $t('commerce') }}</td>
					<td style="padding-top:10px;padding-bottom:10px;width:33%">{{ $t('Grand compte') }}, {{ $t('relation client') }}, {{ $t('formation') }}, {{ $t('démo') }}, {{ $t('commerce') }}</td>
				</tr>
				<tr>
					<td><img alt="Céline" src="../assets/img/CLE.png"/></td>
					<td><img alt="Rachid" src="../assets/img/RRA.png"/></td>
					<td><img alt="Marion" id="im_p" src="../assets/img/MBA.png"/></td>
				</tr>
				<tr>
					<td style="padding-top:10px;"><p class="Nom">Céline Lérault</p></td>
					<td style="padding-top:10px;"><p class="Nom">Rachid Ramdani</p></td>
					<td style="padding-top:10px;"><p class="Nom">Marion Baur</p></td>
				</tr>
				<tr>
					<td style="padding-top:5px;">{{ $t('assistante_commerciale') }}</td>
					<td style="padding-top:5px;">{{ $t('Business Developper') }}</td>
					<td style="padding-top:5px;">{{ $t('Head of sales and marketing') }}</td>
				</tr>
				<tr>
					<td style="padding-top:5px;"><Button @click="ContactVisible=false" :label="$t('adresser_une_demande')" class="p-button-link font-medium no-underline ml-2 text-blue-500 cursor-pointer" style="padding: 0px;    font-family: Roboto, arial, sans-serif;font-size: 11pt;" onclick='$("#zohohc-asap-web-launcherbox").click();' /></td>
					<td style="padding-top:5px;">Prendre <a class="bleu" @click="ContactVisible=false" href="https://outlook-sdf.office.com/bookwithme/user/2325c1a654174c54ac7892d691387fa4%40adequation-france.com/meetingtype/5242d0f8-016a-4af7-a62a-e471b6f87900?anonymous" target="blank">{{ $t('rendez-vous') }}</a></td>
					<td style="padding-top:5px;">&nbsp;</td>
				</tr>
				<tr>
					<td style="padding-top:5px;">04 27 19 48 53</td>
					<td style="padding-top:5px;"><a class="bleu" href="mailto:r.ramdani@adequation-france.com">r.ramdani@adequation-france.com</a></td>
					<td style="padding-top:5px;"><a class="bleu" href="mailto:m.baur@adequation-france.com">m.baur@adequation-france.com</a></td>
				</tr>
			</table>
			<template #footer>
				
			</template>
		</Dialog>
		<loading-bar :isLoading="loading" :progress="apiProgress" ></loading-bar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingBar from './global/LoadingBar';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import axios from 'axios';
import { Bus } from '../Bus';
//import VueSlider from 'vue-slider-component'
//import 'vue-slider-component/theme/default.css'
import ScrollPanel from 'primevue/scrollpanel';
import Tree from 'primevue/tree';
import InputNumber from 'primevue/inputnumber';

export default {
	name: 'PaneHeader',

	data () {
		return {
			ClearedKey : {
				Transports : { checked : false },
				Bus : { checked : false },
				Tramway : { checked : false },
				Métro : { checked : false },
				Gare : { checked : false },
				Aéroport : { checked : false },
				GPE : { checked : false },
				Education : { checked : false },
				Collége : { checked : false },
				'Ecole élémentaire' : { checked : false },
				'Ecole maternelle' : { checked : false },
				Lycée : { checked : false },
				'Section d\'enseignement' : { checked : false },
				'Enseignement spécialisé' : { checked : false },
				'Enseignement supérieur' : { checked : false },
				Alimentaire : { checked : false },
				Boulangerie : { checked : false },
				Epicerie : { checked : false },
				Supermarché : { checked : false },
				Hypermarché : { checked : false },
				Boucher : { checked : false },
				Primeur : { checked : false },
				'Café - Hôtel - Restaurant' : { checked : false },
				Hôtel : { checked : false },
				Bar : { checked : false },
				Café : { checked : false },
				Restaurant : { checked : false },
				Club : { checked : false },
			},
			selectedKey1: "",
			options2: [
				{
					key: "Transports",
					label: "Transports",
					data: "Transports",
					icon: "pi pi-fw pi-car",
					children: [
					{ key: "Bus", label: "Bus", data: "Bus"},
					{ key: "Tramway", label: "Tramway", data: "Tramway"},
					{ key: "Métro", label: "Métro", data: "Métro"},
					{ key: "Gare", label: "Gare", data: "Gare"},
					{ key: "Aéroport", label: "Aéroport", data: "Aéroport"},
					{ key: "GPE", label: "Grand Paris Express", data: "GPE"},
					]
				},
				{
					key: "Alimentaire",
					label: "Alimentaire",
					data: "Alimentaire",
					icon: "pi pi-fw pi-shopping-cart",
					children: [
					{ key: "Boulangerie", label: "Boulangerie", data: "Boulangerie"},
					{ key: "Epicerie", label: "Epicerie", data: "Epicerie"},
					{ key: "Supermarché", label: "Supermarché", data: "Supermarché"},
					{ key: "Hypermarché", label: "Hypermarché", data: "Hypermarché"},
					{ key: "Centre commercial", label: "Centre commercial", data: "Centre commercial"},
					{ key: "Boucher", label: "Boucher", data: "Boucher"},
					{ key: "Primeur", label: "Primeur", data: "Primeur"},
					]
				},
				{
					key: "Café - Hôtel - Restaurant",
					label: "Café - Hôtel - Restaurant",
					data: "Café - Hôtel - Restaurant",
					icon: "pi pi-fw pi-sun",
					children: [
					{ key: "Hôtel", label: "Hôtel", data: "Hôtel"},
					{ key: "Bar", label: "Bar", data: "Bar"},
					{ key: "Café", label: "Café", data: "Café"},
					{ key: "Restaurant", label: "Restaurant", data: "Restaurant"},
					{ key: "Camping", label: "Camping", data: "Camping"},
					{ key: "Camping caravane", label: "Camping caravane", data: "Camping caravane"},
					]
				},
				{
					key: "Culture",
					label: "Culture",
					data: "Culture",
					icon: "pi pi-fw pi-eye",
					children: [
					{ key: "Librairie", label: "Librairie", data: "Librairie"},
					{ key: "Marchand de journaux", label: "Marchand de journaux", data: "Marchand de journaux"},
					{ key: "Château", label: "Château", data: "Château"},
					{ key: "Musée", label: "Musée", data: "Musée"},
					{ key: "Parc", label: "Parc", data: "Parc"},
					{ key: "Théâtre", label: "Théâtre", data: "Théâtre"},
					]
				},
				{
					key: "Loisirs",
					label: "Loisirs",
					data: "Loisirs",
					icon: "pi pi-fw pi-ticket",
					children: [
					{ key: "Magasin de jouets", label: "Magasin de jouets", data: "Magasin de jouets"},
					{ key: "Fleuriste", label: "Fleuriste", data: "Fleuriste"},
					{ key: "Boutique de souvenirs", label: "Boutique de souvenirs", data: "Boutique de souvenirs"},
					{ key: "Magasin de sport", label: "Magasin de sport", data: "Magasin de sport"},
					{ key: "Papeterie", label: "Papeterie", data: "Papeterie"},
					{ key: "Tabac - Presse", label: "Tabac - Presse", data: "Tabac - Presse"},
					{ key: "Cinéma", label: "Cinéma", data: "Cinéma"},
					{ key: "Terrain de golf", label: "Terrain de golf", data: "Terrain de golf"},
					{ key: "Patinoire", label: "Patinoire", data: "Patinoire"},
					{ key: "Terrain de sport", label: "Terrain de sport", data: "Terrain de sport"},
					{ key: "Aire de jeux", label: "Aire de jeux", data: "Aire de jeux"},
					{ key: "Centre sportif", label: "Centre sportif", data: "Centre sportif"},
					{ key: "Stade", label: "Stade", data: "Stade"},
					{ key: "Piscine", label: "Piscine", data: "Piscine"},
					{ key: "Zoo", label: "Zoo", data: "Zoo"},
					]
				},
				{
					key: "Equipement de la maison",
					label: "Equipement de la maison",
					data: "Equipement de la maison",
					icon: "pi pi-fw pi-home",
					children: [
					{ key: "Magasin de bricolage", label: "Magasin de bricolage", data: "Magasin de bricolage"},
					{ key: "Jardinerie", label: "Jardinerie", data: "Jardinerie"},
					{ key: "Magasin de meubles", label: "Magasin de meubles", data: "Magasin de meubles"},
					]
				},
				{
					key: "Equipement de la personne",
					label: "Equipement de la personne",
					data: "Equipement de la personne",
					icon: "pi pi-fw pi-user",
					children: [
					{ key: "Magasin de chaussures", label: "Magasin de chaussures", data: "Magasin de chaussures"},
					{ key: "Bijouterie", label: "Bijouterie", data: "Bijouterie"},
					{ key: "Magasin de vêtements", label: "Magasin de vêtements", data: "Magasin de vêtements"},
					]
				},
				{
					key: "Equipement public",
					label: "Equipement public",
					data: "Equipement public",
					icon: "pi pi-fw pi-envelope",
					children: [
					{ key: "Police", label: "Police", data: "Police"},
					{ key: "Hôtel de ville", label: "Hôtel de ville", data: "Hôtel de ville"},
					{ key: "Tribunal", label: "Tribunal", data: "Tribunal"},
					{ key: "Bureau de poste", label: "Bureau de poste", data: "Bureau de poste"},
					{ key: "Enseignement", label: "Enseignement", data: "Enseignement"},
					{ key: "Université", label: "Université", data: "Université"},
					{ key: "Prison", label: "Prison", data: "Prison"},
					{ key: "Bâtiment public", label: "Bâtiment public", data: "Bâtiment public"},
					]
				},
				{
					key: "Santé - Beauté",
					label: "Santé - Beauté",
					data: "Santé - Beauté",
					icon: "pi pi-fw pi-id-card",
					children: [
					{ key: "Hôpital", label: "Hôpital", data: "Hôpital"},
					{ key: "Pharmacie", label: "Pharmacie", data: "Pharmacie"},
					{ key: "Cosmétique", label: "Cosmétique", data: "Cosmétique"},
					{ key: "Opticien", label: "Opticien", data: "Opticien"},
					{ key: "Coiffeur", label: "Coiffeur", data: "Coiffeur"},
					{ key: "Dentiste", label: "Dentiste", data: "Dentiste"},
					{ key: "Salon de beauté", label: "Salon de beauté", data: "Salon de beauté"},
					{ key: "Médecins", label: "Médecins", data: "Médecins"},
					{ key: "Maison de repos", label: "Maison de repos", data: "Maison de repos"},
					]
				},
				{
					key: "Services",
					label: "Services",
					data: "Services",
					icon: "pi pi-fw pi-money-bill",
					children: [
					{ key: "Vendeur auto", label: "Vendeur auto", data: "Vendeur auto"},
					{ key: "Banque", label: "Banque", data: "Banque"},
					{ key: "Magasin de téléphonie", label: "Magasin de téléphonie", data: "Magasin de téléphonie"},
					{ key: "Cimetière", label: "Cimetière", data: "Cimetière"},
					{ key: "Garderie", label: "Garderie", data: "Garderie"},
					]
				},
			],
			optionssave: [
				{
					key: "Transports",
					label: "Transports",
					data: "Transports",
					icon: "pi pi-fw pi-car",
					children: [
					{ key: "Bus", label: "Bus", data: "Bus"},
					{ key: "Tramway", label: "Tramway", data: "Tramway"},
					{ key: "Métro", label: "Métro", data: "Métro"},
					{ key: "Gare", label: "Gare", data: "Gare"},
					{ key: "Aéroport", label: "Aéroport", data: "Aéroport"},
					{ key: "GPE", label: "Grand Paris Express", data: "GPE"},
					]
				},
				{
					key: "Education",
					label: "Education",
					data: "Education",
					icon: "pi pi-fw pi-book",
					children: [
					{ key: "Collége", label: "Collége", data: "Collége"},
					{ key: "Ecole élémentaire", label: "Ecole élémentaire", data: "Ecole élémentaire"},
					{ key: "Ecole maternelle", label: "Ecole maternelle", data: "Ecole maternelle"},
					{ key: "Lycée", label: "Lycée", data: "Lycée"},
					{ key: "Section d'enseignement", label: "Section d'enseignement", data: "Section d'enseignement"},
					{ key: "Enseignement spécialisé", label: "Enseignement spécialisé", data: "Enseignement spécialisé"},
					{ key: "Enseignement supérieur", label: "Enseignement supérieur", data: "Enseignement supérieur"},
					]
				},
				{
					key: "Alimentaire",
					label: "Alimentaire",
					data: "Alimentaire",
					icon: "pi pi-fw pi-shopping-cart",
					children: [
					{ key: "Boulangerie", label: "Boulangerie", data: "Boulangerie"},
					{ key: "Epicerie", label: "Epicerie", data: "Epicerie"},
					{ key: "Supermarché", label: "Supermarché", data: "Supermarché"},
					{ key: "Hypermarché", label: "Hypermarché", data: "Hypermarché"},
					{ key: "Boucher", label: "Boucher", data: "Boucher"},
					{ key: "Primeur", label: "Primeur", data: "Primeur"},
					]
				},
				{
					key: "Café - Hôtel - Restaurant",
					label: "Café - Hôtel - Restaurant",
					data: "Café - Hôtel - Restaurant",
					icon: "pi pi-fw pi-sun",
					children: [
					{ key: "Hôtel", label: "Hôtel", data: "Hôtel"},
					{ key: "Bar", label: "Bar", data: "Bar"},
					{ key: "Café", label: "Café", data: "Café"},
					{ key: "Restaurant", label: "Restaurant", data: "Restaurant"},
					{ key: "Club", label: "Club", data: "Club"},
					]
				},
				{
					key: "Achats de proximité",
					label: "Achats de proximité",
					data: "Achats de proximité",
					icon: "pi pi-fw pi-gift",
					children: [
					{ key: "Magasin de jouets", label: "Magasin de jouets", data: "Magasin de jouets"},
					{ key: "Fleuriste", label: "Fleuriste", data: "Fleuriste"},
					{ key: "Librairie", label: "Librairie", data: "Librairie"},
					{ key: "Marchand de journaux", label: "Marchand de journaux", data: "Marchand de journaux"},
					{ key: "Tabac - Presse", label: "Tabac - Presse", data: "Tabac - Presse"},
					]
				},
				{
					key: "Shopping",
					label: "Shopping",
					data: "Shopping",
					icon: "pi pi-fw pi-shopping-bag",
					children: [
					{ key: "Magasin de bricolage", label: "Magasin de bricolage", data: "Magasin de bricolage"},
					{ key: "Jardinerie", label: "Jardinerie", data: "Jardinerie"},
					{ key: "Magasin de meubles", label: "Magasin de meubles", data: "Magasin de meubles"},
					{ key: "Marchand de vêtements", label: "Marchand de vêtements", data: "Marchand de vêtements"},
					{ key: "Friperie", label: "Friperie", data: "Friperie"},
					{ key: "Magasin de chaussures", label: "Magasin de chaussures", data: "Magasin de chaussures"},
					{ key: "Bijouterie", label: "Bijouterie", data: "Bijouterie"},
					{ key: "Vendeur de vélo", label: "Vendeur de vélo", data: "Vendeur de vélo"},
					{ key: "Magasin de sport", label: "Magasin de sport", data: "Magasin de sport"},
					]
				},
				{
					key: "Equipement public",
					label: "Equipement public",
					data: "Equipement public",
					icon: "pi pi-fw pi-envelope",
					children: [
					{ key: "Police", label: "Police", data: "Police"},
					{ key: "Ambassade", label: "Ambassade", data: "Ambassade"},
					{ key: "Hôtel de ville", label: "Hôtel de ville", data: "Hôtel de ville"},
					{ key: "Tribunal", label: "Tribunal", data: "Tribunal"},
					{ key: "Bureau de poste", label: "Bureau de poste", data: "Bureau de poste"},
					]
				},
				{
					key: "Santé",
					label: "Santé",
					data: "Santé",
					icon: "pi pi-fw pi-inbox",
					children: [
					{ key: "Hôpital", label: "Hôpital", data: "Hôpital"},
					{ key: "Pharmacie", label: "Pharmacie", data: "Pharmacie"},
					{ key: "Opticien", label: "Opticien", data: "Opticien"},
					{ key: "Dentiste", label: "Dentiste", data: "Dentiste"},
					{ key: "Médecin", label: "Médecin", data: "Médecin"},
					]
				},
				{
					key: "Beauté",
					label: "Beauté",
					data: "Beauté",
					icon: "pi pi-fw pi-user",
					children: [
					{ key: "Cosmétique", label: "Cosmétique", data: "Cosmétique"},
					{ key: "Coiffeur", label: "Coiffeur", data: "Coiffeur"},
					{ key: "Salon de beauté", label: "Salon de beauté", data: "Salon de beauté"},
					]
				},
				{
					key: "Services",
					label: "Services",
					data: "Services",
					icon: "pi pi-fw pi-money-bill",
					children: [
					{ key: "Distributeurs", label: "Distributeurs", data: "Distributeurs"},
					{ key: "Location de voiture", label: "Location de voiture", data: "Location de voiture"},
					{ key: "Vétérinaire", label: "Vétérinaire", data: "Vétérinaire"},
					{ key: "Vendeur auto", label: "Vendeur auto", data: "Vendeur auto"},
					{ key: "Agence de voyage", label: "Agence de voyage", data: "Agence de voyage"},
					{ key: "Garage", label: "Garage", data: "Garage"},
					{ key: "Blanchisserie", label: "Blanchisserie", data: "Blanchisserie"},
					{ key: "Banque", label: "Banque", data: "Banque"},
					]
				},
			],
			loading: true,
			visible: false,
			Aidevisible: false,
			Appvisible: false,
			apiProgress: 0,
			ContactVisible: false,
			formations: false,
			affichage_prix_etat : 'tout',
			affichage_prix_tva : 'pleine',
			affichage_prix_pk : 'inclus',
			nbannee: 3,
			termine : true,
			actif : true,
			projet : true,
			psla : true,
			brs : true,
			anru: true,
			aucun: true,
			autres : true,
			col: true,
			ind: true,
			contour_com: false,
			qpv: false,
			zac: false,
			qpv_trois: false,
			fond: 2,
			tvap: true,
			tvar: true,
			contour_poi : [],
			
			typeId : 2,
			area : 60,
			rooms : 3,
			condition : 3,

			legende : 'prix',

			seniors: true,
			etudiants: false,
			jeunes: false,
			sociales: false,
			tourisme: false,
			toutage: false,

			beguinage: true,
			copropriete: true,
			ephad: true,
			maison_repos: true,
			maison_repos_soin: true,
			res_autonomie: true,
			rss_libre: true,
			village_senior_prive: true,
			village_senior_social: true,
			marpa: true,

			rse_social: false,
			rse_mixte: false,
			rse_libre: false,
			fjt: false,
			chrs: false,
			hotel: false,
			res_tourisme: false,
			hotel_residence: false,
			coliving: false,
			habitat_partage: false,
			colocation: false,
			modif: false,
			ggsv: false,
			veb_avec: true,
			veb_sans: true,
		}
	},

	watch: {
		'app.imggooglestreetview': function(v) {
			if ((this.app.adr_init.type != 'city') && (this.app.adr_init.type != 'boundingbox') && (this.app.adr_init.type != 'lasso')) {
				this.ggsv = v;
			} else this.ggsv = false;
		},

		'app.view': function() {
			this.visible = false
			this.Aidevisible = false
			this.Appvisible = false
			this.ContactVisible = false
		},

		'app.progress_bar': function(v) {
			this.loading = v;
		},

		'app.loading_yanport': function(v) {
			this.apiProgress = (100/34) * v;
		},
		
		// Gestion préférences
		//'app.preferences': function(v) {
		//	this.InitFromPreferences(v)
		//},

		// eslint-disable-next-line
		'app.filters': function(v) {
		}
	},

	methods: {
		getLienTutoriel: function() {
			return "https://www.myadequation.fr/EFOCUS/Tutoriels.html?" + _.now();
		},

		getLienAide: function() {
			return "https://www.myadequation.fr/EFOCUS/Aide.html?" + _.now();
		},

		OuvreFicheTerritoire: function() {
			if (this.app.viewlayer == 'insee') {
				this.$store.dispatch('set_view_layer', { view: '' })
			} else {
				this.$store.dispatch('set_view_layer', {view: 'insee'});
			}
		},
		
		// Gestion préférences
		//ChargeDefaut: function() {
		//	this.InitFromPreferences(this.app.preferences_defaut)
		//},

/*
		record_pref: function() {
			let p = {
				neuf: {
					filtres : {
						fu: [],
						etat: [],
						tva: [],
						financements: [],
						anciennete : 3,
					},
					legende : 'prix', // gamme, etat
					listing: {
						etat : 'tout', // tout, vente, offre
						tva : 'reduite', // pleine, reduite
						parking: 'inclus', // inclus, sans
					}
				},
				rs: {
					filtres : {
						cible: [], 
						nature: [],
					},
				},
				ancien: {
					estimation: {
						typeId: 2,
						area: 60, 
						rooms: 3,
						condition: 3,
					}
				},
				locatif: {
					geo: 'iris' // iris, ville, epci
				},
				adresse_niv1: [],
				adresse_niv2: [],
				carto: {
					fond: 'couleurs', // couleurs, gris, satellite
					layers : [], // zac, qpv, qpv_300, communes
					poi : [],
				},
				livedata: ['statistiques_secteur', 'synoptique_programmes', 'synoptique_projets', 'optimisation', 'rs', 'ancien', 'locatif', 'adresse'],
			}

			p.neuf.listing.etat = this.affichage_prix_etat
			p.neuf.listing.tva = this.affichage_prix_tva
			p.neuf.listing.parking = this.affichage_prix_pk

			p.ancien.estimation.typeId = this.typeId
			p.ancien.estimation.area = this.area
			p.ancien.estimation.rooms = this.rooms
			p.ancien.estimation.condition = this.condition

			if (this.seniors) p.rs.filtres.cible.push('seniors')
			if (this.etudiants) p.rs.filtres.cible.push('etudiants')
			if (this.jeunes) p.rs.filtres.cible.push('jeunes')
			if (this.sociales) p.rs.filtres.cible.push('sociales')
			if (this.tourisme) p.rs.filtres.cible.push('tourisme')
			if (this.toutage) p.rs.filtres.cible.push('toutage')

			if (this.beguinage) p.rs.filtres.nature.push('beguinage')
			if (this.copropriete) p.rs.filtres.nature.push('copropriete')
			if (this.ephad) p.rs.filtres.nature.push('ephad')
			if (this.maison_repos) p.rs.filtres.nature.push('maison_repos')
			if (this.maison_repos_soin) p.rs.filtres.nature.push('maison_repos_soin')
			if (this.res_autonomie) p.rs.filtres.nature.push('res_autonomie')
			if (this.rss_libre) p.rs.filtres.nature.push('rss_libre')
			if (this.village_senior_prive) p.rs.filtres.nature.push('village_senior_prive')
			if (this.village_senior_social) p.rs.filtres.nature.push('village_senior_social')
			if (this.marpa) p.rs.filtres.nature.push('marpa')
			if (this.rse_social) p.rs.filtres.nature.push('rse_social')
			if (this.rse_mixte) p.rs.filtres.nature.push('rse_mixte')
			if (this.rse_libre) p.rs.filtres.nature.push('rse_libre')
			if (this.fjt) p.rs.filtres.nature.push('fjt')
			if (this.chrs) p.rs.filtres.nature.push('chrs')
			if (this.hotel) p.rs.filtres.nature.push('hotel')
			if (this.res_tourisme) p.rs.filtres.nature.push('res_tourisme')
			if (this.hotel_residence) p.rs.filtres.nature.push('hotel_residence')
			if (this.coliving) p.rs.filtres.nature.push('coliving')
			if (this.habitat_partage) p.rs.filtres.nature.push('habitat_partage')
			if (this.colocation) p.rs.filtres.nature.push('colocation')

			if (this.psla) p.neuf.filtres.financements.push('psla')
			if (this.brs) p.neuf.filtres.financements.push('brs')
			if (this.anru) p.neuf.filtres.financements.push('anru')
			if (this.aucun) p.neuf.filtres.financements.push('aucun')
			if (this.autres) p.neuf.filtres.financements.push('autres')

			if (this.tvar) p.neuf.filtres.tva.push('reduite')
			if (this.tvap) p.neuf.filtres.tva.push('pleine')

			if (this.veb_avec) p.neuf.filtres.tva.push('veb_avec')
			if (this.veb_sans) p.neuf.filtres.tva.push('veb_sans')

			if (this.col) p.neuf.filtres.fu.push('collectif')
			if (this.ind) p.neuf.filtres.fu.push('individuel')

			if (this.termine) p.neuf.filtres.etat.push('termines')
			if (this.actif) p.neuf.filtres.etat.push('actifs')
			if (this.projet) p.neuf.filtres.etat.push('projets')

			if (this.fond == 1) p.carto.fond = 'gris'
			if (this.fond == 2) p.carto.fond = 'couleurs'
			if (this.fond == 3) p.carto.fond = 'satellite'

			p.neuf.filtres.anciennete = this.nbannee

			if (this.contour_com) p.carto.layers.push('communes')
			if (this.qpv) p.carto.layers.push('qpv')
			if (this.zac) p.carto.layers.push('zac')
			if (this.qpv_trois) p.carto.layers.push('qpv_300')
			p.carto.poi = this.contour_poi
			p.neuf.legende = this.legende
			this.modif = false
			this.$store.dispatch('set_preferences', { preferences: p, init : false })
			this.$store.dispatch('record_pref', { })
			this.$store.dispatch('setContours', {type: 'poi', contours: this.contour_poi})
		},
*/

		// Gestion préférences
		/*
		record_pref: function() {
			const p = this.initializePreferences();

			this.updateObject(p.neuf.listing, {
				etat: this.affichage_prix_etat,
				tva: this.affichage_prix_tva,
				parking: this.affichage_prix_pk
			});

			this.updateObject(p.ancien.estimation, {
				typeId: this.typeId,
				area: this.area,
				rooms: this.rooms,
				condition: this.condition
			});

			const rsCibleFilters = ['seniors', 'etudiants', 'jeunes', 'sociales', 'tourisme', 'toutage'];
			const rsNatureFilters = ['beguinage', 'copropriete', 'ephad', 'maison_repos', 'maison_repos_soin', 'res_autonomie', 'rss_libre', 'village_senior_prive', 'village_senior_social', 'marpa', 'rse_social', 'rse_mixte', 'rse_libre', 'fjt', 'chrs', 'hotel', 'res_tourisme', 'hotel_residence', 'coliving', 'habitat_partage', 'colocation'];
			const neufFinancementFilters = ['psla', 'brs', 'anru', 'aucun', 'autres'];
			const neufTvaFilters = ['tvar:reduite', 'tvap:pleine', 'veb_avec', 'veb_sans'];
			const neufFuFilters = ['col:collectif', 'ind:individuel'];
			const neufEtatFilters = ['termine:termines', 'actif:actifs', 'projet:projets'];
			const cartoLayers = ['contour_com:communes', 'qpv', 'zac', 'qpv_trois:qpv_300'];

			this.addFilters(rsCibleFilters, this, p.rs.filtres.cible);
			this.addFilters(rsNatureFilters, this, p.rs.filtres.nature);
			this.addFilters(neufFinancementFilters, this, p.neuf.filtres.financements);
			this.addFilters(neufTvaFilters, this, p.neuf.filtres.tva);
			this.addFilters(neufFuFilters, this, p.neuf.filtres.fu);
			this.addFilters(neufEtatFilters, this, p.neuf.filtres.etat);
			this.addFilters(cartoLayers, this, p.carto.layers);

			p.neuf.filtres.anciennete = this.nbannee;
			p.carto.fond = {1: 'gris', 2: 'couleurs', 3: 'satellite'}[this.fond] || 'couleurs';
			p.carto.poi = this.contour_poi;
			p.neuf.legende = this.legende;

			this.modif = false;
			this.$store.dispatch('set_preferences', { preferences: p, init: false });
			this.$store.dispatch('record_pref', {});
			this.$store.dispatch('setContours', { type: 'poi', contours: this.contour_poi });
		},
		*/
		// Gestion préférences
		//initializePreferences: function() {
		//	return {
		//		neuf: { filtres: { fu: [], etat: [], tva: [], financements: [], anciennete: 3 }, legende: 'prix', listing: { etat: 'tout', tva: 'reduite', parking: 'inclus' }},
		//		rs: { filtres: { cible: [], nature: [] }},
		//		ancien: { estimation: { typeId: 2, area: 60, rooms: 3, condition: 3 }},
		//		locatif: { geo: 'iris' },
		//		adresse_niv1: [],
		//		adresse_niv2: [],
		//		carto: { fond: 'couleurs', layers: [], poi: [] },
		//		livedata: ['statistiques_secteur', 'synoptique_programmes', 'synoptique_projets', 'optimisation', 'rs', 'ancien', 'locatif', 'adresse']
		//	};
		//},

		updateObject: function(target, values) {
			Object.assign(target, values);
		},

		addFilters: function(filters, context, targetArray) {
			filters.forEach(filter => {
				const [key, value] = filter.split(':');
				if (context[key] !== undefined && context[key] !== false) {
					targetArray.push(value || key);
				}
			});
		},

		poiChange2: function () {
			let value = [];
			for (let attribut in this.selectedKey1) {
				// eslint-disable-next-line 
				if (this.selectedKey1.hasOwnProperty(attribut)) {
					if (this.selectedKey1[attribut].checked) value.push(attribut)
				}
			}
			this.contour_poi = value;
			gtag('event', 'map_layer_poi', {'event_category': 'map','event_label': 'map_layer_poi'});
			this.$store.dispatch('send_event_backend', {module: 'layer', action: 'poi', option: value});
			this.modif=true
		},

		poiSelect2: function () {			
			this.poiChange2();
		},

		poiDeselect2: function () {			
			this.poiChange2();
		},

		setf: function() {
			this.$store.dispatch('set_fond_carte', {fond: this.fond})
		},

		setcontours: function(type, b) {
			gtag('event', 'map_layer_zone', {'event_category': 'map','event_label': 'map_layer_zone'});
			this.$store.dispatch('send_event_backend', {module: 'layer', action: 'zone', option: type});
			this.$store.dispatch('setContours', {type: type, contours: b})
		},

		toggle_switch: function(filter, value) {
			this.modif=true
			switch (filter) {
				case 'forme':
					gtag('event', 'filtre_neuf_fu', {'event_category': 'filtre','event_label': 'filtre_neuf_fu'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'forme urbaine'});
					switch (value) {
						case 'collectif':
						this.col = !this.col
						break;
						default:
						this.ind = !this.ind
						break;
					}
					break;
				case 'commercialisation':
					gtag('event', 'filtre_neuf_etat', {'event_category': 'filtre','event_label': 'filtre_neuf_etat'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'etat de commercialisation'});
					switch (value) {
						case 'actif':
						this.actif = !this.actif
						break;
						case 'projet':
						this.projet = !this.projet
						break;
						default:
						this.termine = !this.termine
						break;
					}
					break;
				case 'financement':
					gtag('event', 'filtre_neuf_financements', {'event_category': 'filtre','event_label': 'filtre_neuf_financements'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'financement'});
					switch (value) {
						case 'psla':
						this.psla = !this.psla
						break;
						case 'anru':
						this.anru = !this.anru
						break;
						case 'aucun':
						this.aucun = !this.aucun
						break;
						case 'brs':
						this.brs = !this.brs
						break;
						default:
						this.autres = !this.autres
						break;
					}
					break;
				case 'tva':
					gtag('event', 'filtre_neuf_tva', {'event_category': 'filtre','event_label': 'filtre_neuf_tva'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'tva'});
					switch (value) {
						case 'reduite':
						this.tvar = !this.tvar
						break;
						default:
						this.tvap = !this.tvap
						break;
					}
					break;
				case 'veb':
					gtag('event', 'filtre_neuf_veb', {'event_category': 'filtre','event_label': 'filtre_neuf_veb'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'veb'});
					switch (value) {
						case 'avec':
						this.veb_avec = !this.veb_avec
						break;
						default:
						this.veb_sans = !this.veb_sans
						break;
					}
					break;
				case 'affichage_prix_etat':
					this.affichage_prix_etat = value
					break;
				case 'affichage_prix_tva':
					this.affichage_prix_tva = value
					break;
				case 'affichage_prix_pk':
					this.affichage_prix_pk = value
					break;
				default:
				break;
			}
		},

		changeLanguage(lang) {
			this.$i18n.locale = lang;
		},
		RetourRecherche() {
			Bus.$emit('focussearch');
			document.getElementById('search_input').value = "";
			document.getElementById('search_input').focus();
			const keydownEvent = new KeyboardEvent('keydown', {
				key: 'Enter',
				keyCode: 13, // Le code de la touche "Enter" est 13
				code: 'Enter', // Le code identifiant de la touche
				bubbles: true, // Permet à l'événement de se propager
			});
			document.getElementById('search_input').dispatchEvent(keydownEvent);
		},

		toggle_compte: function() {
			// Nothing
		},
		
		showContact: function() {
			this.ContactVisible = !this.ContactVisible
			if (this.ContactVisible) this.Aidevisible = false;this.Appvisible = false;this.visible = false;
			if (this.ContactVisible) {
				window.addEventListener('click', this.clickOutsideHandlerContact);
			} else {
				window.removeEventListener('click', this.clickOutsideHandlerContact);
			}
		},

		show: function() {
			this.visible = !this.visible
			if (this.visible) this.Aidevisible = false;this.Appvisible = false; this.ContactVisible = false;
			if (this.visible) {
				window.addEventListener('click', this.clickOutsideHandlerCompte);
			} else {
				window.removeEventListener('click', this.clickOutsideHandlerCompte);
			}
		},

		Aideshow: function() {
			this.Aidevisible = !this.Aidevisible
			if (this.Aidevisible) this.visible = false;this.Appvisible = false; this.ContactVisible = false;
			if (this.Aidevisible) {
				window.addEventListener('click', this.clickOutsideHandlerAide);
			} else {
				window.removeEventListener('click', this.clickOutsideHandlerAide);
			}
		},

		Appshow: function() {
			this.Appvisible = !this.Appvisible
			if (this.Appvisible) this.visible = false;this.Aidevisible = false; this.ContactVisible = false;
			if (this.Appvisible) {
				window.addEventListener('click', this.clickOutsideHandler);
			} else {
				window.removeEventListener('click', this.clickOutsideHandler);
			}
		},

		clickOutsideHandler(event) {
			if (!this.$el.contains(event.target)) {
				try {
					this.FermeMenu();
				} catch (error) {
					// Erreur
				}
			}
		},

		clickOutsideHandlerAide(event) {
			if (!this.$el.contains(event.target)) {
				try {
					this.FermeAide();
				} catch (error) {
					// Erreur
				}
			}
		},

		clickOutsideHandlerCompte(event) {
			if (!this.$el.contains(event.target)) {
				try {
					this.FermeCompte();
				} catch (error) {
					// Erreur
				}
			}
		},

		clickOutsideHandlerContact(event) {
			if (!this.$el.contains(event.target)) {
				try {
					this.FermeContact();
				} catch (error) {
					// Erreur
				}
			}
		},

		FermeMenu: function() {
			this.Appvisible = false;
			window.removeEventListener('click', this.clickOutsideHandler);
		},

		FermeAide: function() {
			this.Aidevisible = false;
			window.removeEventListener('click', this.clickOutsideHandlerAide);
		},

		FermeCompte: function() {
			this.visible = false;
			window.removeEventListener('click', this.clickOutsideHandlerCompte);
		},

		FermeContact: function() {
			this.ContactVisible = false;
			window.removeEventListener('click', this.clickOutsideHandlerContact);
		},

		signout: function() {
			this.$store.dispatch('signout')
		},
		sliderChangeAnnee: function(e, name) {
			if (name=='annees') gtag('event', 'filtre_neuf_annees', {'event_category': 'filtre','event_label': 'filtre_neuf_annees'}); this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'annee'});
			this.$store.dispatch('set_filters', { veb_avec: this.veb_avec, veb_sans: this.veb_sans, termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, tvap: this.tvap, tvar: this.tvar, annee_actif: this.jusqua-this.nbannee, psla: this.psla, brs: this.brs, autres: this.autres, anru: this.anru, aucun: this.aucun, affichage_prix_etat: this.affichage_prix_etat, affichage_prix_tva: this.affichage_prix_tva, affichage_prix_pk: this.affichage_prix_pk})
		},
/*
		InitFromPreferences:function(p) {
			this.contour_poi = p.carto.poi
			this.selectedKey1 = []
			for (let attribut in p.carto.poi) {
				// eslint-disable-next-line 
				let sa = p.carto.poi[attribut]
				this.selectedKey1[sa] = {partialChecked: false, checked: true}
				for (let i in this.options2) {
					for (let j in this.options2[i].children) {
						if (this.options2[i].children[j].key == sa) {
							this.selectedKey1[this.options2[i].key] = {partialChecked: true, checked: false}
						}
					}
				}
			}

			for (let i in this.options2) {
				let b = true
				for (let j in this.options2[i].children) {
					if (this.selectedKey1[this.options2[i].children[j].key]) {
						if (!this.selectedKey1[this.options2[i].children[j].key].checked) {
							b = false
							break;
						}
					} else {
						b = false
						break;
					}
				}
				if (b) {
					this.selectedKey1[this.options2[i].key] = {partialChecked: false, checked: true}
				}
			}
			

			this.affichage_prix_etat = p.neuf.listing.etat
			this.affichage_prix_tva = p.neuf.listing.tva
			this.affichage_prix_pk = p.neuf.listing.parking
			this.psla = p.neuf.filtres.financements.indexOf('psla')>-1
			this.brs = p.neuf.filtres.financements.indexOf('brs')>-1
			this.anru = p.neuf.filtres.financements.indexOf('anru')>-1
			this.aucun = p.neuf.filtres.financements.indexOf('aucun')>-1
			this.autres =  p.neuf.filtres.financements.indexOf('autres')>-1
			this.tvar = p.neuf.filtres.tva.indexOf('reduite')>-1
			this.tvap = p.neuf.filtres.tva.indexOf('pleine')>-1
			this.col = p.neuf.filtres.fu.indexOf('collectif')>-1,
			this.ind = p.neuf.filtres.fu.indexOf('individuel')>-1
			this.termine = p.neuf.filtres.etat.indexOf('termines')>-1
			this.actif = p.neuf.filtres.etat.indexOf('actifs')>-1
			this.projet = p.neuf.filtres.etat.indexOf('projets')>-1
			this.nbannee = p.neuf.filtres.anciennete
			if (p.carto.fond == 'gris') this.fond = 1
			if (p.carto.fond == 'couleurs') this.fond = 2
			if (p.carto.fond == 'satellite') this.fond = 3


			if (p.neuf.filtres.veb) {
				this.veb_avec = p.neuf.filtres.veb.indexOf('veb_avec')>-1
				this.veb_sans = p.neuf.filtres.veb.indexOf('veb_sans')>-1
			}

			this.contour_com = p.carto.layers.indexOf('communes')>-1
			this.qpv = p.carto.layers.indexOf('qpv')>-1
			this.zac = p.carto.layers.indexOf('zac')>-1
			this.qpv_trois = p.carto.layers.indexOf('qpv_300')>-1
			this.legende = p.neuf.legende

			this.typeId = p.ancien.estimation.typeId
			this.area = p.ancien.estimation.area
			this.rooms = p.ancien.estimation.rooms
			this.condition = p.ancien.estimation.condition

			this.seniors = p.rs.filtres.cible.indexOf('seniors')>-1
			this.etudiants = p.rs.filtres.cible.indexOf('etudiants')>-1
			this.jeunes = p.rs.filtres.cible.indexOf('jeunes')>-1
			this.sociales = p.rs.filtres.cible.indexOf('sociales')>-1
			this.tourisme = p.rs.filtres.cible.indexOf('tourisme')>-1
			this.toutage = p.rs.filtres.cible.indexOf('toutage')>-1

			this.beguinage = p.rs.filtres.nature.indexOf('beguinage')>-1
			this.copropriete = p.rs.filtres.nature.indexOf('copropriete')>-1
			this.ephad = p.rs.filtres.nature.indexOf('ephad')>-1
			this.maison_repos = p.rs.filtres.nature.indexOf('maison_repos')>-1
			this.maison_repos_soin = p.rs.filtres.nature.indexOf('maison_repos_soin')>-1
			this.res_autonomie = p.rs.filtres.nature.indexOf('res_autonomie')>-1
			this.rss_libre = p.rs.filtres.nature.indexOf('rss_libre')>-1
			this.village_senior_prive = p.rs.filtres.nature.indexOf('village_senior_prive')>-1
			this.village_senior_social = p.rs.filtres.nature.indexOf('village_senior_social')>-1
			this.marpa = p.rs.filtres.nature.indexOf('marpa')>-1
			this.rse_social = p.rs.filtres.nature.indexOf('rse_social')>-1
			this.rse_mixte = p.rs.filtres.nature.indexOf('rse_mixte')>-1
			this.rse_libre = p.rs.filtres.nature.indexOf('rse_libre')>-1
			this.fjt = p.rs.filtres.nature.indexOf('fjt')>-1
			this.chrs = p.rs.filtres.nature.indexOf('chrs')>-1
			this.hotel = p.rs.filtres.nature.indexOf('hotel')>-1
			this.res_tourisme = p.rs.filtres.nature.indexOf('res_tourisme')>-1
			this.hotel_residence = p.rs.filtres.nature.indexOf('hotel_residence')>-1
			this.coliving = p.rs.filtres.nature.indexOf('coliving')>-1
			this.habitat_partage = p.rs.filtres.nature.indexOf('habitat_partage')>-1
			this.colocation = p.rs.filtres.nature.indexOf('colocation')>-1
			this.modif=true
		}
*/

		// Gestion préférences
		/*
		InitFromPreferences: function(p) {
			this.contour_poi = p.carto.poi;
			this.selectedKey1 = [];
			const fondMap = { 'gris': 1, 'couleurs': 2, 'satellite': 3 };

			// Initialize selected keys and update partialChecked
			p.carto.poi.forEach(sa => {
				this.selectedKey1[sa] = { partialChecked: false, checked: true };
				this.options2.forEach(option => {
					option.children.forEach(child => {
						if (child.key === sa) {
							this.selectedKey1[option.key] = { partialChecked: true, checked: false };
						}
					});
				});
			});

			// Update checked status for parent keys
			this.options2.forEach(option => {
				if (option.children.every(child => this.selectedKey1[child.key] && this.selectedKey1[child.key].checked)) {
					this.selectedKey1[option.key] = { partialChecked: false, checked: true };
				}
			});

			// Set listing preferences
			['etat', 'tva', 'parking'].forEach(key => this[`affichage_prix_${key}`] = p.neuf.listing[key]);

			// Set neuf filters
			['psla', 'brs', 'anru', 'aucun', 'autres'].forEach(key => this[key] = p.neuf.filtres.financements.includes(key));
			['reduite', 'pleine'].forEach(key => this[`tva${key === 'pleine' ? 'p' : 'r'}`] = p.neuf.filtres.tva.includes(key));
			['collectif', 'individuel'].forEach(key => this[key === 'collectif' ? 'col' : 'ind'] = p.neuf.filtres.fu.includes(key));
			['termines', 'actifs', 'projets'].forEach(key => this[key.slice(0, -1)] = p.neuf.filtres.etat.includes(key));
			this.nbannee = p.neuf.filtres.anciennete;
			if (p.neuf.filtres.veb) {
				this.veb_avec = p.neuf.filtres.veb.includes('veb_avec');
				this.veb_sans = p.neuf.filtres.veb.includes('veb_sans');
			}

			// Set carto preferences
			this.fond = fondMap[p.carto.fond] || this.fond;
			['communes', 'qpv', 'zac', 'qpv_300'].forEach(key => this[key.replace('qpv_300', 'qpv_trois')] = p.carto.layers.includes(key));

			// Set estimation preferences
			['typeId', 'area', 'rooms', 'condition'].forEach(key => this[key] = p.ancien.estimation[key]);

			// Set rs filters
			['seniors', 'etudiants', 'jeunes', 'sociales', 'tourisme', 'toutage'].forEach(key => this[key] = p.rs.filtres.cible.includes(key));
			['beguinage', 'copropriete', 'ephad', 'maison_repos', 'maison_repos_soin', 'res_autonomie', 'rss_libre', 'village_senior_prive', 'village_senior_social', 'marpa', 'rse_social', 'rse_mixte', 'rse_libre', 'fjt', 'chrs', 'hotel', 'res_tourisme', 'hotel_residence', 'coliving', 'habitat_partage', 'colocation'].forEach(key => this[key] = p.rs.filtres.nature.includes(key));

			this.legende = p.neuf.legende;
			this.modif = true;
		}
			*/

	},


	computed: Object.assign(mapGetters([ 'app', 'user']), {
		getformations: function() {
			return this.formations
		}
	}),
	
	mounted: function() {
		// Gestion préférences
		// this.InitFromPreferences(this.app.preferences)
		

		let that = this
		let url = 'https://www.myadequation.fr/EFOCUS/formations.json' + "?" + _.now()
		axios.get(url)
		.then(function(response) {
			if (response && response.data) {
				that.formations = response.data
			}
		})
	},
	
	// eslint-disable-next-line
	components: { LoadingBar, Button, Dialog, /*VueSlider,*/ ScrollPanel, Tree, InputNumber }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.loader
	display inline-block
	background main_color
	border none
	border-radius 100%
	color #6c21f9
	.icon
		display block
		animation rotate 1.5s infinite linear

.btnhover
	&:hover
		color palette2


.help-button
	width 190px
	top 0
	color #fff
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	&:hover
		color palette2

.adresse
	vertical-align middle
	color white
	padding-left 10px
	margin-left 20px
	border-left 2px solid white

.opacity-enter, .opacity-leave-to
	opacity 0

.header
	z-index 600
	vertical-align middle	
	position absolute
	top 0
	height 60px
	width 100%

.m
	display block
	background white
	cursor pointer
	&:hover
		background #f5f5f5

.meta
	font-size 0.9rem
	font-weight 500
	color black
	line-height 40px

.dropdown-menu
	left auto
	right 0
	position absolute
	top 100%
	z-index 20
	margin-top 55px
	box-shadow 0 5px 16px hsl(0deg, 0%, 93%)
	border-color hsl(0deg, 0%, 93%)
	padding-top 0
	min-width 260px
	display block
.dropdown-content
	background-color #fff
	border-radius 4px
	box-shadow none
	padding-bottom 0.5rem
	border 1px solid #ccc
	padding-top 0
	overflow hidden

.dropdown-head
	display flex
	align-items center
	padding 28px 16px
	margin-bottom 12px
	background #f1f1f1

.dropdown.is-spaced .dropdown-item.is-media
	display flex
	align-items center
	
.dropdown .dropdown-menu .dropdown-item
	color hsl(232deg, 14%, 68%)
	font-family "Roboto", sans-serif
.dropdown-item
	padding 0.5rem 1rem
	font-size 0.95rem
	color hsl(232deg, 14%, 68%)
	transition all 0.3s
a.dropdown-item, button.dropdown-item
	padding-right 3rem
	text-align inherit
	white-space nowrap
	width 100%

.dropdown-item
	color hsl(0deg, 0%, 29%)
	display block
	font-size 0.875rem
	line-height 1.5
	padding 0.375rem 1rem
	position relative

.dropdown-divider
	background-color hsl(0deg, 0%, 93%)
	border none
	display block
	height 1px
	margin 0.5rem 0

.button.v-button:not([disabled])
	cursor pointer

.button.is-fullwidth
	display flex
	width 100%

.button.is-primary
	background-color palette0
	border-color transparent
	color #ededed
	&:hover
		opacity 0.9
		box-shadow var(--primary-box-shadow)

.button
	-webkit-appearance none
	align-items center
	border 1px solid transparent
	border-radius 4px
	box-shadow none
	display inline-flex
	font-size 1rem
	height 2.5em
	justify-content flex-start
	line-height 1.5
	padding-bottom calc(0.5em - 1px)
	padding-left calc(0.75em - 1px)
	padding-right calc(0.75em - 1px)
	padding-top calc(0.5em - 1px)
	position relative
	vertical-align top

.button.v-button
	padding 8px 22px
	height 38px
	line-height 1.1
	font-size 0.95rem
	font-family "Roboto", sans-serif
	transition all 0.3s
	justify-content center
	text-align center
	white-space nowrap
	border-width 1px

.v-avatar.is-large
	width 40px
	height 40px
	border-radius 20px
	border 1px solid #CCCCCC
	line-height 40px

.avatar
	border-radius 20px
	line-height 40px
	

.popup
	display block
	margin auto
	position absolute
	top 50vh
	left 0
	right 0
	bottom 0
	width 800px
	height 400px
	z-index 20
	box-shadow 0 5px 16px hsl(0deg, 0%, 93%)
	border-color hsl(0deg, 0%, 93%)
.bleu
	color blue
	cursor pointer
.Nom
	font-size 18px
	font-weight 600

.choix
	padding-bottom 20px

.group_layer
	padding-bottom 20px

.buttons-fixed
	position fixed
	bottom 10px

.checkbox
	left 0px !important

.radiobox
	width 20px
	height 20px

.checkbox
	position absolute
	left 0px
	top 4px
	.text
		padding-left 20px
		display block
		line-height 24px
		white-space nowrap
	&.checked
		background-color transparent !important
		.chk .icon
			color palette0
			display block
	&:hover
		border-color palette2
		.chk
			background-color #e5e5e5
	.chk
		float left
		width 24px
		height 24px
		//SDU margin 0 8px 0 0
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
.vb-dragger
	display none

.logo
	background palette0
	border 1px solid white

.v-avatar.is-medium {
	max-width: 50px;
	min-width: 50px;
}

.v-avatar.is-medium .avatar.is-fake, .v-avatar.is-medium .avatar.is-more {
	width: 40px;
	min-width: 40px;
	height: 40px;
}

.v-avatar.is-medium .avatar {
	width: 50px;
	min-width: 50px;
	height: 50px;
}

.v-avatar .avatar.is-fake {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #b388ff;
	color: white
	border-radius: var(--radius-rounded);
}

.v-avatar .avatar {
	width: 40px;
	min-width: 40px;
	height: 40px;
	object-fit: cover;
}

.l16 {
	line-height: 16px;
}

</style>
