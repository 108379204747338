import { render, staticRenderFns } from "./PaneAncienMutation.vue?vue&type=template&id=42813656&scoped=true"
import script from "./PaneAncienMutation.vue?vue&type=script&lang=js"
export * from "./PaneAncienMutation.vue?vue&type=script&lang=js"
import style0 from "./PaneAncienMutation.vue?vue&type=style&index=0&id=42813656&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42813656",
  null
  
)

export default component.exports