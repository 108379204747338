 <template>
	<div class="stat-box" v-if="app.livedata.synoptique_rs.length">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-bluegray-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-list text-bluegray-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('synoptique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('des_residences_services') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<DataTable showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" dataKey="id" sortMode="single" sortField="secteur" responsiveLayout="scroll" class="synoptique" id="synors"
				:selection.sync="selectedPrograms"
				:value="synojson" 
				:sortOrder="1"
				:expandableRowGroups="true" :expandedRowGroups.sync="expandedRowGroups"
			>
				<ColumnGroup type="header">
					<Row>
						<Column header="" :rowspan="2" :headerStyle="{width: '30px'}">
							<template #header>
								<div class="table-header">
									<Button icon="pi pi-trash" class="p-button-danger p-button-sm" @click="supprime_selection()" :disabled="!selectedPrograms || !selectedPrograms.length" />
								</div>
							</template>
						</Column>
						<Column field="id" header="ID" :rowspan="2"/>
						<Column field="nom" :header="$t('residence')" :rowspan="2"/>
						<Column field="url_photo" :header="$t('image')" :rowspan="2"/>
						<Column field="adresse" :header="$t('adresse')" :rowspan="2"/>
						<Column field="gestionnaire" :header="$t('gestionnaire')" :rowspan="2"/>
						<Column field="capacite" :header="$t('capacite')" :rowspan="2"/>
						<Column field="ouverture" :header="$t('ouverture')" :rowspan="2"/>
						<Column field="cible" :header="$t('cible')" :rowspan="2"/>
						<Column field="nature" :header="$t('nature')" :rowspan="2"/>
					</Row>
				</ColumnGroup>
				<Column selectionMode="multiple" :styles="{'min-width': '3rem', 'text-align': 'center' }"></Column>
				<Column field="id" header="ID"></Column>
				<Column field="nom" :header="$t('residence')"></Column>
				<Column field="url_photo" :header="$t('image')">
					<template #body="{data}">
						<div style="text-align: center;"><ImagePreview :src="data.url_photo" alt="Image" height="50" preview /></div>
					</template>
				</Column>
				<Column field="adresse" :header="$t('adresse')"></Column>
				<Column field="gestionnaire" :header="$t('gestionnaire')"></Column>
				<Column field="capacite" :header="$t('capacite')"></Column>
				<Column field="ouverture" :header="$t('Ouverture')"></Column>
				<Column field="cible" :header="$t('cible')"></Column>
				<Column field="nature" :header="$t('nature')"></Column>					
				<template #groupheader="slotProps">
					<span class="image-text">{{slotProps.data.secteur}}</span>
				</template>
			</DataTable>
			<div class="table">
				<table id='synoptiquers' style='visibility: collapse;'>
					<tbody>
						<tr>
							<th>Id</th>
							<th>{{ $t('residence') }}</th>
							<th>{{ $t('adresse') }}</th>
							<th>{{ $t('ville') }}</th>
							<th>{{ $t('gestionnaire') }}</th>
							<th>{{ $t('capacite') }}</th>
							<th>{{ $t('ouverture') }}</th>
							<th>{{ $t('cible') }}</th>
							<th>{{ $t('nature') }}</th>
						</tr>
						<tr v-for="prog in syno"  v-bind:key="prog.id" :class="{trprog:!prog.rupture}" style="height:46px;">
							<td v-if="prog.rupture" class="rupture" colspan=20>{{prog.rupture}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.id}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nom}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.adresse}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.ville}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.gestionnaire}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.capacite}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.ouverture}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.cible}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nature}}</td>
						</tr>
					</tbody>
				</table>
				<br>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import ImagePreview from 'primevue/imagepreview';
import SpeedDial from 'primevue/speeddial';
import Button from 'primevue/button';

export default {
	name: 'ArraySynoptiqueRs',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'synoptiquers', name: 'Synoptique des résidences services'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'synors'});} },
			],
			expandedRowGroups: [],
			selectedPrograms: null,
			secteurencours: '',
			tab: 'mois'
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		synojson:function(){
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_rs.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique_rs[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_rs[i].tableau[k]
					p.secteur = this.app.livedata.synoptique_rs[i].secteur
					p.rupture= false
					tabsyno.push(p);
				}
			}
			return tabsyno
		},

		syno:function(){
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_rs.length; i++){
				tabsyno.push({rupture : this.app.livedata.synoptique_rs[i].secteur})
				for(let k = 0; k < this.app.livedata.synoptique_rs[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_rs[i].tableau[k]
					p.rupture= false
					tabsyno.push(p);
				}
			}
			return tabsyno
		}
	}),

	mounted: function() {
		this.expandedRowGroups = this.app.livedata.tab_sr
	},

	methods: {
		supprime_selection: function() {
			let tabsyno = [];
			for(let ii = 0; ii < this.app.livedata.synoptique.length; ii++){
				for(let k = 0; k < this.app.livedata.synoptique[ii].tableau.length; k++){
					tabsyno.push(this.app.livedata.synoptique[ii].tableau[k]);
				}
			}
			let tabsyno_pc = [];
			for(let iipc = 0; iipc < this.app.livedata.synoptique_pc.length; iipc++){
				for(let kpc = 0; kpc < this.app.livedata.synoptique_pc[iipc].tableau.length; kpc++){
					tabsyno_pc.push(this.app.livedata.synoptique_pc[iipc].tableau[kpc]);
				}
			}
			let tabsyno_rs = [];
			for(let iirs = 0; iirs < this.app.livedata.synoptique_rs.length; iirs++){
				for(let krs = 0; krs < this.app.livedata.synoptique_rs[iirs].tableau.length; krs++){
					tabsyno_rs.push(this.app.livedata.synoptique_rs[iirs].tableau[krs]);
				}
			}
			let t = []
			let p = []
			let r = []
			for (let i in tabsyno_rs) {
				let b = true
				for (let j in this.selectedPrograms) {
					
					if (this.selectedPrograms[j].program_id == tabsyno_rs[i].program_id) {
						b = false
						break
					}
				}
				if (b) r.push({code : tabsyno_rs[i].program_id})
			}

			for (let ip in tabsyno) {
				t.push({code : tabsyno[ip].program_id})
			}
			for (let ir in tabsyno_pc) {
				p.push({code : tabsyno_pc[ir].program_id})
			}
			this.selectedPrograms = null
			this.$store.dispatch('report_by_id', { programs: t, pc: p, rss: r, view: this.app.source_view})
		},		
	},

	components: { DataTable, Column, ColumnGroup, Row, ImagePreview, SpeedDial, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.rupture
	background secondary
	color white
	font-weight 500 
	text-align center
	text-transform uppercase

.center
	text-align center
.right
	text-align right

:deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
:deep(.p-datatable.synoptique) {
	.p-rowgroup-header{
		background-color: #eeeeee !important;
		color: #333 !important;
		font-size: 1rem;
	}
	.p-rowgroup-header .image-text {
		margin-left: 10px;
	}
	.p-datatable-header {
		padding: 1rem;
		text-align: center;
		font-size: 1rem;
	}
	.p-column-title {
		text-align: center;
		font-size: 0.9rem;
	}
	.p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-datatable-tbody {
		font-size: 0.75rem;
	}
	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: center;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
		padding: 0.5rem 0.5rem
	}
}
.product-image {
	text-align: center;
	width: auto;
	max-height : 50px
}
	
</style>
