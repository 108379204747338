<template>
	<div class="program" :class="{ expanded: rpls == expanded_rpls, selected: rpls.selected }">
		<div class="program-main" @click="expand_rpls()">
			<div class="image">
				<img alt="Rpls" :src="rpls.urlphoto">
				<div style="display: flex;left: 4px;bottom: 0px;height: 100%;">
				</div>
			</div>
			<div class="program-content">
				<p class="name" v-if="rpls.COMPLGEO && !isInteger(rpls.COMPLGEO) && rpls.COMPLGEO!=rpls.DEPCOM_LIBELLE" :title="rpls.COMPLGEO">{{ rpls.COMPLGEO }}</p><p class="name" v-else>{{ rpls.NUMVOIE }}&nbsp;{{ rpls.NOMVOIE }}</p>
				<p class="city"><span v-if="rpls.NUMVOIE">{{ rpls.NUMVOIE }}&nbsp;</span><span v-if="rpls.NOMVOIE">{{ rpls.NOMVOIE }}</span></p>
				<p class="city"><span v-if="rpls.CODEPOSTAL">{{ rpls.CODEPOSTAL }} </span>{{ rpls.DEPCOM_LIBELLE }}</p>
				<p class="seller">{{rpls.nb_logement}} logement<span v-if="rpls.nb_logement>1">s</span><span>&nbsp;{{ rpls.TYPECONST_LIBELLE }}</span></p>
			</div>
		</div>
		<div v-if="false" class="cz">
			<label v-if="app.authorized&&!app.nolive" class="checkbox" :class="{ checked: false }"  :id="rpls.residence + '-' + rpls.DEPCOM_LIBELLE" :data="rpls.DEPCOM_LIBELLE">
				<input type="checkbox" :checked="rpls.selected" @click="select_rpls(rpls.residence + '-' + rpls.DEPCOM_LIBELLE); return false;">
				<span class="chk"><icon file="check" :size="16" /></span>
				<span class="text"></span>
			</label>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Rpls',

	data: function PaneRs() {
		return {
			expanded: false,
			filter: 'volume',
			filter2: 'typologie',
			messageprix: '',
		}
	},

	props: [ 'rpls' ],

	computed: Object.assign(mapGetters([ 'app', 'expanded_rpls', 'selected_rpls_secteurs' ]), {

	}),

	methods: {

		isInteger: function(str) {
			const num = Number(str);
			return Number.isInteger(num);
		},
		getNatureLibelle: function(nature) {
			if (nature.toUpperCase() == "RÉSIDENCE SERVICES ETUDIANTS (RSE) LIBRE") return 'ETUDIANTS'
			if (nature.toUpperCase() == "RÉSIDENCE SERVICES ETUDIANTS (RSE) MIXTE") return 'ETUDIANTS'
			if (nature.toUpperCase() == "RÉSIDENCE SERVICES ETUDIANTS (RSE) SOCIAL") return 'ETUDIANTS'

			if (nature.toUpperCase() == "FOYER JEUNES TRAVAILLEURS (FJT)") return 'JEUNES'
			if (nature.toUpperCase() == "RÉSIDENCE SERVICES ETUDIANTS (RSE) LIBRE") return 'JEUNES'
			if (nature.toUpperCase() == "FOYER JEUNES TRAVAILLEURS (FJT)") return 'JEUNES'
		},

		getPrice: function() {
			let nPrice=0
			if (this.program.tva_reduite_prix_m2_parking_avec > 0) {
				nPrice = this.program.tva_reduite_prix_m2_parking_avec
				this.messageprix = "Prix en TVA réduite parking inclus"
			} else {
				if (this.program.tva_reduite_prix_m2_parking_sans > 0) {
					nPrice = this.program.tva_reduite_prix_m2_parking_sans
					this.messageprix = "Prix en TVA réduite hors parking"
				} else {
					if (this.program.tva_pleine_libre_prix_m2_parking_avec > 0) {
						nPrice = this.program.tva_pleine_libre_prix_m2_parking_avec
						this.messageprix = "Prix en TVA pleine libre parking inclus"
					} else {
						if (this.program.tva_pleine_libre_prix_m2_parking_sans > 0) {
							nPrice = this.program.tva_pleine_libre_prix_m2_parking_sans
							this.messageprix = "Prix en TVA pleine libre hors parking"
						} else {
							if (this.program.tva_pleine_aide_prix_m2_parking_avec > 0) {
								nPrice = this.program.tva_pleine_aide_prix_m2_parking_avec
								this.messageprix = "Prix en TVA pleine aidée parking inclus"
							} else {
								if (this.program.tva_pleine_aide_prix_m2_parking_sans > 0) {
									nPrice = this.program.tva_pleine_aide_prix_m2_parking_sans
									this.messageprix = "Prix en TVA pleine aidée hors parking"
								}
							}
						}
					}
				}
			}
			const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		getAvecStat: function() {
			return (!this.program.tva_reduite_prix_m2_parking_avec && !this.program.tva_pleine_libre_prix_m2_parking_avec && !this.program.tva_pleine_aide_prix_m2_parking_avec)
		},

		DonneTrimestre: function(val) {
			let DateTrim
			let t = String(val).substr(4,2)
			let a = String(val).substr(0,4)
			switch(parseInt(t))
			{
				case 1:
				case 2:
				case 3:
					DateTrim = "1T " + a; 
					break;
				case 4:
				case 5:
				case 6:
					DateTrim = "2T " + a; 
					break;
				case 7:
				case 8:
				case 9:
					DateTrim = "3T " + a; 
					break;
				case 10:
				case 11:
				case 12:
					DateTrim = "4T " + a; 
					break;
			}
			return DateTrim
		},

		DonneValeurPrix: function(val, fin) {
			if (val==0) {
				return "-"
			} else {
				return val + fin
			}
		},

		DonneValeurPrix1000: function(val) {
			if (val==0) {
				return "-"
			} else {
				return Math.round(val/1000)
			}
		},

		expand_rpls: function() {
			if (this.expanded_rpls != this.rpls) {
				this.$store.dispatch('expand_rpls', { rpls: this.rpls })
				this.$router.push({ name: 'rpls', params: { id: this.rpls.residence }}).catch(()=>{});
			} else {
				this.$store.dispatch('expand_rpls', { rpls: false })
				this.$router.push({ name: 'rpls'}).catch(()=>{});
			}
		},

		select_rpls: function(id) {
			let selected = false
			if (document.getElementById(id).className == 'checkbox checked') {
				document.getElementById(id).className = 'checkbox'
				selected = false
				this.$store.dispatch('dec_nbrplsselect', {})
			} else {
				document.getElementById(id).className = 'checkbox checked'
				selected = true
				this.$store.dispatch('inc_nbrplsselect', {})
			}
			for (let i in this.app.rplss) {
				if ((this.app.rplss[i].id + "-" + this.app.rplss[i].DEPCOM_LIBELLE)==id) {
					if (this.app.rplss[i].marker) {
						if (selected) {
							this.app.rplss[i].marker.classList.add('selected')
							this.app.rplss[i].marker.classList.remove('invisible')
						} else {
							this.app.rplss[i].marker.classList.remove('selected')
							if (this.app.uniquementselection) this.app.rplss[i].marker.classList.add('invisible')
						}
					}
				}
			}
		},

		show_program_on_map: function() {
			this.$store.dispatch('center_map', { coords: { lat: this.program.location.lat, lng: this.program.location.lon }, zoom: 16 })
		},
		
		print: function() {
			this.$store.dispatch('set_print', { print: 'expanded-program' })
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'


.cz
	position absolute
	right 4px !important
	top 12px !important
.sp
	position absolute
	padding 5px 6px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 10px
	line-height 4px
	color rgba(0, 0, 0, 0.87)
	margin-right 2px
	height 20px
	left 0
	bottom 2px
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px

.BRS
	width 36px

.program
	border-top 1px solid #eee
	border-right: 4px solid white;
	background #FFF
	&.inactif
		.program-main			
			.program-content
				.name
					color #666
	&.expanded
		border-right 4px solid lighten(primaryA100,92%) !important
		&:hover
			.program-main
				background lighten(primaryA100,92%)
		.program-main
			background lighten(primaryA100,92%)
			.program-content
				.name, .prix
					color palette2 !important
	&:hover
		border-right 4px solid hsl(153deg 48% 49%)
	&.disabled, &.disabled:hover
		.program-main
			background #fff
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		//background #fff
		padding 16px
		overflow hidden
		cursor pointer
		.image
			position absolute
			left 8px
			top 16px
			height calc(100% - 22px)
			width 114px
			border-radius 12px
			overflow hidden
			background #eee url(../../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 114px
			.name
				color #333 !important
				font-weight 600
				height 24px
				font-size 13px
				line-height 24px
				white-space nowrap
				width 174px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				text-transform uppercase
				font-size 12px
				width 174px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
			.gen
				font-size 12px
				text-align center
				line-height 28px
				background-color #666
				color #fff
				border-radius 16px
				height 32px
				width 32px
		.price
			position absolute
			left 10px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		background #fff
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							border-bottom 1px solid #eee
							&:last-child
								border none
							&:hover
								td
									background #eee
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight 500
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0


.prix
	text-align right
	font-weight 600
	color #000

.BRS
	padding 4px 8px
	width 44px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.PSLA
	padding 4px 8px
	width 52px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.tvaR
	padding 4px 8px
	width 44px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	color rgba(0, 0, 0, 0.87)
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
.sansp
	padding 4px 12px
	width 110px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.vide
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	font-size 12px
	line-height 16px
	letter-spacing 0.4px

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
